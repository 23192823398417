import React, { MutableRefObject } from "react";

export const Iframe: React.FC<
  React.PropsWithChildren<React.IframeHTMLAttributes<HTMLIFrameElement>> & {
    className?: string;
    iframeRef?: MutableRefObject<HTMLIFrameElement>;
  }
> = ({ children, ...props }) => {
  return (
    <iframe
      {...props}
      id="email-preview"
      ref={props.iframeRef}
      title="Email Preview"
    >
      <meta name="color-scheme" content="light dark" />
      {children}
    </iframe>
  );
};
