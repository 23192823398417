import styled from "styled-components";
import { Body2, H2, Subtitle1 } from "melodies-source/Text";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import { GoogleMap } from "./GoogleMap";
import zips from "./zips.json";
import { Card } from "Components/Card";
import { Spinner } from "melodies-source/Spinner";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const FanLocations = () => {
  const { event, eventLoading, entriesZips, entriesLoading } =
    useArtistEventContext();

  const { customApp } = useCustomAppContext();
  const eventAudiencePlural = customApp?.event.audience.plural || "Fans";

  const points = entriesZips
    ? Object.entries(entriesZips)?.map(([key, value]) => {
        const { lat, lng } = zips[key] || {};
        const options = {
          center: { lat, lng },
          radius: Math.min(6000, Math.pow(value || 0, 0.8) * 500),
        };
        return options;
      })
    : [];

  const center = event?.coordinates;

  return (
    <StyledCard isElevated>
      <H2>{eventAudiencePlural} By Location</H2>
      <Body2>
        A quick look at where tonight's {eventAudiencePlural.toLowerCase()} are
        from around the venue
      </Body2>
      <Map>
        {eventLoading || entriesLoading ? (
          <Spinner />
        ) : (
          <GoogleMap
            center={{ lat: center.latitude, lng: center.longitude }}
            points={points}
          />
        )}
      </Map>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 32px;

  ${H2} {
    color: var(--header-text-color);
    text-align: center;
  }
  ${Body2} {
    color: var(--secondary-text-color);
    text-align: center;
    font-size: 14px;
  }
  & > ${Body2} {
    color: var(--secondary-text-color);
    margin-top: 15px;
    text-align: center;
  }
  ${Subtitle1} {
    color: var(--text-color);
    margin-top: 4px;
    margin-bottom: 12px;
    text-align: center;
  }
`;

const Map = styled.div`
  width: 100%;
  height: 280px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  margin-top: 24px;
  overflow: hidden;
`;
