import { ReactComponent as TemplateIcon } from "assets/svg/theStoryBehind.svg";
import { SurveyTemplate } from "../../types";

export const theStoryBehind: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "The Story Behind…",
  description: "Allow fans to vote on which song you tell the story behind.",
  templateId: "theStoryBehind",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "The Story Behind…",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "Which song's backstory do you want to hear?",
        },
        type: "SingleSelectQuestion",
        actionRequired: true,
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-livestream-ba24ed"
        : "pp-livestream-50566a",
    fields: [
      {
        name: "date",
        type: "text",
        label: "When does your livestream take place?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.date ? false : "This field cannot be left blank",
      },
    ],
  },
};
