import { Body2, H3 } from "melodies-source/Text";
import { PropsWithChildren, ReactElement } from "react";
import styled from "styled-components";

const Container = styled.div`
  border-radius: 12px;
  background: #f2f5f7;
  padding: 20px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 16px 20px 28px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
  position: relative;

  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 8px;
  }
`;

const Icon = styled.div`
  display: flex;
  svg {
    color: #1b0076;
    width: 20px;
    height: 20px;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

const Title = styled(H3)`
  color: ${(p) => p.theme.colors.navy};
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 16px;
    line-height: 24px;
  }
`;

const HelpText = styled(Body2)<{ error?: boolean }>`
  margin-left: auto;
  color: ${(p) => p.error && p.theme.colors.error};
  ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;

const Content = styled.div<{ padded?: boolean }>`
  text-align: left;
  ${({ theme }) => theme.mediaQueries.desktop} {
    padding-left: ${(p) => p.padded && "36px"};
  }
`;

interface QuestionCardProps {
  title: string;
  icon: ReactElement;
  helpText?: string;
  hasError?: boolean;
  hasPadding?: boolean;
}

export const QuestionCard = ({
  title,
  icon,
  helpText,
  hasError,
  hasPadding = true,
  children,
}: PropsWithChildren<QuestionCardProps>) => {
  return (
    <Container>
      <Header>
        <Icon>{icon}</Icon>
        <Title>{title}</Title>
        {helpText && <HelpText error={hasError}>{helpText}</HelpText>}
      </Header>
      <Content padded={hasPadding}>{children}</Content>
    </Container>
  );
};
