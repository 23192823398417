import { PageHeaderImage, PageTitle, useArtistContext } from "Components";
import styled, { css } from "styled-components";
import { useHistory } from "react-router";
import ProgressCircle from "Components/ProgressCircle";
import StatusCard from "./StatusCard";
import { DateTime } from "luxon";
import { truncateString } from "Utils";
import LiveNow from "Components/RecordingIcon";
import { useNextShow, useDesktopMediaQuery, useTimeDiff } from "hooks";
import { svg } from "svg";
import Skeleton from "react-loading-skeleton";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { formatNumber } from "Utils/format";
import { H1, H4 } from "melodies-source/Text";

export const StatusCards = () => {
  const { privateData, privateProfileData } = useArtistContext();
  const data = privateData?.data();
  const profileData = privateProfileData?.data();

  const profileCompletionPercentage = Math.round(
    (profileData?.completion?.total || 0) * 100,
  );

  const { nextShow, loading: loadingNextShow } = useNextShow();
  const { customApp } = useCustomAppContext();

  const displayAddress =
    nextShow && truncateString(nextShow.displayAddress || nextShow.address);

  return (
    <Container>
      <PageHeaderImage isExtended />
      <Content hasNextShow={!!nextShow}>
        <PageTitle>Dashboard</PageTitle>
        <CardsContainer>
          {loadingNextShow ? (
            <NextShowLoader />
          ) : (
            !!nextShow && (
              <NextShow
                nextEventDate={nextShow.startsAt}
                nextEventLocation={displayAddress}
                nextEventVenue={nextShow.venue}
                eventId={nextShow.id}
              />
            )
          )}

          <Contacts totalContacts={data?.totalContacts} />
          {!customApp && (
            <FanProfile percentageComplete={profileCompletionPercentage} />
          )}
        </CardsContainer>
      </Content>
    </Container>
  );
};

const Contacts = ({ totalContacts }: { totalContacts?: number }) => {
  const { id: artistGroupId } = useArtistContext();
  const history = useHistory();

  return (
    <ContactsStatusCard
      footerText="View Contacts"
      onClickFooter={() => history.push(`/${artistGroupId}/fans`)}
    >
      <HeaderText>Contacts</HeaderText>
      <ContactsNumberContainer>
        <StyledContactsIcon />
        <NumberText>{formatNumber(totalContacts ?? 0)}</NumberText>
      </ContactsNumberContainer>
    </ContactsStatusCard>
  );
};

const FanProfile = ({ percentageComplete }: { percentageComplete: number }) => {
  const { id: artistGroupId } = useArtistContext();
  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();

  return (
    <FanProfileStatusCard
      footerText="See Fan Profile"
      onClickFooter={() =>
        history.push(`/${artistGroupId}/reporting/fan-profile`)
      }
    >
      <HeaderText style={{ marginBottom: isDesktop ? 20 : 0 }}>
        Fan Insights
      </HeaderText>
      <ProfileCompletionContainer>
        <StyledProgressCircle percentage={percentageComplete} color="#953a93" />
        <ProfileTextContainer>
          <PercentageText>{percentageComplete}%</PercentageText>
          <StatusText>Complete</StatusText>
        </ProfileTextContainer>
      </ProfileCompletionContainer>
    </FanProfileStatusCard>
  );
};

const FanProfileStatusCard = styled(StatusCard)(({ theme }) => {
  if (!theme.custom) return null;
  const { background, color } = theme.custom.pages.dashboard.cards.fanProfile;
  return css`
    background: ${background};

    * {
      color: ${color} !important;
    }

    rect {
      fill: ${color} !important;
    }
  `;
});

const StyledProgressCircle = styled(ProgressCircle)`
  ${({ theme }) => theme.media.mobile} {
    width: 55px;
    margin-right: 6px;
  }
`;

const NextShowLoader = () => {
  const isDesktop = useDesktopMediaQuery();
  const { customApp } = useCustomAppContext();

  return (
    <NextShowCard
      footerText={`loading next ${
        customApp?.event.type.singular.toLowerCase() || "show"
      }...`}
    >
      <HeaderText>
        <Skeleton width={125} />
      </HeaderText>
      {isDesktop && (
        <NextEventVenue>
          <Skeleton width={150} />
        </NextEventVenue>
      )}
      <TimeUntilContainer>
        <TimeUntilText>
          <Skeleton width={140} />
        </TimeUntilText>
      </TimeUntilContainer>
      <div>
        <StatusText>
          <Skeleton width={isDesktop ? 100 : 200} />
        </StatusText>
      </div>
    </NextShowCard>
  );
};

const NextShow = ({
  eventId,
  nextEventDate,
  nextEventLocation,
  nextEventVenue,
}: {
  eventId: string;
  nextEventDate: number;
  nextEventLocation: string;
  nextEventVenue: string;
}) => {
  const isDesktop = useDesktopMediaQuery();
  const { customApp } = useCustomAppContext();

  const { diff: timeUntilEvent, time } = useTimeDiff({
    eventTime: nextEventDate,
  });

  const isLiveNow = nextEventDate < time.toMillis();

  return (
    <NextShowCard
      footerText={`View  ${customApp?.event.type.singular || "Show"}`}
      onClickFooter={() =>
        window.open(
          `${
            customApp?.onsiteAppUrl || process.env.REACT_APP_BS_URL
          }/${eventId}`,
        )
      }
    >
      <HeaderText>
        {isLiveNow
          ? `Current ${customApp?.event.type.singular || "Show"}`
          : `Next  ${customApp?.event.type.singular || "Show"}`}
      </HeaderText>
      {isDesktop && <NextEventVenue>{nextEventVenue}</NextEventVenue>}
      <TimeUntilContainer>
        <TimeUntilText>
          {!isLiveNow && timeUntilEvent.displayString}
        </TimeUntilText>
        {isLiveNow && <LiveNow label={<LiveNowText>Live Now</LiveNowText>} />}
      </TimeUntilContainer>
      <div>
        {!isDesktop && (
          <NextEventVenue style={{ marginRight: 5 }}>
            {nextEventVenue}
          </NextEventVenue>
        )}
        <StatusText>{truncateString(nextEventLocation, 50)}</StatusText>
        <StatusText>
          {" "}
          {DateTime.fromMillis(nextEventDate).toLocaleString(
            DateTime.TIME_SIMPLE,
          )}
        </StatusText>
      </div>
    </NextShowCard>
  );
};

const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 12px;

  ${({ theme }) => theme.mediaQueries.desktop} {
    display: flex;
    gap: 20px;
    max-width: 1300px;
  }
`;

const ContactsNumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.mobile} {
    align-items: center;
    flex-direction: row;
  }
`;

const ContactsStatusCard = styled(StatusCard)`
  ${({ theme }) => {
    if (!theme.custom) return null;
    const { background, color } =
      theme.custom.pages.dashboard.cards.fanContacts;
    return css`
      background: ${background};

      * {
        color: ${color} !important;
      }

      rect,
      path {
        fill: ${color} !important;
      }
    `;
  }}
`;

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div<{ hasNextShow?: boolean }>`
  position: relative;
  padding: 40px 40px 32px;
  ${H1} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.media.mobile} {
    padding: 20px;
    ${H1} {
      margin-bottom: 20px;
    }
  }
`;

const HeaderText = styled.span`
  font-family: Poppins;
  font-size: 25px;
  line-height: 35px;
  margin-bottom: 10px;
  font-weight: 700;
  color: var(--main-color);

  ${({ theme }) => theme.media.mobile} {
    font-size: 14.3px;
    line-height: 25.7px;
    margin-bottom: 0px;
  }
`;

const LiveNowText = styled.div`
  font-family: Poppins;
  color: #953a93;
  font-size: min(3vw, 45px);
  line-height: 150%;
  font-weight: 700;

  ${({ theme }) => theme.media.mobile} {
    font-size: 25.6px;
    line-height: 38.4px;
  }
`;

const NextEventVenue = styled(H4)`
  font-weight: 600;
  color: #1b0076;
`;

const NextShowCard = styled(StatusCard)`
  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-column: 1 / span 2;
  }

  ${({ theme }) => {
    if (!theme.custom) return null;
    const { background, color } = theme.custom.pages.dashboard.cards.nextShow;
    return css`
      background: ${background};

      * {
        color: ${color} !important;
      }

      rect {
        fill: ${color} !important;
      }
    `;
  }}
`;

const NumberText = styled.div`
  font-family: Poppins;
  color: #953a93;
  font-size: 40px;
  line-height: 67.5px;
  font-weight: 700;

  ${({ theme }) => theme.media.mobile} {
    font-size: 28px;
    line-height: 38.4px;
    margin-bottom: -10px;
  }
`;

const PercentageText = styled(NumberText)`
  margin-left: 5px;
  line-height: 55px;

  ${({ theme }) => theme.media.mobile} {
    margin-left: 0px;
    margin-top: 5px;
    line-height: 35px;
  }
`;

const ProfileCompletionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.mobile} {
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

const ProfileTextContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.desktop} {
    position: absolute;
    top: 100px;
  }

  ${({ theme }) => theme.media.mobile} {
    height: 50px;
  }
`;

const StatusText = styled(H4)`
  color: #1b0076;
`;

export const StyledContactsIcon = styled(svg.ContactsIcon)`
  margin-top: 10px;
  margin-bottom: -15px;
  width: 44px;
  height: 44px;

  ${({ theme }) => theme.media.mobile} {
    margin-right: 10px;
    margin-bottom: -10px;
    width: 40px;
    height: 40px;
  }
`;

const TimeUntilContainer = styled.div`
  position: relative;
`;

const TimeUntilText = styled(NumberText)`
  margin-bottom: 5px;

  ${({ theme }) => theme.media.mobile} {
    margin-bottom: 0px;
  }
`;
