import { useArtistContext } from "Components";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { useState } from "react";

type UseCreateSetLiveEvent = {
  artistId: string;
};

export const useCreateSetLiveEvent = ({ artistId }: UseCreateSetLiveEvent) => {
  const [loading, setLoading] = useState(false);
  const { logAction } = useArtistContext();
  const createEvent = async () => {
    try {
      setLoading(true);
      const ref = await addDoc(
        collection(getFirestore(), `set_fresh_builder_events`),
        { artistGroupId: artistId },
      );
      await logAction("portal_create_event", { event: { id: ref.id } });
      window.location.href = `/set-live-creator/b/${ref.id}/theme?ref=ap`
    } catch (err: any) {
      alert("Error creating new event: " + err.message);
    } finally {
      setLoading(false);
    }
  };

  return { createEvent, loading };
};
