import { useState, useEffect } from "react";
import { getAuth, onIdTokenChanged } from "firebase/auth";

export default function useUserIdToken() {
  const [accessToken, setAccessToken] = useState("");

  useEffect(
    () =>
      onIdTokenChanged(getAuth(), (user) =>
        user?.getIdToken().then((token) => setAccessToken(token)),
      ),
    [],
  );

  return accessToken;
}
