import { FlexColumn } from "Components/Flex";
import styled from "styled-components";
import { Body2, H4 } from "melodies-source/Text";
import { SvgLockAlt } from "melodies-source/Svgs/LockAlt";
import { StyledCheck } from "./StyledCheck";
import { CSSProperties } from "react";

export type SelectionCardProps = {
  header?: string;
  icon?: JSX.Element;
  onClick?: () => void;
  selected?: boolean;
  subHeader?: string;
  className?: string;
  locked?: boolean;
  children?: React.ReactNode;
  style?: CSSProperties;
};

export const SelectionCard = ({
  header,
  subHeader,
  icon,
  onClick,
  selected,
  className,
  locked,
  children,
  ...props
}: SelectionCardProps) => {
  return (
    <Container
      {...props}
      selected={selected || locked}
      className={className}
      onClick={onClick}
      {...(locked && { style: { pointerEvents: "none" } })}
    >
      <Content>
        {locked ? <StyledLock /> : selected ? <StyledCheck /> : null}
        {!!icon && <IconContainer>{icon}</IconContainer>}
        <SelectionCardHeaderContainer>
          {header && <H4>{header}</H4>}
          {subHeader && <Body2>{subHeader}</Body2>}
        </SelectionCardHeaderContainer>
      </Content>
      <SelectionCardBody>{children}</SelectionCardBody>
    </Container>
  );
};

export const SelectionCardBody = styled.div``;

export const SelectionCardHeaderContainer = styled(FlexColumn)``;

export const IconContainer = styled.div`
  margin-right: 16px;
  width: 44px;
  display: flex;
  justify-content: center;
`;

const StyledLock = styled(SvgLockAlt)`
  position: absolute;
  top: -8px;
  right: -5px;
  border-radius: 13px;
  background-color: #1b0076;
  width: 26px;
  height: 26px;
  color: #ffffff;
  padding: 4px;
`;

type ContainerProps = {
  selected?: boolean;
  disabled?: boolean;
};

const Content = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.mediaQueries.mobile} {
    align-items: flex-start;
  }
`;

const Container = styled.div<ContainerProps>`
  position: relative;
  background: #f4f8fa;
  border-radius: 24px;
  padding: 12px 24px;
  border: 2px solid #f4f8fa;

  display: flex;
  flex-direction: column;
  justify-content: center;

  cursor: pointer;

  ${({ selected }) => selected && "border-color: #1B0076"};

  color: #1b0076;
  ${H4} {
    color: #1b0076;
  }
  ${Body2} {
    color: #666666;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    border-radius: 12px;
    padding: 6px 12px 8px 8px;
    ${H4} {
      font-size: 14px;
      line-height: 20px;
    }
    ${Body2} {
      font-size: 10px;
      line-height: 16px;
    }
    ${IconContainer} {
      margin-right: 12px;
      width: 32px;
      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
`;
