import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { useLocation } from "react-router-dom";
import { TextInput } from "melodies-source/TextInput";
import { SvgSearch } from "melodies-source/Svgs/Search";
import { Spinner } from "melodies-source/Spinner";
import { FlexColumn } from "Routes/styled";
import { EventCard } from "Components/EventCard";
import { svg } from "svg";
import Pagination from "Components/Pagination";
import DropdownMenu from "Components/DropdownMenu";
import toast from "react-hot-toast";
import { getFirestore, doc, getDoc, collection } from "firebase/firestore";
import { useDialog } from "Components/DialogProvider";
import { FlexRow } from "Components/Flex";
import { IconQRCode } from "Components";
import {
  FilterOptions,
  RESULTS_PER_PAGE,
  useSetLiveEventsContext,
} from "contexts/SetLiveEventsContext";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { ReactComponent as CalendarIcon } from "assets/svg/calendar.svg";

export const Shows = () => {
  const { setDialog } = useDialog();

  const {
    searchInput,
    currentPage,
    filterOption,
    setSearchInput,
    setCurrentPage,
    setFilterOption,
    setLiveEvents,
    type,
    totalEvents,
    loading,
    deleteEvent,
    updateEvent,
  } = useSetLiveEventsContext();
  const { customApp, language } = useCustomAppContext();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      if (location.search) {
        const params = new URLSearchParams(location.search);
        if (params.get("b")) {
          // TODO: Add actual publish check once publish status is implemented
          const event = await getDoc(
            doc(
              collection(getFirestore(), "set_fresh_events"),
              params.get("b"),
            ),
          );
          if (event.exists()) {
            toast.success(
              "Your experience has been published and is ready to use!",
            );
          }
        }
      }
    })();
  }, [location]);

  const filterOptions = [
    { label: "Newest", value: "newest" },
    { label: "Oldest", value: "oldest" },
    ...(type === "upcoming"
      ? [
          { label: "Published", value: "published" },
          { label: "Drafts", value: "drafts" },
        ]
      : []),
  ];

  useEffect(() => {
    setCurrentPage(1);
  }, [searchInput, type, filterOption]);

  useEffect(() => {
    setFilterOption(filterOptions[0].value as FilterOptions);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [currentPage]);

  return (
    <>
      <FilterContainer>
        <SearchInput
          value={searchInput}
          onChange={setSearchInput}
          placeholder={`Enter Venue to Search for ${language.event.type.singularWithArticle}`}
          leftIcon={<SvgSearch />}
        />
        <MenuContainer>
          <QRLink onClick={() => setDialog({ qrCode: true })}>
            <IconQRCode style={{ marginRight: 8 }} />
            {language.event.owner.singular} QR Code
          </QRLink>
          <StyledDropdownMenu
            options={filterOptions}
            value={filterOption}
            onClick={(val) => setFilterOption(val as FilterOptions)}
            styles={{
              label: menuLabelStyle,
              option: menuOptionStyle,
              arrow: menuArrowStyle,
            }}
          />
        </MenuContainer>
      </FilterContainer>
      <ShowsContainer>
        {loading ? (
          <Spinner style={{ justifySelf: "center" }} />
        ) : totalEvents === 0 ? (
          searchInput ? (
            <NoShowsContainer>
              <LargeSearchIcon />
              <NoShowsText>
                No {language.event.type.plural.toLowerCase()} match your
                criteria. Try using a different term.
              </NoShowsText>
            </NoShowsContainer>
          ) : (
            <NoShowsContainer>
              {customApp ? (
                <CalendarIcon width={80} height={80} />
              ) : (
                <MusicIcon />
              )}
              <NoShowsText>
                {customApp ? "No" : "This artist has no"} {type}{" "}
                {language.event.type.plural.toLowerCase()} to display.
              </NoShowsText>
            </NoShowsContainer>
          )
        ) : (
          <>
            {setLiveEvents?.map((show) => (
              <EventCard
                section="set.live"
                event={show}
                showType={type}
                key={`event-${show.id}`}
                onDelete={deleteEvent}
                onUpdate={updateEvent}
              />
            ))}
            <PaginationContainer>
              <Pagination
                selectedPage={currentPage}
                resultsPerPage={RESULTS_PER_PAGE}
                totalResults={totalEvents}
                onSetPage={setCurrentPage}
                scrollOnChange
              />
            </PaginationContainer>
          </>
        )}
      </ShowsContainer>
    </>
  );
};

const StyledDropdownMenu = styled(DropdownMenu)`
  order: 1;
  ${({ theme }) => theme.mediaQueries.desktop} {
    order: 2;
  }
`;

const MenuContainer = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  ${({ theme }) => theme.mediaQueries.desktop} {
    margin-top: 0;
    height: 42px;
    justify-content: flex-end;
  }
`;

const QRLink = styled.div`
  display: flex;
  align-items: center;
  color: var(--main-color);
  order: 2;
  font-weight: bold;
  cursor: pointer;
  ${({ theme }) => theme.mediaQueries.desktop} {
    order: 1;
    margin-right: 32px;
  }
`;

const menuArrowStyle = css`
  width: 25px;
  height: 25px;
`;

const menuOptionStyle = css`
  font-size: 14px;
`;

const menuLabelStyle = css`
  font-size: 15px;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 17px 0;
  margin: 0 10px;

  ${({ theme }) => theme.media.mobile} {
    padding: 0 0 20px 0;
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 39px;
`;

const LargeSearchIcon = styled(SvgSearch)`
  width: 110px;
  height: 110px;
  color: #e8e5f1;
`;

const MusicIcon = styled(svg.MusicAltIcon)`
  margin-bottom: 25px;
`;

const NoShowsContainer = styled(FlexColumn)`
  align-items: center;
  background: var(--component-background-color);
  padding: 52px 53px 60px 53px;
  max-width: 372px;
  margin: 80px auto;
  gap: 5px;
`;

const NoShowsText = styled.p`
  color: var(--text-color);
  text-align: center;
  line-height: 1.4;
`;

const NoMatchesCard = () => (
  <NoShowsContainer>
    <LargeSearchIcon />
    <NoShowsText>
      No shows match your criteria. Try using a different term.
    </NoShowsText>
  </NoShowsContainer>
);

const SearchInput = styled(TextInput)`
  max-width: 380px;
`;

const ShowsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  flex-grow: 1;
  margin: 40px 10px 20px;
  align-items: flex-start;

  & > div:nth-last-child(n + 2) {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.media.mobile} {
    margin: 0;
  }
`;
