import { TooltipProps } from "recharts";
import { ChartDefinition } from ".";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import styled from "styled-components";
import { FlexColumn, FlexRow } from "Components/Flex";
import { Body1, Subtitle2 } from "melodies-source/Text";
import { ReactComponent as ChartLineSymbol } from "assets/svg/chart-legend-line.svg";

type CustomTooltipProps = {
  defs?: ChartDefinition[];
} & TooltipProps<ValueType, NameType>;

export const CustomTooltip = ({
  active,
  payload,
  label,
  defs,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <TooltipHeader>
          <Subtitle2>{label}</Subtitle2>
        </TooltipHeader>
        <TooltipLabels>
          {payload.map((p) => {
            const definition: ChartDefinition = defs?.find(
              ({ dataKey }) => dataKey === p.dataKey,
            );
            let value = p.value;
            if (definition.formatValue) {
              value = definition.formatValue(p);
            }
            return (
              <TooltipLabelContainer>
                {definition.chartType === "line" ? (
                  <ChartLineSymbol
                    style={{
                      color: definition.fill || p.payload.fill,
                      marginLeft: -2,
                    }}
                  />
                ) : (
                  <TooltipLabelColor
                    style={{
                      backgroundColor: definition.fill || p.payload.fill,
                    }}
                  />
                )}
                <TooltipLabel>
                  {definition?.tooltipLabel || definition?.label || p.dataKey}
                </TooltipLabel>
                <TooltipValue>{value.toLocaleString()}</TooltipValue>
              </TooltipLabelContainer>
            );
          })}
        </TooltipLabels>
      </TooltipContainer>
    );
  }

  return null;
};

const TooltipHeader = styled(FlexRow)`
  padding: 5px 10px 4px;
  border-bottom: 1px solid #e6e9eb;
  justify-content: center;
  ${Subtitle2} {
    color: #1b0076;
    font-weight: 500;
    text-align: center;
  }
`;

const TooltipContainer = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.25);
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const TooltipLabelContainer = styled.div`
  display: grid;
  grid-template-columns: 14px 1fr 50px;
  column-gap: 2px;
  padding: 2px 10px;
  justify-content: flex-start;
  align-items: center;
`;

const TooltipLabelColor = styled.div`
  border-radius: 50%;
  width: 9px;
  height: 9px;
`;

const TooltipLabels = styled(FlexColumn)`
  padding: 3px 0 2px;
`;

const TooltipLabel = styled(Body1)`
  color: #8f8f8f;
  font-size: 12px;
  font-weight: 500;
`;

const TooltipValue = styled(Body1)`
  font-size: 12px;
  color: #4c4c4c;
  font-weight: 500;
  text-align: right;
`;
