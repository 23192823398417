import React from "react";
import styled, { css } from "styled-components";
import { Caption, Label } from "../Text";

export const HTMLTextearea = styled.textarea`
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;
  font-family: var(--max-font-primary);
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;

  border-radius: 6px;
  background: #ffffff;
  border: solid 1px #cccccc;
  border-radius: 6px;
  padding: 10px 12px;

  resize: none;
  height: 86px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #000000;

  ::placeholder {
    font-size: 15px;
    color: #999999;
    ${(p: any) =>
      p.isDisabled &&
      css`
        color: #e6e9eb;
      `};
  }
  :focus {
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.24);
  }

  ${(p: any) =>
    p.isDisabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: #999999;
      border: solid 1px #e6e9eb;
      ${p.secondary &&
      css`
        color: rgba(255, 255, 255, 0.35);
      `}
    `};

  ${(p: any) =>
    p.hasError &&
    css`
      padding-right: 50px;
      border: solid 1px ${(p) => p.theme.colors.error};
    `};
  /* ${Caption} {
    ${(p: any) =>
    p.hasError &&
    css`
      color: #e71e3d;
    `}
  } */
`;

export const TextAreaLabelWrapper = styled.div`
  width: calc(100% - 24px);
  height: 36px;
  padding-bottom: 5px;
  background-color: white;
  position: absolute;
  top: 1px;
  ${(props: any) =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `};
`;
type Wrapper = {
  secondary?: boolean;
  disabled?: boolean;
};
export const TextareaWrapper = styled.div<Wrapper>`
  ${Label} {
    color: var(--text-color);
    margin-bottom: 4px;
    ${(p) =>
      p.secondary &&
      css`
        color: rgba(255, 255, 255, 0.35);
      `}
  }
`;

type Props = {
  label?: any;
  name?: string;
  placeholder?: string;
  value: any;
  onChange: (v: string) => void;
  hasError?: boolean;
  helperText?: string;
  onBlur?: any;
  style?: any;
  secondary?: boolean;
  disabled?: boolean;
  asString?: boolean;
  rows?: number;
};

export const Textarea = ({
  label = null,
  name,
  placeholder = "",
  value,
  onChange,
  hasError = false,
  helperText,
  onBlur = null,
  secondary,
  disabled,
  asString = true,
  rows,
  ...props
}: Props) => {
  return (
    <TextareaWrapper {...{ disabled, secondary }} {...props}>
      {label && <Label>{label}</Label>}
      <div style={{ marginTop: "4px" }}>
        <HTMLTextearea
          {...{
            name,
            onBlur,
            hasError,
            placeholder,
            disabled,
            secondary,
            rows,
          }}
          value={value}
          onChange={(e) => {
            onChange(asString ? e.target.value : (e as any));
          }}
        />
        {(helperText || hasError) && (
          <Caption hasError={hasError} style={{ marginTop: "4px" }}>
            {helperText}
          </Caption>
        )}
      </div>
    </TextareaWrapper>
  );
};
