import { useCustomAppContext } from "contexts/CustomAppContext";

const MapPinIcon = ({ className }: { className?: string }) => {
  const { customApp } = useCustomAppContext();

  return (
    <svg
      width="93"
      height="93"
      viewBox="0 0 93 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="46.5"
        cy="46.5"
        r="46.5"
        fill={customApp?.theme.icon.background || "#E8E5F1"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.5 22.2998C55.9382 22.2998 64.4 30.3587 64.4 40.2998C64.4 50.2409 56.84 61.8998 45.5 72.6998C34.16 61.8998 26.6 50.2409 26.6 40.2998C26.6 30.3587 35.0618 22.2998 45.5 22.2998ZM45.5 25.4498C36.7681 25.4498 29.75 32.1337 29.75 40.2998C29.75 48.1717 35.4252 58.1349 45.5 68.291C55.5748 58.1349 61.25 48.1717 61.25 40.2998C61.25 32.1337 54.2319 25.4498 45.5 25.4498ZM45.5 28.5998C52.4588 28.5998 58.1 34.241 58.1 41.1998C58.1 48.1586 52.4588 53.7998 45.5 53.7998C38.5412 53.7998 32.9 48.1586 32.9 41.1998C32.9 34.241 38.5412 28.5998 45.5 28.5998ZM45.5 31.7498C40.2809 31.7498 36.05 35.9807 36.05 41.1998C36.05 46.4189 40.2809 50.6498 45.5 50.6498C50.7191 50.6498 54.95 46.4189 54.95 41.1998C54.95 35.9807 50.7191 31.7498 45.5 31.7498Z"
        fill="var(--main-color)"
      />
    </svg>
  );
};

export default MapPinIcon;
