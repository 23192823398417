import { useMobileMediaQuery } from "hooks";
import { Body1, Body2, H3, H4, Subtitle2 } from "melodies-source/Text";
import { CSSProperties } from "react";
import styled from "styled-components";
import { CalendarCard } from "./CalendarCard";
import { DateTime } from "luxon";
import { MutableDateConcert } from "Components";

const Container = styled.div`
  display: flex;
  align-items: center;
  background: #f2f5f7;
  border-radius: 10px;
  padding: 10px;
  gap: 14px;
  flex: 1;
  min-width: 0;
`;

const Content = styled.div`
  min-width: 0;
  flex: 1;

  ${H3}, ${H4} {
    color: #333;
  }
  ${Body1} {
    font-size: 14px;
    line-height: 20px;
  }
  ${Body1}, ${Body2} {
    color: #666;
  }
`;

const New = styled.div`
  background: #1b0076;
  border-radius: 9999px;
  padding: 0 10px;
  color: white;
`;

const truncate: CSSProperties = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  width: "100%",
};

interface ConcertCardProps {
  concert: MutableDateConcert<DateTime>;
  isNew?: boolean;
  isSelected?: boolean;
  disableTruncate?: boolean;
}

export const ConcertCard = ({
  concert,
  isNew,
  isSelected,
  disableTruncate,
}: ConcertCardProps) => {
  const isMobile = useMobileMediaQuery();
  const Title = isMobile ? H4 : H3;
  const Text = isMobile ? Body2 : Body1;

  return (
    <Container style={{ opacity: isNew && isSelected ? 1 : 0.7 }}>
      <CalendarCard date={concert.date} />
      <Content>
        <Title style={!disableTruncate ? truncate : undefined}>
          {concert.venue}
        </Title>
        <Text style={!disableTruncate ? truncate : undefined}>
          {concert.address} • {concert.date.toFormat("h:mm a (ZZZZ)")}
        </Text>
      </Content>
      <div style={{ alignSelf: "flex-start" }}>
        {isNew && (
          <New>
            <Subtitle2>NEW</Subtitle2>
          </New>
        )}
      </div>
    </Container>
  );
};
