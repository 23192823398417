import { ReactComponent as IconDreamSetlist } from "assets/svg/dream-setlist.svg";
import { SurveyTemplate } from "../../types";

export const trackListAnticipation: SurveyTemplate = {
  icon: <IconDreamSetlist />,
  title: "Track List Anticipation",
  description:
    "Invite fans to vote on which track they're most excited to hear based on their titles.",
  templateId: "trackListAnticipation",
  template: (v, formState) => ({
    fields: {
      description: { content: { en: formState.title } },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "Which track are you most excited to hear?",
        },
        type: "SingleSelectQuestion",
        options: [
          { label: { en: "" } },
          { label: { en: "" } },
          { label: { en: "" } },
        ],
      },
      {
        id: "question_2",
        label: { en: "Why did you choose that track?" },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes" ? "pp-tracklist-524d50" : "pp-tracklist-31aeac",
    fields: [
      {
        name: "format",
        type: "radio",
        label: "What format is this release?",
        options: ["EP", "Album"],
        getValidation: (fields) =>
          !!fields.format ? false : "You must select an option.",
      },
      {
        name: "title",
        type: "text",
        label: "What's the title of the release?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.title ? false : "This field cannot be left blank",
      },
      {
        name: "tracks",
        type: "textarea",
        label: "Paste in the tracklist below",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.tracks ? false : "This field cannot be left blank",
      },
      {
        name: "releaseDate",
        type: "text",
        label: "When will it be available (or is it already available)?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.releaseDate ? false : "This field cannot be left blank",
      },
    ],
  },
};
