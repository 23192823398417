import { gtb as gtbConfig } from "@max/thirdparty/companies/gtb";
import { GtbAffiliationMap } from "@max/common/dist/thirdparty/gtb";

import { UserMenuOptions } from "./Components/UserMenuOptions";
import { GtbCustomApp } from "./types";
import { Routes } from "./Routes";

export const gtb: GtbCustomApp = {
  ...gtbConfig,
  routes: {
    paths: ["/", "/regions/:regionId"],
    Component: Routes,
  },
  UserMenuOptions,
  userRoles: GtbAffiliationMap,
};
