"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fizz = void 0;
const colors = {
    main: "#DC00F0",
    secondary: "#6986ff",
    tertiary: "#6986ff",
    background: "#001670",
    text: "#eeeeee",
    headerText: "white",
    secondaryText: "#cdcdcd",
    primaryButtonText: "white",
    primaryButtonHover: "#f8a0ff",
    componentBackground: "#0D1E5E",
    border: "#6d6d6d",
    inputBorder: "#ced4da",
    hoverBackground: "#963a9e",
    boxShadow: "rgba(0,0,0,0.35)",
    selected: "#892da5",
    contentBackground: "#5222DB",
    disabled: "#565656",
    spinner: "#DC00F0",
    liveEventCardBackground: "#5b2670",
};
exports.fizz = {
    app: { name: "SPONSORSHIP PROGRAMS" },
    company: {
        name: "Fizz",
        logo: {
            url: "/custom/fizz/tags/logo.png",
            description: "fizz",
        },
        icon: "/custom/fizz/tags/favicon.ico",
    },
    event: {
        owner: {
            singular: "Program",
            plural: "Programs",
            singularWithArticle: "a Program",
        },
        type: {
            singular: "Event",
            plural: "Events",
            singularWithArticle: "an Event",
        },
        audience: {
            singular: "Attendee",
            plural: "Attendees",
            singularWithArticle: "an Attendee",
        },
    },
    footerLinks: [
        { text: "Fizz Privacy Policy", url: "https://www.max.live/privacy-policy" },
        { text: "MAX Privacy Policy", url: "https://www.max.live/privacy-policy" },
    ],
    fanAppUrl: `https://fizzevents.live`,
    onsiteAppUrl: `https://onsite.fizzevents.live`,
    privacyPolicyUrl: "https://www.ford.com/help/privacy",
    regionsCollection: "fizz_regions",
    menu: {
        sections: {
            engagement: {
                header: "User Engagement",
                items: {
                    live: {
                        label: "Fizz.Live",
                        icon: "",
                    },
                },
            },
        },
    },
    theme: {
        colors,
        layout: {
            menuBar: {
                background: colors.background,
                color: colors.main,
            },
            sideBar: {
                background: colors.componentBackground,
                color: colors.text,
                active: {
                    background: colors.main,
                    color: "white",
                },
                sectionHeader: {
                    color: colors.text,
                },
            },
            content: {
                background: colors.contentBackground,
                backgroundHeader: {
                    background: `linear-gradient(180deg, ${colors.main} 20%, ${colors.contentBackground} 85%)`,
                },
            },
            footer: {
                background: colors.background,
                color: colors.text,
            },
            boxShadowColor: colors.border,
        },
        pages: {
            dashboard: {
                cards: {
                    nextShow: {
                        background: colors.background,
                        color: colors.main,
                    },
                    fanContacts: {
                        background: colors.background,
                        color: colors.main,
                    },
                    fanProfile: {
                        background: "gray",
                        color: "white",
                    },
                    shows: {
                        background: `linear-gradient(180deg,${colors.main}  -20%, ${colors.secondary} 110%)`,
                    },
                },
            },
            contacts: {
                table: {
                    row: {
                        hover: {
                            background: colors.hoverBackground,
                        },
                        border: { color: "#adb5bd" },
                    },
                },
                noContacts: {
                    background: colors.componentBackground,
                    icon: {
                        color: colors.main,
                    },
                },
            },
            reporting: {
                setlive: {
                    fanReportCard: {
                        background: colors.contentBackground,
                    },
                },
            },
            account: {
                header: {
                    background: `linear-gradient(180deg, ${colors.main} 0%, ${colors.tertiary} 100%), #20222A`,
                },
            },
            signup: {
                content: {
                    background: "linear-gradient(155deg, #046f91, #080a12 55%)",
                },
            },
            region: {
                cards: {
                    contactsTotal: {
                        background: `linear-gradient(180deg, ${colors.main} -30%, ${colors.secondary} 90%)`,
                        color: "white",
                    },
                    contactsLast30Days: {
                        background: `linear-gradient(180deg, ${colors.secondary} 20%, ${colors.main} 120%)`,
                        color: "white",
                    },
                },
            },
        },
        charts: {
            bar: {
                bar: { background: colors.secondaryText },
            },
            donut: {
                colors: [
                    colors.main,
                    colors.secondary,
                    "#5222DB",
                    "#760080",
                    "#3f5099",
                    "#311483",
                    "#f366ff",
                    "#a5b6ff",
                    "#977ae9",
                ],
            },
        },
        icon: {
            background: colors.contentBackground,
        },
    },
};
