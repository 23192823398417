import styled from "styled-components";
import { useDesktopMediaQuery } from "hooks";
import { svg } from "svg";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { H3 } from "melodies-source/Text";
import { SetBioKnockout } from "assets/logos";
import { bucketImageSource } from "assets/bucketImages";
import { Button } from "melodies-source/Button";
import { useHistory } from "react-router-dom";

type DivElement = React.HTMLAttributes<HTMLDivElement>;

const AppCardButton = styled(Button)`
  background-color: #ffffff;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 18px;
  color: #1b0076;
`;

type AppCardNoDataProps = {
  bgImg?: JSX.Element;
  overlayImg?: JSX.Element;
  headerIcon: JSX.Element;
  bodyText: String;
  button: string | JSX.Element;
  onButtonClick?: () => void;
};

const AppCardNoData = ({
  bgImg,
  overlayImg,
  headerIcon,
  bodyText,
  button,
  onButtonClick,
  ...props
}: AppCardNoDataProps) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <AppCardContainer {...props}>
      {bgImg}
      {isDesktop && overlayImg}
      <AppCardBody>
        <HeaderIcon>{headerIcon}</HeaderIcon>
        <AppCardBodyText as="div">{bodyText}</AppCardBodyText>
        {typeof button === "string" ? (
          <AppCardButton onClick={() => onButtonClick?.()} variant="round">
            {button}
          </AppCardButton>
        ) : (
          button
        )}
        {!isDesktop && overlayImg}
      </AppCardBody>
    </AppCardContainer>
  );
};

const StyledSetFanCard = styled(AppCardNoData)<{ loading?: boolean }>`
  background: linear-gradient(225.39deg, #2bc0e4 12.5%, #1a86b4 100%);

  ${AppCardButton} {
    background-color: #2bc0e4;
    border: none;
  }
`;

export const SetFanAppNoData = ({ ...props }: DivElement) => {
  const isDesktop = useDesktopMediaQuery();
  const history = useHistory();

  return (
    <StyledSetFanCard
      {...props}
      bgImg={
        <BackgroundImage
          src={
            isDesktop
              ? bucketImageSource.background.fanDesktop
              : bucketImageSource.background.fanMobile
          }
        />
      }
      overlayImg={
        <OverlayImage
          src={
            isDesktop
              ? bucketImageSource.graphic.fanDesktop
              : bucketImageSource.graphic.fanMobile
          }
        />
      }
      headerIcon={<svg.SetFanKnockout />}
      bodyText="Turn followers into superfans"
      button="Create a Survey"
      onButtonClick={() => history.push("set-fan/surveys")}
    />
  );
};

const StyledSetLiveCard = styled(AppCardNoData)<{ loading?: boolean }>`
  background: ${({ theme }) =>
    theme.custom
      ? theme.custom.pages.dashboard.cards.shows.background
      : "linear-gradient(225deg, #E71F3D 13.35%, #67000F 92.93%)"};
  ${AppCardButton} {
    background-color: #e71e3d;
    border: none;
  }
`;

export const SetLiveAppNoData = ({ ...props }: DivElement) => {
  const isDesktop = useDesktopMediaQuery();
  const { customApp } = useCustomAppContext();
  const history = useHistory();

  return (
    <StyledSetLiveCard
      {...props}
      {...(!customApp && {
        bgImg: (
          <BackgroundImage
            src={
              isDesktop
                ? bucketImageSource.background.liveDesktop
                : bucketImageSource.background.liveMobile
            }
          />
        ),
        overlayImg: (
          <OverlayImage
            src={
              isDesktop
                ? bucketImageSource.graphic.liveDesktop
                : bucketImageSource.graphic.liveMobile
            }
          />
        ),
      })}
      headerIcon={<svg.SetLiveKnockout />}
      bodyText="Connect with fans at your shows"
      button="Design an Experience"
      onButtonClick={() => history.push("set-live/upcoming-shows")}
    />
  );
};

export const SetBioAppNoData = ({ ...props }: DivElement) => {
  const isDesktop = useDesktopMediaQuery();
  const { customApp } = useCustomAppContext();
  const history = useHistory();

  return (
    <StyledSetBioCard
      {...props}
      {...(!customApp && {
        bgImg: (
          <BackgroundImage
            src={
              isDesktop
                ? bucketImageSource.background.bioDesktop
                : bucketImageSource.background.bioMobile
            }
          />
        ),
        overlayImg: (
          <OverlayImage
            src={
              isDesktop
                ? bucketImageSource.graphic.bioDesktop
                : bucketImageSource.graphic.bioMobile
            }
          />
        ),
      })}
      headerIcon={<SetBioKnockout />}
      bodyText="Engage fans with your #linkinbio"
      button="Claim Your Page"
      onButtonClick={() => history.push("set-bio")}
    />
  );
};

const StyledSetBioCard = styled(AppCardNoData)<{ loading?: boolean }>`
  background: ${({ theme }) =>
    theme.custom
      ? theme.custom.pages.dashboard.cards.shows.background
      : "linear-gradient(244.03deg, #9747FF 17.41%, #5701B7 93.87%)"};

  ${AppCardButton} {
    background-color: #8017f6;
    border: none;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    background: linear-gradient(265.3deg, #9747ff 0.74%, #5701b7 99.26%);
  }
`;

const AppCardBody = styled.div`
  height: 100%;
  padding-left: 54px;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 380px;
  z-index: 1;

  ${AppCardButton} {
    color: #fff;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    max-width: 350px;
    align-items: center;
    text-align: center;
    padding-left: 0;

    ${AppCardButton} {
      width: 85%;
      max-width: 300px;
    }
  }
`;

const AppCardContainer = styled.div`
  position: relative;
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  height: 276px;
  max-width: 1300px;
  margin: auto 40px;
  order: 3;

  ${({ theme }) => theme.mediaQueries.mobile} {
    height: 497px;
    aspect-ratio: 350/497;
    max-width: 100%;
    justify-content: center;
    margin: auto 20px;
  }
`;

const AppCardBodyText = styled(H3)`
  color: #fff;
  font-size: 35px;
  line-height: 39px;
  font-weight: 700;
  margin-bottom: 24px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 20px;
    max-width: 75%;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: left top;

  ${({ theme }) => theme.mediaQueries.mobile} {
    object-position: center bottom;
  }
`;

const HeaderIcon = styled.div`
  margin-bottom: 24px;
  svg {
    width: 74px;
    height: 25px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 20px;
  }
`;

const OverlayImage = styled.img`
  position: absolute;
  bottom: 0;
  height: 270px;
  right: 5%;

  ${({ theme }) => theme.mediaQueries.mobile} {
    height: 255px;
    right: initial;
  }
`;
