import React from "react";
import styled from "styled-components";
import { H2 } from "melodies-source/Text";
import { formatNumber } from "Utils/format";

interface Props {
  value: string | number;
  label: string;
}

export const StatsItem: React.FC<Props> = ({ value, label, ...props }) => {
  return (
    <Item {...props}>
      <ItemCount>{formatNumber(value)}</ItemCount>
      <ItemLabel>{label}</ItemLabel>
    </Item>
  );
};

const Item = styled.div`
  display: flex;
  flex-direction: column;
  width: 120px;

  ${({ theme }) => theme.media.mobile} {
    width: 70px;
  }
`;

const ItemCount = styled(H2)`
  margin: 0 !important;
  text-align: center;
`;

const ItemLabel = styled.span`
  color: #666;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;

  ${({ theme }) => theme.media.mobile} {
    line-height: 16px;
    font-size: 12px;
  }
`;
