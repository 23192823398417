import React from "react";
import { useUser } from "auth";
import { useArtistContext } from "Components";
import styled from "styled-components";
import { Tooltip } from "melodies-source/Tooltip";

export const UserCard = ({ member }) => {
  const { artistGroupDoc } = useArtistContext();
  const artistRoles = artistGroupDoc.data()?.roles;
  const { name, roles, uid, email } = member;
  const { user } = useUser();
  const display = name || email;

  const colorArray = ["#493391", "#EC4B64", "#3795BD", "#33CA86"];
  const num = display.length % 4;
  const color = colorArray[num];
  const displayName = user.uid === uid ? `Me (${display})` : display;
  return (
    <Container>
      <Image name={display} image={color} />
      <RightContainer>
        <Tooltip placement="auto-end" parent={<Name>{displayName}</Name>}>
          {displayName}
        </Tooltip>
        <Role>
          {(artistRoles || {})[uid]}
          {roles?.includes("admin") && ", Admin"}
        </Role>
      </RightContainer>
    </Container>
  );
};

const Container = styled.div`
  padding: 8px 10px;
  height: 52px;
  display: flex;
  align-items: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 0 8px;
  overflow: hidden;
`;

const Name = styled.div`
  color: inherit;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 20px;
  padding-right: 4px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Role = styled.div`
  color: var(--secondary-text-color);
  font-family: Poppins;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 16px;
`;

const Image = ({ image, name = "" }) => {
  return (
    <ImageContainer style={{ backgroundColor: `${image}` }}>
      {name.substr(0, 1).toLocaleUpperCase()}
    </ImageContainer>
  );
};

const ImageContainer = styled.div`
  height: 30px;
  width: 30px;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-family: Poppins;
  font-size: 16.5px;
  letter-spacing: 0;
  line-height: 25px;
  text-align: center;
  border-radius: 50%;
`;
