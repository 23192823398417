import { customPrizeOption } from "Routes/SetFan/constants/prizes";
import { PromptFormState } from "./defaults/types";
import { SurveyTemplateConfig } from "./types";
import { builderToClass } from "@max/common";
import { generateUUID } from "@max/common/dist/setfan/modules/util";

interface CreateTemplateFields {
  template: SurveyTemplateConfig;
  patch?: SurveyTemplateConfig;
  formState?: PromptFormState;
}

const processSurveyQuestions = (template, patch) => {
  return template.surveyQuestions?.map((sq) => {
    const update = patch.surveyQuestions?.find(({ id }) => id === sq.id) || {};
    const question = { ...sq, required: true, id: generateUUID() };

    if (update.label?.en && typeof update.label.en === "string") {
      question.label = update.label;
    }

    if (
      question.options &&
      update.options &&
      Array.isArray(update.options) &&
      update.options[0]?.label?.en
    ) {
      question.options = update.options.map((opt, optIdx) => ({
        label: {
          en: opt.label?.en || question.options[optIdx]?.label?.en || "",
        },
      }));
    }

    return builderToClass(question).toBuilderConfig();
  });
};

const mergeContent = (
  patch,
  template,
  key: string,
  defaultValue: string | number = "",
) => patch?.[key]?.content?.en || template?.[key]?.content?.en || defaultValue;

const mergeFields = (template, patch, formState) => {
  const fields = ["header", "body", "thankYouHeader", "thankYouBody"].reduce(
    (acc, key) => {
      acc[key] = {
        content: { en: mergeContent(patch.fields, template.fields, key) },
      };
      return acc;
    },
    {},
  );

  if (formState?.hasSweeps === "Yes") {
    Object.assign(fields, {
      isContest: { content: true },
      hasContestCustomRules: { content: null },
      contestPrize: { content: { en: customPrizeOption.value } },
      contestPrizeValue: {
        content: mergeContent(
          patch.fields,
          template.fields,
          "contestPrizeValue",
          150,
        ),
      },
      contestCustomPrizeDescription: {
        content: {
          en: mergeContent(
            patch.fields,
            template.fields,
            "contestCustomPrizeDescription",
            formState?.prize || "",
          ),
        },
      },
    });
  }

  return { ...template.fields, ...fields };
};

export const convertTemplateToConfig = ({
  template,
  patch = {},
  formState,
}: CreateTemplateFields): SurveyTemplateConfig => {
  const config = {
    ...template,
    fields: mergeFields(template, patch, formState),
  };

  if (config.surveyQuestions?.length) {
    config.surveyQuestions = processSurveyQuestions(template, patch);
  }

  if (formState?.hasSweeps === "Yes") {
    config.flags = {
      ...config.flags,
      showContestConfirmationMessage: true,
    };
  }

  return config;
};
