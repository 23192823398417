import {
  CATEGORIES_OPTIONS_IDS,
  CategoryOptionId,
  DefaultProfilingCategory,
  ProfilingModule,
  TextModule,
  defaultProfiling,
  generateSurveyConfig,
} from "@max/common/dist/setfan";
import { Divider, TextInput, useArtistContext } from "Components";
import {
  Accordion,
  SectionContainer,
  SectionProps,
} from "Components/Accordion";
import { CATEGORIES_OPTIONS } from "Routes/SetFan/constants/profileCategories";
import { AnimatedCaret } from "Routes/styled";
import { Button } from "melodies-source/Button";
import { Body1, H2 } from "melodies-source/Text";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useBuilderContext } from "../BuilderContext";
import { useIsMobile } from "melodies-source/utils";
import { ModalActions } from "./AddQuestion/ImageQuestionUploader";
import { ResponsiveModal } from "Components/ResponsiveModal";

type Values = Partial<Record<CategoryOptionId, string>>;

interface EditCategoriesIntroTextFormProps {
  initialValues?: Values;
  displayedCategories?: CategoryOptionId[];
  onCancel?: () => void;
  onSave?: (values: Values) => void;
}

const EditCategoriesIntroTextForm = ({
  initialValues = {},
  displayedCategories = [...CATEGORIES_OPTIONS_IDS],
  onCancel,
  onSave,
}: EditCategoriesIntroTextFormProps) => {
  const { name: artistName } = useArtistContext();
  const { data } = useBuilderContext();
  const [openId, setOpenId] = useState<CategoryOptionId>(
    displayedCategories[0],
  );
  const [values, setValues] = useState(initialValues);
  const [iframeInitialized, setIframeInitialized] = useState(false);
  const selectedCategoryRef = useRef<HTMLDivElement>(null);
  const iframe = useRef<HTMLIFrameElement>(null);
  const category = CATEGORIES_OPTIONS.find(({ id }) => id === openId);
  const isMobile = useIsMobile();

  useEffect(() => {
    setTimeout(() => {
      selectedCategoryRef.current
        ?.querySelector<HTMLInputElement>(`input[name=${openId}]`)
        ?.focus();
    }, 0);
  }, [openId]);

  useEffect(() => {
    iframe.current?.contentWindow?.scrollTo(0, 0);
  }, [openId]);

  useEffect(() => {
    if (!iframeInitialized) {
      return;
    }

    const survey = generateSurveyConfig({ ...data, artistName });

    survey.pages[1].modules[1].modules[0].modules = [
      new ProfilingModule({
        force: `profilecategory.${openId}`,
        index: 0,
      }).toSurveyConfig(),
    ];

    const profilingWithPreface = (() => {
      let profileCategory: DefaultProfilingCategory | undefined = undefined;
      defaultProfiling.find((categoryGroup) =>
        categoryGroup.find((category) => {
          if (category.id === `profilecategory.${openId}`) {
            profileCategory = category;
            return true;
          }
          return false;
        }),
      );
      if (!profileCategory) {
        return [];
      }
      const profilingPreface = new TextModule({
        label: { en: values[openId] },
        variants: { mobile: "Headline4", desktop: "Headline3" },
      }).toSurveyConfig();

      profileCategory.preface = [
        {
          content: profilingPreface.content,
          style: profilingPreface.style,
          type: "text",
        },
      ];
      return [profileCategory];
    })();

    iframe.current?.contentWindow?.postMessage({
      survey: {
        ...survey,
        profiling: profilingWithPreface,
        config: {
          ...survey.config,
          globalStyles: (survey.config.globalStyles += `
            html::-webkit-scrollbar{
              display: none;
            }
            * {
              pointer-events: none;
            }
            `),
        },
      },
      // we use page 1 because it already has the styling for profiling pages built in from generateSurveyConfig
      page: 1,
    });
  }, [data, category, values, iframeInitialized]);

  const sections: SectionProps[] = CATEGORIES_OPTIONS.filter(({ id }) =>
    displayedCategories.includes(id),
  ).map((option) => ({
    id: option.id,
    header: option.header,
    body: (
      <TextInput
        name={option.id}
        label="Introduction Text"
        disabled={openId !== option.id}
        value={values[option.id]}
        onChange={(v) => setValues((p) => ({ ...p, [option.id]: v }))}
      />
    ),
    open: openId === option.id,
    onOpenChange: () => setOpenId(option.id),
  }));

  return (
    <Container>
      <Main ref={selectedCategoryRef}>
        <Divider />
        <StyledAccordion
          open={false}
          sections={sections}
          icons={{
            open: <AnimatedCaret direction="up" />,
            closed: <AnimatedCaret direction="down" />,
          }}
          showDivider={true}
        />
        <Divider />
      </Main>
      <Sidebar>
        {!isMobile && (
          <Preview
            id="setfan-profiling-preview"
            ref={iframe}
            src={`${process.env.PUBLIC_URL}/setfanembed`}
            title="preview"
            onLoad={() => setIframeInitialized(true)}
          />
        )}
        <ModalActions>
          <Button text={true} onClick={() => onCancel?.()}>
            Cancel
          </Button>
          <Save onClick={() => onSave?.(values)}>Save</Save>
        </ModalActions>
      </Sidebar>
    </Container>
  );
};

interface EditCategoriesIntroTextProps {
  initialValues?: Values;
  displayedCategories?: CategoryOptionId[];
  isOpen?: boolean;
  onClose?: () => void;
  onSave?: (values: Values) => void;
}

export const EditCategoriesIntroText = ({
  initialValues,
  displayedCategories,
  isOpen,
  onClose,
  onSave,
}: EditCategoriesIntroTextProps) => {
  return (
    <ResponsiveModal
      variant="large"
      header={
        <>
          <H2>Customize Category Intro</H2>
          <Body1>
            You can create your own introduction text for your categories.
            Please choose a category to customize, or leave blank.
          </Body1>
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      withBackdropClose={false}
    >
      <EditCategoriesIntroTextForm
        initialValues={initialValues}
        displayedCategories={displayedCategories}
        onCancel={onClose}
        onSave={onSave}
      />
    </ResponsiveModal>
  );
};

const Container = styled.div`
  display: flex;
  margin-top: 30px;
  gap: 70px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 0;
    margin-top: 0;
    flex-direction: column;
  }
`;

const Main = styled.div`
  flex: 1;
`;

const StyledAccordion = styled(Accordion)`
  flex-direction: column;
  flex-wrap: nowrap;

  ${SectionContainer} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const Sidebar = styled.div`
  flex-shrink: 0;
`;

const Preview = styled.iframe`
  width: 328px;
  height: 463px;
  border-radius: 26px 26px 0px 0px;
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 22px;
  gap: 20px;
`;

const Save = styled(Button)`
  min-width: 156px;
`;
