import { ReactComponent as IconShirtQuestion } from "assets/svg/shirt-question.svg";
import { SurveyTemplate } from "../../types";

export const shirtColor: SurveyTemplate = {
  icon: <IconShirtQuestion />,
  title: "Shirt Color",
  description:
    "Ask fans to vote on what shirt color they'd like to see the newest design on.",
  templateId: "shirtColor",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Shirt Color",
        },
      },
    },
    surveyQuestions: [
      {
        type: "image",
        actionRequired: true,
        showLabels: false,
        required: false,
        src: "",
      },
      {
        id: "question_1",
        label: {
          en: "Which shirt color should we create next?",
        },
        type: "MultipleSelectQuestion",
        options: [
          {
            label: {
              en: "Soft Black",
            },
          },
          {
            label: {
              en: "Vintage White",
            },
          },
          {
            label: {
              en: "Midnight Blue",
            },
          },
          {
            label: {
              en: "Pastel Pink",
            },
          },
          {
            label: {
              en: "Deep Green",
            },
          },
        ],
      },
      {
        id: "question_2",
        label: {
          en: "What made you choose this color?",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-merch---sh-acb334"
        : "pp-merch---sh-4f5e9e",
    fields: [
      {
        name: "url",
        type: "text",
        label: "Paste the URL to your image here",
        placeholder: "https://...",
      },
    ],
  },
};
