const assetsPath =
  process.env.REACT_APP_ASSETS_PATH || "https://set.live/assets";

export const bucketImageSource = {
  header: {
    default: `${assetsPath}/artist-portal/header/default-2x.png`,
    live: `${assetsPath}/artist-portal/header/live-2x.png`,
    fan: `${assetsPath}/artist-portal/header/fan-2x.png`,
    bio: `${assetsPath}/artist-portal/header/bio-2x.png`,
  },
  background: {
    bioDesktop: `${assetsPath}/artist-portal/marketing/set-bio-background-desktop.jpg`,
    bioMobile: `${assetsPath}/artist-portal/marketing/set-bio-background-mobile.jpg`,
    fanDesktop: `${assetsPath}/artist-portal/marketing/set-fan-background-desktop.jpg`,
    fanMobile: `${assetsPath}/artist-portal/marketing/set-fan-background-mobile.jpg`,
    liveDesktop: `${assetsPath}/artist-portal/marketing/set-live-background-desktop.jpg`,
    liveMobile: `${assetsPath}/artist-portal/marketing/set-live-background-mobile.jpg`,
  },
  graphic: {
    bioDesktop: `${assetsPath}/artist-portal/marketing/set-bio-graphic-desktop.png`,
    bioMobile: `${assetsPath}/artist-portal/marketing/set-bio-graphic-mobile.png`,
    fanDesktop: `${assetsPath}/artist-portal/marketing/set-fan-graphic-desktop.png`,
    fanMobile: `${assetsPath}/artist-portal/marketing/set-fan-graphic-mobile.png`,
    liveDesktop: `${assetsPath}/artist-portal/marketing/set-live-graphic-desktop.png`,
    liveMobile: `${assetsPath}/artist-portal/marketing/set-live-graphic-mobile.png`,
  },
};
