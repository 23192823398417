import { useMenuContext } from "Components";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { UserMenu } from "./Components";
import Logo from "svg/Logo";
import { SvgMenuLarge } from "melodies-source/Svgs/MenuLarge";
import { SvgMenuOpenLarge } from "melodies-source/Svgs/MenuOpenLarge";
import { SvgHelpAlt } from "melodies-source/Svgs/HelpAlt";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { Subtitle1 } from "melodies-source/Text";

export const MenuBar = () => {
  const { menuMode, setMenuMode } = useMenuContext();
  const history = useHistory();
  const { artistId } = useParams<{ artistId: string }>();
  const { customApp } = useCustomAppContext();
  const location = useLocation();

  return (
    <Container id="menu-bar">
      <Left>
        {location.pathname !== "/" && (
          <MenuIcon
            as={menuMode === "expanded" ? SvgMenuOpenLarge : SvgMenuLarge}
            onClick={() =>
              setMenuMode((menuMode) =>
                menuMode === "expanded" ? "hidden" : "expanded",
              )
            }
          />
        )}
        <LogoContainer>
          <LogoLink to="/">
            <StyledLogo isCustom={!!customApp} />
          </LogoLink>
          {customApp?.app.name && (
            <>
              <LogoDivider />
              <Subtitle1>{customApp.app.name}</Subtitle1>
            </>
          )}
        </LogoContainer>
      </Left>
      <Right>
        {artistId && !customApp && (
          <StyledHelpIcon
            onClick={() => history.push(`/${artistId}/help-center`)}
          />
        )}
        <UserMenu artistId={artistId} />
      </Right>
    </Container>
  );
};
const Container = styled.div`
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: inset 0 -1px 0 0 var(--box-shadow-color);
  background: white;
  z-index: 10;

  ${({ theme }) => theme.media.mobile} {
    padding: 12px;
    height: 39px;
    position: fixed;
    top: 0;
    z-index: 16;
    width: 100%;
  }
  ${({ theme }) => {
    if (!theme.custom) return null;
    const { background } = theme.custom.layout.menuBar;
    return css`
      background: ${background};
    `;
  }}
`;

const MenuIcon = styled.svg`
  margin: 0 12px 0 0;
  cursor: pointer;
  user-select: none;
  ${({ theme }) => theme.mediaQueries.desktop} {
    display: none;
  }
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
`;

const StyledHelpIcon = styled(SvgHelpAlt)`
  color: #666666;

  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => {
    if (!theme.custom) return null;
    const { color } = theme.custom.layout.menuBar;
    return css`
      color: ${color};
    `;
  }}
`;

interface StyledLogoProps {
  isCustom: boolean;
}

const StyledLogo = styled(Logo)<StyledLogoProps>`
  cursor: pointer;
  height: 44px;

  ${({ isCustom }) =>
    isCustom &&
    css`
      margin-left: 5px;
    `}

  ${({ theme }) => theme.media.mobile} {
    height: 19px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const LogoDivider = styled.div`
  width: 2px;
  height: 13px;
  background: ${({ theme }) => theme.custom.colors.main};
`;
