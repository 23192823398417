import LogRocket from "logrocket";

export const useExceptionLogging = () => {
  const logException = (
    error: Error,
    tags?: { [tag: string]: string | number | boolean },
  ) => {
    LogRocket.captureException(error, { tags });
  };

  return { logException };
};
