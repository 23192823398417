import { ReactComponent as TemplateIcon } from "assets/svg/theLeak.svg";
import { SurveyTemplate } from "../../types";

export const theLeak: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "The Leak",
  description:
    "Let fans vote on which upcoming song or demo you play a portion of.",
  templateId: "theLeak",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "The Leak",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: { en: "Which unreleased track should we play?" },
        type: "SingleSelectQuestion",
        actionRequired: true,
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-livestream-930ddc"
        : "pp-livestream-699e71",
    fields: [
      {
        name: "date",
        type: "text",
        label: "When does your livestream take place?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.date ? false : "This field cannot be left blank",
      },
    ],
  },
};
