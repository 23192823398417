import { FlexColumn } from "Components/Flex";
import { ThankYouMessage as ThankYouMessageBase } from "Routes/SetFan/slices/ThankYouMessage";
import styled from "styled-components";

export const ThankYouMessage = () => {
  return (
    <Container>
      <ThankYouMessageBase />
    </Container>
  );
};

const Container = styled(FlexColumn)`
  padding-bottom: 24px;
  ${({ theme }) => theme.mediaQueries.desktop} {
    padding-bottom: 26px;
  }
`;
