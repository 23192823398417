import { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { PageHeaderImage, PageTitle, useArtistContext } from "Components";
import { ReactComponent as CitySvg } from "Components/Images/CitiesIcon.svg";
import { ReactComponent as CountrySvg } from "Components/Images/CountriesIcon.svg";
import { ReactComponent as ShowSvg } from "Components/Images/ShowsIcon.svg";
import FanStatusCard from "./FanStatusCard";
import ContactsIcon from "../../../svg/ContactsIcon";
import { useCustomAppContext } from "contexts/CustomAppContext";
import {
  ProxySearchParams,
  useTypesenseProxyContext,
} from "contexts/TypesenseProxyContext";
import { DateTime } from "luxon";
import { formatNumber } from "Utils/format";
import { H1 } from "melodies-source/Text";

export const Header = () => {
  const { privateData, id: groupId } = useArtistContext();
  const [sourcesLength, setSourcesLength] = useState<number>(-1);
  const { reachable, cities, countries } = privateData?.data()?.contacts || {};
  const { customApp } = useCustomAppContext();
  const eventAudiencePlural = customApp ? "Contacts" : "Fans";

  const { proxySearch } = useTypesenseProxyContext();

  const getGroupLeadSourcesCount = useCallback(async () => {
    const url = new URL(
      `https://${process.env.REACT_APP_FANS_PROXY_SEARCH_SERVER}`,
    );
    url.pathname = "collections/lead_sources/documents/search";
    let page = 1;
    const params: ProxySearchParams = {
      q: groupId,
      query_by: `groupId`,
      filter_by: `startAt:<=${DateTime.utc().toMillis()}`,
      page,
      per_page: 250,
    };
    let ok = true;
    let total = 0;
    while (ok) {
      const res = await proxySearch("lead_sources", params);
      total += res?.hits?.length || 0;
      params.page++;
      if (!res?.hits?.length) ok = false;
    }
    return total;
  }, [proxySearch, groupId]);

  useEffect(() => {
    (async () => {
      if (!getGroupLeadSourcesCount || !groupId) return;
      const total = await getGroupLeadSourcesCount();
      setSourcesLength(total);
    })();
  }, [proxySearch, getGroupLeadSourcesCount, groupId]);

  return (
    <Container>
      <PageHeaderImage />
      <Content>
        <PageTitle>Contact List</PageTitle>
        <CardsContainer>
          <FanStatusCard>
            <KPI
              data={formatNumber(reachable || 0)}
              label={`Reachable ${eventAudiencePlural}`}
              svg={<ContactsIcon style={{ marginBottom: "-9px" }} />}
            />
          </FanStatusCard>
          <FanStatusCard>
            <KPI data={cities} label="Cities" svg={<CitySvg />} />
          </FanStatusCard>
          <FanStatusCard>
            <KPI data={countries} label="Countries" svg={<CountrySvg />} />
          </FanStatusCard>
          <FanStatusCard>
            <KPI
              data={sourcesLength > 0 ? formatNumber(sourcesLength) : "--"}
              label="Sources"
              svg={<ShowSvg />}
            />
          </FanStatusCard>
        </CardsContainer>
      </Content>
    </Container>
  );
};

const KPI = ({
  data,
  label,
  svg,
}: {
  data: string;
  label: string;
  svg: JSX.Element;
}) => {
  return (
    <KPIContainer>
      <Label>{label}</Label>
      {svg}
      <Data>{data || "0"}</Data>
    </KPIContainer>
  );
};

const Data = styled.div`
  display: flex;
  align-items: center;
  color: var(--secondary-color);
  font-family: Poppins;
  font-size: clamp(24px, 3vw, 45px);
  font-weight: bold;
  letter-spacing: 0;
  line-height: auto;
  text-align: center;
`;

const Label = styled.div`
  color: var(--main-color);
  font-family: Poppins;
  font-size: clamp(16px, 1.5vw, 25px);
  font-weight: 600;
  letter-spacing: 0;
  line-height: 25px;
  margin-bottom: 10px;
  height: 40px;

  ${({ theme }) => theme.media.mobile} {
    line-height: 20px;
    height: 30px;
  }
`;

const KPIContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 5px;

  path {
    fill: var(--main-color);
  }

  @media (max-width: 1100px) {
    & > svg {
      width: 40px;
      height: 40px;
    }
  }

  ${({ theme }) => theme.media.desktop} {
    margin: 0;
  }
`;

const Container = styled.div`
  width: 100%;
`;

const Content = styled.div<{ hasNextShow?: boolean }>`
  position: relative;
  padding: 40px 40px 42px;

  ${H1} {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.media.mobile} {
    padding: 20px;
    ${H1} {
      margin-bottom: 20px;
    }
  }
`;

const CardsContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: fit-content;
  gap: 20px;
  width: 100%;

  @media (max-width: 1100px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    width: 100%;
    gap: 15px;
  }
`;
