import { doc, getFirestore, setDoc, Timestamp } from "firebase/firestore";
import { DateTime } from "luxon";
import { useState } from "react";
import { ConcertDetails } from "Routes/SetLive/Duplicate/types";
import { isSameISODate } from "Utils/date";

interface Options {
  artistId: string;
  concerts?: ConcertDetails<DateTime | Timestamp>[];
  onCollision?: (data: ConcertDetails<Date>) => void;
  onSuccess?: () => void;
}

interface ExecuteOptions {
  data: ConcertDetails<Date>;
  withCollision?: boolean;
}

export const useCreateConcert = ({
  artistId,
  concerts = [],
  onCollision,
  onSuccess,
}: Options) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  const execute = async ({ data, withCollision = true }: ExecuteOptions) => {
    if (withCollision) {
      const hasCollision = concerts.some(({ date }) =>
        isSameISODate(date, data.date),
      );

      if (hasCollision) {
        return onCollision?.(data);
      }
    }

    try {
      setLoading(true);

      await setDoc(
        doc(getFirestore(), "artist_groups", artistId, "concerts", data.id),
        data,
      );

      onSuccess?.();
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  return { execute, loading, error } as const;
};
