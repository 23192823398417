import { CenterFlexRow } from "Routes/styled";
import { H2 } from "melodies-source/Text";

import { SurveyQuestions as SurveyQuestionsSlice } from "../../slices/SurveyQuestions";
import styled from "styled-components";
import { FlexColumn } from "Components/Flex";

export const SurveyQuestions = () => {
  return (
    <Wrapper>
      <Container>
        <H2>Survey Questions</H2>
      </Container>
      <SurveyQuestionsSlice />
    </Wrapper>
  );
};

const Container = styled(CenterFlexRow)`
  ${({ theme }) => theme.mediaQueries.desktop} {
    margin-top: 28px;
    margin-bottom: -42px;
  }
`;

const Wrapper = styled(FlexColumn)`
  padding-bottom: 32px;
  ${({ theme }) => theme.mediaQueries.desktop} {
    padding-bottom: 44px;
  }
`;
