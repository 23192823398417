import { ReactComponent as TemplateIcon } from "assets/svg/fanCensusFull.svg";
import { SurveyTemplate } from "../../types";

export const fanCensusFull: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Fan Census Full",
  description:
    "Generate a report on the types of music your fans are listening to.",
  templateId: "fanCensusFull",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Fan Census Full",
        },
      },
      numberOfCategories: {
        content: "7",
      },
    },
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-copy-of-br-4a1c45"
        : "pp-brand---fa-b6d397",
  },
};
