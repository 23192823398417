import React from "react";
import { Body1, Body2, H2, H3, Subtitle1 } from "melodies-source/Text";
import styled from "styled-components";
import { Card } from "Components/Card";
import { BarChart, BarChartLoader } from "../../Components/BarChart";
import Background from "assets/svg/survey-detail-bg.svg";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { useTabletMediaQuery } from "hooks/useTabletMediaQuery";
import { DateTime } from "luxon";
import { getSurveyFromTypensenseDoc } from "../../Surveys";
import Skeleton from "react-loading-skeleton";
import { formatNumber } from "Utils/format";
import { SurveyTypesenseDocument } from "@max/common/setfan";

interface Props {
  survey?: SurveyTypesenseDocument;
}

export const Stats: React.FC<Props> = ({ survey }) => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();

  if (!survey) {
    return <StatsLoader />;
  }

  const title = survey.internalTitle || survey.title;
  const responsesStart = survey.firstResponse || survey.timeSeries?.start;
  const responsesEnd = survey.lastResponse || survey.timeSeries?.end;
  const startDate = responsesStart
    ? `${DateTime.fromMillis(responsesStart).monthShort} ${
        DateTime.fromMillis(responsesStart).day
      }`
    : "N/A";
  const endDate = responsesEnd
    ? `${DateTime.fromMillis(responsesEnd).monthShort} ${
        DateTime.fromMillis(responsesEnd).day
      }`
    : "N/A";
  const Count = isMobile ? Body2 : Body1;
  const responsesCount = survey?.responses;
  const consumerResponses = survey?.consumerResponses;
  const demographicResponses = survey?.demographicResponses;

  return (
    <StyledCard isElevated>
      <Container>
        <div>
          <H2>{title}</H2>
          {(isMobile || isTablet) && (
            <ResponsesCountMobile>
              {`${formatNumber(responsesCount)} ${
                responsesCount === 1 ? "Response" : "Responses"
              }`}
            </ResponsesCountMobile>
          )}
          {!!survey.timeSeries && (
            <BarChart survey={getSurveyFromTypensenseDoc(survey)} />
          )}
        </div>
        <DetailsContainer>
          <div>
            {!isMobile && !isTablet && (
              <H3>
                Total Responses:{" "}
                <Bold700>{formatNumber(responsesCount)}</Bold700>
              </H3>
            )}
            <Count>
              Demographic Responses:{" "}
              <Bold700>{formatNumber(demographicResponses)}</Bold700>
            </Count>
            <Count>
              Consumer Responses:{" "}
              <Bold700>{formatNumber(consumerResponses)}</Bold700>
            </Count>
          </div>
          <div>
            <Count>
              First Response: <Bold700>{startDate}</Bold700>
            </Count>
            <Count>
              Last Response: <Bold700>{endDate}</Bold700>
            </Count>
          </div>
        </DetailsContainer>
      </Container>
    </StyledCard>
  );
};

const StatsLoader = () => {
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();

  const Count = isMobile ? Body2 : Body1;

  return (
    <StyledCard isElevated>
      <Container>
        <div>
          <H2>
            <Skeleton width={isMobile ? 200 : 150} />
          </H2>
          {(isMobile || isTablet) && (
            <ResponsesCountMobile>
              <Skeleton width={100} />
            </ResponsesCountMobile>
          )}
          <BarChartLoader />
        </div>
        <DetailsContainer>
          <div>
            {!isMobile && !isTablet && (
              <H3>
                <Skeleton width={200} />
              </H3>
            )}
            <Count>
              <Skeleton width={isMobile ? 125 : 200} />
            </Count>
            <Count>
              <Skeleton width={isMobile ? 125 : 200} />
            </Count>
          </div>
          <div>
            <Count>
              <Skeleton width={isMobile ? 125 : 200} />
            </Count>
            <Count>
              <Skeleton width={isMobile ? 125 : 200} />
            </Count>
          </div>
        </DetailsContainer>
      </Container>
    </StyledCard>
  );
};

const Bold700 = styled.span`
  font-weight: 700;

  ${({ theme }) => theme.media.mobile} {
    display: block;
  }
`;

const ResponsesCountMobile = styled(Subtitle1)`
  color: var(--text-color);
  margin: 10px 0;

  ${({ theme }) => theme.media.maxTablet} {
    text-align: center;
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;

  ${H3}, ${Body1} {
    color: var(--text-color);
    text-align: end;
  }

  @media (min-width: 901px) and (max-width: 950px) {
    ${Body1} {
      font-size: 14px;
    }
  }

  @media (min-width: 871px) and (max-width: 900px) {
    ${Body1} {
      font-size: 13px;
    }
  }

  @media (min-width: 769px) and (max-width: 870px) {
    ${Body1} {
      font-size: 12px;
    }
  }

  ${({ theme }) => theme.media.maxTablet} {
    flex-direction: row-reverse;

    ${Body2} {
      text-align: start;
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;

  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
  }

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

const StyledCard = styled(Card)`
  padding: 31px 40px 24px 40px;
  background: url("${Background}");
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: calc(100% + 400px);
  background-position-y: center;

  ${H2} {
    line-height: 32px;
  }

  ${({ theme }) => theme.media.mobile} {
    padding: 20px;
    background-size: cover;
    background-position-x: -25%;
  }
`;
