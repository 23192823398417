import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import { useTheme } from "styled-components";

export const MenuContext = createContext(undefined);
export const MenuConsumer = MenuContext.Consumer;

export type MenuMode = "hidden" | "collapsed" | "expanded";

interface ContextValue {
  menuMode: MenuMode;
  setMenuMode: Dispatch<SetStateAction<MenuMode>>;
}
export const MenuProvider = ({ children }) => {
  const [menuMode, setMenuMode] = useState<MenuMode>("expanded");
  const theme = useTheme();
  const isMobile = useMediaQuery({
    query: theme.media.maxTablet.split(" and ")[1],
  });
  const location = useLocation();

  useEffect(() => {
    setMenuMode(isMobile ? "hidden" : "expanded");
  }, [isMobile]);

  useEffect(() => {
    if (isMobile) {
      setMenuMode("hidden");
    }
  }, [location.pathname]);

  const value: ContextValue = useMemo(
    () => ({ menuMode, setMenuMode }),
    [menuMode],
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
};
export const useMenuContext = (): ContextValue => useContext(MenuContext);
