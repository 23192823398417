import { Modal, ModalDivider } from "melodies-source/Modal";
import { Body1, H3 } from "melodies-source/Text";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { Age } from "./Age";
import { Gender } from "./Gender";
import { Source } from "./Source";
import { lazy, Suspense } from "react";
import { DateRange } from "./DateRange";
import { useFanTableContext } from "../FanTableContext";
import { useMediaQuery } from "react-responsive";
import { mediaQueryBreakpoints } from "melodies-source/Theme/mediaQueries";
import { formatNumber } from "Utils/format";

const Location = lazy(() => import("./Location"));

const FanFilterModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose?: () => void;
}) => {
  const { found, clear, setFilters, filters } = useFanTableContext();
  const isMobile = useMediaQuery({ query: mediaQueryBreakpoints.mobile });

  return (
    <Modal
      variant={isMobile ? "fullscreen" : "large"}
      isOpen={isOpen}
      onClose={onClose}
      header="Filters"
    >
      <ModalDivider />
      <ContentContainer>
        <H3>Location</H3>
        <Body1>
          You can select multiple cities, setting the radius for each.
        </Body1>
        <Suspense fallback={<div>Loading...</div>}>
          <Location />
        </Suspense>
        <H3>Source</H3>
        <Body1>
          Where your contacts are coming from. This includes shows, surveys, and
          livestream events.
        </Body1>
        <Source />
        <MobileContainer>
          <div>
            <H3>Age</H3>
            <Age />
          </div>
          <div>
            <H3>Gender</H3>
            <Gender />
          </div>
        </MobileContainer>
        <H3>Date Added</H3>
        <Body1>
          What date a user was added to your contact list. Slide the handles or
          type in a range.
        </Body1>
        <DateRange
          start={filters.dateAddedStart}
          end={filters.dateAddedEnd}
          onChange={([start, end]) => {
            setFilters({ dateAddedStart: start, dateAddedEnd: end });
          }}
        />
        <H3>Last Active</H3>
        <Body1>
          The last time a contact was active in our system. Slide the handles or
          type in a range.
        </Body1>
        <DateRange
          last={true}
          start={filters.updatedStart}
          end={filters.updatedEnd}
          onChange={([start, end]) => {
            setFilters({ updatedStart: start, updatedEnd: end });
          }}
        />
      </ContentContainer>
      <FooterContainer>
        <ModalDivider />
        <Footer>
          <Button
            variant="secondary"
            onClick={() => {
              clear();
              setFilters({
                dateAddedStart: null,
                dateAddedEnd: null,
                updatedStart: null,
                updatedEnd: null,
              });
            }}
          >
            Clear Filters
          </Button>
          <Button variant="primary" onClick={() => onClose()}>
            Show {formatNumber(found)} Contacts
          </Button>
        </Footer>
      </FooterContainer>
    </Modal>
  );
};

export default FanFilterModal;

const ContentContainer = styled.div`
  margin: 20px 0;

  ${H3} {
    color: var(--main-color);
    font-weight: 400;
    margin-bottom: 5px;

    ${({ theme }) => theme.mediaQueries.mobile} {
      font-size: 16px;
    }
  }

  ${Body1} {
    margin: 0;

    ${({ theme }) => theme.mediaQueries.mobile} {
      font-size: 13px;
    }
  }
`;

const MobileContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: row;
    column-gap: 30%;
    margin-bottom: 20px;
    box-shadow: inset 0 -1px 0 0 #e6e9eb;
  }

  & > div {
    display: flex;
    flex-direction: column;

    ${({ theme }) => theme.mediaQueries.mobile} {
      flex-direction: column;
    }
  }
`;

const FooterContainer = styled.div`
  position: sticky;
  bottom: 0px;
  margin: 0px 0px -30px;
  padding: 0 0px 34px;
  background: var(--component-background-color);
  border-radius: 0px;
  z-index: 1;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
  position: sticky;
  bottom: 0px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
    gap: 10px;
  }
`;
