import { typedFields, useConfigSlice } from "../Components/BuilderContext";
import { TextInput } from "melodies-source/TextInput";
import { Textarea } from "melodies-source/Textarea";
import styled from "styled-components";
import {
  SurveyInputList,
  SurveyInputListItem,
  SurveyInputHelpText,
} from "../Components/SurveyInputList";
import { Checkbox } from "melodies-source/Selectable";
import { FlexColumn } from "Components/Flex";

export const THANK_YOU_FIELDS = typedFields([
  "thankYouHeader",
  "thankYouBody",
  "hasFeaturedCta",
  "featuredCtaHeaderText",
  "featuredCtaButtonText",
  "featuredCtaUrl",
]);

export const ThankYouMessage = () => {
  const { fields, validation, setField, setFieldTouched } =
    useConfigSlice(THANK_YOU_FIELDS);

  return (
    <SurveyInputList>
      <SurveyInputListItem>
        <TextInput
          label="Header Copy"
          placeholder="Add header copy text..."
          hasError={
            fields.thankYouHeader.isTouched && !!validation.thankYouHeader
          }
          helperText={validation.thankYouHeader || ""}
          value={fields.thankYouHeader.content.en}
          onChange={(text) => setField("thankYouHeader", { en: text })}
          onBlur={() => setFieldTouched("thankYouHeader")}
        />
        <SurveyInputHelpText>
          Tell your fans “thanks” your way!
        </SurveyInputHelpText>
      </SurveyInputListItem>
      <SurveyInputListItem>
        <SurveyBodyTextArea
          label="Body Copy"
          placeholder="Add body copy text..."
          hasError={fields.thankYouBody.isTouched && !!validation.thankYouBody}
          helperText={validation.thankYouBody || ""}
          value={fields.thankYouBody.content.en}
          onChange={(text) => setField("thankYouBody", { en: text })}
          onBlur={() => setFieldTouched("thankYouBody")}
        />
        <SurveyInputHelpText>
          Use this to tell your fans more about how you will use this survey
          data, and what they can expect for any next steps as outlined in your
          sweepstakes.
        </SurveyInputHelpText>
      </SurveyInputListItem>
      <SectionContainer>
        <Divider />
        <FeaturedCtaCheckbox
          label="Featured CTA"
          value={fields.hasFeaturedCta.content === true}
          onChange={(selected) => setField("hasFeaturedCta", selected)}
        />
        {fields.hasFeaturedCta.content && (
          <Content>
            <SurveyInputListItem>
              <TextInput
                label="Header Text"
                placeholder="Enter body text"
                hasError={
                  fields.featuredCtaHeaderText.isTouched &&
                  !!validation.featuredCtaHeaderText
                }
                helperText={
                  (fields.featuredCtaHeaderText.isTouched &&
                    validation.featuredCtaHeaderText) ||
                  ""
                }
                value={fields.featuredCtaHeaderText.content.en}
                onChange={(text) =>
                  setField("featuredCtaHeaderText", { en: text })
                }
                onBlur={() => setFieldTouched("featuredCtaHeaderText")}
              />
            </SurveyInputListItem>
            <SurveyInputListItem>
              <TextInput
                label="Button Text"
                placeholder="Enter button text"
                maxLength={20}
                hasError={
                  fields.featuredCtaButtonText.isTouched &&
                  !!validation.featuredCtaButtonText
                }
                helperText={
                  (fields.featuredCtaButtonText.isTouched &&
                    validation.featuredCtaButtonText) ||
                  ""
                }
                value={fields.featuredCtaButtonText.content.en}
                onChange={(text) =>
                  setField("featuredCtaButtonText", { en: text })
                }
                onBlur={() => setFieldTouched("featuredCtaButtonText")}
              />
            </SurveyInputListItem>
            <SurveyInputListItem>
              <TextInput
                label="Button URL"
                placeholder="Enter button URL"
                hasError={
                  fields.featuredCtaUrl.isTouched && !!validation.featuredCtaUrl
                }
                helperText={
                  (fields.featuredCtaUrl.isTouched &&
                    validation.featuredCtaUrl) ||
                  ""
                }
                value={fields.featuredCtaUrl.content.en}
                onChange={(text) => setField("featuredCtaUrl", { en: text })}
                onBlur={() => setFieldTouched("featuredCtaUrl")}
              />
            </SurveyInputListItem>
          </Content>
        )}
      </SectionContainer>
    </SurveyInputList>
  );
};

const SectionContainer = styled(FlexColumn)`
  margin-bottom: -4px;
  padding: 0 0 2px 0;
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0;
    margin-bottom: -8px;
  }
`;

const SurveyBodyTextArea = styled(Textarea)`
  textarea {
    height: 137px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: var(--max-font-primary);
    color: var(--text-color);

    ::placeholder {
      color: #999999;
      font-family: var(--max-font-primary);
      font-weight: 400;
      font-size: 12px;
      line-height: normal;
      user-select: none;
    }
  }
`;

const FeaturedCtaCheckbox = styled(Checkbox)`
  margin: 0;
  p {
    font-size: 20px;
    line-height: 1;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    p {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

const Divider = styled.div`
  background-color: var(--box-shadow-color);
  height: 1px;
  margin: 12px -32px 26px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 12px -20px 16px;
  }
`;

const Content = styled(FlexColumn)`
  margin-top: 20px;
  padding-bottom: 16px;
  gap: 20px;
`;
