import { FlexColumn } from "Components/Flex";
import { SvgClearCircle } from "melodies-source/Svgs/ClearCircle";
import { Body1, Caption } from "melodies-source/Text";
import { HTMLAttributes } from "react";
import styled from "styled-components";

const Container = styled.div`
  border: 1px solid ${(p) => p.theme.colors.black20};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-y: auto;
  padding: 12px 12px 12px 16px;
  width: 100%;
  min-height: 0;

  ${Body1} {
    font-weight: 500;
    line-height: 20px;
  }

  ${Caption} {
    line-height: 16px;
    color: var(--secondary-text-color);
  }
`;

const RemoveButton = styled(FlexColumn)`
  color: ${({ theme }) => theme.colors.black60};
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.black80};
  }
`;

interface ListItemProps extends HTMLAttributes<HTMLDivElement> {
  label?: string;
  caption?: string;
  caption2?: string;
  onRemove?: VoidFunction;
}

export const ListItem = ({
  label,
  caption,
  caption2,
  onRemove,
  ...props
}: ListItemProps) => {
  return (
    <Container {...props}>
      <FlexColumn flex="1 1 auto">
        <Body1 style={{ ...(!onRemove && { color: "var(--main-color)" }) }}>
          {label}
        </Body1>
        {caption && <Caption>{caption}</Caption>}
        {caption2 && <Caption>{caption2}</Caption>}
      </FlexColumn>
      {onRemove && (
        <RemoveButton onClick={onRemove}>
          <SvgClearCircle />
        </RemoveButton>
      )}
    </Container>
  );
};
