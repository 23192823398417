import { useHistory } from "react-router-dom";
import { SetFanAppNoData } from "./AppCardNoData";
import styled from "styled-components";
import { FlexColumn } from "Components/Flex";
import { SetFanKnockout } from "assets/logos";
import { Body1, H2 } from "melodies-source/Text";
import {
  AppCard,
  ListItemButton,
  ListItemButtonAvatar,
  ListItemButtonMetric,
} from "./AppCard";
import { DateTime } from "luxon";
import { SurveyTypesenseDocument } from "@max/common";
import { useState, useEffect } from "react";
import { SvgCheck } from "melodies-source/Svgs/Check";
import { SvgClipboard } from "melodies-source/Svgs/Clipboard";
import { BorderContainer } from "./common";
import { CurrentSurveyCard } from "./CurrentSurveyCard";
import { formatNumber } from "Utils/format";

interface SetFanAppCardProps extends React.HTMLAttributes<HTMLDivElement> {
  custom?: boolean;
  surveys?: SurveyTypesenseDocument[];
  active?: boolean;
}

export const SetFanAppCard = ({
  surveys,
  active,
  ...props
}: SetFanAppCardProps) => {
  const [currentSurvey, setCurrentSurvey] = useState<
    SurveyTypesenseDocument | undefined
  >();
  const [pastSurveys, setPastSurveys] = useState<SurveyTypesenseDocument[]>([]);
  const noPastSurveys = !surveys?.length;
  const { push: nav } = useHistory();

  useEffect(() => {
    if (surveys.length) {
      const mostRecentSurveyResponse = DateTime.fromMillis(
        surveys[0]?.lastResponse ?? 0,
      );

      const currentSurvey =
        surveys.length &&
        Math.abs(mostRecentSurveyResponse.diffNow().as("days")) < 14
          ? surveys[0]
          : undefined;

      setCurrentSurvey(currentSurvey);

      if (currentSurvey) {
        setPastSurveys(surveys.slice(1));
      } else {
        setPastSurveys(surveys);
      }
    }
  }, [surveys]);

  if (!active) {
    return <SetFanAppNoData {...props} />;
  }

  const colSpan = { ...(noPastSurveys && { gridColumn: "1 / span 2" }) };
  return (
    <StyledAppCard
      logo={<SetFanKnockout />}
      secondaryButtonText="View All"
      onSecondaryClick={() => nav("set-fan/surveys")}
      primaryButtonText="Create New"
      onPrimaryClick={() => nav("set-fan/surveys", { create: true })}
    >
      <List style={colSpan}>
        <H2>Current</H2>
        {!currentSurvey ? (
          <BorderContainer>
            <SvgClipboard />
            <Body1>No Active Surveys</Body1>
          </BorderContainer>
        ) : (
          <CurrentSurveyCard
            {...{
              ...currentSurvey,
              lastResponse: DateTime.fromMillis(currentSurvey.lastResponse),
            }}
            compact={!noPastSurveys}
          />
        )}
      </List>
      {!noPastSurveys && (
        <List>
          <H2>Past</H2>
          {pastSurveys?.map((survey) => (
            <ListItemButton
              key={`past-${survey.id}`}
              secondary
              icon={<SvgCheck width={30} height={30} />}
              title={survey.internalTitle || survey.title}
              description={
                survey.lastResponse > 0
                  ? `Last Response: ${DateTime.fromMillis(
                      survey.lastResponse,
                    ).toLocaleString(DateTime.DATE_SHORT)}`
                  : "No Responses"
              }
              metricLabel={survey.responses === 1 ? "Response" : "Responses"}
              metricValue={formatNumber(survey.responses ?? 0)}
              onClick={() => nav(`reporting/set-fan/surveys/${survey.id}`)}
            />
          ))}
        </List>
      )}
    </StyledAppCard>
  );
};

const StyledAppCard = styled(AppCard)`
  background: linear-gradient(225.39deg, #2bc0e4 12.5%, #1a86b4 100%);
  ${ListItemButtonAvatar} {
    background-color: #2bc0e4;
    color: #ffffff;
  }
  ${ListItemButtonMetric} {
    width: 78px;
  }
`;

const List = styled(FlexColumn)`
  gap: 16px;
`;
