import React, { useEffect, useState } from "react";
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  PhoneMultiFactorInfo,
  RecaptchaVerifier,
  getAuth,
} from "firebase/auth";
import styled from "styled-components";
import { Body1, Body2, H2 } from "melodies-source/Text";
import { useFormik } from "formik";
import * as yup from "yup";
import { yupRequired } from "Utils/yup";
import { Button } from "melodies-source/Button";
import { TextInput } from "melodies-source/TextInput";
import { FirebaseError } from "firebase/app";
import toast from "react-hot-toast";
import { useCountdown } from "hooks/useCountdown";
import { useUser } from "auth";

const INITIAL_VALUES = { code: "" };

const VALIDATION_SCHEMA = yup.object().shape({
  code: yupRequired.matches(/^\d{6}$/, "Invalid code"),
});

export const PhoneMFA: React.FC = () => {
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>(null);
  const [recaptchaVerified, setRecaptchaVerified] = useState(true);
  const [verificationId, setVerificationId] = useState<string>(null);
  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [verifyCodeLoading, setVerifyCodeLoading] = useState(false);
  const { resendIn, startCountdown } = useCountdown(60 * 1000);
  const { mfaResolver } = useUser();

  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleVerify,
  });

  const phoneFactorInfo = mfaResolver.hints[0] as PhoneMultiFactorInfo;

  async function handleVerify() {
    try {
      setVerifyCodeLoading(true);
      const cred = PhoneAuthProvider.credential(
        verificationId,
        formik.values.code,
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      await mfaResolver.resolveSignIn(multiFactorAssertion);
    } catch (err) {
      const error = err as FirebaseError;
      switch (error.code) {
        case "auth/invalid-verification-code": {
          toast.error("Invalid code");
          break;
        }
        case "auth/code-expired": {
          toast.error("Code has expired");
          break;
        }
        default: {
          console.error(err);
          toast.error("There was an error");
        }
      }
    } finally {
      setVerifyCodeLoading(false);
    }
  }

  const sendCode = async () => {
    try {
      setSendCodeLoading(true);
      const auth = getAuth();

      const phoneInfoOptions = {
        multiFactorHint: mfaResolver.hints[0],
        session: mfaResolver.session,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);

      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier,
      );

      startCountdown();
      setVerificationId(verificationId);
    } catch (error) {
      console.error(error);
      toast.error("There was an error");
    } finally {
      setTimeout(() => setSendCodeLoading(false), 1000);
    }
  };

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(
      getAuth(),
      "recaptcha-container-id",
      {
        size: "invisible",
        callback: () => setRecaptchaVerified(true),
        "expired-callback": () => setRecaptchaVerified(false),
      },
    );
    recaptchaVerifier.render();
    setRecaptchaVerifier(recaptchaVerifier);
  }, []);

  return (
    <Container>
      <H2>SMS Authentication</H2>
      <Body1>We'll sent a message to {phoneFactorInfo.phoneNumber}.</Body1>
      <Recaptcha id="recaptcha-container-id"></Recaptcha>
      {resendIn ? (
        <Body2>Resend in {resendIn}</Body2>
      ) : (
        <Button
          onClick={sendCode}
          loading={sendCodeLoading}
          disabled={!recaptchaVerified}
        >
          Send Code
        </Button>
      )}
      {!sendCodeLoading && verificationId && (
        <Form onSubmit={formik.handleSubmit}>
          <TextInput
            value={formik.values.code}
            onChange={formik.handleChange("code")}
            label="Code"
            placeholder="Enter the 6-digit code"
            type="number"
            {...(formik.errors.code && {
              hasError: true,
              helperText: formik.errors.code,
            })}
          />
          <Button type="submit" loading={verifyCodeLoading}>
            Verify
          </Button>
        </Form>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${H2} {
    margin-bottom: 20px;
  }

  button {
    width: -webkit-fill-available;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
`;

const Recaptcha = styled.div`
  margin: 15px 0 20px;
`;
