import { useCustomAppContext } from "contexts/CustomAppContext";
import { DateTime } from "luxon";
import styled from "styled-components";

const Calendar = ({
  date,
  className,
}: {
  date: DateTime;
  className?: string;
}) => {
  return (
    <div style={{ position: "relative" }} className={className}>
      <DayOfWeekText>{date.weekdayLong}</DayOfWeekText>
      <MonthText>{date.monthShort}</MonthText>
      <DateText>
        {date.day}
        {getNumberSuffix(date.day)}
      </DateText>
      <CalendarBG />
    </div>
  );
};

const CalendarSVG = ({ className }: { className?: string }) => {
  const { customApp } = useCustomAppContext();

  return (
    <svg
      width="88"
      height="89"
      viewBox="0 0 88 89"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.823392 9.10062C0.823392 5.0079 4.1412 1.69009 8.23392 1.69009H79.7661C83.8588 1.69009 87.1766 5.0079 87.1766 9.10062V21.5544H0.823392V9.10062Z"
        fill="var(--main-color)"
        stroke="var(--main-color)"
        strokeWidth="1.64678"
      />
      <path
        d="M0.823392 21.2458H87.1766V80.6329C87.1766 84.7256 83.8588 88.0434 79.7661 88.0434H8.23392C4.1412 88.0434 0.823392 84.7256 0.823392 80.6329V21.2458Z"
        fill={customApp?.theme.icon.background || "#E8E5F1"}
        stroke={customApp?.theme.icon.background || "#E8E5F1"}
        strokeWidth="1.64678"
      />
    </svg>
  );
};

export default Calendar;

const CalendarBG = styled(CalendarSVG)`
  ${({ theme }) => theme.media.mobile} {
    width: 73.6px;
    height: 76px;
  }
`;

const DayOfWeekText = styled.div`
  position: absolute;
  font-family: Lato;
  font-weight: 800;
  font-size: 10.7041px;
  line-height: 24px;
  text-align: center;
  color: var(--primary-button-text-color);
  width: 88px;
  text-align: center;

  ${({ theme }) => theme.media.mobile} {
    font-size: 8.9px;
    line-height: 23px;
    width: 73.6px;
  }
`;

const MonthText = styled.div`
  position: absolute;
  font-family: "Lato";
  font-style: normal;
  font-size: 21.4082px;
  line-height: 28px;
  color: var(--main-color);
  width: 88px;
  top: 27px;
  text-align: center;

  ${({ theme }) => theme.media.mobile} {
    font-size: 17.9056px;
    line-height: 23px;
    width: 73.6px;
    top: 22px;
  }
`;

const DateText = styled.div`
  position: absolute;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-size: 18.1146px;
  line-height: 28px;
  width: 88px;
  text-align: center;
  top: 50px;
  color: var(--main-color);

  ${({ theme }) => theme.media.mobile} {
    font-size: 15.1509px;
    line-height: 23px;
    width: 73.6px;
    top: 44px;
  }
`;

function getNumberSuffix(num: number): "th" | "st" | "nd" | "rd" {
  if (num === 11 || num === 12 || num === 13) return "th";

  let lastDigit = num.toString().slice(-1);

  switch (lastDigit) {
    case "1":
      return "st";
    case "2":
      return "nd";
    case "3":
      return "rd";
    default:
      return "th";
  }
}
