import { SvgHelp } from "melodies-source/Svgs/Help";
import { Tooltip } from "melodies-source/Tooltip";
import styled from "styled-components";

export const HelpTooltip = ({ message }: { message: string }) => {
  return (
    <StyledTooltip parent={<SvgHelp style={{ display: "block" }} />}>
      {message}
    </StyledTooltip>
  );
};

const StyledTooltip = styled(Tooltip)`
  ${({ theme }) => theme.mediaQueries.mobile} {
    display: none;
  }
`;
