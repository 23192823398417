export const addHttpsProtocolToUrl = (url: string) =>
  url.replace(/^(http(s)?:\/\/)?/i, () => "https://");

// Note: noembed might not find a matching provider for some valid youtube links,
// even though patterns like "https?://.*\\.youtube\\.com/v/.*" are provided in the list of
// supported sites at https://noembed.com/providers.
export const validVideoUrl = async (value?: string) => {
  // if youtube url, extract id into canonical url
  const url = getCanonicalUrl(value);

  const response = await fetch(`https://noembed.com/embed?url=${url}`);
  const json = await response.json();

  if ("error" in json) {
    return false;
  }

  return ["Vimeo", "YouTube"].includes(json.provider_name);
};

const youtubeCanonicalUrl = (id: string) =>
  `https://www.youtube.com/watch?v=${id}`;

const youtubeIdMatchers = [
  /youtube\.com\/embed\/([\w-]+)/i,
  /youtube\.com\/shorts\/([\w-]+)/i,
  /youtube\.com\/v\/([\w-]+)/i,
  /youtube\.com\/watch\?v=([\w-]+)/i,
  /youtu\.be\/([\w-]+)/i,
];

const youtubeRE =
  /^(((?:https?:)?\/\/)?((?:www|m)\.)?((?:(youtube\.com)\/(((((watch\?v=|embed\/|v\/|shorts\/)[\w-]+(&|\?)?)?)?))|((?:youtu.be))\/([\w-]+)\??))(?:(\w+=\w+)&?)*)$/;

/**
 * Takes a variant of a youtube video url and returns a canonical url for that video, if applicable.
 * If not a valid youtube url, or no id can be extracted, it returns the original string.
 */
export function getCanonicalUrl(url?: string): string {
  const urlWithHttps = addHttpsProtocolToUrl(url ?? "");
  // not a valid youtube url, pass along for additional checks
  if (!urlWithHttps?.match(youtubeRE)) {
    return urlWithHttps;
  }

  for (const matcher of youtubeIdMatchers) {
    const match = urlWithHttps.match(matcher);
    if (match?.[1]) {
      return youtubeCanonicalUrl(match[1]);
    }
  }

  return urlWithHttps;
}

export const hasValidServiceDomain = (
  serviceUrl: string,
  service: { domains: string[] },
) => {
  if (!serviceUrl) {
    return false;
  }

  let url: URL = undefined;
  try {
    url = new URL(addHttpsProtocolToUrl(serviceUrl));
    // ignore invalid urls as the value is either a partial url or already run through a url validator
  } catch (err) {}
  let validDomain = false;
  for (const domain of service.domains) {
    if (url?.hostname.endsWith(domain)) {
      validDomain = true;
    }
  }
  if (!validDomain) {
    return false;
  }

  return true;
};
