import { CountryCode } from "libphonenumber-js/types";
import React, { useState } from "react";
import styled from "styled-components";
import { Avatar } from "../Avatar";
import { Dropdown } from "../Dropdown";
import { SvgCheck } from "../Svgs/Check";
import { SvgDropdown } from "../Svgs/Dropdown";
import { ListItem } from "../ListItem";
import { Body1 } from "../Text";
import { countryList as countryListBase } from "./countryList";

const Wrapper = styled.div<{ $standalone?: boolean }>`
  width: ${(p) => (p.$standalone ? "" : "140px")};
  height: 42px;
  border-right: none;
  border-radius: ${(p) => (p.$standalone ? "6px" : "6px 0 0 6px")};
  padding: 10px 12px;
  background: #fff;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  border: ${(p) => (p.$standalone ? "1px solid rgb(204, 204, 204)" : "")};
`;

const countryList = countryListBase.map((country) => ({
  ...country,
  image: `https://flagcdn.com/w160/${country.value.toLowerCase()}.png`,
}));

type Props = {
  value?: CountryCode;
  standalone?: boolean;
  setValue: React.Dispatch<React.SetStateAction<CountryCode>>;
};
export const CountrySelect = ({ value, standalone, setValue }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedCountry = countryList.find((o) => o.value === value);

  const dropdownUI = (
    <Dropdown
      style={{ width: "280px", maxHeight: "250px", marginTop: "-22px" }}
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
    >
      {countryList.map((option, index) => (
        <div key={index}>
          <Option
            isSelected={value === option.value}
            {...{ setValue, setIsOpen, ...option }}
          />
        </div>
      ))}
    </Dropdown>
  );

  return (
    <>
      {dropdownUI}
      <Wrapper $standalone={standalone} onClick={() => setIsOpen(true)}>
        {standalone ? (
          <div style={{ display: "flex" }}>
            {selectedCountry && (
              <Avatar
                style={{ width: "20px", height: "20px", marginRight: 10 }}
                imgUrl={selectedCountry?.image}
              />
            )}
            <Body1>{selectedCountry?.label ?? "Select a country"}</Body1>
          </div>
        ) : (
          <div style={{ display: "flex" }}>
            <Avatar
              style={{ width: "20px", height: "20px" }}
              imgUrl={countryList.find((c) => c.value === value)?.image}
            />
            <Body1 style={{ marginLeft: "10px" }}>{value?.toUpperCase()}</Body1>
          </div>
        )}

        <SvgDropdown />
      </Wrapper>
    </>
  );
};

type OptionProps = {
  image: string;
  isSelected: boolean;
  value: string;
  setValue: any;
  label: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
const Option = ({
  image,
  isSelected,
  value,
  setValue,
  label,
  setIsOpen,
}: OptionProps) => {
  return (
    <ListItem
      leftIcon={
        <>
          {isSelected ? (
            <SvgCheck color="#333" />
          ) : (
            <Avatar style={{ width: "20px", height: "20px" }} imgUrl={image} />
          )}
        </>
      }
      onClick={() => {
        setValue(value);
        setIsOpen(false);
      }}
    >
      {label}
    </ListItem>
  );
};
