import { DefaultLayout, useArtistContext } from "Components";
import { Button, Button as PrimaryButton } from "melodies-source/Button";
import { SvgPreferencesLarge } from "melodies-source/Svgs/PreferencesLarge";
import { Body2, H4 } from "melodies-source/Text";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import ContactsIcon from "../../svg/ContactsIcon";
import {
  Download,
  FanTable,
  FanTableProvider,
  Header,
  ShowMore,
  TableHeaderContent,
  useFanTableContext,
} from "./Components";
import FanFilterModal from "./Components/Filters";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const Fans = ({ match }) => {
  return (
    <FanTableProvider>
      <FansInternal match={match} />
    </FanTableProvider>
  );
};

const FansInternal = ({ match }) => {
  const { filters, filterCount, fans, loading } = useFanTableContext();
  const { privateData } = useArtistContext();
  const { reachable } = privateData?.data()?.contacts || {};
  const { customApp } = useCustomAppContext();

  const dateFilterDedupe = (total: number) => {
    if (!!filterCount && !!filters?.dateAddedStart && filters?.dateAddedEnd) {
      total--;
    }
    if (!!filterCount && !!filters?.updatedStart && filters?.updatedEnd) {
      total--;
    }
    if (!!filterCount && filters?.allSources) {
      total--;
    }
    return total;
  };

  const reachableFans = reachable || 0 || (loading ? 0 : fans?.length);

  const [modalOpen, setModalOpen] = useState(false);
  return (
    <DefaultLayout>
      <Header />
      <TableContainer>
        <RightContainer>
          <TableHeader>
            <TableHeaderContent />
            {reachableFans > 0 && (
              <>
                <OpenFilter
                  variant="secondary"
                  onClick={() => setModalOpen(true)}
                >
                  <SvgPreferencesLarge
                    style={{ marginRight: 7, marginLeft: "-10px" }}
                  />{" "}
                  Filters
                  {!!dateFilterDedupe(filterCount) &&
                    `(${dateFilterDedupe(filterCount)})`}
                </OpenFilter>
                <Download />
              </>
            )}
          </TableHeader>
          {reachableFans > 0 && (
            <>
              <FanTable />
              <ShowMore />
            </>
          )}
          {!reachableFans && (
            <NoDataContainer>
              <ContactsIcon
                fill={
                  customApp?.theme.pages.contacts.noContacts.icon.color ||
                  "#C0BFD7"
                }
              />
              <H4>You don’t have any contacts yet.</H4>
              <Body2>
                Collect contacts by using{" "}
                {customApp?.menu.sections.engagement.items.live.label ||
                  "SET.Live"}{" "}
                at your{" "}
                {customApp?.event.type.plural.toLowerCase() || "live shows"}
              </Body2>
              <PrimaryButton
                style={{
                  width: 200,
                  textDecoration: "none",
                }}
                //@ts-ignore
                as={Link}
                to={`/${match.params.artistId}/`}
              >
                Get Started
              </PrimaryButton>
            </NoDataContainer>
          )}
        </RightContainer>
        <FanFilterModal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
        />
      </TableContainer>
    </DefaultLayout>
  );
};

const TableContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const TableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  font-family: Poppins;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 39px;
  padding: 0 40px 20px;

  @media (max-width: 900px) {
    flex-direction: column;
    padding: 0 20px 20px;
    button {
      margin: 15px 0 0;
      width: 100% !important;
    }
  }
`;
const OpenFilter = styled(Button)`
  margin: 0 20px 0 auto;
  width: fit-content;
  border-radius: 4px;
  padding: 10px 30px 10px 35px;
  text-wrap: nowrap;
`;

const NoDataContainer = styled.div(({ theme }) => {
  const { noContacts } = theme.custom?.pages.contacts || {};
  return css`
    display: flex;
    margin: 50px auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 5;
    max-width: 700px;
    width: 70%;
    background-color: ${noContacts?.background || "#f7fafc"};
    border-radius: 12px;
    padding: 45px;
    color: var(--text-color);

    ${Body2} {
      margin: 10px auto 40px;
      text-align: center;
    }

    ${H4} {
      text-align: center;
    }

    ${theme.media.mobile} {
      width: 93%;
      margin: 20px auto;
      padding: 25px 10px;
      max-width: unset;
    }
  `;
});
