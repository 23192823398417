import { FlexColumn } from "Components/Flex";
import { Body1, H3, Td } from "melodies-source/Text";
import styled from "styled-components";
import { NoData, ReportMetricCard } from ".";
import { Metric } from "../aggregate";

interface TopReleasesProps {
  metrics: Metric[];
}

export const TopReleases = ({ metrics, ...props }: TopReleasesProps) => {
  return (
    <ReportMetricCard isElevated {...props}>
      <H3>Top Releases</H3>
      <FlexColumn gap="20px">
        {metrics?.length ? (
          metrics?.map((release) => (
            <ReleaseItem key={`release-${release.id}`}>
              <ReleaseImage src={release.image} />
              <Body1>{release.label}</Body1>
              <Td>{release.clicks.toLocaleString()}</Td>
            </ReleaseItem>
          ))
        ) : (
          <NoData />
        )}
      </FlexColumn>
    </ReportMetricCard>
  );
};

const ReleaseImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 4px;
`;

const ReleaseItem = styled.div`
  display: grid;
  grid-template-columns: 48px 1fr 60px;
  align-items: center;
  gap: 12px;
  padding-right: 12px;

  ${Body1} {
    font-weight: 500;
  }
  ${Td} {
    color: #666;
    text-align: right;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: 38px 1fr 40px;
    ${Body1} {
      font-size: 12px;
      line-height: 18px;
    }
    ${ReleaseImage} {
      width: 38px;
      height: 38px;
    }
  }
`;
