import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { Iframe } from "./Iframe";
import { H4 } from "melodies-source/Text";
import { SvgCloseLarge } from "svg/CloseLarge";
import { useOverlay } from "melodies-source/utils";
import { Modal } from "melodies-source/Modal";

interface Props {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
  html: Nullable<string>;
}

export const EmailPreview: React.FC<Props> = ({
  open,
  setOpen,
  onClose,
  html,
}) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [height, setHeight] = useState(0);
  useOverlay(!!open);

  const onLoad = () => {
    setHeight(iframeRef.current?.contentDocument?.body.clientHeight || 0);
  };

  useEffect(() => {
    onLoad();
  }, [iframeRef.current?.contentDocument?.body.clientHeight]);

  return (
    <Modal
      isOpen={open}
      withCloseIcon={false}
      onClose={() => setOpen(false)}
      style={{
        height,
        maxWidth: 600,
        padding: 0,
        marginTop: 55,
      }}
    >
      <Header>
        <H4>Post-show Email Preview</H4>
        <CloseIcon onClick={onClose}>
          <SvgCloseLarge />
        </CloseIcon>
      </Header>
      {html && (
        <StyledIframe
          $height={height}
          iframeRef={iframeRef}
          allowTransparency
          srcDoc={html}
          onLoad={onLoad}
          seamless
        />
      )}
    </Modal>
  );
};

const StyledIframe = styled(Iframe)<{ $height: number }>`
  background: transparent;
  width: 100%;
  height: 100%;
  max-width: 600px;
  margin: 0 auto;
  pointer-events: none;
`;

const Header = styled.div`
  position: absolute;
  margin-top: -30px;
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${H4} {
    color: white;
  }
`;

export const CloseIcon = styled.div`
  display: flex;
  justify-content: flex-end;
  color: white;
  cursor: pointer;
`;
