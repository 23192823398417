import { useState, useRef } from "react";
import { FlexColumn } from "Routes/styled";
import styled from "styled-components";
import { Card } from "melodies-source/Card";
import Calendar from "./Calendar";
import { DateTime } from "luxon";
import { Button } from "melodies-source/Button";
import { svg } from "svg";
import { useDesktopMediaQuery, useEventEntries } from "hooks";
import { SetLiveEvent } from "models/event";
import RecordingIcon from "Components/RecordingIcon";
import { Body2, H2, H3, H4, Subtitle1 } from "melodies-source/Text";
import { ReactComponent as CircleCheckedIcon } from "assets/svg/circle-checked.svg";
import { ReactComponent as DocumentIcon } from "assets/svg/document.svg";
import { MenuButton } from "Components/MenuButton";
import { ReactComponent as KebabMenuIcon } from "assets/svg/kebab-menu.svg";
import { ReactComponent as GearIcon } from "assets/svg/gear.svg";
import { SvgShowLarge } from "melodies-source/Svgs/ShowLarge";
import { ReactComponent as TrashIcon } from "assets/svg/trash.svg";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { useHistory, useParams } from "react-router-dom";
import { Modal } from "melodies-source/Modal";
import { toast } from "react-hot-toast";
import Skeleton from "react-loading-skeleton";
import { Confirmation } from "melodies-source/Confirmation";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { AdditionalGroupsAvatars } from "Components/AdditionalGroups";
import { formatNumber } from "Utils/format";
import { useUser } from "auth";
import { ReactComponent as AddUserIcon } from "assets/svg/add-user.svg";
import { EditAdditionalGroups } from "./EditAdditionalGroups";
import { useArtistContext } from "Components/ArtistProvider";

interface Props {
  event: SetLiveEvent;
  showType: "upcoming" | "past";
  live?: boolean;
  section: "reporting" | "set.live";
  onDelete?: (id: string) => Promise<boolean>;
  onUpdate?: (id: string, data: Partial<SetLiveEvent>) => void;
}

let Venue;
let Info;

export const EventCard: React.FC<Props> = ({
  event,
  showType,
  live,
  section,
  onDelete,
  onUpdate,
}) => {
  const isDesktop = useDesktopMediaQuery();
  const isMobile = useMobileMediaQuery();
  const { entriesTotals } = useEventEntries(event.id, event.version);
  const { artistId } = useParams<{ artistId: string }>();
  const history = useHistory();
  const [modalOpen, setModalOpen] = useState(false);
  const { claims } = useUser();
  const [editAdditionalGroupsOpen, setEditAdditionalGroupsOpen] =
    useState(false);
  const statusRef = useRef<string>("");
  const { customApp, language } = useCustomAppContext();
  const { logAction } = useArtistContext();

  const totalEntries = entriesTotals?.all ?? entriesTotals?.inVenue ?? 0;
  const multiAccount = !!event.additionalGroups?.length;

  Venue = isMobile ? H3 : H2;
  Info = isMobile ? Body2 : H3;

  const handleDelete = async () => {
    statusRef.current = "deleting";
    const req = await onDelete(event.id);
    if (req) {
      await logAction("portal_delete_event", { event: event.id });
      statusRef.current = "";
      toast.success(`${language.event.type.singular} deleted`);
      setModalOpen(false);
    }
  };

  const menuItems = [];

  if (event?.status === "published") {
    if (artistId === event.groupId && !customApp)
      menuItems.push({
        label: "Edit",
        icon: {
          position: "left" as const,
          element: <GearIcon color="var(--text-color)" />,
        },
        onClick: () => {
          window.location.href = `/set-live-creator/b/${event.id}/editor?ref=ap`;
        },
      });

    if (claims.admin) {
      menuItems.push({
        label: "Add Artist",
        icon: {
          position: "left",
          element: <AddUserIcon color="var(--text-color)" />,
        },
        onClick: () => setEditAdditionalGroupsOpen(true),
      });
    }

    menuItems.push({
      label: `${language.event.audience.singular} View`,
      icon: { position: "left" as const, element: <SvgShowLarge /> },
      onClick: () =>
        window.open(
          `${customApp?.fanAppUrl || process.env.REACT_APP_SL_URL}/e/${
            event.id
          }`,
          "_blank",
        ),
    });
  }

  if (artistId === event.groupId)
    menuItems.push({
      label: "Delete",
      icon: { position: "left" as const, element: <TrashIcon /> },
      onClick: () => setModalOpen(true),
    });

  const handleCloseModal = () => {
    if (statusRef.current !== "deleting") {
      setModalOpen(false);
    }
  };

  const menu = {
    position: "right" as const,
    width: "200px",
    items: menuItems,
  };

  const entriesText = `${formatNumber(totalEntries)} ${
    totalEntries === 1 ? "Entry" : "Entries"
  }`;

  // Removing this for now because it breaks prod and I'm not sure it is even needed
  //if (event?.status !== "published") return null;

  return (
    <StyledCard isElevated live={live}>
      <>
        {section === "set.live" &&
          showType === "upcoming" &&
          !!menuItems.length && (
            <MenuContainer>
              <MenuButton menu={menu} textButton>
                <KebabMenuIcon color="var(--text-color)" />
              </MenuButton>
            </MenuContainer>
          )}
        {!live && showType === "upcoming" && (
          <StyledCalendar date={DateTime.fromMillis(event.startsAt)} />
        )}
        {!live && showType === "past" && <StyledMapPin />}
        {live && !isDesktop && (
          <MobileLiveNow label={<LiveNowText>Live Now</LiveNowText>} />
        )}
        <FlexColumn style={{ marginRight: "1rem" }}>
          <Venue>{event.venue}</Venue>
          <Info>
            {showType === "upcoming" &&
              DateTime.fromMillis(event.startsAt, {
                zone: event.timeZoneName,
              })
                .toLocaleString(DateTime.DATETIME_FULL)
                .split(" at ")[1]}
            {showType === "past" &&
              DateTime.fromMillis(event.startsAt, {
                zone: event.timeZoneName,
              }).toLocaleString(DateTime.DATE_MED)}
            {` • ${event.displayAddress || event.address} ${
              isMobile ? "" : `• ${entriesText}`
            }`}
            {isMobile && showType === "past" && (
              <Subtitle1>{entriesText}</Subtitle1>
            )}
          </Info>
          {section === "set.live" && showType === "upcoming" && (
            <Status status={event.status}>
              {event.status === "published" ? (
                <CircleCheckedIcon />
              ) : (
                <DocumentIcon />
              )}
              {event.status}
            </Status>
          )}
        </FlexColumn>
        <RightSideContainer>
          {live && isDesktop && (
            <DesktopLiveNow label={<LiveNowText>Live Now</LiveNowText>} />
          )}
          {multiAccount && (
            <AdditionalGroups>
              <AdditionalGroupsAvatars ids={event.additionalGroups} />
            </AdditionalGroups>
          )}
          {(section === "reporting" || showType === "past") && (
            <ActionButton
              variant="primary"
              live={live}
              multiAccount={multiAccount}
              onClick={() =>
                history.push(
                  `/${artistId}/reporting/set-live/shows/${event.id}`,
                )
              }
            >
              View Details
            </ActionButton>
          )}
          {section === "set.live" &&
            showType === "upcoming" &&
            (event.status === "published" ? (
              <ActionButton
                variant="primary"
                live={live}
                multiAccount={multiAccount}
                onClick={() =>
                  window.open(
                    `/go?url=${
                      customApp?.onsiteAppUrl || process.env.REACT_APP_BS_URL
                    }/${event.id}`,
                    "_blank",
                  )
                }
              >
                {language.event.owner.singular} View
              </ActionButton>
            ) : (
              artistId === event.groupId && (
                <ActionButton
                  variant="primary"
                  live={live}
                  multiAccount={multiAccount}
                  onClick={() => {
                    window.location.href = `/set-live-creator/b/${event.id}/editor?ref=ap`;
                  }}
                >
                  Edit
                </ActionButton>
              )
            ))}
        </RightSideContainer>
        {modalOpen && (
          <Modal
            isOpen={modalOpen}
            header={`Delete Upcoming ${language.event.type.singular}`}
            onClose={handleCloseModal}
          >
            <Confirmation
              confirmLabel="Delete"
              confirmVariant="destructive"
              loading={statusRef.current === "deleting"}
              onCancel={handleCloseModal}
              onConfirm={handleDelete}
            >
              {(() => {
                switch (statusRef.current) {
                  case "deleting":
                    return (
                      <p>
                        Deleting {language.event.type.singular.toLowerCase()}...
                      </p>
                    );
                  case "error":
                    return (
                      <p style={{ color: "red" }}>
                        There was an error removing the event.
                      </p>
                    );
                  default:
                    return (
                      <>
                        Are you sure you wish to delete the{" "}
                        {language.event.type.singular.toLowerCase()} for "
                        {event.venue}" on{" "}
                        {DateTime.fromMillis(event.startsAt, {
                          zone: event.timeZoneName,
                        }).toLocaleString(DateTime.DATETIME_FULL)}
                        ?
                      </>
                    );
                }
              })()}
            </Confirmation>
          </Modal>
        )}
        <EditAdditionalGroups
          eventId={event.id}
          open={editAdditionalGroupsOpen}
          onUpdate={onUpdate}
          onClose={() => setEditAdditionalGroupsOpen(false)}
        />
      </>
    </StyledCard>
  );
};

export const EventCardLoader = () => {
  const isDesktop = useDesktopMediaQuery();
  Venue = !isDesktop ? H3 : H2;
  Info = !isDesktop ? Body2 : H3;

  return (
    <StyledCard isElevated live={false}>
      <>
        <StyledMapPinLoader>
          <Skeleton
            circle
            width={isDesktop ? 93 : 75}
            height={isDesktop ? 93 : 75}
          />
        </StyledMapPinLoader>
        <FlexColumn>
          <Venue>
            <Skeleton width={isDesktop ? 200 : 150} />
          </Venue>
          <Info>
            <Skeleton width={isDesktop ? 300 : 200} />
          </Info>
        </FlexColumn>
        <RightSideContainer>
          <ActionButtonLoader>
            <Skeleton height={isDesktop ? 44 : 36} width="100%" />
          </ActionButtonLoader>
        </RightSideContainer>
      </>
    </StyledCard>
  );
};

const MenuContainer = styled.div`
  position: absolute;
  top: 3px;
  right: 5px;
`;

interface StatusProps {
  status: SetLiveEvent["status"];
}

const Status = styled(H4)<StatusProps>`
  display: flex;
  align-items: center;
  gap: 5px;
  text-transform: uppercase;
  margin-top: 3px;

  ${({ status }) =>
    `color: ${status === "published" ? "#1DAB98" : "#FC6716"} }`};

  ${({ theme }) => theme.media.mobile} {
    font-size: 14px;
    justify-content: center;
  }
`;

interface ActionButtonProps {
  live: Props["live"];
  multiAccount: boolean;
}
const ActionButton = styled(Button)<ActionButtonProps>`
  ${({ live, multiAccount }) => !live && !multiAccount && "margin-left: auto"};
  min-width: 150px;

  ${({ theme }) => theme.media.desktop} {
    max-width: 180px;
  }

  ${({ theme }) => theme.media.mobile} {
    margin: 15px 0 0 0;
  }
`;

const ActionButtonLoader = styled.div`
  min-width: 150px;
  margin-left: auto;
  width: 100%;

  ${({ theme }) => theme.media.desktop} {
    max-width: 180px;
  }

  ${({ theme }) => theme.media.mobile} {
    margin-right: auto;
    margin-top: 15px;
  }
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex: 1;
  width: 100%;

  ${(p) => p.theme.media.mobile} {
    flex-direction: column;
  }
`;

const DesktopLiveNow = styled(RecordingIcon)`
  margin-left: auto;
  margin-right: auto;
`;

const MobileLiveNow = styled(RecordingIcon)`
  margin-bottom: 5px;
  margin-left: auto;
  margin-right: auto;
`;

const LiveNowText = styled.div`
  font-weight: 700;
  color: var(--text-color);
  font-size: 30px;
  line-height: 45px;
  font-family: Poppins;
`;

const StyledCalendar = styled(Calendar)`
  ${({ theme }) => theme.media.desktop} {
    margin-right: 22px;
  }

  ${({ theme }) => theme.media.mobile} {
    margin: 15px 0 10px;
  }
`;

const StyledMapPin = styled(svg.MapPinIcon)`
  margin-right: 17px;

  ${({ theme }) => theme.media.mobile} {
    width: 75px;
    height: 75px;
    margin: 15px 0 20px;
  }
`;

const StyledMapPinLoader = styled.div`
  width: 93px;
  height: 93px;
  margin-right: 17px;

  ${({ theme }) => theme.media.mobile} {
    width: 75px;
    height: 75px;
    margin: 15px 0 20px;
  }
`;

interface StyledCardProps {
  live: boolean;
}
const StyledCard = styled(Card)<StyledCardProps>`
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  padding: 33px 38px 32px;
  position: relative;

  ${({ live }) =>
    live && "background: var(--live-event-card-background-color)"};

  ${({ theme }) => theme.media.desktop} {
    box-shadow: 0px 0px 25px var(--box-shadow-color);
  }

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: center;
    padding: 14px 20px 25px 20px;
    border: none;

    & > div,
    span {
      text-align: center;
    }
  }

  ${Venue}, ${Info} {
    color: var(--text-color);
  }

  ${Subtitle1} {
    margin: 5px 0;
  }
`;

const AdditionalGroups = styled.div`
  margin: 0 20px 0 auto;

  ${(p) => p.theme.media.mobile} {
    margin: 15px 0 0 0;
  }
`;
