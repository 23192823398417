import React, { ComponentProps, MutableRefObject } from "react";
import styled, { css } from "styled-components";

const InputIcon = styled.div`
  position: absolute;
  top: 0;
  height: 100%;
`;
export const Label = styled.label`
  display: block;
  font-family: var(--max-font-primary);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
`;
export const Caption = styled.p<{ error?: boolean }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  color: ${(p) => (p.error ? "#FF0000" : "#666666")};
`;

const LeftIcon = styled(InputIcon)`
  color: #999999;
  left: 12px;
`;

const styleSecondaryInputDefault = css`
  background: transparent;
  border-radius: 0px;
  padding: 6px 12px 5px 0;
  color: #ffffff;
  border: none;
  border-bottom: 1px solid #666666;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;

  ::placeholder {
    color: #999999;
    font-family: var(--max-font-primary);
    font-weight: 300;
    font-size: 18px;
  }
  &:focus {
    border-bottom-color: #ffffff;
  }
`;

const styleSecondaryInputDisabled = css`
  background: transparent;
  border-bottom-color: rgba(255, 255, 255, 0.25);
`;

export const HTMLInput = styled.input`
  outline: none;
  border: none;
  width: 100%;
  box-sizing: border-box;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  -moz-appearance: textfield;

  background: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 6px;
  padding: 11px;
  font-family: var(--max-font-primary);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #000000;
  text-overflow: ellipsis;

  ::placeholder {
    color: #999999;
    font-family: var(--max-font-primary);
    font-weight: 400;
    font-size: 12px;
    line-height: 22px;
  }

  ${(p: any) => p.secondary && styleSecondaryInputDefault};
  ${(p: any) =>
    p.disabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      color: #999999;
      border: solid 1px #e6e9eb;
    `};

  ${(p: any) => p.secondary && p.disabled && styleSecondaryInputDisabled};
  ${(p: any) =>
    p.hasError &&
    css`
      border: solid 1px #ff0000;
    `};
  ${(p: any) =>
    p.hasError &&
    p.secondary &&
    css`
      border: none;
      border-bottom: solid 1px #ff0000;
    `};
  ${(p: any) =>
    p.hasRightIcon &&
    css`
      padding-right: 44px;
    `};
  ${(p: any) =>
    p.hasLeftIcon &&
    css`
      padding-left: 44px;
    `};
  ${(p: any) =>
    p.hasLeftIcon &&
    p.secondary &&
    css`
      padding-left: 32px;
    `};
`;

type InputWrapProps = {
  secondary?: boolean;
  disabled?: boolean;
};

export const InputWrap = styled.div<InputWrapProps>`
  width: 100%;

  ${Label} {
    color: var(--text-color);
    margin-bottom: 5px;
  }
  ${(p: any) =>
    p.secondary &&
    css`
      ${Label} {
        font-size: 14px;
        line-height: 20px;
        color: #ffffff80;
        margin-bottom: 0px;
        font-weight: 500;
        ${p.disabled &&
        css`
          color: rgba(255, 255, 255, 0.35);
        `}
      }
      ${LeftIcon} {
        left: 0;
        svg {
          color: #ffffff80;
        }
      }
    `};
`;

export type TextInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  "onChange"
> & {
  label?: string;
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  placeholder?: string;
  value?: string;
  type?: string;
  secondary?: boolean;
  onChange?: (v: string) => void;
  onBlur?: ComponentProps<"input">["onBlur"];
  disabled?: boolean;
  hasError?: boolean;
  onFocus?: () => void;
  helperText?: string;
  style?: any;
  inputRef?: MutableRefObject<HTMLInputElement>;
  asString?: boolean;
  className?: string;
};

export const TextInput = ({
  label = "",
  leftIcon,
  rightIcon,
  placeholder = "",
  value = "",
  secondary = false,
  type = "text",
  disabled = false,
  hasError = false,
  helperText,
  onChange,
  onBlur,
  onFocus,
  inputRef,
  asString = true,
  className,
  ...props
}: TextInputProps) => {
  return (
    <InputWrap secondary={secondary} disabled={disabled} className={className}>
      {label && <Label>{label}</Label>}
      <div style={{ position: "relative" }}>
        <LeftIcon
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {leftIcon}
        </LeftIcon>
        <HTMLInput
          value={value}
          ref={inputRef}
          {...(onChange && {
            onChange: (e) =>
              !disabled && onChange(asString ? e.target.value : (e as any)),
          })}
          //onBlur={() => onBlur && onBlur()}
          {...{
            type,
            hasLeftIcon: leftIcon,
            placeholder,
            secondary,
            disabled,
            onFocus,
            onBlur,
            hasError,
          }}
          {...props}
        />
      </div>
      {helperText && (
        <Caption
          style={{
            marginTop: "4px",
            color: `${hasError ? "#FF0000" : "#666666"}`,
          }}
        >
          {helperText}
        </Caption>
      )}
    </InputWrap>
  );
};
