import { ReactComponent as IconFavortiePoster } from "assets/svg/favorite-poster.svg";
import { SurveyTemplate } from "../../types";

export const releaseArtworkVibes: SurveyTemplate = {
  icon: <IconFavortiePoster />,
  title: "Release Artwork Vibes",
  description:
    "Share the artwork from an upcoming release and ask what vibes the fans get from it",
  templateId: "releaseArtworkVibes",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: formState.title,
        },
      },
    },
    surveyQuestions: [
      {
        type: "image",
        actionRequired: true,
        showLabels: false,
        src: "",
      },
      {
        id: "question_1",
        label: {
          en: "What vibes do you get from the artwork?",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-sweeps---r-0cde37"
        : "pp-release-ar-aaf6a6",
    fields: [
      {
        name: "format",
        type: "radio",
        label: "What format is this release?",
        options: ["Single", "EP", "Album"],
        getValidation: (fields) =>
          !!fields.format ? false : "You must select an option.",
      },
      {
        name: "title",
        type: "text",
        label: "What's the title of the release?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.title ? false : "This field cannot be left blank",
      },
      {
        name: "releaseDate",
        type: "text",
        label: "When will it be available (or is it already available)?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.releaseDate ? false : "This field cannot be left blank",
      },
    ],
  },
};
