import React from "react";
import styled, { css } from "styled-components";
import { FlexRow } from "Components/Flex";
import { Item } from ".";
import { formatNumber } from "Utils/format";

interface Props {
  total: number;
  item: Item;
  mode?: "number" | "percentage";
}

export const Bar: React.FC<Props> = ({ total, item, mode }) => {
  const percentage = total === 0 ? 0 : Math.round((item.value / total) * 100);
  const count = formatNumber(item.value);

  return (
    <Container>
      {item.image && <StyledImage src={item.image.src} alt={item.image.alt} />}
      <Content>
        <Label hasImage={!!item.image}>
          <Name>{item.label}</Name>
          {item.unit && (
            <Detail>
              ({count}{" "}
              {item.value === 1 ? item.unit.singular : item.unit.plural})
            </Detail>
          )}
        </Label>
        <BarContainer>
          <Background>
            <Fill percentage={percentage}>&nbsp;</Fill>
          </Background>
          <Value>{mode === "percentage" ? `${percentage}%` : count}</Value>
        </BarContainer>
      </Content>
    </Container>
  );
};

export const Container = styled(FlexRow)`
  display: flex;
  align-items: center;
  gap: 10px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

const Content = styled.div`
  flex: 1;
  width: 100%;
`;

const StyledImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;

  border: 3px solid #e9e9e9;
  border-radius: 10px;
`;

const Detail = styled.span`
  font-weight: 400;
  font-size: 12px;
  color: #aeaeae;
`;

const Name = styled.span`
  font-weight: 600;
  font-size: 12px;
  color: var(--main-color);
  display: inline-block;
  max-width: 300px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ theme }) => theme.media.tablet} {
    max-width: 250px;
  }

  ${({ theme }) => theme.media.mobile} {
    max-width: 200px;
  }
`;

interface LabelProps {
  hasImage: boolean;
}

const Label = styled.div<LabelProps>`
  display: flex;
  gap: 5px;
  line-height: 1.4;

  ${({ theme }) => theme.media.mobile} {
    ${({ hasImage }) =>
      hasImage &&
      css`
        justify-content: center;
      `};
  }
`;

const Value = styled.span`
  font-weight: 600;
  font-size: 17px;
  line-height: 26px;
  text-align: left;
  color: var(--secondary-text-color);
  min-width: 40px;
`;

const Background = styled.div(({ theme }) => {
  const { background } = theme.custom?.charts.bar.bar || {};
  return css`
    background: ${background || "#eeeeee"};
    border-radius: 10px;
    width: 100%;
  `;
});

interface FillProps {
  percentage: number;
}

const Fill = styled.div<FillProps>`
  height: 16px;
  background-color: var(--main-color);
  border-radius: 10px;
  ${({ percentage }) => `width: ${percentage}%`}
`;

const BarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;

  ${({ theme }) => theme.media.mobile} {
    gap: 15px;
  }
`;
