import { ReactComponent as TemplateIcon } from "assets/svg/specialReq.svg";
import { SurveyTemplate } from "../../types";

export const specialRequest: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Special Request",
  description: "Ask fans to register to make a special request for the live.",
  templateId: "specialRequest",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Special Request",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "What's your special request?",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-livestream-396db4"
        : "pp-livestream-73b4fa",
    fields: [
      {
        name: "date",
        type: "text",
        label: "When does your livestream take place?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.date ? false : "This field cannot be left blank",
      },
    ],
  },
};
