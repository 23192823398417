import React from "react";
import styled from "styled-components";
import { ProgressCircle } from "Components";
import { StatsItem } from "../StatsItem";

interface Stats {
  openRate: number;
  delivered: number;
  opened: number;
  clicks: number;
}

interface Props {
  stats: Stats;
}

export const EmailStats: React.FC<Props> = ({ stats }) => {
  return (
    <Container>
      <OpenRate>
        <StyledProgressCircle percentage={stats.openRate} color="#1C0D6A" />
        <OpenRateStatsItem value={`${stats.openRate}%`} label="Open Rate" />
      </OpenRate>
      <Items>
        <StatsItem value={stats.delivered} label="Confirmed Delivered" />
        <StatsItem value={stats.opened} label="Confirmed Opened" />
        <StatsItem value={stats.clicks} label="Total Clicks" />
      </Items>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px 30px 0;
  gap: 20px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    margin: 40px 0 0;
  }
`;

const Items = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
  }
`;

const OpenRate = styled.div`
  display: flex;
  position: relative;
  max-width: 160px;
  min-width: 120px;

  ${({ theme }) => theme.media.mobile} {
    max-width: 160px;
  }
`;

const OpenRateStatsItem = styled(StatsItem)`
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100px;
  height: 75px;
  ${({ theme }) => theme.media.mobile} {
    height: 70px;
  }
`;

const StyledProgressCircle = styled(ProgressCircle)`
  margin-top: -15px;
`;
