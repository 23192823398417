import { H3 } from "melodies-source/Text";
import { PieChart } from "Components/PieChart";
import { FlatCard, NoData } from ".";
import { Metric } from "../aggregate";
import { useTheme } from "styled-components";

interface ClicksByDSPProps {
  metrics?: Metric[];
}

export const ClicksByDSP = ({ metrics, ...props }: ClicksByDSPProps) => {
  const theme = useTheme();
  return (
    <FlatCard {...props}>
      <H3>Clicks by DSP</H3>
      {metrics?.length ? (
        <PieChart
          dataKey="clicks"
          nameKey="label"
          data={metrics}
          colors={theme.custom?.charts?.donut.colors}
        />
      ) : (
        <NoData />
      )}
    </FlatCard>
  );
};
