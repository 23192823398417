import {
  Bar,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Cell,
  Legend,
  LabelList,
} from "recharts";
import styled from "styled-components";
import { CustomTooltip } from "./CustomTooltip";
import { TickFormatter } from "recharts/types/cartesian/CartesianAxis";
import { BaseAxisProps } from "recharts/types/util/types";
import {
  NameType,
  Payload,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { Payload as LegendPayload } from "recharts/types/component/DefaultLegendContent";
import { H4 } from "melodies-source/Text";
import { FlexRow } from "Components/Flex";
import { ReactComponent as ChartLineSymbol } from "assets/svg/chart-legend-line.svg";
import { Props } from "recharts/types/component/ResponsiveContainer";

export type KeyedValue = {
  [key: string]: number | string;
  id: string;
  fill?: string;
};

const ChartTypes = ["bar", "line"] as const;
type ChartType = (typeof ChartTypes)[number];

export type ChartDefinition = {
  dataKey: string;
  chartType: ChartType;
  label?: string;
  color?: string;
  fill?: string;
  formatValue?: (v: Payload<ValueType, NameType>) => string;
  showLabel?: boolean;
  formatLabel?: (label: any) => number | string;
  tooltipLabel?: string;
  secondaryAxisId?: string;
};

type TickFontAttributes = {
  fontSize?: string;
  fontWeight?: number | string;
  color?: string;
};

interface MixedChartProps
  extends React.RefAttributes<HTMLDivElement>,
    Omit<Props, "children"> {
  data?: KeyedValue[];
  defs?: ChartDefinition[];
  indexBy?: string;
  tickFormatter?: TickFormatter;
  layout?: "horizontal" | "vertical";
  hideLegend?: boolean;
  hideYAxis?: boolean;
  hideTooltip?: boolean;
  tickFont?: TickFontAttributes;
  tickMarginXAxis?: number;
  secondaryAxisId?: string;
}

export const MixedChart = ({
  data,
  indexBy,
  defs,
  tickFormatter,
  layout = "horizontal",
  hideLegend,
  hideYAxis,
  hideTooltip,
  tickFont,
  tickMarginXAxis,
  secondaryAxisId,
  ...props
}: MixedChartProps) => {
  const bars = defs?.filter(({ chartType }) => chartType === "bar");
  const lines = defs?.filter(({ chartType }) => chartType === "line");

  const settings = {
    horizontal: {
      barCategoryGap: "15%",
      fontSize: "14px",
      ...tickFont,
      margin: {
        top: 5,
        right: 5,
        bottom: 5,
        left: 5,
      },
      xAxis: {
        dataKey: indexBy,
        interval: 0,
        minTickGap: 0,
        tickMargin: tickMarginXAxis || 15,
        tickFormatter,
        type: "category",
      } as BaseAxisProps,
      yAxis: {
        tickMargin: 20,
        type: "number",
        padding: { top: 10 },
      } as BaseAxisProps,
    },
    vertical: {
      barCategoryGap: "20%",
      fontSize: "10px",
      ...tickFont,
      margin: {
        top: 0,
        right: 5,
        bottom: 5,
        left: 0,
      },
      xAxis: {
        tickMargin: tickMarginXAxis || 20,
        type: "number",
      } as BaseAxisProps,
      yAxis: {
        dataKey: indexBy,
        interval: 0,
        minTickGap: 0,
        tickMargin: 10,
        tickFormatter,
        type: "category",
      } as BaseAxisProps,
    },
  };

  const activeSettings = settings[layout];

  const renderLegend = ({ payload }: { payload: LegendPayload[] }) => {
    const getDefinition = (key: string) =>
      defs.find(({ dataKey }) => dataKey === key);

    return (
      <LegendContainer>
        {payload.map((entry, index) => {
          const def = getDefinition(entry.value);
          const fillShape =
            def.chartType === "line" ? (
              <ChartLineSymbol
                style={{ color: entry.color, width: 20, height: 20 }}
              />
            ) : (
              <Color style={{ background: entry.color || def.color }} />
            );
          return (
            <FlexRow yCenter gap="8px">
              {fillShape}
              <H4 key={`legend-item-${index}`}>{def?.label || entry.value}</H4>
            </FlexRow>
          );
        })}
      </LegendContainer>
    );
  };

  return (
    <Container {...props}>
      <ComposedChart
        data={data}
        barCategoryGap={activeSettings.barCategoryGap}
        layout={layout}
        margin={activeSettings.margin}
      >
        <XAxis
          axisLine={false}
          tickLine={false}
          style={{
            fill: activeSettings.color || "var(--disabled-color)",
            fontSize: activeSettings.fontSize,
            fontWeight: activeSettings.fontWeight,
          }}
          allowDataOverflow
          allowDecimals={false}
          {...activeSettings.xAxis}
        />
        {!hideYAxis && (
          <>
            <YAxis
              axisLine={false}
              tickLine={false}
              style={{
                fill: "var(--disabled-color)",
                fontSize: activeSettings.fontSize,
              }}
              allowDataOverflow
              allowDecimals={false}
              {...activeSettings.yAxis}
            />
            {secondaryAxisId &&
              (layout === "horizontal" ? (
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  style={{
                    fill: "var(--disabled-color)",
                    fontSize: activeSettings.fontSize,
                  }}
                  allowDataOverflow
                  allowDecimals={false}
                  {...activeSettings.yAxis}
                  yAxisId={secondaryAxisId}
                  unit="%"
                  orientation="right"
                />
              ) : (
                <XAxis
                  axisLine={false}
                  tickLine={false}
                  style={{
                    fill: activeSettings.color || "var(--disabled-color)",
                    fontSize: activeSettings.fontSize,
                    fontWeight: activeSettings.fontWeight,
                  }}
                  allowDecimals={false}
                  {...activeSettings.xAxis}
                  xAxisId={secondaryAxisId}
                  unit="%"
                  orientation="top"
                />
              ))}
          </>
        )}
        {!hideTooltip && (
          <Tooltip
            isAnimationActive={false}
            cursor={false}
            content={<CustomTooltip defs={defs} />}
          />
        )}
        {!hideLegend && <Legend align="center" content={renderLegend} />}
        {bars?.map((barData, barIdx) => (
          <Bar
            key={`bar-${barIdx}`}
            dataKey={barData.dataKey}
            activeBar={{ opacity: 0.5 }}
            minPointSize={9}
            {...(barData.dataKey === secondaryAxisId && {
              [layout === "horizontal" ? "yAxisId" : "xAxisId"]:
                secondaryAxisId,
            })}
          >
            {barData.showLabel && (
              <LabelList
                dataKey={barData.dataKey}
                position="top"
                formatter={barData.formatLabel}
                style={{ fontSize: "10px", fontWeight: 700 }}
              />
            )}
            {data?.map((entry, index) => (
              <Cell
                key={`bar-cell-${index}`}
                fill={entry.fill || barData.fill || "#0095EF"}
              />
            ))}
          </Bar>
        ))}
        {lines?.map((lineData, index) => (
          <Line
            key={`line-${index}`}
            activeDot={{ strokeWidth: 3 }}
            dataKey={lineData.dataKey}
            dot={{
              fill: lineData.fill || "var(--main-color)",
              strokeWidth: 5,
            }}
            stroke={lineData.fill || "var(--main-color)"}
            strokeWidth={4}
            type="linear"
            animationDuration={750}
            animationBegin={200}
            {...(lineData.dataKey === secondaryAxisId && {
              [layout === "horizontal" ? "yAxisId" : "xAxisId"]:
                secondaryAxisId,
            })}
          />
        ))}
      </ComposedChart>
    </Container>
  );
};

const Container = styled(ResponsiveContainer)`
  width: 100%;
  min-height: 400px;

  svg.recharts-surface {
    overflow: visible;
  }
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 32px;

  ${H4} {
    color: var(--secondary-text-color);
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 16px;
    margin-top: 20px;
    ${H4} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const Color = styled(FlexRow)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  flex-shrink: 0;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 12px;
    height: 12px;
  }
`;
