import { Dispatch, PropsWithChildren } from "react";
import { ModalType } from "melodies-source/Modal";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import { CenterFlexRow } from "Routes/styled";
import { Body1, H2 } from "melodies-source/Text";
import styled from "styled-components";
import { SurveyQuestionModule } from "@max/common/dist/setfan";
import { ResponsiveModal } from "Components/ResponsiveModal";
import {
  SurveyModulesDispatch,
  SurveyModulesState,
} from "Routes/SetFan/hooks/useSurveyModules";

export type QuestionType =
  | "freeResponseQuestion"
  | "imageQuestion"
  | "multipleChoiceQuestion"
  | "ratingQuestion"
  | "image"
  | "text";

export type QuestionModalProps<T = SurveyQuestionModule> = {
  isOpen: boolean;
  onClose: () => void;
  dispatch: Dispatch<SurveyModulesDispatch>;
  state: SurveyModulesState;
  existing?: T;
};

export type QuestionTypeMeta = {
  icon: JSX.Element;
  key: QuestionType;
  header: string;
  subHeader: string;
  body: (props: Omit<QuestionModalProps, "state">) => JSX.Element;
  variant?: ModalType;
};

export const BaseQuestionModal = (
  props: PropsWithChildren<QuestionModalProps & QuestionTypeMeta>,
) => {
  const { unlockScroll } = useScrollLockContext();
  const BodyComponent = props.body;

  return (
    <ResponsiveModal
      isOpen={props.state.isOpen}
      onClose={() => {
        unlockScroll();
        props.dispatch({ type: "closeModal" });
      }}
      variant={props.variant ?? "large"}
      withBackdropClose={false}
      header={
        <>
          <CenterFlexRow>
            {props.icon && <IconContainer>{props.icon}</IconContainer>}
            <StyledHeader>{props.header}</StyledHeader>
          </CenterFlexRow>
          {props.subHeader && <Body1>{props.subHeader}</Body1>}
        </>
      }
    >
      <BodyComponent {...props} />
    </ResponsiveModal>
  );
};

const StyledHeader = styled(H2)`
  color: var(--main-color) !important;
`;

const IconContainer = styled.div`
  margin-right: 16px;
  & > svg {
    display: block;
    width: 32px;
    height: 32px;
    color: var(--main-color);
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-right: 12px;
    & > svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const QuestionCardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
