import { HourlyAnalytics as HourlyAnalyticsBase } from "@max/common/src/setpage/analytics";
import { firestore } from "firebase-internal";
import {
  query,
  collection,
  where,
  documentId,
  DocumentData,
  FirestoreDataConverter,
  Query,
  QueryDocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { DateTime, Interval } from "luxon";
import { useEffect, useMemo, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { DateRange } from "Routes/Reporting/SetBio";
import {
  aggregateHourlyMetrics,
  Metrics,
} from "Routes/Reporting/SetBio/aggregate";
import {
  getClicksBySection,
  getDailyMetrics,
  getTopClicks,
  getTopReleases,
  getTopSources,
  getTotalClicks,
  SET_PAGE_MODULE_NAMES,
} from "Routes/Reporting/SetBio/getMetrics";

export type HourlyAnalyticsWithId = HourlyAnalyticsBase & {
  id: string;
  date?: DateTime;
};

interface UseSetBioAnalyticsProps {
  dateRange?: DateRange;
  artistId: string;
}

const postConverter: FirestoreDataConverter<HourlyAnalyticsBase> = {
  toFirestore(hour: WithFieldValue<HourlyAnalyticsBase>): DocumentData {
    return hour;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): HourlyAnalyticsWithId {
    const data = snapshot.data(options) as HourlyAnalyticsBase;
    return {
      ...data,
      id: `${Math.floor(parseInt(snapshot.id) / 24)}`,
    };
  },
};

const topLinkTypes = Object.keys(SET_PAGE_MODULE_NAMES).filter(
  (type) =>
    !["spotify_embed", "embed", "social", "image_gallery"].includes(type),
);

const initialMetrics = {
  metricsByDay: [],
  clicksBySection: [],
  clicksByDsp: [],
  topReleases: [],
  topSources: [],
  topLinks: [],
  totalClicks: 0,
  totalVisits: 0,
  uniqueVisits: 0,
  signups: 0,
  modules: {},
};

type UseSetBioAnalytics = {
  loading: boolean;
  hasAnalytics: boolean;
} & Metrics;

export const useSetBioAnalytics = ({
  artistId,
  dateRange,
}: UseSetBioAnalyticsProps): UseSetBioAnalytics => {
  const [metrics, setMetrics] = useState<Metrics>(initialMetrics);
  const [loading, setLoading] = useState(true);

  const duration = useMemo(() => {
    const toHours = (dates: DateTime[]) =>
      dates.map((date) => Math.floor(date.toMillis() / (60 * 60 * 1000)));
    const startRange = (dateRange.start || DateTime.now()).endOf("day");
    const start = startRange.startOf("day").minus(dateRange.duration);
    const end = startRange;
    return toHours([start, end]);
  }, [dateRange]);

  const dates = useMemo(() => {
    const start = (dateRange.start || DateTime.now()).endOf("day");
    return Interval.fromDateTimes(
      start.startOf("day").minus(dateRange.duration),
      start,
    )
      .splitBy({ days: 1 })
      .map((d) => d.start);
  }, [dateRange]);

  const [docs, docsLoading] = useCollectionData(
    duration &&
      (query(
        collection(
          firestore,
          "set_page",
          artistId,
          "analytics_hourly",
        ).withConverter(postConverter),
        where(documentId(), ">", duration[0].toString()),
        where(documentId(), "<", duration[1].toString()),
      ) as Query<HourlyAnalyticsWithId>),
  );

  const processMetrics = (hours: HourlyAnalyticsWithId[]): Metrics => {
    const aggregatedMetrics = aggregateHourlyMetrics(hours);
    return {
      metricsByDay: getDailyMetrics(hours, dates),
      clicksBySection: getClicksBySection(aggregatedMetrics.links),
      clicksByDsp: getTopClicks(
        aggregatedMetrics,
        ["music_links"],
        (v) => v.split(":")?.[1],
        "",
      ),
      topReleases: getTopReleases(aggregatedMetrics),
      topSources: getTopSources(aggregatedMetrics.sources),
      topLinks: getTopClicks(aggregatedMetrics, topLinkTypes),
      totalClicks: getTotalClicks(aggregatedMetrics.links),
      totalVisits: aggregatedMetrics.visits?.total || 0,
      uniqueVisits: aggregatedMetrics.visits?.unique || 0,
      signups: aggregatedMetrics.signups || 0,
      modules: aggregatedMetrics.modules,
    };
  };

  useEffect(() => {
    if (docs && !docsLoading) {
      const processedMetrics = processMetrics(docs);
      setMetrics(processedMetrics);
      setLoading(false);
    }
  }, [docs, docsLoading]);

  return {
    ...metrics,
    loading: loading || docsLoading,
    hasAnalytics: !docsLoading && !!docs?.length,
  };
};
