import styled, { css } from "styled-components";
import { bucketImageSource } from "assets/bucketImages";
const AppNames = ["live", "fan", "bio"] as const;
export type AppName = (typeof AppNames)[number];

interface PageHeaderImageProps extends React.HTMLAttributes<HTMLDivElement> {
  app?: AppName | "default";
  isExtended?: boolean;
}

export const PageHeaderImage = ({
  app = "default",
  isExtended,
  ...props
}: PageHeaderImageProps) => (
  <HeaderImage
    {...props}
    isExtended={isExtended}
    image={bucketImageSource.header[app]}
  />
);

type HeaderImageProps = { isExtended?: boolean; image?: string };

const HeaderImage = styled.div<HeaderImageProps>`
  background-position: left top;
  background-size: cover;
  width: 100%;
  height: 205px;
  position: absolute;

  ${({ theme, image }) =>
    !theme.custom
      ? css`
          background: ${`url(${image})`};
          z-index: -1;
        `
      : css`
          background: ${theme.custom?.layout?.content?.backgroundHeader
            ?.background};
        `}

  ${({ isExtended }) =>
    isExtended &&
    css`
      height: 300px;
    `};

  ${({ theme }) => theme.media.mobile} {
    height: 142px;

    ${({ isExtended }) =>
      isExtended &&
      css`
        height: 178px;
      `};
  }
`;
