import {
  collection,
  getFirestore,
  Query,
  query,
  where,
} from "firebase/firestore";
import { ArtistSubdomain } from "models/artistSubdomain";
import { useCollection } from "react-firebase-hooks/firestore";

export const useArtistGroupSubdomain = (artistId: string) => {
  const [snapshot, loading] = useCollection(
    query(
      collection(getFirestore(), "artist_subdomain"),
      where("artistGroupId", "==", artistId),
    ) as Query<ArtistSubdomain>,
  );

  return [snapshot?.docs?.[0], loading] as const;
};
