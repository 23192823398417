import { Tooltip } from "melodies-source/Tooltip";
import styled, { css } from "styled-components";

type ContainerProps = {
  value: boolean;
  isDisabled: boolean;
};
const Container = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  position: relative;

  width: 42px;
  height: 22px;
  background: #1b0076;
  border: 2px solid #151f30;
  border-radius: 14px;
  user-select: none;
  ${(props) =>
    !props.value &&
    css`
      background: #ccc;
      border: 2px solid #ccc;
    `};
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
  ${(props) =>
    props.isDisabled &&
    css`
      pointer-events: none;
    `}
`;

type CircleProps = {
  value: boolean;
};
const Circle = styled.div<CircleProps>`
  position: absolute;
  width: 20px;
  height: 20px;
  border: solid 2px white;
  background: #ffffff;
  border-radius: 100%;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.24);
  left: ${({ value }) => (value ? "18px" : "0px")};
  transition: left 0.25s;
`;

type Props = {
  value: any;
  onChange?: (v: boolean) => void;
  isDisabled?: boolean;
  disabledTooltip?: JSX.Element | JSX.Element[];
  className?: string;
};
export const Switch = ({
  value,
  onChange,
  className,
  disabledTooltip,
  isDisabled = false,
}: Props) => {
  const SwitchComponent = (
    <Container
      value={value}
      className={className}
      isDisabled={isDisabled}
      onClick={(e) => {
        e.stopPropagation();
        if (onChange) onChange(!value);
      }}
    >
      <Circle value={value} />
    </Container>
  );
  return isDisabled && disabledTooltip ? (
    <Tooltip parent={SwitchComponent} placement="top">
      {disabledTooltip}
    </Tooltip>
  ) : (
    SwitchComponent
  );
};
