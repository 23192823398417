import { ReactComponent as IconFavortieTrack } from "assets/svg/favorite-track.svg";
import { SurveyTemplate } from "../../types";

export const favPrevRelease: SurveyTemplate = {
  icon: <IconFavortieTrack />,
  title: "Favorite Previous Release",
  description:
    "Ask fans to share their favorite past single or album by the artist",
  templateId: "favPrevRelease",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Favorite Previous Release",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: { en: "Which Album is your favorite?" },
        type: "SingleSelectQuestion",
        options: [
          { label: { en: "Album 1" } },
          { label: { en: "Album 2" } },
          { label: { en: "Album 3" } },
        ],
      },
      {
        id: "question_2",
        label: { en: "Why is this Album your favorite?" },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-sweeps---f-793be5"
        : "pp-favorite-p-def222",
    fields: [
      {
        name: "format",
        type: "radio",
        label: "What format are these releases?",
        options: ["Singles", "EPs", "Albums"],
        getValidation: (fields) =>
          fields.format ? false : "You must select an option.",
      },
      {
        name: "tracks",
        type: "textarea",
        label: "Paste in the list below",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.tracks ? false : "This field cannot be left blank",
      },
    ],
  },
};
