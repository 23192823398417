import React, { useContext, useEffect, useMemo, useState } from "react";
import { Modal } from "melodies-source/Modal";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { PhoneMFA } from "./PhoneMFA";
import { TotpMFA } from "./TotpMFA";
import { SelectMFA } from "./SelectMFA";
import useUserIdToken from "hooks/useUserIdToken";
import { GetAccountInfoUserResponse } from "firebase-admin/lib/auth/user-record";
import { Spinner } from "melodies-source/Spinner";
import { FactorDetail } from "./FactorDetail";

interface Props {
  parent?: string;
  onClose?: () => void;
  onSuccess?: () => void;
  withModal?: boolean;
}

export enum Step {
  SelectMFA,
  FactorDetail,
  SetupPhone,
  SetupTOTP,
}

interface Context {
  setStep: (step: Step) => void;
  onClose: () => void;
  onSuccess: () => void;
}

const SetupMFAContext = React.createContext(undefined);

export const SetupMFA: React.FC<Props> = ({
  parent,
  onClose,
  onSuccess,
  withModal = true,
}) => {
  const [user, setUser] = useState<GetAccountInfoUserResponse>(null);
  const [userLoading, setUserLoading] = useState(true);
  const [step, setStep] = useState(Step.SelectMFA);
  const history = useHistory();
  const idToken = useUserIdToken();

  const handleClose = () =>
    parent ? history.push(parent) : setStep(Step.SelectMFA);

  let screen = null;

  switch (step) {
    case Step.SelectMFA: {
      screen = <SelectMFA />;
      break;
    }
    case Step.FactorDetail: {
      screen = <FactorDetail mfaInfo={user?.mfaInfo} />;
      break;
    }
    case Step.SetupPhone: {
      screen = <PhoneMFA />;
      break;
    }
    case Step.SetupTOTP: {
      screen = <TotpMFA />;
      break;
    }
  }

  const value = useMemo(
    () => ({
      setStep,
      onClose: onClose || handleClose,
      onSuccess: onSuccess || handleClose,
    }),
    [],
  );

  useEffect(() => {
    if (!user && idToken)
      fetch(
        `https://identitytoolkit.googleapis.com/v1/accounts:lookup?key=${process.env.REACT_APP_FIREBASE_APIKEY}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ idToken }),
        },
      )
        .then((res) => res.json())
        .then((res) => {
          const user = res?.users[0] as GetAccountInfoUserResponse;
          if (user) {
            if (user.mfaInfo?.length === 1) {
              setStep(Step.FactorDetail);
            }
            setUser(user);
          }
        })
        .finally(() => {
          setUserLoading(false);
        });
  }, [user, idToken]);

  const content = (
    <SetupMFAContext.Provider value={value}>
      <Container>{userLoading ? <Spinner /> : screen}</Container>
    </SetupMFAContext.Provider>
  );

  return !withModal ? (
    content
  ) : (
    <Modal
      header="Set up MFA"
      withCloseIcon={false}
      isOpen={true}
      onClose={handleClose}
    >
      {content}
    </Modal>
  );
};

export const useSetupMFAContext = (): Context => {
  const context = useContext(SetupMFAContext);

  if (context === undefined) {
    throw new Error("useSetupMFAContext must be used within a SetupMFAContext");
  }

  return context;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
