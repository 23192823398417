import React, { useEffect, useState } from "react";
import styled from "styled-components";

interface Props {
  progressStrokeColor?: string;
  backgroundStrokeColor?: string;
  progress?: number;
  loadingTime?: number;
  icon?: React.ReactNode;
  onFinish?: () => void;
}

export const CircularProgress: React.FC<Props> = ({
  progressStrokeColor = "var(--main-color)",
  backgroundStrokeColor = "#504D59",
  progress,
  loadingTime = 2000,
  icon,
  onFinish,
}) => {
  const [internalProgress, setInternalProgress] = useState(0);
  const circleRadius = 66.5;
  const circumference = 2 * Math.PI * circleRadius;
  const currentProgress = progress || internalProgress;
  const offset = circumference - (currentProgress / 100) * circumference;

  useEffect(() => {
    let interval;
    if (!progress && internalProgress === 0) {
      interval = setInterval(() => {
        setInternalProgress((progress) => {
          if (progress === 99) clearInterval(interval);
          return progress + 1;
        });
      }, loadingTime / 100);
    }
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentProgress === 100 && onFinish) onFinish();
  }, [currentProgress]);

  return (
    <Container>
      <SvgContainer>
        <svg
          width="141"
          height="141"
          viewBox="0 0 141 141"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="70.5"
            cy="70.5"
            r={circleRadius}
            stroke={backgroundStrokeColor}
            strokeOpacity="0.15"
            strokeWidth="7"
          />
          <circle
            cx="70.5"
            cy="70.5"
            r={circleRadius}
            stroke={progressStrokeColor}
            strokeWidth="7.6"
            strokeLinecap="round"
            strokeDasharray={circumference}
            strokeDashoffset={offset}
            transform="rotate(-90 70.5 70.5)"
          />
        </svg>
        {icon && <IconContainer>{icon}</IconContainer>}
      </SvgContainer>
      <span>{currentProgress}%</span>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const SvgContainer = styled.div`
  position: relative;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;
