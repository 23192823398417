import { SurveyStatus, SurveyTypesenseDocument } from "@max/common/setfan";
import { useArtistContext } from "Components";
import { useTypesenseProxyContext } from "contexts/TypesenseProxyContext";
import { useCallback, useEffect, useState } from "react";

export const useMostRecentSurvey = ({
  onLoad,
  statuses = ["live"],
}: {
  onLoad?: (survey: SurveyTypesenseDocument) => void;
  statuses?: SurveyStatus[];
} = {}) => {
  const [survey, setSurvey] = useState<SurveyTypesenseDocument>();
  const [loading, setLoading] = useState(false);
  const { id: artistGroupId } = useArtistContext();

  const { proxySearch } = useTypesenseProxyContext();

  const stableOnLoad = useCallback(onLoad, []);
  const statusFilter = statuses.join(",");

  useEffect(() => {
    if (proxySearch) {
      setLoading(true);
      proxySearch("surveys", {
        q: "*",
        query_by: "surveyId",
        limit: 1,
        per_page: 1,
        sort_by: "createdAt:desc",
        filter_by: `artistGroupId:=${artistGroupId} && status:=[${statusFilter}]`,
      })
        .then((response) => {
          if (response?.hits?.length) {
            setSurvey(response.hits[0].document);
            stableOnLoad?.(response.hits[0].document);
          }
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    }
  }, [proxySearch, artistGroupId, stableOnLoad, statusFilter]);

  return { survey, loading };
};
