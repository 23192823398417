import styled, { css } from "styled-components";
import { NavLink as RouterLink, NavLinkProps } from "react-router-dom";
import { RefAttributes } from "react";
import { Tooltip } from "melodies-source/Tooltip";
import { useMenuContext } from "Components/MenuProvider";

interface NavLinkInterface
  extends NavLinkProps,
    RefAttributes<HTMLAnchorElement> {
  label?: string;
}

export const NavLink = ({ children, label, ...props }: NavLinkInterface) => {
  const { menuMode } = useMenuContext();

  if (menuMode !== "expanded") {
    return (
      <Tooltip
        parent={
          <Link {...props} activeClassName="active">
            {children}
          </Link>
        }
        placement="right"
      >
        {label}
      </Tooltip>
    );
  }

  return (
    <Link {...props} activeClassName="active">
      {children}
    </Link>
  );
};

const Link = styled(RouterLink)`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 0 0 32px;
  color: inherit;
  font-weight: 500;
  line-height: 24px;
  height: 44px;
  font-family: Poppins;
  font-size: 16px;
  letter-spacing: 0;
  text-decoration: none;
  white-space: nowrap;
  &.active {
    color: #1b0076;
    background-color: #e8e5f1;
    border-radius: 0 24px 24px 0;
    font-weight: 600;
  }
  ${({ theme }) => {
    if (!theme.custom) return null;
    const { background, color } = theme.custom.layout.sideBar.active;
    return css`
      &.active {
        color: ${color};
        background-color: ${background};
      }
    `;
  }}
`;
