import { TextInput } from "Components";
import { useState } from "react";
import { Selectable } from "melodies-source/Selectable";
import { QuestionModalProps, QuestionTypeMeta } from "./BaseQuestionModal";
import { SvgHelp } from "melodies-source/Svgs/Help";
import { SvgDetails } from "melodies-source/Svgs/Details";
import { AddQuestionFooter } from "./Footer";
import { TextQuestionModule } from "@max/common/dist/setfan";
import { QuestionCard } from "../../QuestionCard";

export const FreeResponseQuestionModal = ({
  existing,
  ...props
}: Omit<QuestionModalProps<TextQuestionModule>, "state">) => {
  const [questionText, setQuestionText] = useState(existing?.label.en ?? "");
  const [allowLongResponse, setAllowLongResponse] = useState(
    !!existing?.multiline,
  );
  const [required, setRequired] = useState(!!existing?.required);
  const [error, setError] = useState("");
  const [validationTriggered, setValidationTriggered] = useState(false);

  const onAdd = () => {
    setValidationTriggered(true);

    if (!questionText.length) {
      setError("This is a required field and cannot be blank");
      return;
    }

    const newModule = new TextQuestionModule({
      id: existing?.id,
      multiline: allowLongResponse,
      label: { en: questionText },
    });

    props.dispatch(
      existing
        ? {
            type: "updateModule",
            payload: { idToReplace: existing.id, module: newModule },
          }
        : {
            type: "addModule",
            payload: { module: newModule },
          },
    );
    props.dispatch({ type: "closeModal" });
  };

  return (
    <>
      <QuestionCard title="Question" icon={<SvgHelp />}>
        <TextInput
          label="What is your question?"
          placeholder="Type your question..."
          value={questionText}
          onChange={(text) => {
            if (validationTriggered && !text) {
              setError("This is a required field and cannot be blank");
            }
            if (text) {
              setError("");
            }
            setQuestionText(text);
          }}
          style={{ maxWidth: 550 }}
          hasError={!!error}
          helperText={error}
        />
        <Selectable
          variant="checkbox"
          label="Allow Long Response"
          value={allowLongResponse}
          onChange={(checked) => setAllowLongResponse(checked)}
          style={{ marginTop: 20 }}
        />
      </QuestionCard>
      <AddQuestionFooter
        required={required}
        setRequired={setRequired}
        confirmLabel={existing ? "Update" : "Add"}
        cancelLabel={existing ? "Cancel" : "Back"}
        onCancel={() =>
          props.dispatch({ type: existing ? "closeModal" : "openModal" })
        }
        onConfirm={onAdd}
        confirmDisabled={!questionText}
      />
    </>
  );
};

export const freeResponse: QuestionTypeMeta = {
  icon: <SvgDetails />,
  key: "freeResponseQuestion",
  header: "Free response",
  subHeader: "Let a participant enter their own free-form response",
  body: FreeResponseQuestionModal,
};
