import { getConfigFromHost } from "@max/thirdparty";
import { gtb, towne, fizz } from "./companies";

const companies = [
  [/gtbevents/, gtb] as const,
  [/towne/, towne] as const,
  [/fizz/, fizz] as const,
];

export const getConfig = (host: string) => getConfigFromHost(host, companies);
