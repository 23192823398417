import React, { useEffect, useState } from "react";
import { GtbAddMemberRequest, GtbRegion } from "@max/common/src/thirdparty/gtb";
import { Profile } from "@max/common/src/user";
import {
  QueryDocumentSnapshot,
  doc,
  getFirestore,
  setDoc,
} from "firebase/firestore";
import { toast } from "react-hot-toast";
import styled from "styled-components";
import { TextInput } from "melodies-source/TextInput";
import { Label } from "melodies-source/Text";
import { ReactComponent as EmailIcon } from "assets/svg/email-outlined.svg";
import { Modal } from "../../../../../../../../Components/Modal";
import { functions } from "firebase-internal";
import { httpsCallable } from "firebase/functions";
import { FormikConfig, useFormik } from "formik";
import * as yup from "yup";
import { yupRequired, yupEmail } from "Utils/yup";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { GtbCustomApp } from "custom/companies/gtb/types";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  region: QueryDocumentSnapshot<GtbRegion>;
  member?: QueryDocumentSnapshot<Profile>;
}

const INITIAL_VALUES = { firstName: "", lastName: "", role: "", email: "" };

const VALIDATION_SCHEMA = yup.object().shape({
  firstName: yupRequired,
  lastName: yupRequired,
  role: yupRequired,
  email: yupEmail,
});

export const MemberModal: React.FC<Props> = ({
  isOpen,
  onClose,
  region,
  member,
}) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: !member ? handleAdd : handleEdit,
  });
  const { customApp } = useCustomAppContext<GtbCustomApp>();

  async function handleAdd(
    ...params: Parameters<FormikConfig<typeof INITIAL_VALUES>["onSubmit"]>
  ) {
    setLoading(true);
    const [{ firstName, lastName, role, email }, { resetForm }] = params;
    try {
      await httpsCallable<GtbAddMemberRequest>(
        functions,
        "gtb-addMember",
      )({
        regionId: region.id,
        member: {
          email,
          firstName,
          lastName,
          role,
        },
      });
      toast.success(`Member added successfully!`);
    } catch (error) {
      console.log(error);
      toast.error("There was an error adding the member");
    }
    setLoading(false);
    onClose();
    resetForm();
  }

  const updateRole = async (memberId: string, role: string) => {
    const roles = { ...(region.data().roles || {}) };
    roles[memberId] = role;
    await setDoc(
      doc(getFirestore(), `${customApp.regionsCollection}/${region.id}`),
      {
        roles,
        updatedAt: new Date(),
      },
      { merge: true },
    );
  };

  const updateProfile = async (memberId: string, data: Partial<Profile>) => {
    await setDoc(doc(getFirestore(), `profiles/${memberId}`), data, {
      merge: true,
    });
  };

  async function handleEdit() {
    setLoading(true);
    try {
      await updateRole(member.id, formik.values.role);
      await updateProfile(member.id, {
        name: {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
        },
      });
      toast.success(`Member edited successfully!`);
    } catch (error) {
      console.log(error);
      toast.error(`There was an error updating the member's profile`);
    }

    setLoading(false);
    onClose();
  }

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  useEffect(() => {
    if (member) {
      const { name, verifiedEmail } = member?.data() || {};
      const { roles } = region?.data() || {};
      formik.setValues({
        firstName: name?.firstName || "",
        lastName: name?.lastName || "",
        role: roles?.[member.id] || "",
        email: verifiedEmail || "",
      });
    } else {
      formik.resetForm();
    }
  }, [member]);

  const actionName = !member ? "Add" : "Edit";

  return (
    <Modal
      isOpen={isOpen}
      header={`${actionName} Member`}
      onClose={handleClose}
      actions={{
        secondary: {
          text: "Cancel",
          onClick: handleClose,
        },
        main: {
          text: actionName,
          onClick: formik.handleSubmit,
          loading,
        },
      }}
      withCloseIcon
      isForm
    >
      <Name>
        <Label>Name</Label>
        <div>
          <TextInput
            placeholder="First..."
            value={formik.values.firstName}
            onChange={formik.handleChange("firstName")}
            {...(formik.errors.firstName && {
              hasError: true,
              helperText: formik.errors.firstName,
            })}
          />
          <TextInput
            placeholder="Last..."
            value={formik.values.lastName}
            onChange={formik.handleChange("lastName")}
            {...(formik.errors.lastName && {
              hasError: true,
              helperText: formik.errors.lastName,
            })}
          />
        </div>
      </Name>
      <TextInput
        label="Title"
        placeholder="Title..."
        value={formik.values.role}
        onChange={formik.handleChange("role")}
        {...(formik.errors.role && {
          hasError: true,
          helperText: formik.errors.role,
        })}
      />
      <TextInput
        label="Email Address"
        placeholder="Email..."
        leftIcon={<EmailIcon />}
        value={formik.values.email}
        onChange={formik.handleChange("email")}
        disabled={!!member}
        {...(formik.errors.email && {
          hasError: true,
          helperText: formik.errors.email,
        })}
      />
    </Modal>
  );
};

const Name = styled.div`
  & > div {
    display: flex;

    & > div:nth-child(1) input {
      border-radius: 6px 0 0 6px;
    }

    & > div:nth-child(2) input {
      border-radius: 0 6px 6px 0;
      border-left: none;
    }
  }

  ${Label} {
    margin-bottom: 4px;
  }
`;
