import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DocumentReference, doc, getFirestore } from "firebase/firestore";
import { useArtistContext } from "Components";
import IntegrationCard from "../IntegrationCard";
import mailchimpHeader from "./mailchimp-header.png";
import mailchimpButton from "./mailchimp-button.png";
import {
  AudienceIntegrationModal,
  MailchimpRemovalModal,
} from "./MailchimpModals";
import {
  MailchimpList,
  MailchimpDocument,
  MailchimpIntegrationStatus,
} from "./types";
import { CircularLoader } from "Components";
import styled from "styled-components";
import {
  DocumentData,
  QueryDocumentSnapshot,
  SnapshotOptions,
} from "firebase/firestore";
import LastSyncIcon from "../LastSyncIcon";
import { DateTime } from "luxon";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { Button } from "melodies-source/Button";
import AlertIcon from "../AlertIcon";
import { startOauth } from "./mailchimpActions";
import { useDesktopMediaQuery, useSearchParams } from "hooks";
import IncompleteIcon from "./IncompleteIcon";

const MailchimpIntegration = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [removalModalOpen, setRemovalModalOpen] = useState(false);
  const [selectedList, setSelectedList] = useState<MailchimpList | null>(null);

  const { id: artistGroupId, name: artistName, logAction } = useArtistContext();

  const [mailchimpDoc] = useDocumentData<MailchimpDocument>(
    doc(
      getFirestore(),
      `artist_groups/${artistGroupId}/artist_group_private/mailchimp`,
    ).withConverter(mailchimpConverter) as DocumentReference<MailchimpDocument>,
  );

  const lastCompletedSync = mailchimpDoc?.lastSynced
    ? DateTime.fromMillis(mailchimpDoc.lastSynced.toMillis())
    : undefined;

  const { searchParams } = useSearchParams();

  // we append a query parameter to the redirect url after mailchimp authentication.
  // see /functions/src/oauth/mailchimp.ts
  useEffect(() => {
    if (searchParams.get("mailchimpStatus") === "connected") {
      setModalOpen(true);
    }
  }, [searchParams]);

  const integrationStatus = () => {
    switch (true) {
      case mailchimpDoc?.syncStatus === "error":
        return MailchimpIntegrationStatus.NEEDS_ATTENTION;
      case mailchimpDoc?.syncStatus === "in_progress":
        return MailchimpIntegrationStatus.CONTACTS_SYNCING;
      // if mailchimp lists exist, show connected + sync
      case !!mailchimpDoc?.listName:
        return MailchimpIntegrationStatus.AUDIENCE_CONNECTED;
      // if server prefix and secret path but no lists, show "connect with audience"
      case !!mailchimpDoc?.serverPrefix && !!mailchimpDoc.secretPath:
        return MailchimpIntegrationStatus.MAILCHIMP_CONNECTED;
      default:
        return MailchimpIntegrationStatus.NOT_CONNECTED;
    }
  };

  return (
    <>
      <AudienceIntegrationModal
        artistGroupId={artistGroupId}
        artistName={artistName}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        selectedList={selectedList}
        setSelectedList={setSelectedList}
      />
      <MailchimpRemovalModal
        artistGroupId={artistGroupId}
        isOpen={removalModalOpen}
        onClose={() => setRemovalModalOpen(false)}
      />
      <MailchimpIntegrationCard
        lastSynced={lastCompletedSync}
        listName={mailchimpDoc?.listName}
        integrationStatus={integrationStatus()}
        onRemoveMailchimp={() => setRemovalModalOpen(true)}
        startOauth={() => {
          logAction("portal_connect_mailchimp");
          startOauth(artistGroupId);
        }}
        setModalOpen={setModalOpen}
      />
    </>
  );
};

export default MailchimpIntegration;

const mailchimpConverter = {
  toFirestore(mailchimp: MailchimpDocument): DocumentData {
    return mailchimp;
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions,
  ): MailchimpDocument {
    const data = snapshot.data(options);
    return { ...data, id: snapshot.id, ref: snapshot.ref } as MailchimpDocument;
  },
};

const MailchimpIntegrationCard = ({
  integrationStatus,
  lastSynced,
  listName,
  onRemoveMailchimp,
  setModalOpen,
  startOauth,
}: {
  integrationStatus: MailchimpIntegrationStatus;
  lastSynced?: DateTime;
  listName?: string;
  onRemoveMailchimp: () => void;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  startOauth: () => void;
}) => {
  const isDesktop = useDesktopMediaQuery();
  // card view if something went wrong
  if (integrationStatus === MailchimpIntegrationStatus.NEEDS_ATTENTION) {
    return (
      <IntegrationCard
        header={<img src={mailchimpHeader} alt="mailchimp logo" />}
        body={
          <>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div style={{ marginRight: 5 }}>
                <AlertIcon height={17} />
              </div>
              <ErrorText isDesktop={isDesktop}>
                Something is incomplete
              </ErrorText>
            </div>
            <br />
            It's likely that we failed collecting some data. Please delink your
            Mailchimp account and try again.
          </>
        }
        footer={
          <RemoveMailchimpText onClick={onRemoveMailchimp}>
            Remove Mailchimp
          </RemoveMailchimpText>
        }
      />
    );
  }

  // card view if mailchimp is connected but audience not connected
  if (integrationStatus === MailchimpIntegrationStatus.MAILCHIMP_CONNECTED) {
    return (
      <IntegrationCard
        header={<img src={mailchimpHeader} alt="mailchimp logo" />}
        body={
          <>
            <CenterDiv>
              <IncompleteIcon />
              <ErrorText isDesktop={isDesktop}>
                Something is incomplete
              </ErrorText>
            </CenterDiv>
            <BodyText>Please select an Audience to sync to</BodyText>
          </>
        }
        footer={
          <div
            style={{
              marginBottom: -10,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Button onClick={() => setModalOpen(true)}>
              <span>Select an audience</span>
            </Button>
            <RemoveMailchimpText
              style={{ marginTop: 10, textAlign: "center" }}
              onClick={onRemoveMailchimp}
            >
              Remove Mailchimp Connection
            </RemoveMailchimpText>
          </div>
        }
      />
    );
  }

  // card view if mailchimp is connected and audience has been connected
  if (integrationStatus === MailchimpIntegrationStatus.AUDIENCE_CONNECTED) {
    return (
      <IntegrationCard
        header={<img src={mailchimpHeader} alt="mailchimp logo" />}
        body={
          <>
            <CenterDiv>
              <LastSyncIcon />
              <LastSyncedText isDesktop={isDesktop}>
                Last Sync:{" "}
                {lastSynced
                  ? lastSynced.toLocaleString(DateTime.TIME_SIMPLE)
                  : "Never Synced"}
              </LastSyncedText>
            </CenterDiv>
            <BodyText>You're connected to Mailchimp</BodyText>
            <br />
            {`Data is being synced to the Mailchimp Audience "${
              listName ?? ""
            }"`}
          </>
        }
        footer={
          <RemoveMailchimpText onClick={onRemoveMailchimp}>
            Remove Mailchimp
          </RemoveMailchimpText>
        }
      />
    );
  }

  if (integrationStatus === MailchimpIntegrationStatus.CONTACTS_SYNCING) {
    return (
      <IntegrationCard
        header={<img src={mailchimpHeader} alt="mailchimp logo" />}
        body={
          <>
            <CenterDiv style={{ marginTop: 5 }}>
              <CircularLoader height={18} />
              <SyncingText>Currently Syncing</SyncingText>
            </CenterDiv>
            <br />
            {`Contacts are being synced to the Mailchimp audience "
            ${listName ?? ""}"`}
          </>
        }
      />
    );
  }

  // mailchimp is not yet connected
  return (
    <IntegrationCard
      header={<img src={mailchimpHeader} alt="mailchimp logo" />}
      body="We use Mailchimp to access your contacts. Click Connect To Mailchimp to start syncing your data."
      footer={
        <Button onClick={startOauth}>
          <span style={{ marginRight: "0.25rem" }}>Connect to</span>
          <img src={mailchimpButton} alt="connect to mailchimp" />
        </Button>
      }
    />
  );
};

const BodyText = styled.div`
  font-size: 10px;
  line-height: 170%;
  font-weight: 400;
  text-align: center;
  color: #666;
`;

const CenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorText = styled.div<{ isDesktop: boolean }>`
  color: #e45c52;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 13px)" : "13px")};
  line-height: 19.5px;
  margin-left: 5px;
`;

const LastSyncedText = styled.div<{ isDesktop: boolean }>`
  color: #635bff;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-left: 0.25rem;
`;

const RemoveMailchimpText = styled.div`
  color: #180171;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0px;
  margin: 0 auto;
  padding-bottom: 5px;
  cursor: pointer;
  bottom: 0;
`;

const SyncingText = styled.div`
  color: #635bff;
  font-weight: 500;
  font-size: 13px;
  line-height: 19.5px;
  margin-left: 5px;
`;
