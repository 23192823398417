import { Placement } from "@popperjs/core";
import React, { useEffect, useState } from "react";
import { usePopper } from "react-popper";
import styled, { css } from "styled-components";

type TooltipContentProps = {
  isActionable?: boolean;
};
export const TooltipContent = styled.div<TooltipContentProps>`
  ${(p) =>
    p.isActionable
      ? css`
          box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
          background: #ffffff;
          border-radius: 18px;
          padding: 10px 12px;
        `
      : css`
          padding: 4px 6px;
          background: rgba(0, 0, 0, 0.6);
          color: #ffffff;
          border-radius: 3px;
          font-size: 11px;
          font-weight: 400;
          text-align: center;
        `}
`;

const TooltipContainer = styled.div`
  user-select: none;
  z-index: 999;
`;

type TooltipProps = {
  parent: JSX.Element | string;
  isActionable?: boolean;
  placement?: Placement;
  children?: React.ReactNode;
};
export const Tooltip: React.FC<TooltipProps> = ({
  parent,
  isActionable,
  placement = "top-start",
  children,
  ...props
}) => {
  const [isDisplayingTooltip, setIsDisplayingTooltip] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes, update } = usePopper(
    referenceElement,
    popperElement,
    { placement, strategy: "fixed" },
  );

  useEffect(() => {
    if (update) update();
  }, [isDisplayingTooltip]);

  return (
    <>
      <TooltipContainer
        ref={setReferenceElement}
        onMouseOver={() => setIsDisplayingTooltip(true)}
        onMouseOut={() => setIsDisplayingTooltip(false)}
        {...props}
      >
        {parent}
      </TooltipContainer>
      {isDisplayingTooltip && (
        <TooltipContent
          isActionable={isActionable}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
        >
          {children}
        </TooltipContent>
      )}
    </>
  );
};
