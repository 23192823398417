import { ReactComponent as TemplateIcon } from "assets/svg/fanCensusShort.svg";
import { SurveyTemplate } from "../../types";

export const fanCensusShort: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Fan Census Short",
  description:
    "Generate a report on the types of music your fans are listening to.",
  templateId: "fanCensusShort",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Fan Census Short",
        },
      },
      numberOfCategories: {
        content: "3",
      },
    },
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-brand---fa-c7498e"
        : "pp-brand---fa-745161",
  },
};
