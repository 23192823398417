import Arrow from "./Arrow";
import BarChartIcon from "./BarChartIcon";
import ContactsIcon from "./ContactsIcon";
import ClipboardLogo from "./ClipboardLogo";
import GreenCheck from "./GreenCheck";
import HelpIcon from "./HelpIcon";
import Logo from "./Logo";
import MapPinIcon from "./MapPinIcon";
import MicrophoneLogo from "./MicrophoneLogo";
import MusicAltIcon from "./MusicAlt";
import SetDotLiveDark from "./SetDotLiveDark";
import SetFanKnockout from "./SetFanKnockout";
import SetLiveKnockout from "./SetLiveKnockout";
import Calendar from "./Calendar";
import Microphone from "./Microphone";

export const svg = {
  Arrow,
  BarChartIcon,
  ClipboardLogo,
  ContactsIcon,
  GreenCheck,
  HelpIcon,
  Logo,
  MapPinIcon,
  MicrophoneLogo,
  MusicAltIcon,
  SetDotLiveDark,
  SetFanKnockout,
  SetLiveKnockout,
  Calendar,
  Microphone,
};
