import SweepsWinnersList from "./WinnersList";
import SweepsSearch, { SweepsLoader } from "./SweepsSearch";
import { useState } from "react";
import { useSetfanSweeps } from "./hooks/useSetfanSweeps";
import { SurveyTypesenseDocument } from "@max/common/setfan";

// if a sweeps is using the default set.fan rules, we can only pick winners from the US
const SweepsCard = ({
  survey,
  forceUS,
}: {
  survey?: SurveyTypesenseDocument;
  forceUS?: boolean;
}) => {
  const [numberOfWinners, setNumberOfWinners] = useState(3);

  const {
    activeSweeps,
    cancelCurrentSweeps,
    removeWinner,
    replaceWinner,
    winnerBeingReplaced,
    runNewSweeps,
    loading: loadingMostRecentSweeps,
  } = useSetfanSweeps({
    surveyId: survey?.id,
  });

  if (loadingMostRecentSweeps || !survey) {
    return <SweepsLoader />;
  }

  if (
    activeSweeps?.status === "replacing" ||
    activeSweeps?.status === "sampled"
  ) {
    return (
      <SweepsWinnersList
        {...{
          sweeps: activeSweeps,
          cancelCurrentSweeps,
          removeWinner,
          replaceWinner,
          winnerBeingReplaced,
        }}
      />
    );
  }

  return (
    <SweepsSearch
      {...{
        surveyId: survey.surveyId,
        responses: survey.responses,
        numberOfWinners,
        runNewSweeps,
        setNumberOfWinners,
        forceUS,
      }}
    />
  );
};

export default SweepsCard;
