type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
    ? RecursivePartial<T[P]>
    : T[P];
};

/**
 * Enumerate the keys from the given object (which can also be an "enum").
 * This is convinient for looping over the keys of an object and maintining the
 * correct typings.  Usage:
 *
 *    for (const key in enumKeys(obj)) {
 *      const value = obj[key]; // This is now properly typed
 *      // Do something with value
 *    }
 */
export const enumKeys = <O extends object, K extends keyof O = keyof O>(
  obj: O,
): K[] => {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
};

/**
 * Removes all keys from `obj` that have `undefined` values.  If recursive is
 * true, this will attempt to unset undefined on all objects.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function removeUndefined<T extends Record<string, any>>(
  obj: T,
  recursive?: false,
): Partial<T>;
export function removeUndefined<T extends Record<string, any>>(
  obj: T,
  recursive?: true,
): RecursivePartial<T>;
export function removeUndefined<T extends Record<string, any>>(
  obj: T,
  recursive = false,
) {
  enumKeys(obj).forEach((k) => {
    const v = obj[k];
    if (typeof v === "undefined") {
      delete obj[k];
    } else if (obj[k] !== null && recursive && k in obj) {
      Object.defineProperty(obj, k, {
        value: removeUndefined(obj[k], recursive),
        enumerable: true,
      });
    }
  });
  return obj;
}