import React from "react";
import { Body1, H2 } from "melodies-source/Text";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import { useEmailLogin, useHistoryPush } from "hooks";
import { Button } from "melodies-source/Button";
import { SESSION_PATHS } from "../paths";

export const LoginWithEmailToSetupMFA: React.FC = () => {
  const location = useLocation<{ email: string }>();
  const { login, isSendingEmail, ui } = useEmailLogin();
  const historyPush = useHistoryPush();

  const handleEmailSend = async () => {
    try {
      login(location.state?.email);
    } catch (error) {
      console.error(error);
      toast.error("Error sending login link");
    }
  };

  if (!location.state?.email) {
    historyPush(SESSION_PATHS.LOGIN_WITH_PASSWORD);
  }

  return (
    <Container>
      <H2>MFA Required</H2>
      <Body1>
        In order to log in, you must set up MFA. We'll send a one-time login
        link to <b>{location.state?.email}</b>.
      </Body1>
      {ui}
      <Button loading={isSendingEmail} onClick={handleEmailSend}>
        Send Link
      </Button>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${H2} {
    margin-bottom: 15px;
  }

  button {
    width: -webkit-fill-available;
    margin-top: 20px;
  }
`;
