import React, { useEffect, useState } from "react";
import { Survey } from "models/survey";
import { FlexColumn, FlexRow } from "Components/Flex";
import { H3, H4 } from "melodies-source/Text";
import DropdownMenu from "Components/DropdownMenu";
import styled from "styled-components";
import { TextInput } from "melodies-source/TextInput";
import { SvgSearch } from "melodies-source/Svgs/Search";
import { SurveyCard, SurveyCardLoader } from "./SurveyCard";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";
import Pagination from "Components/Pagination";
import { svg } from "svg";
import { useDebounce } from "hooks/useDebounce";
import { resetPage } from "Utils";
import Skeleton from "react-loading-skeleton";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { ReactComponent as FileIcon } from "assets/svg/file.svg";
import {
  useTypesenseProxyContext,
  ProxySearchParams,
} from "contexts/TypesenseProxyContext";
import { SurveyTypesenseDocument } from "@max/common/setfan";

type OrderBy = "newest" | "oldest";

const RESULTS_PER_PAGE = 5;

export const getSurveyFromTypensenseDoc = (
  document: SurveyTypesenseDocument,
) => {
  const {
    id,
    title,
    internalTitle,
    timeSeries,
    consumerResponses,
    demographicResponses,
    responses,
  } = document || {};

  const survey: Survey = {
    consumerResponses,
    demographicResponses,
    id,
    label: internalTitle || title,
    startDate: DateTime.fromMillis(timeSeries ? timeSeries.start : Date.now()),
    endDate: DateTime.fromMillis(timeSeries ? timeSeries.end : Date.now()),
    series: timeSeries ? timeSeries.data : [],
    responses,
  };
  return survey;
};

export const Surveys: React.FC = () => {
  const [searchText, setSearchText] = useState("");
  const [orderBy, setOrderBy] = useState<OrderBy>("newest");
  const { artistId } = useParams<{ artistId: string }>();
  const [currentPage, setCurrentPage] = useState(1);
  const [surveys, setSurveys] = useState<SurveyTypesenseDocument[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const debouncedSearchText = useDebounce(searchText, undefined, () =>
    setCurrentPage(1),
  );
  const { customApp } = useCustomAppContext();

  const { proxySearch } = useTypesenseProxyContext();

  const handleOrderByChange = (value: string) =>
    resetPage(setCurrentPage, setOrderBy, value);

  const surveyList = surveys?.map((document) => {
    const survey = getSurveyFromTypensenseDoc(document);
    return <SurveyCard key={survey.id} survey={survey} />;
  });

  const noSurveys = (
    <NoSurveysContainer>
      {customApp ? <FileIcon width={80} height={80} /> : <MusicIcon />}
      <p>{customApp ? "No" : "This artist has no"} surveys to display.</p>
    </NoSurveysContainer>
  );

  const noMatches = (
    <NoSurveysContainer>
      <LargeSearchIcon />
      <p>No surveys match your criteria. Try using a different term.</p>
    </NoSurveysContainer>
  );

  useEffect(() => {
    if (!proxySearch) return;
    const searchParams: ProxySearchParams = {
      q: debouncedSearchText,
      query_by: "internalTitle,title",
      filter_by: [
        `artistGroupId:${artistId}`,
        "status:=[live,completed,published]",
      ].join(" && "),
      page: currentPage,
      per_page: RESULTS_PER_PAGE,
      sort_by: `createdAt:${orderBy === "newest" ? "desc" : "asc"}`,
    };
    setLoading(true);
    proxySearch("surveys", searchParams)
      .then((res) => setSurveys(res?.hits?.map((hit) => hit.document) || []))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [proxySearch, currentPage, orderBy, debouncedSearchText]);

  return (
    <>
      <FlexRow xSpaceBetween>
        <H3>Surveys</H3>
        <DropdownMenu
          options={[
            { label: "Newest", value: "newest" },
            { label: "Oldest", value: "oldest" },
          ]}
          value={orderBy}
          onClick={handleOrderByChange}
          labelAs={H4}
          forceSide="left"
        />
      </FlexRow>
      <SearchInput
        value={searchText}
        onChange={setSearchText}
        placeholder="Search for a Survey"
        leftIcon={<SvgSearch />}
      />
      <SurveysContainer>
        {loading ? (
          <SurveyCardLoader />
        ) : surveyList?.length === 0 ? (
          debouncedSearchText === "" ? (
            noSurveys
          ) : (
            noMatches
          )
        ) : (
          surveyList
        )}
      </SurveysContainer>
      {loading ? (
        <PaginationContainer>
          <Skeleton width={100} />
        </PaginationContainer>
      ) : (
        !!surveys?.length && (
          <PaginationContainer>
            <Pagination
              selectedPage={currentPage}
              resultsPerPage={RESULTS_PER_PAGE}
              totalResults={surveys?.length || 0}
              onSetPage={setCurrentPage}
              scrollOnChange
            />
          </PaginationContainer>
        )
      )}
    </>
  );
};

const SurveysContainer = styled.div`
  & > div:nth-last-child(n + 2) {
    margin-bottom: 20px;
  }
`;

const SearchInput = styled(TextInput)`
  max-width: 380px;
  margin: 30px 0;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 39px;
`;

const NoSurveysContainer = styled(FlexColumn)`
  align-items: center;
  border-radius: 20px;
  padding: 52px 53px 60px 53px;
  max-width: 372px;
  margin: 80px auto;
  gap: 15px;
  line-height: 1.4;

  p {
    color: var(--text-color);
    text-align: center;
  }
`;

const MusicIcon = styled(svg.MusicAltIcon)``;

const LargeSearchIcon = styled(SvgSearch)`
  width: 110px;
  height: 110px;
  color: #e8e5f1;
`;
