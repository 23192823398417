import { ModuleType, TranslatableContent } from "@max/common";
import { useExceptionLogging } from "hooks/useExceptionLogging";
import useUserIdToken from "hooks/useUserIdToken";
import toast from "react-hot-toast";

export interface PromptRequestOptions {
  threaded?: boolean;
  templateId?: string;
  threadId?: string;
  assistantId?: string;
  values?: any;
  prompt?: string;
}

export type QuestionResponse = {
  id: string;
  label: TranslatableContent;
  options?: TranslatableContent[];
  type: Extract<
    ModuleType,
    | "TextQuestion"
    | "SingleSelectQuestion"
    | "MultipleSelectQuestion"
    | "ImageQuestion"
    | "RankedQuestion"
    | "text"
    | "image"
  >;
};
export interface PromptResponse {
  fields?: { [key: string]: { content: TranslatableContent } };
  surveyQuestions?: QuestionResponse[];
}
export interface UsePromptResponse {
  result?: PromptResponse;
  error?: string;
}

export interface PromptResult {
  response: PromptResponse;
  assistantId?: string;
  threadId?: string;
  messageId?: string;
}

export const usePrompt = () => {
  const accessToken = useUserIdToken();
  const { logException } = useExceptionLogging();

  const request = async (
    options: PromptRequestOptions,
  ): Promise<UsePromptResponse> => {
    try {
      const response = await fetch(process.env.REACT_APP_AI_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(options),
      });
      const json = await response.json();
      return { result: json?.response };
    } catch (error) {
      console.error(error);
      logException(error, {
        action: "template-generate",
        promptId: options.templateId,
      });
      return { error: error };
    }
  };

  return { request };
};
