import { useUser } from "auth";
import LogRocket from "logrocket";
import { useEffect } from "react";
import posthog from "posthog-js";

export const LogrocketHelper = () => {
  const { user } = useUser();
  useEffect(() => {
    if (process.env.REACT_APP_ENV !== "dev") {
      LogRocket.identify(user?.uid, {
        name: user?.displayName,
        email: user?.email,
        phone: user?.phoneNumber,
      });

      if (user?.uid) {
        posthog.identify(user.uid, {
          name: user?.displayName,
          email: user?.email,
          phone: user?.phoneNumber,
        });
      }
    }
  }, [user]);
  return null;
};
