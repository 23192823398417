import {
  CATEGORIES_OPTIONS_IDS,
  SurveyBuilderConfig,
  SurveyBuilderRootDocument,
  SurveyBuilderVersionDocument,
} from "@max/common/dist/setfan";
import { validateField } from "./builderConfigSchema";

export const buildSurveyBuilderConfig = (
  survey?: SurveyBuilderRootDocument,
  version?: SurveyBuilderVersionDocument,
): SurveyBuilderConfig => ({
  artistGroupId: survey?.artistGroupId ?? "",
  artistPolicy: version?.artistPolicy ?? "",
  disclaimers: version?.disclaimers ?? [],
  fields: {
    description: {
      content: version?.fields?.description?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.description.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    header: {
      content: version?.fields?.header?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.header.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    body: {
      content: version?.fields?.body?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.body.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    // NOTE: our original survey creator took one of (backgroundColor, backgroundImage).
    // not sure our current goals but keep this in mind since they both validate
    // individually
    backgroundImage: {
      content: version?.fields?.backgroundImage?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.backgroundImage.content.en !== undefined
          ? false
          : "Select a background image",
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    image: {
      content: version?.fields?.image?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.image.content.en !== undefined
          ? false
          : "Select an image",
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    backgroundColor: {
      content: version?.fields?.backgroundColor?.content ?? "#fff",
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.backgroundColor.content !== undefined
          ? false
          : "Select a background color",
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    buttonColor: {
      content: version?.fields?.buttonColor?.content ?? "#0d0a0a",
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.buttonColor.content !== undefined
          ? false
          : "Select a button color",
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    layout: {
      content: version?.fields?.layout?.content ?? "default",
      getValidation: () => false,
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    texture: {
      content: version?.fields?.texture?.content ?? "none",
      getValidation: () => false,
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    darkMode: {
      content: version?.fields?.darkMode?.content ?? false,
      getValidation: () => false,
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    isContest: {
      content: version?.fields?.isContest?.content ?? false,
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.isContest.content", config),
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    contestPrize: {
      content: version?.fields?.contestPrize?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.contestPrize.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    contestEndDate: {
      content: version?.fields?.contestEndDate?.content ?? null,
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.contestEndDate.content", config),
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    contestPrizeValue: {
      content: version?.fields?.contestPrizeValue?.content ?? null,
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.contestPrizeValue.content", config),
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    contestCustomPrizeDescription: {
      content: version?.fields?.contestCustomPrizeDescription?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField(
          "fields.contestCustomPrizeDescription.content.en",
          config,
        ),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    hasContestCustomRules: {
      content:
        version?.fields?.hasContestCustomRules?.content === undefined
          ? false
          : version?.fields?.hasContestCustomRules?.content,
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.hasContestCustomRules.content", config),
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    contestCustomRulesUrl: {
      content: version?.fields?.contestCustomRulesUrl?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.contestCustomRulesUrl.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    numberOfCategories: {
      content: version?.fields?.numberOfCategories?.content ?? "3",
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.numberOfCategories.content !== undefined
          ? false
          : "Enter a value",
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    displayedCategories: {
      content: version?.fields?.displayedCategories?.content ?? [
        ...CATEGORIES_OPTIONS_IDS,
      ],
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.displayedCategories.content.length >= 3
          ? false
          : "Select at least 3 categories",
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    demographicsIntroText: {
      content: version?.fields?.demographicsIntroText?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.demographicsIntroText.content.en !== undefined
          ? false
          : "Enter a value",
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    fooddrinkIntroText: {
      content: version?.fields?.fooddrinkIntroText?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.fooddrinkIntroText.content.en !== undefined
          ? false
          : "Enter a value",
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    alcoholIntroText: {
      content: version?.fields?.alcoholIntroText?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.alcoholIntroText.content.en !== undefined
          ? false
          : "Enter a value",
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    clothingIntroText: {
      content: version?.fields?.clothingIntroText?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.clothingIntroText.content.en !== undefined
          ? false
          : "Enter a value",
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    vehicleIntroText: {
      content: version?.fields?.vehicleIntroText?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.vehicleIntroText.content.en !== undefined
          ? false
          : "Enter a value",
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    entertainmentIntroText: {
      content: version?.fields?.entertainmentIntroText?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        config.fields.entertainmentIntroText.content.en !== undefined
          ? false
          : "Enter a value",
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    phoneNumberValidation: {
      content: version?.fields?.phoneNumberValidation?.content ?? "required",
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.phoneNumberValidation.content", config),
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    thankYouHeader: {
      content: version?.fields?.thankYouHeader?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.thankYouHeader.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    thankYouBody: {
      content: version?.fields?.thankYouBody?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.thankYouBody.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    hasFeaturedCta: {
      content: version?.fields?.hasFeaturedCta?.content ?? false,
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.hasFeaturedCta.content", config),
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    featuredCtaHeaderText: {
      content: version?.fields?.featuredCtaHeaderText?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.featuredCtaHeaderText.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    featuredCtaButtonText: {
      content: version?.fields?.featuredCtaButtonText?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.featuredCtaButtonText.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    featuredCtaUrl: {
      content: version?.fields?.featuredCtaUrl?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.featuredCtaUrl.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    useCustomTermsAndPrivacyPolicy: {
      content:
        version?.fields?.useCustomTermsAndPrivacyPolicy?.content ?? false,
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.useCustomTermsAndPrivacyPolicy.content", config),
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    customTermsAndPrivacyPolicy: {
      content: version?.fields?.customTermsAndPrivacyPolicy?.content ?? {
        en:
          version.optIns?.find((optIn) => optIn.id === "optInTermsPrivacy1")
            ?.htmlLabel || "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.customTermsAndPrivacyPolicy.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    termsAndPrivacyPolicySeparateLineItems: {
      content: version?.fields?.termsAndPrivacyPolicySeparateLineItems
        ?.content ?? {
        en: "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField(
          "fields.termsAndPrivacyPolicySeparateLineItems.content.en",
          config,
        ),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
    useCustomSmsOptIn: {
      content: version?.fields?.useCustomSmsOptIn?.content ?? false,
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.useCustomSmsOptIn.content", config),
      isTranslateable: false,
      isDirty: false,
      isTouched: false,
    },
    customSmsOptIn: {
      content: version?.fields?.customSmsOptIn?.content ?? {
        en:
          version.optIns?.find((optIn) => optIn.id === "optInText")
            ?.htmlLabel || "",
      },
      getValidation: (config: SurveyBuilderConfig) =>
        validateField("fields.customSmsOptIn.content.en", config),
      isTranslateable: true,
      isDirty: false,
      isTouched: false,
    },
  },
  flags: {
    showContestConfirmationMessage:
      version?.flags?.showContestConfirmationMessage ?? null,
  },
  optIns: version?.optIns ?? [],
  surveyQuestions: version?.surveyQuestions ?? [],
});
