import { FlexRow } from "Components/Flex";
import { H3 } from "melodies-source/Text";
import styled, { css, useTheme } from "styled-components";
import { MixedChart } from "Components";
import { useCallback, useMemo, useState } from "react";
import { getDailyMetrics } from "../getMetrics";
import { DateRange, HourlyAnalyticsWithId } from "..";
import { DateTime, Interval } from "luxon";
import { ReportCard } from ".";
import { useIsMobile } from "melodies-source/utils";

interface ChartTabsProps {
  hours: HourlyAnalyticsWithId[];
  dateRange: DateRange;
}

export const ChartTabs = ({ hours, dateRange, ...props }: ChartTabsProps) => {
  const [activeTab, setActiveTab] = useState<"visits" | "clicks">("visits");
  const isMobile = useIsMobile();
  const theme = useTheme();

  const lightenColor = (hex: string) => `${hex}99`; // 60% opacity

  const colors = {
    visits: !!theme.custom?.charts
      ? theme.custom.charts.donut.colors[0]
      : "#9747FF",
    clicks: !!theme.custom?.charts
      ? theme.custom.charts.donut.colors[0]
      : "#0095EF",
    cvr: !!theme.custom?.colors.secondary
      ? theme.custom.colors.secondary
      : "var(--main-color)",
  };

  const activeColor = colors[activeTab];

  const chartColorArray = [lightenColor(activeColor), activeColor];

  // create array of days from duration start/end
  const dates = useMemo(() => {
    const start = (dateRange.start || DateTime.now()).endOf("day");
    return Interval.fromDateTimes(
      start.startOf("day").minus(dateRange.duration),
      start,
    )
      .splitBy({ days: 1 })
      .map((d) => d.start);
  }, [dateRange]);

  const tickFormat = useCallback(
    (tick: string, index) => {
      const day = parseInt(tick.replace(/\D/g, ""));
      if (dates.length <= 7) {
        return tick;
      }
      const isFirstOfMonth = day === 1;
      const isStartAnchor = index === 0;
      const isEndAnchor = index === dates.length - 1;

      const anchorPadding =
        dates.length <= 30
          ? { start: 3, end: 3, lastVisDayOfMonth: 31, interval: 5 }
          : dates.length <= 60
          ? { start: 4, end: 4, lastVisDayOfMonth: 30, interval: 5 }
          : dates.length <= 94
          ? { start: 6, end: 6, lastVisDayOfMonth: 28, interval: 5 }
          : { start: 8, end: 8, lastVisDayOfMonth: 25, interval: 10 };

      const checkForCollision = (dateIndex: number) => {
        return (
          dateIndex < anchorPadding.start ||
          dateIndex > dates.length - anchorPadding.end ||
          day >= anchorPadding.lastVisDayOfMonth
        );
      };

      const hasCollision = checkForCollision(index);
      const indexOfFirstOfMonth = dates.findIndex(
        (d, cIdx) => index < cIdx && d.day === 1,
      );
      const firstOfMonthCollision = checkForCollision(indexOfFirstOfMonth);
      const isVisibleInterval = !(day % anchorPadding.interval);

      if ((isFirstOfMonth && !hasCollision) || isEndAnchor || isStartAnchor) {
        return tick;
      }

      if (!isVisibleInterval || hasCollision) {
        return " ";
      }

      if (
        firstOfMonthCollision &&
        day <= anchorPadding.interval &&
        dates.length <= 60
      ) {
        return tick;
      }

      return day.toString();
    },
    [dates],
  );

  const values = getDailyMetrics(hours, dates, chartColorArray);

  return (
    <Tabs isElevated {...props}>
      <ReportTabContainer>
        <Tab
          active={activeTab === "visits"}
          onClick={() => setActiveTab("visits")}
        >
          <H3>Visits</H3>
        </Tab>

        <Tab
          active={activeTab === "clicks"}
          onClick={() => setActiveTab("clicks")}
        >
          <H3>Clicks</H3>
        </Tab>
      </ReportTabContainer>
      {activeTab === "visits" && (
        <TabPanel>
          <MixedChart
            data={values}
            indexBy="label"
            tickFormatter={tickFormat}
            layout={isMobile ? "vertical" : "horizontal"}
            defs={[
              {
                chartType: "bar",
                dataKey: "visits",
                label: "Visits",
                color: colors.visits,
              },
            ]}
          />
        </TabPanel>
      )}
      {activeTab === "clicks" && (
        <TabPanel>
          <MixedChart
            data={values}
            indexBy="label"
            tickFormatter={tickFormat}
            layout={isMobile ? "vertical" : "horizontal"}
            secondaryAxisId="cvr"
            defs={[
              {
                chartType: "bar",
                dataKey: "clicks",
                label: "Clicks",
                color: colors.clicks,
              },
              {
                chartType: "line",
                dataKey: "cvr",
                secondaryAxisId: "cvr",
                label: "Conversion Rate (CVR)",
                fill: colors.cvr,
                formatValue: (v) => `${v.payload.cvr.toFixed(2)}%`,
                tooltipLabel: "CVR",
              },
            ]}
          />
        </TabPanel>
      )}
    </Tabs>
  );
};

const TabPanel = styled(FlexRow)`
  min-height: 400px;
  padding: 44px 44px 24px 24px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 32px 20px 16px 10px;
  }
`;

const Tabs = styled(ReportCard)`
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;

  ${({ theme }) => theme.mediaQueries.desktop} {
    border-radius: 20px;
  }
`;

const Tab = styled(FlexRow)<{ active?: boolean }>`
  align-items: center;
  justify-content: center;
  ${H3} {
    color: var(--disabled-text-color);
    font-weight: 500;
  }
  ${(p) =>
    p.active
      ? css`
          ${H3} {
            color: var(--text-color);
          }
          background-color: var(--component-background-color);
        `
      : css`
          cursor: pointer;
        `};
`;

const ReportTabContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #f2f5f7;
  height: 72px;
  width: 100%;

  ${({ theme }) =>
    theme.custom &&
    css`
      background-color: color-mix(
        in srgb,
        var(--content-background-color),
        transparent 60%
      );
    `}

  ${Tab} {
    border-radius: 20px 20px 0 0;
    &:first-child {
      border-radius: 0 20px 0 0;
    }
    &:last-child {
      border-radius: 20px 0 0 0;
    }
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    height: 56px;
    ${Tab} {
      border-radius: 12px 12px 0 0;
      &:first-child {
        border-radius: 0 12px 0 0;
      }
      &:last-child {
        border-radius: 12px 0 0 0;
      }

      ${H3} {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }
`;
