import {
  DocumentReference,
  doc,
  getDoc,
  getFirestore,
} from "firebase/firestore";
import { EntriesDocument, EntriesDocumentByKey } from "@max/common/analytics";
import { useEffect, useState } from "react";

export type EntriesTotals = EntriesDocument["totals"];

interface Value {
  entriesTotals: EntriesTotals;
  entriesTimes: EntriesDocument["times"];
  entriesZips: EntriesDocument["zips"];
  entriesLoading: boolean;
  entriesError: boolean;
  reload: () => Promise<void>;
}

export const useEventEntries = (
  eventId: string,
  eventVersion: number | undefined,
  runOnInit: boolean = true,
): Value => {
  const [entriesTotals, setEntriesTotals] = useState<EntriesTotals>();
  const [entriesTimes, setEntriesTimes] = useState<EntriesTotals>();
  const [entriesZips, setEntriesZips] = useState<EntriesTotals>();
  const [entriesLoading, setEntriesLoading] = useState(true);
  const [entriesError, setEntriesError] = useState(false);

  const getEntries = async () => {
    try {
      setEntriesError(false);
      const entriesPath =
        eventVersion === 2
          ? `set_fresh_events/${eventId}/analytics/entries`
          : `setlive_events/${eventId}/analytics/entries`;
      const uniqueKey = eventVersion === 2 ? "phone" : undefined;
      const entriesSnapshot = await getDoc(
        doc(getFirestore(), entriesPath) as DocumentReference<EntriesDocument>,
      );
      let totals = { inVenue: 0, all: 0, noLocation: 0 };
      let zips = null;
      let times = null;

      if (entriesSnapshot?.exists()) {
        const data = entriesSnapshot.data();
        const stats =
          (eventVersion === 2
            ? (data as EntriesDocumentByKey)?.[uniqueKey]
            : (data as EntriesDocument)) || {};
        if (stats.totals) totals = { ...totals, ...stats.totals };
        if (stats.zips) zips = stats.zips;
        if (stats.times) times = stats.times;

        setEntriesTotals(totals);
        setEntriesZips(zips);
        setEntriesTimes(times);
      }
    } catch (error) {
      console.error(error);
      setEntriesError(true);
    } finally {
      setEntriesLoading(false);
    }
  };

  useEffect(() => {
    if (runOnInit && eventVersion) {
      getEntries();
    }
  }, [eventVersion]);

  return {
    entriesTotals,
    entriesTimes,
    entriesZips,
    entriesLoading,
    entriesError,
    reload: getEntries,
  };
};
