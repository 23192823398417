import { ReactComponent as IconFavortieTrackTitle } from "assets/svg/favorite-track-title.svg";
import { SurveyTemplate } from "../../types";

export const favLineNewRelease: SurveyTemplate = {
  icon: <IconFavortieTrackTitle />,
  title: "Favorite Line from New Release",
  description:
    "Build interest in the latest release by asking fans about their favorite line from it.",
  templateId: "favLineNewRelease",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: formState.title,
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "What's your favorite line from our latest release, and why does it stand out to you?",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-sweeps---f-547834"
        : "pp-favorite-l-672745",
    fields: [
      {
        name: "format",
        type: "radio",
        label: "What format is this release?",
        options: ["Single", "EP", "Album"],
        getValidation: (fields) =>
          !!fields.format ? false : "You must select an option.",
      },
      {
        name: "title",
        type: "text",
        label: "What's the title of the release?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.title ? false : "This field cannot be left blank",
      },
      {
        name: "releaseDate",
        type: "text",
        label: "When will it be available (or is it already available)?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.releaseDate ? false : "This field cannot be left blank",
      },
    ],
  },
};
