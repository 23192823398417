import { Body1 } from "melodies-source/Text";
import styled from "styled-components";

export const BorderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffffcc;
  border-radius: 12px;
  height: 100%;
  min-height: 80px;
  color: #fff;

  svg {
    margin-right: 12px;
  }

  ${Body1} {
    font-weight: 600;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    min-height: 57px;
  }
`;
