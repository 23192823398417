import { ReactComponent as IconShirtIndividual } from "assets/svg/shirt-individual.svg";
import { SurveyTemplate } from "../../types";

export const merchConnections: SurveyTemplate = {
  icon: <IconShirtIndividual />,
  title: "Merch Connections",
  description:
    "Ask fans to share a story about how wearing their merch connected them to others.",
  templateId: "merchConnections",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Merch Connections",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "Tell us about a time your merch helped you connect with someone. If it's about our merch, even better!",
        },
        type: "TextQuestion",
        multiline: true,
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-merch---me-f879ea"
        : "pp-merch---me-e467b7",
  },
};
