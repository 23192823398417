import { MenuMode, useArtistContext, useMenuContext } from "Components";
import styled, { css, useTheme } from "styled-components";
import { FlexRow } from "Components/Flex";
import { useMediaQuery } from "melodies-source/utils";
import { IconLeftPanelClose } from "assets/svg";
import { Tooltip, TooltipContent } from "melodies-source/Tooltip";

export const ArtistGroup = () => {
  const { assets, name } = useArtistContext();
  const { menuMode, setMenuMode } = useMenuContext();
  const longName = name?.length > 25;
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.mediaQueryBreakpoints.desktop);
  const isExpanded = menuMode === "expanded";

  return (
    <ArtistGroupContainer>
      <FlexRow yCenter gap="10px">
        <ArtistGroupImage src={assets?.icon?.path} isExpanded={isExpanded}>
          {!isExpanded && (
            <SideMenuAvatarButton
              onClick={() => setMenuMode(isExpanded ? "collapsed" : "expanded")}
            >
              <Tooltip placement="right" parent={<IconLeftPanelClose />}>
                Expand Menu
              </Tooltip>
            </SideMenuAvatarButton>
          )}
        </ArtistGroupImage>
        {isExpanded && (
          <ArtistGroupName isLong={longName}>{name}</ArtistGroupName>
        )}
      </FlexRow>
      {isDesktop && isExpanded && (
        <>
          <SideMenuToggleButton
            menuMode={menuMode}
            onClick={() => setMenuMode(isExpanded ? "collapsed" : "expanded")}
          >
            <Tooltip placement="right" parent={<IconLeftPanelClose />}>
              Collapse Menu
            </Tooltip>
          </SideMenuToggleButton>

          <SideMenuToggleBackdrop />
        </>
      )}
    </ArtistGroupContainer>
  );
};

export const SideMenuToggleBackdrop = styled.div`
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 80%
  );
  height: 100%;
  width: 100px;
  position: absolute;
  right: 10px;
  top: 0;
  opacity: 0;
  transition: all ease 150ms;
  z-index: 1;

  ${({ theme }) =>
    theme.custom &&
    css`
      background: ${`
      linear-gradient(90deg, transparent 0%, ${theme.custom.layout.sideBar.background} 80%)`};
    `};
`;

const ArtistGroupContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 20px;
  width: 100%;
  height: 74px;
  position: relative;

  ${TooltipContent} {
    white-space: nowrap;
    z-index: 999;
  }
`;

const ArtistGroupName = styled.h1<{ isLong: boolean }>`
  color: var(--text-color);
  font-family: Poppins;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 28px;
  width: 200px;
  flex-shrink: 0;

  ${({ isLong }) =>
    isLong && "font-size: 18px; line-height: 26px; word-wrap: break-word;"};
`;

interface SideMenuToggleButtonProps {
  menuMode: MenuMode;
}

export const SideMenuToggleButton = styled.div<SideMenuToggleButtonProps>`
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  color: #cccccc;
  border-radius: 4px;
  position: absolute;
  right: 8px;
  width: 24px;
  height: 24px;
  margin-bottom: 18px;
  cursor: pointer;
  opacity: 0;
  visibility: none;
  pointer-events: none;
  transition: all ease 150ms;
  z-index: 2;

  &:hover {
    color: #1b0076;
  }

  ${(p) =>
    p.menuMode === "collapsed" &&
    css`
      display: none;
    `}

  svg {
    height: 24px;
    width: 24px;
  }

  ${({ theme }) =>
    theme.custom &&
    css`
      color: ${theme.custom.layout.sideBar.active.background}aa;
      &:hover {
        color: ${theme.custom.layout.sideBar.active.background}ff;
      }
    `};
`;

type GroupImageProps = {
  isExpanded?: boolean;
  src?: string;
};

export const ArtistGroupImage = styled.div<GroupImageProps>`
  background-image: ${(p) => `url(${p.src})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  display: flex;
  height: 54px;
  width: 54px;
  object-fit: cover;
  margin-left: 20px;
  align-self: flex-start;
  overflow: hidden;

  svg {
    width: 24px;
    height: 24px;
    display: none;
    rotate: 180deg;
  }

  ${(p) =>
    !p.isExpanded &&
    css`
      height: 45px;
      width: 45px;
      svg {
        display: block;
      }
    `}
`;

export const SideMenuAvatarButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e8e5f1;
  opacity: 0;
  width: 100%;
  height: 100%;
  color: #a499c8;
  transition: all ease 150ms;

  &:hover {
    color: #1b0076;
    cursor: pointer;
  }

  ${({ theme }) =>
    theme.custom &&
    css`
      background: ${theme.custom.colors.contentBackground};
      color: ${theme.custom.layout.sideBar.active.background}aa;
      &:hover {
        color: ${theme.custom.layout.sideBar.active.background}ff;
      }
    `};
`;
