import { DateTime } from "luxon";
import { HourlyAnalyticsWithId } from ".";
import {
  aggregateHourlyMetrics,
  Metric,
  Metrics,
  RawLinkMetrics,
} from "./aggregate";
import { HourlyAnalytics } from "@max/common/src/setpage/analytics";
import { SetPageMusicLinkServices } from "@max/common/dist/setpage";

export const SET_PAGE_MODULE_NAMES = {
  buttons_links: "Links",
  music_links: "Smart Music Links",
  merch: "Merch",
  video: "Single Video",
  video_gallery: "Video Gallery",
  image_gallery: "Image Gallery",
  shows: "Shows",
  embed: "Social Widgets",
  signup_form: "Sign Up Form",
  survey_link: "Survey Link",
  embedded_tickets: "Embedded Elements",
  spotify_embed: "Spotify Embed",
};

const updateMetricLabel = (text: string) => {
  let label = text;
  if (label in SET_PAGE_MODULE_NAMES) {
    label = SET_PAGE_MODULE_NAMES[label];
  }
  return label.replaceAll("_", " ");
};

const createChartMetrics = (
  metrics: RawLinkMetrics,
  nameKey?: string,
): Metric[] => {
  return (
    Object.entries(metrics)
      ?.map(([id, metric]) => ({
        ...metric,
        id,
        label: updateMetricLabel(metric[nameKey] || id),
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
      .sort((a, b) => b.clicks - a.clicks) || []
  );
};

export const getTotalClicks = (links: RawLinkMetrics): number =>
  Object.values(links || {}).reduce((acc, link) => acc + link.clicks, 0);

export const getDailyMetrics = (
  hours: HourlyAnalyticsWithId[],
  dates: DateTime[],
  colors?: string[],
) => {
  if (!dates?.length) return [];

  // create object with hex values indexed by month
  const colorObject = dates?.reduce((acc, curr) => {
    if (!acc[curr.monthShort] && colors) {
      acc[curr.monthShort] = colors[Object.keys(acc).length % colors.length];
    }
    return acc;
  }, {});

  const dailyAnalytics: Metric[] = [];
  for (const date of dates) {
    const day = Math.floor(date.toSeconds() / (60 * 60 * 24));
    const matchingHours = hours?.filter(({ id }) => id === day.toString());
    const mergedHours = aggregateHourlyMetrics(matchingHours);
    const clicks = getTotalClicks(mergedHours.links);
    const fill = colorObject?.[date.monthShort];
    const visits = mergedHours.visits?.total || 0;
    const cvr = clicks > 0 ? (clicks / visits) * 100 : null;

    dailyAnalytics.push({
      id: day.toString(),
      label: date.toFormat("LLL d"),
      clicks,
      visits,
      cvr,
      ...(fill && { fill }),
    });
  }
  return dailyAnalytics;
};

export const getClicksBySection = (links: RawLinkMetrics) => {
  const sectionClicks: RawLinkMetrics = {};
  let total = 0;
  for (const link of Object.values(links || {})) {
    const clickTotal = (sectionClicks[link.type]?.clicks || 0) + link.clicks;
    sectionClicks[link.type] = {
      ...link,
      clicks: clickTotal,
    };
    total += clickTotal;
  }
  if (!total) {
    return [];
  }
  return createChartMetrics(sectionClicks, "type");
};

export const getTopClicks = (
  data: HourlyAnalytics,
  types: string[],
  formatId?: (v: string) => string,
  indexBy?: string,
) => {
  const metrics: RawLinkMetrics = {};
  const links = data.links;
  let total = 0;
  if (links) {
    for (const [linkId, link] of Object.entries(links)) {
      if (!types.length || types.includes(link.type)) {
        const id = formatId?.(linkId) || linkId;
        const clickTotal = (metrics[id]?.clicks || 0) + link.clicks;
        metrics[id] = {
          ...link,
          clicks: (metrics[id]?.clicks || 0) + link.clicks,
          ...(data.releases?.[id]?.image && {
            image: data.releases[id]?.image,
          }),
        };
        total += clickTotal;
      }
    }
  }
  if (!total) {
    return [];
  }
  return createChartMetrics(metrics, indexBy ?? "label").slice(0, 5);
};

export const getTopSources = (sources?: HourlyAnalytics["sources"]) => {
  return (
    Object.entries(sources || {})
      ?.map(([id, visits]) => ({
        id,
        label: updateMetricLabel(id),
        visits,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))
      .sort((a, b) => b.visits - a.visits)
      .slice(0, 5) || []
  );
};

export const getTopReleases = (metrics: HourlyAnalytics) => {
  const metric = getTopClicks(
    metrics,
    ["music_links"],
    (v) => v.split(":")?.[0],
  );

  if (!metric?.length) {
    return [];
  }

  const removeServiceFromLabel = (label: string): string => {
    for (const service of SetPageMusicLinkServices) {
      const formattedService = `(${updateMetricLabel(service)})`;
      if (label.includes(formattedService)) {
        label = label.replace(formattedService, "");
      }
    }
    return label.trim();
  };
  return metric.map((m) => ({ ...m, label: removeServiceFromLabel(m.label) }));
};

const topLinkTypes = Object.keys(SET_PAGE_MODULE_NAMES).filter(
  (type) =>
    !["spotify_embed", "embed", "social", "image_gallery"].includes(type),
);

export const processMetrics = (
  hours: HourlyAnalyticsWithId[],
): (Metrics & { modules: HourlyAnalytics["modules"] }) | undefined => {
  if (!hours) {
    return;
  }
  const metrics = aggregateHourlyMetrics(hours);

  const clicksBySection = getClicksBySection(metrics.links);
  const clicksByDsp = getTopClicks(
    metrics,
    ["music_links"],
    (v) => v.split(":")?.[1],
    "",
  );
  const topReleases = getTopReleases(metrics);
  const topSources = getTopSources(metrics.sources);
  const topLinks = getTopClicks(metrics, topLinkTypes);
  const totalClicks = getTotalClicks(metrics.links);

  return {
    metricsByDay: [],
    clicksBySection,
    clicksByDsp,
    topLinks,
    topReleases,
    topSources,
    totalClicks,
    totalVisits: metrics.visits?.total || 0,
    uniqueVisits: metrics.visits?.unique || 0,
    signups: metrics.signups || 0,
    modules: metrics.modules,
  };
};
