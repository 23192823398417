import { firestore } from "firebase-internal";
import { doc } from "firebase/firestore";
import { useSetLiveEvents } from "hooks";
import { useArtistSurveys } from "hooks/useArtistSurveys";
import { useDocumentOnce } from "react-firebase-hooks/firestore";
import { useParams } from "react-router-dom";

export const useDashboard = () => {
  const { artistId } = useParams<{ artistId: string }>();

  const [bioSnap, setBioLoading] = useDocumentOnce(
    doc(firestore, "set_page", artistId),
  );

  const setBio = {
    active: !setBioLoading && bioSnap?.exists(),
    loading: setBioLoading,
  };

  const { setLiveEvents: past, loading: setLivePastLoading } = useSetLiveEvents(
    {
      showType: "past",
      resultsPerPage: 3,
    },
  );

  const { setLiveEvents: upcoming, loading: setLiveUpcomingLoading } =
    useSetLiveEvents({
      showType: "upcoming",
      filter: "published",
      resultsPerPage: 3,
    });

  const setLive = {
    shows: { upcoming, past },
    loading: setLivePastLoading || setLiveUpcomingLoading,
    active:
      !setLivePastLoading &&
      !setLiveUpcomingLoading &&
      (!!upcoming.length || !!past.length),
  };

  const { documents: surveys, loading: setFanLoading } = useArtistSurveys({
    resultsPerPage: 4,
    sortBy: "lastResponse:desc",
    filterBy: `artistGroupId:${artistId}`,
    limitHits: 4,
  });

  const setFan = {
    surveys,
    loading: setFanLoading,
    active: !!surveys.length,
  };

  const loading =
    setBioLoading ||
    setLiveUpcomingLoading ||
    setLivePastLoading ||
    setFanLoading;

  return { loading, setLive, setFan, setBio };
};
