import { useCallback, useEffect, useRef } from "react";
import { useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import styled, { CSSProperties } from "styled-components";
import { Body2 } from "melodies-source/Text";
import "./override.css";
import { SvgEdit } from "melodies-source/Svgs/Edit";
import { usePopper } from "react-popper";
import { useClickOutside } from "hooks";

const modifiers = [
  {
    name: "offset",
    options: {
      offset: ({ placement }: { placement: string }) => {
        if (placement.includes("top")) {
          return [46, -46];
        } else {
          return [46, -46];
        }
      },
    },
  },
  {
    name: "preventOverflow",
    options: {
      padding: 8,
    },
  },
];

export const ColorPicker = ({
  label,
  value,
  onSelect,
  error,
  style,
}: {
  label?: string;
  value: string;
  onSelect: (color: string) => void;
  error?: string;
  style?: CSSProperties;
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const popover = useRef<HTMLDivElement>(null);
  const colorSwatch = useRef<HTMLDivElement>(null);

  const { styles, attributes, update } = usePopper(
    colorSwatch?.current,
    popover?.current,
    {
      placement: "bottom-start",
      modifiers,
    },
  );

  useEffect(() => {
    if (update) {
      update?.();
    }
  }, [showPicker]);

  const close = useCallback(() => setShowPicker(false), []);
  useClickOutside(popover, close, colorSwatch);

  return (
    <ColorDisplay style={style} $hasError={!!error}>
      <ColorSwatch
        background={value}
        onClick={() => setShowPicker((show) => !show)}
        ref={colorSwatch}
      >
        <EditCircle>
          <SvgEdit />
        </EditCircle>
      </ColorSwatch>
      {!!label && <LabelText>{label}</LabelText>}

      <ColorPickerWrapper
        open={showPicker}
        className="color-picker"
        ref={popover}
        style={styles.popper}
        {...attributes.popper}
      >
        <HexColorPicker
          // this color picker does not handle an empty string properly. Pass undefined instead.
          color={value || undefined}
          onChange={onSelect}
          style={{
            marginBottom: 10,
            height: 268,
            paddingBottom: 68,
            borderRadius: 12,
          }}
        />
        <HexColorInput color={value} onChange={onSelect} prefixed />
      </ColorPickerWrapper>
    </ColorDisplay>
  );
};

export const ColorDisplay = styled.div<{ $hasError?: boolean }>`
  position: relative;
  width: 110px;
  height: 149px;
  flex-shrink: 0;
  border-radius: 6px;
  border: 1px solid ${({ $hasError }) => ($hasError ? "red" : "#d2d2d2")};
  background: #fff;
  padding: 10px 12px 14px 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ColorSwatch = styled.div<{ background?: string }>`
  width: 86px;
  height: 86px;
  flex-shrink: 0;
  border-radius: 6px;
  background: ${({ background }) => background || "#F2F5F7"};

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const EditCircle = styled.div`
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
`;

const ColorPickerWrapper = styled.div<{ open?: boolean }>`
  display: ${(p) => (p.open ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  z-index: 10;
  position: absolute;
`;

const LabelText = styled(Body2)`
  color: #333;
  display: flex;
  margin-top: auto;
`;
