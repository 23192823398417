import { IconCalendarDisplay } from "assets/svg";
import { DateTime } from "luxon";
import styled, { css } from "styled-components";

export const CalendarDisplay = ({ date }: { date: DateTime }) => {
  return (
    <CalendarWrapper>
      <DayOfWeek>{date.toFormat("ccc")}</DayOfWeek>
      <Day>{date.day}</Day>
      <Month>{date.toFormat("LLL")}</Month>
      <CalendarImage />
    </CalendarWrapper>
  );
};

const DayOfWeek = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 8px;
  font-weight: 700;
  letter-spacing: 0.5px;
  line-height: 9px;
  opacity: 0.6;
  text-transform: uppercase;

  ${({ theme }) => theme.mediaQueries.mobile} {
    letter-spacing: 0.3px;
    line-height: 8px;
    font-size: 7px;
  }
`;

const Month = styled.p`
  font-family: "Poppins", sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.3px;
  line-height: 10px;
  opacity: 0.85;
  text-transform: uppercase;

  ${({ theme }) => theme.mediaQueries.mobile} {
    letter-spacing: 0.5px;
    line-height: 9px;
    font-size: 8px;
  }
`;

const Day = styled.p`
  color: #e71f3d;
  font-family: "Poppins", sans-serif;
  font-size: 19px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;

  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 16px;
    line-height: 16px;
  }
`;

const CalendarImage = styled(IconCalendarDisplay)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const CalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 0;
  align-items: center;
  position: relative;
  width: 54px;
  height: 57px;
  padding-top: 11px;
  margin-top: -3px;

  ${({ theme }) =>
    theme.custom &&
    css`
      ${Day}, svg {
        color: #ffffff;
      }
    `};

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 42px;
    height: 44px;
    padding-top: 8px;
    margin-top: -2px;
  }
`;
