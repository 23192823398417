import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { SvgBack as BackIcon } from "melodies-source/Svgs/Back";
import { Stats } from "./Stats";
import { ChartCard, Props as ChartCardProps } from "../../Components/ChartCard";
import { H2, H3 } from "melodies-source/Text";
import { Divider } from "Components/Divider";
import { Button } from "melodies-source/Button";
import { ReactComponent as DownloadIcon } from "assets/svg/download.svg";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { DocumentReference, doc, getFirestore } from "firebase/firestore";
import {
  Locations,
  Question,
  Questions,
  RankedQuestion,
  TextQuestion,
} from "models/survey";
import { Spinner } from "melodies-source/Spinner";
import { Filter } from "./Filter";
import { useTabletMediaQuery } from "hooks/useTabletMediaQuery";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { downloadFromBlob } from "Utils/file";
import { useArtistContext } from "Components";
import { useArtistSurveys } from "hooks/useArtistSurveys";
import { DateTime } from "luxon";
import { FreeResponsesModal } from "../../Components/FreeResponsesModal";
import SweepsCard from "Routes/Reporting/SetFan/Components/Sweeps";
import { SurveyRootDoc } from "@max/common/dist/setfan";

const FanLocations = React.lazy(
  () => import("Routes/Reporting/Components/FanLocations"),
);

interface Context {
  questions: Question[];
}

const SurveyQuestionsContext = createContext<Nullable<Context>>(null);

export const SurveyDetails: React.FC = () => {
  const { name: artistName, logAction } = useArtistContext();
  const [selectedQuestion, setSelectedQuestion] = useState("all");
  const [selectedOption, setSelectedOption] = useState("all");
  const [resultsLoading, setResultsLoading] = useState(false);
  const { artistId, surveyId } = useParams<{
    artistId: string;
    surveyId: string;
  }>();
  const isMobile = useMobileMediaQuery();
  const isTablet = useTabletMediaQuery();
  const [aggregations, aggregationsLoading] = useDocumentData<Questions>(
    doc(
      getFirestore(),
      `sts3_surveys/${surveyId}/aggregations/${
        selectedOption !== "all"
          ? `${selectedQuestion}#${selectedOption}`
          : "prod"
      }`,
    ),
  );
  const [surveyDoc, surveyDocLoading] = useDocumentData<SurveyRootDoc>(
    doc(
      getFirestore(),
      `sts3_surveys/${surveyId}`,
    ) as DocumentReference<SurveyRootDoc>,
  );

  // if clicking survey details at bottom of list, scroll back to top of details page
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  const { documents: surveys } = useArtistSurveys({
    queryBy: "title",
    limitHits: 1,
    resultsPerPage: 1,
    filterBy: [`surveyId:${surveyId}`].join(" && "),
  });

  const [allRespondentsQuestions, setAllRespondentsQuestions] =
    useState<Question[]>(null);

  const [surveyQuestions, setSurveyQuestions] = useState<Question[]>(null);

  const locations: Locations = aggregations?.locations as unknown as Locations;

  const downloadSummary = async () => {
    setResultsLoading(true);

    const storage = getStorage();
    const gsReference = ref(
      storage,
      `gs://${process.env.REACT_APP_PRIVATE_BUCKET}/artists/${artistId}/setfan/${surveyId}.csv`,
    );

    try {
      const downloadUrl = await getDownloadURL(gsReference);
      if (downloadUrl) {
        const response = await fetch(downloadUrl);
        const blob = await response.blob();
        await logAction("portal_download_results", {
          responses: surveyDoc.totalResponses,
          survey: surveyId,
        });
        downloadFromBlob(
          blob,
          `${artistName} - ${
            surveys[0].title
          } - ${DateTime.now().toLocaleString()}.csv`,
        );
      }
    } catch (error) {
      console.error(error);
    }

    setResultsLoading(false);
  };

  const getBarsChartData = (
    type: "info-bar" | "horizontal-bar",
    question: Question,
    total: number,
  ): ChartCardProps["chart"] => ({
    type,
    data: Object.entries(question.options)
      .filter(([_, option]) =>
        question.type === "ImageQuestion" ? !!option.src : !!option.label,
      )
      .filter(([_, option]) =>
        question.type === "SongsQuestion" ? !!option.count : true,
      )
      .map(([id, option]) => ({
        label: option.label ?? "",
        value: option.count,
        ...(question.type === "ImageQuestion"
          ? { image: { src: question.options[id].src, alt: option.label } }
          : null),
      }))
      .sort((a, b) => b.value - a.value),
    total,
  });

  const getRatingChartData = (
    question: RankedQuestion,
    total: number,
  ): ChartCardProps["chart"] => {
    return {
      type: "rating",
      data: Object.entries(question.options).map(
        ([score, { label, count }]) => ({
          score: +score,
          label: label ?? score,
          count,
        }),
      ),
      total,
    };
  };

  const getWordCloudData = (
    question: TextQuestion,
  ): ChartCardProps["chart"] => {
    let data = Object.entries(question.options ?? {})
      .map(([label, count]) => ({
        text: label,
        value: count,
      }))
      .sort((a, b) => b.value - a.value);

    data = data.slice(
      0,
      Math.min(data.length, isMobile ? 20 : isTablet ? 30 : 40),
    );
    return {
      type: "word-cloud",
      data,
    };
  };

  const ageChart: ChartCardProps["chart"] = {
    type: "donut",
    data: aggregations
      ? Object.entries(aggregations.age.options)
          .sort(([optionIdA], [optionIdB]) =>
            optionIdA.localeCompare(optionIdB),
          )
          .map(([optionId, option]) => ({
            id: optionId,
            label: `${option.label} years old`,
            value: option.count,
          }))
      : [],
  };

  const genderChart: ChartCardProps["chart"] = {
    type: "donut",
    data: aggregations
      ? Object.entries(aggregations.gender.options)
          .sort(([optionIdA], [optionIdB]) =>
            optionIdA.localeCompare(optionIdB),
          )
          .map(([optionId, option]) => ({
            id: optionId,
            label: option.label,
            value: option.count,
          }))
      : [],
  };

  const surveyQuestionsContextValue: Context = useMemo(
    () => ({ questions: allRespondentsQuestions }),
    [allRespondentsQuestions],
  );

  const chartCards = useMemo(
    () =>
      !aggregationsLoading &&
      !surveyDocLoading &&
      surveyQuestions &&
      surveyQuestions.map((question) => {
        const filterQuestion = aggregations[selectedQuestion];
        const totalResponses =
          selectedOption !== "all" && filterQuestion.type !== "TextQuestion"
            ? filterQuestion?.options[selectedOption]?.count
            : surveyDoc?.totalResponses;

        const dataMap = [
          {
            types: [
              "Autocomplete",
              "Dropdown",
              "SingleSelectQuestion",
              "MultipleSelectQuestion",
              "SongsQuestion",
            ],
            getData: () =>
              getBarsChartData("horizontal-bar", question, totalResponses),
          },
          {
            types: ["ImageQuestion"],
            getData: () =>
              getBarsChartData("info-bar", question, totalResponses),
          },
          {
            types: ["RankedQuestion"],
            getData: () =>
              question.type === "RankedQuestion" &&
              getRatingChartData(question, totalResponses),
          },
          {
            types: ["TextQuestion"],
            getData: () =>
              question.type === "TextQuestion" && getWordCloudData(question),
          },
        ];

        const getData = dataMap.find((elem) =>
          elem.types.includes(question.type),
        )?.getData;

        const chart = getData && getData();
        if (chart) {
          const chartCard = (
            <ChartCard
              key={question.header}
              title={question.header}
              chart={chart}
              {...(question.type === "TextQuestion" && {
                modal: {
                  buttonText: "See Details",
                  component: FreeResponsesModal,
                  props: {
                    questionId: question.id,
                    title: question.header,
                  },
                },
              })}
            />
          );

          if (question.type === "TextQuestion") {
            return (
              <SurveyQuestionsContext.Provider
                key={`${question.header}-provider`}
                value={surveyQuestionsContextValue}
              >
                {chartCard}
              </SurveyQuestionsContext.Provider>
            );
          } else {
            return chartCard;
          }
        } else {
          return null;
        }
      }),
    [aggregationsLoading, surveyDocLoading, surveyQuestions],
  );

  const DemographicsHeading = isMobile ? H3 : H2;
  useEffect(() => {
    if (!aggregationsLoading && !surveyDocLoading) {
      const getQuestions = (
        filter: (entry: Entries<Questions>[0]) => boolean,
      ) =>
        aggregations
          ? (Object.entries(aggregations) as Entries<Questions>)
              .filter(filter)
              .sort(([aId, aData], [bId, bData]) =>
                surveyDoc.displayOrder &&
                surveyDoc.displayOrder[aId] &&
                surveyDoc.displayOrder[bId]
                  ? surveyDoc.displayOrder[aId].display -
                    surveyDoc.displayOrder[bId].display
                  : aData.header.localeCompare(bData.header),
              )
              .map(([id, data]) => ({ ...data, id }))
          : [];

      const array = [
        ...getQuestions(
          ([questionId, question]) =>
            (questionId.includes("-") ||
              // temporary hack to handle static ids in survey templates because they won't have '-'
              questionId.startsWith("question_") ||
              question.type === "SongsQuestion") &&
            !questionId.includes("#"),
        ),
        ...getQuestions(([questionId]) =>
          ["age", "gender", "setliveEvent"].includes(questionId),
        ),
      ];
      setSurveyQuestions(array);

      if (!allRespondentsQuestions) {
        setAllRespondentsQuestions(array);
      }
    }
  }, [aggregationsLoading, surveyDocLoading]);

  return (
    <>
      <BackLink to={`/${artistId}/reporting/set-fan`}>
        <BackIcon /> Surveys
      </BackLink>
      <Stats survey={surveys[0]} />
      {!!surveyDoc?.sweeps && (
        <SweepsCard
          survey={surveys[0]}
          // if there is a rulesUrl present in the sweeps of the survey root doc, a custom
          // rules url has been provided and we can open the sweeps to non-US residents
          forceUS={!surveyDoc?.sweeps?.rulesUrl}
        />
      )}
      <Actions>
        <Filter
          key="survey"
          surveyQuestions={allRespondentsQuestions}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
        <ExportButton onClick={downloadSummary} disabled={resultsLoading}>
          <DownloadIcon />
          {resultsLoading ? "Loading..." : "Export Results"}
        </ExportButton>
      </Actions>
      {!chartCards ? <Spinner /> : chartCards}
      <StyledDivider />
      <DemographicsContainer>
        <DemographicsHeading>Demographics</DemographicsHeading>
        <DemographicsChartsContainer>
          <ChartCard
            title="Age"
            chart={ageChart}
            loading={aggregationsLoading}
          />
          <ChartCard
            title="Gender"
            chart={genderChart}
            loading={aggregationsLoading}
          />
        </DemographicsChartsContainer>
        {selectedOption === "all" && (
          <React.Suspense fallback={<Spinner />}>
            {aggregations && (
              <FanLocations
                dmaData={locations.dma ?? []}
                worldData={locations.world ?? []}
              />
            )}
          </React.Suspense>
        )}
      </DemographicsContainer>
    </>
  );
};

export const useSurveyQuestionsContext = (): Context => {
  const context = useContext(SurveyQuestionsContext);

  if (context === undefined) {
    throw new Error(
      "useSurveyQuestionsContext must be used within a Survey Questions Provider",
    );
  }

  return context!;
};

const ExportButton = styled(Button)`
  display: flex;
  justify-content: space-evenly;
  width: 200px;
  background: transparent !important;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  gap: 5px;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 30px 0;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  ${H3} {
    margin-bottom: 5px;
  }
`;

const StyledDivider = styled(Divider)`
  margin-top: 40px;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 30px;
  }
`;

const DemographicsChartsContainer = styled.div`
  display: flex;
  gap: 2%;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    gap: 0px;
  }
`;

const DemographicsContainer = styled.div`
  margin: 40px 0 20px;

  ${({ theme }) => theme.media.mobile} {
    margin: 20px 0;
  }
`;

const BackLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  color: var(--main-color);
  font-weight: 500;
`;
