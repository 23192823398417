import { useRef, useState } from "react";
import { useExceptionLogging } from "./useExceptionLogging";
import useUserIdToken from "./useUserIdToken";
import { SpotifyAlbum, SpotifyTrack } from "@max/common/functions/setfan";

export const useSpotifySearch = () => {
  const spotifyToken = useUserIdToken();
  const { logException } = useExceptionLogging();
  const [loading, setLoading] = useState(false);

  const timer = useRef(null);

  const search = async (
    term: string,
    type: ("track" | "album") | ("track" | "album")[] = "track",
    params?: Record<string, string>,
  ) => {
    if (!spotifyToken || !term) {
      return undefined;
    }

    const typesParam = Array.isArray(type) ? type.join(",") : type;

    try {
      const url = new URL(
        "/v1/search",
        process.env.REACT_APP_SPOTIFY_SEARCH_URL.replace(/\/$/, ""),
      );

      url.searchParams.set("q", term);
      url.searchParams.set("type", typesParam);

      for (const [paramKey, paramVal] of Object.entries(params ?? {})) {
        url.searchParams.set(paramKey, paramVal);
      }

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${spotifyToken}`,
        },
      });

      return await response.json();
    } catch (err) {
      console.error(err);
      logException(err, {
        service: "spotify",
        action: "search",
        term,
        type: typesParam,
      });
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  const debouncedSearch = (
    ...args: Parameters<typeof search>
  ): Promise<
    Promise<{
      albums: PagingObject<SpotifyAlbum>;
      tracks: PagingObject<SpotifyTrack>;
    }>
  > => {
    setLoading(true);
    clearTimeout(timer.current);
    return new Promise<ReturnType<typeof search>>((resolve) => {
      timer.current = setTimeout(() => {
        resolve(search(...args));
      }, 800);
    });
  };

  const fetchAlbumTracks = async (id: string) => {
    if (!spotifyToken) {
      return undefined;
    }

    try {
      const url = new URL(
        `/v1/albums/${id}/tracks`,
        process.env.REACT_APP_SPOTIFY_SEARCH_URL.replace(/\/$/, ""),
      );

      const response = await fetch(url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${spotifyToken}`,
        },
      });

      return await response.json();
    } catch (err) {
      console.error(err);
      logException(err, {
        service: "spotify",
        action: "fetch_album_tracks",
        albumId: id,
      });
      return undefined;
    }
  };

  return { debouncedSearch, loading, search, fetchAlbumTracks };
};

interface PagingObject<T> {
  href: string;
  items: T[];
  limit: number;
  next: string | null;
  offset: number;
  previous: string | null;
  total: number;
}
