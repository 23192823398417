import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import posthog from "posthog-js";

setupLogRocketReact(LogRocket);

if (process.env.REACT_APP_ENV !== "dev") {
  LogRocket.init("fyqxo2/artist-portal", {
    release: process.env.REACT_APP_GIT_SHA,
    network: {
      responseSanitizer: (response) => response,
    },
    mergeIframes: true,
    shouldDebugLog: false,
  });

  if (process.env.REACT_APP_POSTHOG_APIKEY) {
    posthog.init(process.env.REACT_APP_POSTHOG_APIKEY, {
      api_host: "https://us.i.posthog.com",
      person_profiles: "identified_only",
      persistence: "localStorage+cookie",
    });
  } else {
    console.warn("No PostHog API KEY is defined; PostHog will be disabled");
  }
}
