import { SurveyTemplate } from "Routes/SetFan/dashboard/Components/Templates/types";
import { ReactComponent as IconFavorite } from "assets/svg/favorite.svg";

export const hometownFavorites: SurveyTemplate = {
  icon: <IconFavorite />,
  title: "Tell Us About Your Hometown",
  description:
    "Offer fans the chance to share insights about their area and learn where the next tour should take you.",
  complexity: "high",
  contestSuggestion: "Sweepstakes",
  templateId: "hometownFavorites",
  template: () => ({
    fields: {
      alcoholIntroText: {
        content: {
          en: "Please share a bit about what you like to drink. Your feedback could help us find a sponsor for the tour.",
        },
      },
      body: {
        content: {
          en: "We hope to be in your area on our next tour and want your insider tips about can't miss spots.",
        },
      },
      clothingIntroText: {
        content: {
          en: "Working with a fashion brand as a sponsor could help make our tour even better, but we want to find one that would be a good fit with our fans.",
        },
      },
      demographicsIntroText: {
        content: {
          en: "We'd love to know a bit more about you to help plan how to get the word out about the tour.",
        },
      },
      description: {
        content: {
          en: "Hometown Favorites",
        },
      },
      displayedCategories: {
        content: [
          "demographics",
          "fooddrink",
          "alcohol",
          "clothing",
          "vehicle",
          "entertainment",
        ],
      },
      entertainmentIntroText: {
        content: {
          en: "Please take a minute tell us what you like in your free time. Your insights can help us collaborate with tech and entertainment brands that you love, adding an extra spark to our shows.",
        },
      },
      fooddrinkIntroText: {
        content: {
          en: "A food or drink sponsor could help us make the tour even better. Help us find one that would be a good fit for this tour.",
        },
      },
      header: {
        content: {
          en: "Tell Us About your Hometown!",
        },
      },
      numberOfCategories: {
        content: "2",
      },
      thankYouBody: {
        content: {
          en: "Your insider tips are invaluable, and we're excited to explore the places you love! We'll be sure to let you know when we're heading to your area next.",
        },
      },
      thankYouHeader: {
        content: {
          en: "Thank You for Sharing Your Local Favorites!",
        },
      },
      vehicleIntroText: {
        content: {
          en: "Your feedback about what you'd like to drive can help us work with a vehicle brand to make the next tour even better.",
        },
      },
    },
    surveyQuestions: [
      {
        label: {
          en: "What's a can't miss restaurant in your town?",
        },
        multiline: false,
        noBorder: false,
        required: true,
        type: "TextQuestion",
      },
      {
        label: {
          en: "Anything else we should check out?",
        },
        multiline: false,
        noBorder: false,
        required: true,
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-tour---hom-200841"
        : "pp-copy-of-to-17c275",
  },
};
