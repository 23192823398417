import { ReactComponent as IconFavortieTrackTitle } from "assets/svg/favorite-track-title.svg";
import { SurveyTemplate } from "../../types";

export const playlistIdeas: SurveyTemplate = {
  icon: <IconFavortieTrackTitle />,
  title: "Playlist Ideas",
  description:
    "Gain playlist adds by asking fans what playlists should feature the new release.",
  templateId: "playlistIdeas",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: formState.title,
        },
      },
    },
    surveyQuestions: [
      {
        type: "TextQuestion",
        id: "question_1",
        multiline: true,
        label: { en: "What playlists fit this release?" },
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-sweeps---p-0bb499"
        : "pp-playlist-i-e36486",
    fields: [
      {
        name: "title",
        type: "text",
        label: "What's the title of the release?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.title ? false : "This field cannot be left blank",
      },
      {
        name: "releaseDate",
        type: "text",
        label: "When will it be available (or is it already available)?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.releaseDate ? false : "This field cannot be left blank",
      },
    ],
  },
};
