import { ReactComponent as TemplateIcon } from "assets/svg/todaysFit.svg";
import { SurveyTemplate } from "../../types";

export const todaysFit: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Today's Fit",
  description:
    "Give your fans the chance to vote on which artist's merch you'll be wearing on the live.",
  templateId: "todaysFit",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Today's Fit",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "Which merch should I wear?",
        },
        type: "SingleSelectQuestion",
        options: [
          {
            label: {
              en: "Black Beanie",
            },
          },
          {
            label: {
              en: "Oversized Hoodie",
            },
          },
          {
            label: {
              en: "Graphic Tee",
            },
          },
          {
            label: {
              en: "Denim Jacket",
            },
          },
          {
            label: {
              en: "Bucket Hat",
            },
          },
          {
            label: {
              en: "Custom Sneakers",
            },
          },
        ],
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-livestream-d06416"
        : "pp-livestream-a54ffd",
    fields: [
      {
        name: "date",
        type: "text",
        label: "When does your livestream take place?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.date ? false : "This field cannot be left blank",
      },
    ],
  },
};
