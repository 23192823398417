import React, { useState } from "react";
import { TextInput } from "melodies-source/TextInput";
import { Areas } from "..";
import { Select } from "melodies-source/Select";
import { toast } from "react-hot-toast";
import { addDoc, collection, getFirestore } from "firebase/firestore";
import { Modal } from "../../../../../../Components/Modal";
import { FormikConfig, useFormik } from "formik";
import * as yup from "yup";
import { yupRequired, yupOptional } from "Utils/yup";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { GtbCustomApp } from "custom/companies/gtb/types";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  areas: Areas;
}

const INITIAL_VALUES = { regionName: "", areaName: "", newAreaName: "" };

const VALIDATION_SCHEMA = yup.object().shape({
  regionName: yupRequired,
  areaName: yupRequired,
  newAreaName: yup.string().when("areaName", (value) => {
    if (value === "new") {
      return yupRequired;
    } else return yupOptional;
  }),
});

export const RegionModal: React.FC<Props> = ({ isOpen, onClose, areas }) => {
  const [loading, setLoading] = useState(false);
  const formik = useFormik({
    initialValues: INITIAL_VALUES,
    validationSchema: VALIDATION_SCHEMA,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: handleAdd,
  });
  const { customApp } = useCustomAppContext<GtbCustomApp>();

  async function handleAdd(
    ...params: Parameters<FormikConfig<typeof INITIAL_VALUES>["onSubmit"]>
  ) {
    setLoading(true);
    const [{ regionName, areaName, newAreaName }, { resetForm }] = params;
    try {
      await addDoc(collection(getFirestore(), customApp.regionsCollection), {
        area: areaName === "new" ? newAreaName : areaName,
        name: regionName,
        members: {},
        programs: {},
      });
      toast.success("Region added successfully!");
    } catch (error) {
      console.log(error);
      toast.error(`There was an error adding the region`);
    }
    setLoading(false);
    onClose();
    resetForm();
  }

  const handleClose = () => {
    onClose();
    formik.resetForm();
  };

  const areaOptions = [
    { value: "new", label: "-NEW AREA-" },
    ...Object.keys(areas).map((areaName) => ({
      value: areaName,
      label: areaName,
    })),
  ];

  return (
    <Modal
      isOpen={isOpen}
      header="Add Region"
      onClose={handleClose}
      actions={{
        secondary: {
          text: "Cancel",
          onClick: handleClose,
        },
        main: {
          text: "Add",
          onClick: formik.handleSubmit,
          loading,
        },
      }}
      withCloseIcon
      isForm
    >
      <TextInput
        label="Region Name"
        placeholder="Enter region name..."
        value={formik.values.regionName}
        onChange={formik.handleChange("regionName")}
        {...(formik.errors.regionName && {
          hasError: true,
          helperText: formik.errors.regionName,
        })}
      />
      <Select
        label="Area"
        value={formik.values.areaName}
        onChange={formik.handleChange("areaName")}
        options={areaOptions}
        {...(formik.errors.areaName && {
          hasError: true,
          helperText: formik.errors.areaName,
        })}
      />
      {formik.values.areaName === "new" && (
        <TextInput
          label="New Area"
          placeholder="Enter new area name..."
          value={formik.values.newAreaName}
          onChange={formik.handleChange("newAreaName")}
          {...(formik.errors.newAreaName && {
            hasError: true,
            helperText: formik.errors.newAreaName,
          })}
        />
      )}
    </Modal>
  );
};
