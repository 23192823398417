import {
  SurveyBuilderRootDocument,
  SurveyBuilderVersionDocument,
  SurveyRootDoc,
} from "@max/common";
import {
  CollectionReference,
  DocumentData,
  DocumentReference,
  doc,
  collection,
} from "firebase/firestore";
import { firestore as db } from "firebase-internal";

import { Narrow } from "./ts-toolbelt";
import { enumKeys } from "@max/common/dist/utils";

export type MapperType<A> = {
  [K in keyof A]: A[K] extends DocumentData ? A[K] : never;
};

/**
 * A type that splits literal string S with delimiter D.
 *
 * For example Split<"a/b/c", "/"> is ['a' | "b" | "c"]
 */
type Split<S extends string, D extends string> = string extends S
  ? string[]
  : S extends ""
  ? []
  : S extends `${D}${infer Tail}`
  ? [...Split<Tail, D>]
  : S extends `${infer Head}${D}${infer Tail}`
  ? string extends Head
    ? [...Split<Tail, D>]
    : [Head, ...Split<Tail, D>]
  : [S];

/**
 * A type that ensure that type S is not null or undefined.
 */
type NullSafe<S extends null | undefined | string> = S extends null
  ? never
  : S extends undefined
  ? never
  : S extends string
  ? S
  : never;

/**
 * A type that extracts parameter name enclosed in bracket as string.
 * Ignore wildcard matches
 *
 * For example, Extract<"{uid}"> is "uid".
 * For example, Extract<"{uid=*}"> is "uid".
 * For example, Extract<"{uid=**}"> is "uid".
 */
type ExtractParam<Part extends string> = Part extends `{${infer Param}=**}`
  ? Param
  : Part extends `{${infer Param}=*}`
  ? Param
  : Part extends `{${infer Param}}`
  ? Param
  : never;

/**
 * A type that maps all parameter capture gropus into keys of a record.
 * For example, ParamsOf<"users/{uid}"> is { uid: string }
 * ParamsOf<"users/{uid}/logs/{log}"> is { uid: string; log: string }
 * ParamsOf<"some/static/data"> is {}
 *
 * For flexibility reasons, ParamsOf<string> is Record<string, string>
 */
export type ParamsOf<PathPattern extends string> = string extends PathPattern
  ? Record<string, string>
  : {
      [Key in ExtractParam<Split<NullSafe<PathPattern>, "/">[number]>]: string;
    };

type StringReplaceAll<
  T extends string,
  M extends { [k: string]: string },
  A extends string = "",
> = T extends `{${Extract<keyof M, string>}}${infer R}`
  ? T extends `{${infer K}}${R}`
    ? StringReplaceAll<R, M, `${A}${M[Extract<K, keyof M>]}`>
    : never
  : T extends `${infer F}${infer R}`
  ? StringReplaceAll<R, M, `${A}${F}`>
  : A;

/**
 * Removes leading and trailing slashes from a path.  For example
 *
 *    - StripSlashes<"/a/b/"> is "a/b"
 *    - StripSlashes<"/a/b"> is "a/b"
 *    - StripSlashes<"a/b/"> is "a/b"
 */
type StripSlashes<Path extends string> = Path extends `/${infer Rest}`
  ? StripSlashes<Rest>
  : Path extends `${infer Rest}/`
  ? StripSlashes<Rest>
  : Path;

type Parity = "even" | "odd";

/**
 * Determines whether there are an "even" or "odd" number of path segments.
 * For example:
 *    - SegmentParity<"a/">         // "odd"
 *    - SegmentParity<"/a/">        // "odd"
 *    - SegmentParity<"a/b">        // "even"
 *    - SegmentParity<"/a/b">       // "even"
 *    - SegmentParity<"a/b/c/d/e">  // "odd"
 */
type SegmentParity<
  Path extends string,
  FoundParity extends Parity = "odd",
> = StripSlashes<Path> extends `${string}/${infer Tail}`
  ? FoundParity extends "odd"
    ? SegmentParity<Tail, "even">
    : SegmentParity<Tail, "odd">
  : FoundParity;

type GetDataType<Path extends string> = Path extends keyof Mapper
  ? Mapper[Path] extends DocumentData
    ? Mapper[Path]
    : DocumentData
  : DocumentData;

export type DocumentPaths<Mapper, A extends "even" | "odd"> = {
  [K in Extract<keyof Mapper, string> as SegmentParity<K> extends A
    ? K
    : never]: Mapper[K];
};

/**
 * Generate a new function for building document and collection references
 * from the paths and data types defined in `Mapper`.
 */
const DocumentRefBuilder = () => {
  return <
    Path extends Extract<keyof DocumentPaths<Mapper, "even">, string>,
    Params extends ParamsOf<Path> = ParamsOf<Path>,
    PathOut extends StringReplaceAll<Path, Params> = StringReplaceAll<
      Path,
      Params
    >,
  >(
    path: Path,
    ...[params]: Params extends { [key: string]: never }
      ? [undefined?]
      : [Narrow<Params>]
  ): DocumentReference<GetDataType<Path>> => {
    const truePath = enumKeys(params).reduce((path: string, key) => {
      return path.replace(String(key), params?.[String(key)]);
    }, path as unknown as PathOut);
    const cleanedPath = truePath.replace(/[{}]/g, "");
    return doc(db, cleanedPath) as DocumentReference<GetDataType<Path>>;
  };
};

const CollectionRefBuilder = () => {
  return <
    Path extends Extract<keyof DocumentPaths<Mapper, "odd">, string>,
    Params extends ParamsOf<Path> = ParamsOf<Path>,
    PathOut extends StringReplaceAll<Path, Params> = StringReplaceAll<
      Path,
      Params
    >,
  >(
    path: Path,
    ...[params]: Params extends { [key: string]: never }
      ? [undefined?]
      : [Narrow<Params>]
  ): CollectionReference<GetDataType<Path>> => {
    const truePath = enumKeys(params).reduce((path: string, key) => {
      return path.replace(String(key), params?.[String(key)]);
    }, path as unknown as PathOut);
    const cleanedPath = truePath.replace(/[{}]/g, "");
    return collection(db, cleanedPath) as CollectionReference<
      GetDataType<Path>
    >;
  };
};

export interface Mapper {
  "set_fan_builder_surveys/{surveyId}": SurveyBuilderRootDocument;
  "set_fan_builder_surveys/{surveyId}/versions/{versionId}": SurveyBuilderVersionDocument;

  sts3_surveys: SurveyRootDoc;
  "sts3_surveys/{surveyId}": SurveyRootDoc;
}

export const documentRef = DocumentRefBuilder();
export const collectionRef = CollectionRefBuilder();
