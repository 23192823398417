import styled from "styled-components";
import {
  getFirestore,
  doc,
  setDoc,
  addDoc,
  collection,
  updateDoc,
  deleteField,
} from "firebase/firestore";
import Modal from "../modal";
import { Button } from "melodies-source/Button";
import { Select } from "melodies-source/Select";
import { useState } from "react";
import { useDesktopMediaQuery } from "hooks";
import { CircularLoader, useArtistContext } from "Components";
import { TextInput } from "melodies-source/TextInput";
import CheckCircleIcon from "../CheckCircleIcon";
import {
  EntityCountry,
  UMGOptInSearchResponse,
  UMGDocument,
  UMGActionsRequest,
  UMGActionsResponse,
} from "./types";
import { countries } from "./countries";
import { getFunctions, httpsCallable } from "firebase/functions";
import { UserActionDoc } from "@max/common";

export const UMGIntegrationModal = ({
  artistGroupId,
  isOpen,
  onClose,
}: {
  artistGroupId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [status, setStatus] = useState<
    "addArtist" | "gettingLists" | "selectLists" | "artistAdded"
  >("addArtist");
  const [apiAddError, setApiAddError] = useState("");
  const [artistName, setArtistName] = useState("");
  const [selectedCountry, setSelectedCountry] = useState<EntityCountry | null>(
    null,
  );
  const [emailOptInList, setEmailOptInList] = useState<
    UMGOptInSearchResponse[]
  >([]);
  const [smsOptInList, setSmsOptInList] = useState<UMGOptInSearchResponse[]>(
    [],
  );
  const [selectedEmailOptIn, setSelectedEmailOptIn] =
    useState<UMGOptInSearchResponse>();
  const [selectedSmsOptIn, setSelectedSmsOptIn] =
    useState<UMGOptInSearchResponse>();
  const { logAction } = useArtistContext();

  const isDesktop = useDesktopMediaQuery();

  const onSubmitNameAndCountry = async () => {
    setApiAddError("");
    setStatus("gettingLists");

    try {
      const { data } = await httpsCallable<
        UMGActionsRequest,
        UMGActionsResponse
      >(
        getFunctions(),
        "services-umgActions",
      )({
        action: "get_lists",
        artistGroupId,
        umgArtistName: artistName,
        countryIsoCode: selectedCountry?.id,
      }).catch((error) => {
        console.error(error);
        throw new Error(`Unknown error occurred. Please try again.`);
      });

      const { emailOptInKeys, smsOptInKeys } = data;

      if (emailOptInKeys.length === 0 && smsOptInKeys.length === 0) {
        throw new Error(
          `Sorry, ${artistName} was not found. Please note the name entered needs to be an exact match with the artist's name in the Universal database (including both spelling and punctuation).`,
        );
      }

      setEmailOptInList(emailOptInKeys);
      setSmsOptInList(smsOptInKeys);

      setStatus("selectLists");
    } catch (error) {
      console.error(error);

      setApiAddError((error as Error).message);

      setStatus("addArtist");
    }
  };

  const onSubmitLists = async () => {
    const ref = doc(
      getFirestore(),
      `artist_groups/${artistGroupId}/artist_group_private/umg`,
    );
    const umgDoc: UMGDocument = {
      artistName,
      countryIsoCode: selectedCountry.id,
    };
    if (selectedEmailOptIn) {
      umgDoc.email = selectedEmailOptIn.key;
    }
    if (selectedSmsOptIn) {
      umgDoc.sms = selectedSmsOptIn.key;
    }
    await setDoc(ref, umgDoc, { merge: true });
    await logAction("portal_connect_umg");
    setStatus("artistAdded");
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} service="umg">
      {status === "addArtist" && (
        <>
          <HeaderText isDesktop={isDesktop}>Connect to UMG</HeaderText>
          <br />
          <BodyText isDesktop={isDesktop}>
            This will connect the artist's fans with UMG. It uses the
            International Form Builder (IFB) API to ensure compliance with UMG
            data standards for marketing data and data privacy.
          </BodyText>
          <br />
          <InputLabel isDesktop={isDesktop}>Artist Name</InputLabel>
          <TextInput
            placeholder="Please enter exact artist name..."
            value={artistName}
            onChange={(text) => setArtistName(text)}
            hasError={!!apiAddError}
          />
          {!!apiAddError && (
            <ErrorText isDesktop={isDesktop}>{apiAddError}</ErrorText>
          )}
          <br />
          <InputLabel isDesktop={isDesktop}>Entity Country</InputLabel>
          <StyledSelect
            value={selectedCountry?.id}
            placeholder="Please choose..."
            options={countries.map((country) => ({
              label: country.label,
              value: country.id,
            }))}
            onChange={(selectionId) =>
              setSelectedCountry(
                countries.find((country) => country.id === selectionId),
              )
            }
          />
          <br />
          <StyledButton
            disabled={!artistName || !selectedCountry}
            onClick={onSubmitNameAndCountry}
          >
            Search for lists
          </StyledButton>
        </>
      )}
      {status === "gettingLists" && (
        <>
          <CircularLoader
            height={isDesktop ? 60 : 41}
            sx={{ marginBottom: 10 }}
          />
          <BodyText isDesktop={isDesktop}>Please wait...</BodyText>
          <BodyText isDesktop={isDesktop}>
            We're looking for lists for {artistName} in {selectedCountry?.label}
          </BodyText>
        </>
      )}
      {status === "artistAdded" && (
        <>
          <CheckCircleIcon />
          <BodyText isDesktop={isDesktop}>Success!</BodyText>
          <BodyText isDesktop={isDesktop}>
            Connection to UMG is complete.
          </BodyText>
          <br />
          <Button
            onClick={() => {
              onBackfillClickHandler(artistGroupId, onClose, logAction);
            }}
          >
            <span style={{ marginRight: "0.35rem" }}>
              Sync all existing fans to UMG
            </span>
          </Button>
        </>
      )}
      {status === "selectLists" && (
        <>
          <HeaderText isDesktop={isDesktop}>Please choose list(s)</HeaderText>
          <br />
          {emailOptInList.length > 0 && (
            <>
              <InputLabel isDesktop={isDesktop}>
                Which Email List do You Want to Use?
              </InputLabel>
              <StyledSelect
                value={selectedEmailOptIn?.key}
                placeholder="Please choose..."
                options={emailOptInList.map((item) => ({
                  label: item.tag,
                  value: item.key,
                }))}
                onChange={(selectionId) =>
                  setSelectedEmailOptIn(
                    emailOptInList.find((item) => item.key === selectionId),
                  )
                }
              />
            </>
          )}
          <br />
          {smsOptInList.length > 0 && (
            <>
              <InputLabel isDesktop={isDesktop}>
                Which Texting List do You Want to Use?
              </InputLabel>
              <StyledSelect
                value={selectedSmsOptIn?.key}
                placeholder="Please choose..."
                options={smsOptInList.map((item) => ({
                  label: item.tag,
                  value: item.key,
                }))}
                onChange={(selectionId) =>
                  setSelectedSmsOptIn(
                    smsOptInList.find((item) => item.key === selectionId),
                  )
                }
              />
            </>
          )}
          <StyledButton
            disabled={
              (emailOptInList.length > 0 && !selectedEmailOptIn) ||
              (smsOptInList.length > 0 && !selectedSmsOptIn)
            }
            onClick={onSubmitLists}
          >
            Connect
          </StyledButton>
        </>
      )}
    </Modal>
  );
};

const onBackfillClickHandler = async (
  artistGroupId: string,
  onClose: () => void,
  logAction: (
    action: UserActionDoc["action"],
    metadata?: UserActionDoc["metadata"],
  ) => Promise<{ success: boolean }>,
) => {
  const response = await addDoc(
    collection(
      getFirestore(),
      `/artist_groups/${artistGroupId}/artist_group_private/umg/backfills`,
    ),
    {
      backfillRequest: new Date(),
    },
  );
  logAction("portal_backfill_umg", { backfillRequestId: response.id });
  onClose();
};

export const UMGRemovalModal = ({
  artistGroupId,
  onClose,
  isOpen,
}: {
  artistGroupId: string;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [removingUMG, setRemovingUMG] = useState(false);
  const [removalError, setRemovalError] = useState("");
  const { logAction } = useArtistContext();

  const isDesktop = useDesktopMediaQuery();

  const onRemoveUMG = async () => {
    setRemovingUMG(true);

    setRemovalError("");

    const ref = doc(
      getFirestore(),
      `/artist_groups/${artistGroupId}/artist_group_private/umg`,
    );

    await updateDoc(ref, {
      artistName: deleteField(),
      countryIsoCode: deleteField(),
      email: deleteField(),
      sms: deleteField(),
    })
      .then(() => {
        logAction("portal_remove_umg");
        onClose();
      })
      .catch((error) => {
        console.error((error as Error).message);
        setRemovalError(
          `Error removing UMG account: ${(error as Error).message}`,
        );
      });

    setRemovingUMG(false);
  };

  const modalBody = () => {
    if (removalError) {
      return (
        <HeaderText style={{ color: "#e45c52" }} isDesktop={isDesktop}>
          {removalError}
        </HeaderText>
      );
    }

    if (removingUMG) {
      return (
        <>
          <CircularLoader height={30} sx={{ marginRight: "0.5rem" }} />
          <br />
          <BodyText isDesktop={isDesktop}>Removing UMG link...</BodyText>
        </>
      );
    }
    return (
      <HeaderText isDesktop={isDesktop}>
        Are you sure you want to unlink your account from UMG?
      </HeaderText>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} service="umg">
      {modalBody()}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginTop: 40,
        }}
      >
        <Button
          onClick={() => onRemoveUMG()}
          style={{ marginRight: "2rem" }}
          disabled={removingUMG}
        >
          Confirm
        </Button>
        <Button variant="secondary" onClick={onClose} disabled={removingUMG}>
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

const BodyText = styled.div<{ isDesktop: boolean }>`
  font-weight: 400;
  font-size: ${({ isDesktop }) => (isDesktop ? "17px" : "12px")};
  line-height: ${({ isDesktop }) => (isDesktop ? "25.5px" : "18px")};
`;

const InputLabel = styled.div<{ isDesktop: boolean }>`
  font-weight: 400;
  margin-right: auto;
  font-size: ${({ isDesktop }) => (isDesktop ? "14px" : "10px")};
  line-height: ${({ isDesktop }) => (isDesktop ? "25.5px" : "18px")};
`;

const ErrorText = styled.div<{ isDesktop: boolean }>`
  color: #e45c52;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-top: 5px;
  align-self: flex-start;
  margin-left: 10px;
`;

const HeaderText = styled.div<{ isDesktop: boolean }>`
  font-weight: 600;
  font-size: ${({ isDesktop }) => (isDesktop ? "20px" : "17px")};
  line-height: ${({ isDesktop }) => (isDesktop ? "30px" : "25.5px")};
`;

const StyledButton = styled(Button)`
  max-width: 385px;
`;

const StyledSelect = styled(Select)`
  max-width: 550px;
  & p {
    text-align: left;
  }
`;
