import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { CenterFlexRow, FlexColumn } from "Routes/styled";
import { Button } from "melodies-source/Button";
import { Card } from "melodies-source/Card";
import { SvgDelete } from "melodies-source/Svgs/Delete";
import { SvgDragLarge } from "melodies-source/Svgs/DragLarge";
import { SvgHelp } from "melodies-source/Svgs/Help";
import { Body2, Subtitle2 } from "melodies-source/Text";
import { Tooltip } from "melodies-source/Tooltip";
import { useIsMobile } from "melodies-source/utils";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

export interface SortableCardProps {
  id: string | number;
  title: string;
  onDelete?: VoidFunction;
  onEdit?: VoidFunction;
  children?: React.ReactNode;
  helperText?: string;
  actionRequired?: boolean;
}

export const SortableCard = ({
  id,
  title,
  onEdit,
  onDelete,
  children,
  helperText,
  actionRequired,
}: SortableCardProps) => {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
  });

  const style = {
    opacity: isDragging ? 0 : 1,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  return (
    <SortCard ref={setNodeRef} style={style}>
      {actionRequired && (
        <SelectedBorder>
          <Pill>
            <Body2>Action Required</Body2>
          </Pill>
        </SelectedBorder>
      )}
      <Handle {...attributes} {...listeners} style={{ cursor: "grab" }}>
        <SvgDragLarge />
      </Handle>
      <CardContent>
        <CardHeader>
          <CenterFlexRow>
            <Body2>{title}</Body2>
            {helperText && (
              <Tooltip
                parent={
                  <div style={{ paddingRight: 8 }}>
                    <HelpIcon />
                  </div>
                }
                placement="right"
                isActionable={true}
              >
                <Subtitle2 style={{ fontWeight: "normal", maxWidth: 288 }}>
                  {helperText}
                </Subtitle2>
              </Tooltip>
            )}
          </CenterFlexRow>
          <CenterFlexRow>
            {onEdit && (
              <Button
                text
                style={{ padding: "6px 12px", height: 36 }}
                onClick={onEdit}
              >
                Edit
              </Button>
            )}
            {onDelete && (
              <Button
                text
                onClick={onDelete}
                style={{ marginLeft: 4, padding: "6px", height: 36 }}
              >
                <SvgDelete />
              </Button>
            )}
          </CenterFlexRow>
        </CardHeader>
        {children}
      </CardContent>
    </SortCard>
  );
};

const HelpIcon = styled(SvgHelp)`
  display: block;
  margin-left: 8px;
  width: 15px;
  height: 15px;
`;

const CardHeader = styled(CenterFlexRow)`
  width: 100%;
  color: #666;
  justify-content: space-between;
  margin-bottom: 16px;
  button {
    padding: 6px 12px;
  }
  button + button {
    padding: 6px;
    margin-left: 4px;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 4px;
    ${Body2} {
      font-size: 10px;
      line-height: 16px;
    }
    button {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

const SortCard = styled(Card)`
  width: 100%;
  background-color: #ffffff;
  border-color: #dcdfe0;
  margin-top: 16px;
  display: flex;
  align-items: stretch;
  position: relative;
  padding: 0;
  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 12px;
  }
`;

const CardContent = styled(FlexColumn)`
  flex-grow: 1;
  padding: 16px 24px 32px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 8px 16px 24px;
  }
`;

const Handle = styled.div`
  background-color: #f2f5f7;
  width: 32px;
  flex-grow: 0;
  display: flex;
  align-items: flex-start;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  svg {
    color: #a9b8c2;
    width: 34px;
    height: 34px;
    margin-left: -1px;
    flex-shrink: 0;
    margin-top: 16px;
  }
`;

const SelectedBorder = styled.div`
  display: flex;
  align-items: flex-start;
  border-radius: 12px;
  position: absolute;
  top: -1px;
  left: -1px;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  border: 2px solid #1b0076;
  pointer-events: none;
`;

const Pill = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  border-radius: 11px;
  background: linear-gradient(88deg, #1b0076 17.93%, #e71e3d 118.3%);
  padding: 0 16px;
  position: absolute;
  top: -12px;
  left: 54px;
  ${Body2} {
    font-size: 11px;
    font-weight: 600;
    line-height: 16px;
    color: #ffffff;
    text-transform: uppercase;
  }
`;
