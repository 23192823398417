import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as FreeReponse from "./FreeResponse";
import { Filter } from "../../SetFan/SurveyDetail/Filter";
import { Body1 } from "melodies-source/Text";
import { TypesenseSubmission } from "models/survey";
import { TextInput } from "melodies-source/TextInput";
import Pagination from "Components/Pagination";
import { ReactComponent as SearchIcon } from "assets/svg/search.svg";
import { Spinner } from "melodies-source/Spinner";
import { ModalProps } from "../ChartCard";
import { useDebounce } from "hooks/useDebounce";
import { resetPage } from "Utils";
import { useSurveyQuestionsContext } from "../../SetFan/SurveyDetail";
import { ReactComponent as FileIcon } from "assets/svg/file.svg";
import { Checkbox } from "melodies-source/Selectable";
import { Modal } from "melodies-source/Modal";
import { useParams } from "react-router-dom";
import {
  useTypesenseProxyContext,
  ProxySearchParams,
} from "contexts/TypesenseProxyContext";

interface Props extends ModalProps {
  title: string;
  questionId: string;
}

const RESULTS_PER_PAGE = 10;

export const FreeResponsesModal: React.FC<Props> = ({
  title,
  questionId,
  open,
  onClose,
}) => {
  const [selectedQuestion, setSelectedQuestion] = useState("all");
  const [selectedOption, setSelectedOption] = useState("all");
  const [otherResponsesIsSelected, setOtherResponsesIsSelected] =
    useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const debouncedSearchInput = useDebounce(searchInput, undefined, () =>
    setCurrentPage(1),
  );
  const { surveyId, artistId } = useParams() as {
    surveyId: string;
    artistId: string;
  };
  const [totalSubmissions, setTotalSubmissions] = useState(0);
  const [submissions, setSubmissions] = useState<TypesenseSubmission[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { questions: surveyQuestions } = useSurveyQuestionsContext();

  const { proxySearch } = useTypesenseProxyContext();

  const getOtherResponses = (responses: FreeReponse.OtherResponse[]) => {
    const map = new Map<string, FreeReponse.OtherResponse>();
    responses.forEach((response) => {
      const { question, option } = response;
      map.set(question, {
        question,
        option: map.has(question)
          ? `${map.get(question).option}, ${option}`
          : option,
      });
    });
    return Array.from(map.values());
  };

  const handleSelectedOptionChange = (value: string) =>
    resetPage(setCurrentPage, setSelectedOption, value);

  const responses: FreeReponse.Props["response"][] =
    submissions?.length && surveyQuestions
      ? submissions.map((document) => {
          const {
            id,
            firstName,
            age,
            gender,
            region,
            city,
            state,
            free_responses,
            responses,
          } = document;
          const responseSplit = free_responses
            .find((response) => response.includes(questionId))
            .split(" ");
          responseSplit.shift();

          const text = responseSplit.join(" ");
          return {
            id,
            text,
            respondent: {
              name: firstName,
              age,
              gender,
              location: region,
              city: city ?? "",
              state: state ?? "",
            },
            otherReponses: getOtherResponses(
              responses
                .filter((response) => {
                  const [questionId] = response.split("#");
                  return (
                    questionId.includes("-") ||
                    // temporary hack to handle static ids in survey templates because they won't have '-'
                    questionId.startsWith("question_")
                  );
                })
                .map((response) => {
                  const [questionId, optionId] = response.split("#");
                  const question = surveyQuestions.find(
                    (elem) => elem.id === questionId,
                  );
                  if (question && question.type !== "TextQuestion") {
                    return {
                      question: question.header,
                      option: question.options[optionId].label,
                    };
                  } else {
                    return null;
                  }
                })
                .filter((elem) => !!elem),
            ),
          };
        })
      : [];

  useEffect(() => {
    if (!proxySearch) return;
    const filters = [`free_responses:${questionId}`];
    if (selectedOption !== "all") {
      if (selectedQuestion === "age") {
        const [age1, age2] = selectedOption.split("-");
        const age1IsNumber = !isNaN(parseInt(age1));
        const age2IsNumber = !isNaN(parseInt(age2));
        const query = [];
        if (age1IsNumber) {
          query.push(`age:>=${age1}`);
        }
        if (age2IsNumber) {
          query.push(`age:${age1IsNumber ? "<=" : ">="}${age2}`);
        }
        filters.push(query.join(" && "));
      } else if (selectedQuestion === "gender") {
        filters.push(`gender:${selectedOption}`);
      } else {
        filters.push(`responses:${selectedQuestion}#${selectedOption}`);
      }
    }

    // the typesense proxy server we use verifies that the calling user has permissions
    // to view the submissions searched. However, an admin has access to all typesense documents
    // so we use a front end filter to only return those for this survey and artist group, as well
    // as ensuring members of the artist group only see responses for this survey.
    filters.push(`surveyId:${surveyId}`);
    filters.push(`artistGroupId:${artistId}`);

    const searchParams: ProxySearchParams = {
      q: debouncedSearchInput,
      query_by: "free_responses",
      filter_by: filters.join(" && "),
      page: currentPage,
      per_page: RESULTS_PER_PAGE,
    };
    setLoading(true);
    proxySearch("submissions", searchParams)
      .then((res) => {
        setSubmissions(res?.hits?.map((hit) => hit.document) || []);
        setTotalSubmissions(res?.found || 0);
      })
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [proxySearch, currentPage, selectedOption, debouncedSearchInput]);

  return (
    <Modal isOpen={open} header={title} onClose={onClose} variant="large">
      <Container>
        <FilterContainer>
          <Filter
            key="modal"
            surveyQuestions={surveyQuestions}
            selectedQuestion={selectedQuestion}
            setSelectedQuestion={setSelectedQuestion}
            selectedOption={selectedOption}
            setSelectedOption={handleSelectedOptionChange}
            Heading={Body1}
          />
          <SearchInput
            value={searchInput}
            onChange={setSearchInput}
            placeholder="Search for a key word..."
            leftIcon={<SearchIcon />}
          />
        </FilterContainer>
        <Checkbox
          label="Show Responses To Other Questions"
          value={otherResponsesIsSelected}
          onChange={setOtherResponsesIsSelected}
        />
        <FreeResponsesContainer>
          {loading ? (
            <Spinner />
          ) : responses.length === 0 ? (
            selectedOption === "all" && debouncedSearchInput === "" ? (
              <NoData>
                <FileIcon />
                <p>This question has no reponses</p>
              </NoData>
            ) : (
              <NoData>
                <SearchIcon />
                <p>No responses match your criteria</p>
              </NoData>
            )
          ) : (
            responses.map((response) => (
              <FreeReponse.Component
                key={response.id}
                response={response}
                showOtherResponses={otherResponsesIsSelected}
              />
            ))
          )}
        </FreeResponsesContainer>
        {totalSubmissions > 0 && (
          <PaginationContainer>
            <Pagination
              selectedPage={currentPage}
              resultsPerPage={RESULTS_PER_PAGE}
              totalResults={totalSubmissions}
              onSetPage={setCurrentPage}
              scrollOnChange
            />
          </PaginationContainer>
        )}
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 39px;
`;

const SearchInput = styled(TextInput)`
  max-width: 220px;

  input {
    padding-top: 11px;
    padding-bottom: 11px;
  }

  ${({ theme }) => theme.media.mobile} {
    max-width: 100%;
  }
`;

const FreeResponsesContainer = styled.div`
  overflow: auto;
`;

const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px 0;
  gap: 10px;
  color: var(--text-color);

  svg {
    height: 80px;
    width: 80px;
  }
`;
