import React, { useEffect, useMemo, useState } from "react";
import { DefaultLayout, PageHeaderImage } from "Components";
import { GlobalStyles } from "styled";
import styled, { css } from "styled-components";
import { H3, H4 } from "melodies-source/Text";
import { TextInput } from "melodies-source/TextInput";
import { ReactComponent as SearchIcon } from "assets/svg/search.svg";
import { Card } from "Components/Card";
import { Accordion } from "Components/Accordion";
import { ReactComponent as HelpIcon } from "assets/svg/help.svg";
import { ReactComponent as PaintIcon } from "assets/svg/paint.svg";
import { ReactComponent as MicrophoneIcon } from "assets/svg/microphone.svg";
import { ReactComponent as QRCodeIcon } from "assets/svg/qr-code.svg";
import { ReactComponent as DoorIcon } from "assets/svg/door.svg";
import { ReactComponent as PieChartIcon } from "assets/svg/pie-chart.svg";
import { ReactComponent as CheckListIcon } from "assets/svg/checklist.svg";
import { ReactComponent as ClipboardIcon } from "assets/svg/clipboard-done.svg";
import { ReactComponent as UserProfileIcon } from "assets/svg/user-profile.svg";
import { ReactComponent as UsersIcon } from "assets/svg/users-square.svg";
import { ReactComponent as SettingsIcon } from "assets/svg/settings.svg";
import { ReactComponent as ToolsIcon } from "assets/svg/tools.svg";
import { QuestionContent as QuestionContentComponent } from "./QuestionContent";
import { ReactComponent as PlusIcon } from "assets/svg/plus.svg";
import { ReactComponent as MinusIcon } from "assets/svg/minus.svg";
import { ReactComponent as VideoIcon } from "assets/svg/video.svg";
import { ReactComponent as DocumentIcon } from "assets/svg/document-detail.svg";
import { ReactComponent as LinkIcon } from "assets/svg/link.svg";
import { useDebounceState } from "hooks/useDebounce";
import { BackLink } from "Components/BackLink";
import { ReactComponent as EmptySearchIcon } from "assets/svg/empty-search.svg";
import { useSearchParams } from "hooks";
import { useHistory } from "react-router-dom";
import { useDidUpdate } from "hooks/useDidUpdate";
import { ReactComponent as ClearIcon } from "assets/svg/clear.svg";
import { Select } from "Components/Select";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import Fuse from "fuse.js";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Query, collection, getFirestore, query } from "firebase/firestore";
import { Spinner } from "melodies-source/Spinner";
import { Markdown } from "Components/Markdown";
import { useUser } from "auth";
import { Tooltip } from "melodies-source/Tooltip";
import { addToast } from "melodies-source/Toast";

export interface Video {
  url: string;
  thumbnail?: string;
}

interface Document {
  title: string;
  url: string;
}

export interface QuestionContent {
  answer: string;
  video?: Video;
  documents?: Document[];
}

export interface Question {
  id: string;
  title: string;
  content: QuestionContent;
  topic: string;
}

export interface Topic {
  id: string;
  icon: JSX.Element;
  title: string;
}

const TOPICS: Topic[] = [
  {
    id: "set-basics",
    icon: <HelpIcon />,
    title: "SET Basics",
  },
  {
    id: "set-strategies",
    icon: <MicrophoneIcon />,
    title: "Effective SET.Live Strategies",
  },
  {
    id: "set-customization",
    icon: <PaintIcon />,
    title: "SET.Live: Creation to Customization",
  },
  {
    id: "qr-code",
    icon: <QRCodeIcon />,
    title: "QR Code",
  },
  {
    id: "backstage-mgmt",
    icon: <DoorIcon />,
    title: "Backstage: Live Show Management",
  },
  {
    id: "set-reporting",
    icon: <PieChartIcon />,
    title: "SET.Live Reporting",
  },
  {
    id: "using-set-fan",
    icon: <CheckListIcon />,
    title: "Using SET.Fan",
  },
  {
    id: "fan-reporting",
    icon: <ClipboardIcon />,
    title: "SET.Fan Reporting",
  },
  {
    id: "contact-list",
    icon: <UsersIcon />,
    title: "Contact List",
  },
  {
    id: "fan-profile",
    icon: <UserProfileIcon />,
    title: "Fan Profile",
  },
  {
    id: "settings",
    icon: <SettingsIcon />,
    title: "Settings and Integrations",
  },
  {
    id: "additional-tools",
    icon: <ToolsIcon />,
    title: "Additional Tools",
  },
];

const FUSE_OPTIONS: Fuse.IFuseOptions<Question> = {
  isCaseSensitive: false,
  includeScore: true,
  includeMatches: true,
  ignoreLocation: true,
  shouldSort: true,
  threshold: 0.3,
  keys: ["title", "content.answer"],
};

export const HelpCenter: React.FC = () => {
  const { searchParams } = useSearchParams();
  const topicParam = searchParams.get("topic");
  const termParam = searchParams.get("term");
  const questionParam = searchParams.get("question");
  const [questions, questionsLoading] = useCollectionData<Question>(
    query(collection(getFirestore(), "help_center_faqs")) as Query<Question>,
  );
  const [searchText, setSearchText] = useState(termParam ?? "");
  const [selectedTopic, setSelectedTopic] = useState(
    topicParam ?? (!termParam ? TOPICS[0].id : null),
  );
  const [debouncedSearchText, setDebouncedSearchText] =
    useDebounceState<string>(searchText);
  const history = useHistory();
  const [openedQuestions, setOpenedQuestions] = useState(
    termParam || !questionParam ? [] : questionParam.split(","),
  );
  const isMobile = useMobileMediaQuery();
  const { claims } = useUser();

  let filteredQuestions: Question[] = questions
    ? questions.filter((question) =>
        selectedTopic ? question.topic === selectedTopic : true,
      )
    : [];

  const fuse = useMemo(
    () =>
      new Fuse<Question>(
        questions,
        FUSE_OPTIONS,
        Fuse.createIndex(FUSE_OPTIONS.keys, questions),
      ),
    [questions],
  );

  if (!questionsLoading && debouncedSearchText !== "") {
    filteredQuestions = fuse
      .search(debouncedSearchText)
      .map(({ item }) => item);
  }

  const resetParams = () => {
    setSearchText("");
    setDebouncedSearchText("");
    setOpenedQuestions([questions[0].id]);
    setSelectedTopic(TOPICS[0].id);
  };

  const handleCopyLink = (question: Question) => {
    navigator.clipboard.writeText(
      `${window.location.origin}/help-center?topic=${question.topic}&question=${question.id}`,
    );
    addToast("Link copied to clipboard", "success");
  };

  const questionSections = filteredQuestions.map((question) => {
    const { id, title, content } = question;

    const formattedTitle =
      debouncedSearchText === ""
        ? title
        : title.replaceAll(
            new RegExp(debouncedSearchText, "gi"),
            (match) => `:match[${match}]`,
          );

    const formattedContent = {
      ...content,
      answer:
        debouncedSearchText === ""
          ? content.answer
          : content.answer.replaceAll(
              new RegExp(debouncedSearchText, "gi"),
              (match) => `:match[${match}]`,
            ),
    };
    return {
      header: (
        <TopicHeaderContainer>
          <H4>
            <Markdown children={formattedTitle} />
          </H4>
          {claims.admin && (
            <StyledTooltip
              parent={
                <StyledLinkIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    handleCopyLink(question);
                  }}
                />
              }
            >
              Copy link
            </StyledTooltip>
          )}
          {(content.video || content.documents) &&
            !isMobile &&
            !openedQuestions.includes(id) && (
              <TopicHeaderIcons>
                {content.video && <VideoIcon />}
                {content.documents && <DocumentIcon />}
              </TopicHeaderIcons>
            )}
        </TopicHeaderContainer>
      ),
      body: <QuestionContentComponent content={formattedContent} />,
      open: debouncedSearchText === "" ? openedQuestions.includes(id) : true,
      onOpenChange: (open: boolean) =>
        setOpenedQuestions((questions) =>
          open
            ? questions.filter((question) => question !== id)
            : [...questions, id],
        ),
    };
  });

  const topics = TOPICS.map((topic) => (
    <Topic
      key={topic.id}
      selected={topic.id === selectedTopic}
      onClick={() => setSelectedTopic(topic.id)}
    >
      {topic.icon}
      <span>{topic.title}</span>
    </Topic>
  ));

  const topicOptions = TOPICS.map((topic) => ({
    label: topic.title,
    value: topic.id,
  }));

  useEffect(() => {
    if (termParam && termParam !== "") {
      setSelectedTopic(null);
    }
  }, [termParam]);

  useEffect(() => {
    const params: string[] = [];

    if (debouncedSearchText !== "") {
      params.push(`term=${debouncedSearchText}`);
    } else {
      if (selectedTopic) {
        params.push(`topic=${selectedTopic}`);
      }
    }
    if (openedQuestions.length > 0 && debouncedSearchText === "") {
      params.push(`question=${openedQuestions.join(",")}`);
    }

    history.push(
      `${window.location.pathname}${
        params.length > 0 ? `?${params.join("&")}` : ""
      }`,
    );
  }, [selectedTopic, debouncedSearchText, openedQuestions.length]);

  useEffect(() => {
    if (!questionsLoading && debouncedSearchText === "") {
      if (openedQuestions) {
        if (
          !filteredQuestions.find((question) =>
            openedQuestions.includes(question.id),
          )
        ) {
          if (filteredQuestions.length > 0) {
            setOpenedQuestions([filteredQuestions[0].id]);
          }
        }
      } else {
        if (filteredQuestions.length > 0) {
          setOpenedQuestions([filteredQuestions[0].id]);
        }
      }
    }
  }, [questionsLoading, selectedTopic]);

  useDidUpdate(() => {
    if (debouncedSearchText === "") {
      resetParams();
    }
  }, [debouncedSearchText]);

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      <PageHeaderImage isExtended />
      <Container>
        <HeaderContainer>
          <H3>How can we help you?</H3>
          <SearchInput
            value={searchText}
            onChange={setSearchText}
            placeholder="Search help topics"
            leftIcon={<SearchIcon />}
            rightIcon={
              searchText !== "" && (
                <StyledClearIcon onClick={() => resetParams()} />
              )
            }
          />
        </HeaderContainer>
        <Card>
          <CardContainer>
            {debouncedSearchText !== "" && (
              <SearchHeader>
                <StyledBackLink
                  path="/help-center"
                  onClick={() => resetParams()}
                >
                  Back to Topics
                </StyledBackLink>
                <H3>
                  {filteredQuestions.length}{" "}
                  {filteredQuestions.length === 1 ? "result" : "results"} for "
                  {debouncedSearchText}"
                </H3>
              </SearchHeader>
            )}
            {!questionsLoading && filteredQuestions.length === 0 ? (
              <NoResults>
                <EmptySearchIcon />
                <p>Please try a different search term</p>
              </NoResults>
            ) : (
              <CardContent>
                {isMobile ? (
                  debouncedSearchText === "" && (
                    <StyledSelect
                      label="Choose a help topic:"
                      options={topicOptions}
                      value={selectedTopic}
                      onChange={setSelectedTopic}
                    />
                  )
                ) : (
                  <TopicsContainer hidden={debouncedSearchText !== ""}>
                    {topics}
                  </TopicsContainer>
                )}

                <QuestionsContainer>
                  {questionsLoading ? (
                    <Spinner />
                  ) : (
                    <StyledAccordion
                      open={false}
                      sections={questionSections}
                      icons={{
                        open: <StyledMinusIcon />,
                        closed: <StyledPlusIcon />,
                      }}
                      openBackground="#F7FAFC"
                      showDivider
                    />
                  )}
                </QuestionsContainer>
              </CardContent>
            )}
          </CardContainer>
        </Card>
      </Container>
    </DefaultLayout>
  );
};

const Container = styled.div`
  position: relative;
  padding: 32px 40px;
  font-family: "Poppins";
  line-height: 23px;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${H3} {
    color: white;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    margin-top: 25px;
  }
`;

const SearchInput = styled(TextInput)`
  max-width: 480px;
  margin: 20px 0;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2%;
`;

interface TopicsContainerProps {
  hidden: boolean;
}

const TopicsContainer = styled.div<TopicsContainerProps>`
  display: flex;
  flex-direction: column;
  transition: width 0.7s ease-in-out;
  box-sizing: border-box;

  ${({ hidden }) =>
    hidden
      ? css`
          width: 0;
          margin-right: 0;

          ${Topic} {
            padding: 15px 0;
            white-space: nowrap;
          }
        `
      : css`
          width: 250px;
          margin-right: 2%;
        `}
`;

interface TopicProps {
  selected: boolean;
}

const Topic = styled.div<TopicProps>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  padding: 15px;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }

  ${({ selected }) =>
    selected &&
    css`
      border-radius: 15px;
      background: #f2f5f7;
    `}

  svg {
    color: ${({ selected }) => (selected ? "#1B0076" : "#999999")};
    flex-shrink: 0;
    margin-top: 1px;
  }

  span {
    font-weight: 600;
    font-size: 16px;
    color: ${({ selected }) => (selected ? "#1B0076" : "#333333")};
  }
`;

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StyledAccordion = styled(Accordion)`
  flex-direction: column;
  flex-wrap: nowrap;
`;

const TopicHeaderContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TopicHeaderIcons = styled.div`
  display: flex;
  gap: 5px;
  margin: 0 10px;

  svg {
    color: #6a6a6a;
    background: rgba(237, 237, 237, 0.64);
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 50%;
  }
`;

const StyledPlusIcon = styled(PlusIcon)`
  color: #1b0076;
`;

const StyledMinusIcon = styled(MinusIcon)`
  color: #1b0076;
`;

const SearchHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    align-items: flex-start;

    ${H3} {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
  }
`;

const StyledBackLink = styled(BackLink)`
  position: absolute;
  left: 0;

  ${({ theme }) => theme.media.mobile} {
    position: relative;
  }
`;

const CardContent = styled.div`
  display: flex;

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

const NoResults = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #7d7d7d;
  margin: 40px 0;

  svg {
    width: 70px;
    height: 70px;
    margin-bottom: 20px;
  }
`;

const StyledClearIcon = styled(ClearIcon)`
  cursor: pointer;
`;

const StyledSelect = styled(Select)`
  margin: 10px 0 25px;
`;

const StyledLinkIcon = styled(LinkIcon)`
  margin: 0 5px;
  color: #1b0076;
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  display: flex;
  align-items: center;
`;
