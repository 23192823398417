import * as React from "react";
import { SVGProps } from "react";
export const SvgDetails = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16,3 C16.5522847,3 17,3.44771525 17,4 L17,16 C17,16.5522847 16.5522847,17 16,17 L4,17 C3.44771525,17 3,16.5522847 3,16 L3,4 C3,3.44771525 3.44771525,3 4,3 L16,3 Z M16,4 L4,4 L4,16 L16,16 L16,4 Z M11,12 L11,13 L5,13 L5,12 L11,12 Z M15,10 L15,11 L5,11 L5,10 L15,10 Z M15,8 L15,9 L5,9 L5,8 L15,8 Z M15,6 L15,7 L5,7 L5,6 L15,6 Z"
      fill="currentColor"
      stroke="none"
      strokeWidth={1}
      fillRule="evenodd"
    />
  </svg>
);
