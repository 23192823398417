import { useConfigSlice } from "Routes/SetFan/Components/BuilderContext";
import { EditCategoriesDisplayed } from "Routes/SetFan/Components/Modal/EditCategoriesDisplayed";
import { EditCategoriesIntroText } from "Routes/SetFan/Components/Modal/EditCategoriesIntroText";
import { CATEGORIES_OPTIONS } from "Routes/SetFan/constants/profileCategories";
import {
  CATEGORIES_OPTIONS_IDS,
  PhoneNumberValidation,
} from "@max/common/dist/setfan";
import { CenterFlexRow, FlexColumn } from "Routes/styled";
import { Button } from "melodies-source/Button";
import { Card } from "melodies-source/Card";
import { Select } from "melodies-source/Select";
import { SvgEdit } from "melodies-source/Svgs/Edit";
import { SvgGear } from "melodies-source/Svgs/Gear";
import { Body1, Caption, H3 } from "melodies-source/Text";
import { useState } from "react";
import styled, { css } from "styled-components";
import { Divider as DividerBase, Label, useArtistContext } from "Components";
import { H4 } from "melodies-source/Text";
import { Switch } from "Components/Switch";
import { Textarea } from "melodies-source/Textarea";

const NUMBER_OF_CATEGORIES_OPTIONS = [
  ...Array(CATEGORIES_OPTIONS_IDS.length).keys(),
].map((v) => ({
  label: (v + 1).toString(),
  value: (v + 1).toString(),
}));

const ID_KEY_PAIRS = CATEGORIES_OPTIONS_IDS.map(
  (id) => [id, `${id}IntroText`] as const,
);

const PHONE_NUMBER_OPTIONS: { label: string; value: PhoneNumberValidation }[] =
  [
    { label: "Phone Number Required (default)", value: "required" },
    { label: "Phone Number Optional", value: "optional" },
    { label: "No Phone Number", value: "no-phone" },
  ];

export const ProfilingQuestions = () => {
  const { isGlobalAdmin } = useArtistContext();
  const [editCategoriesOpen, setEditCategoriesOpen] = useState(false);
  const [editIntroTextOpen, setEditIntroTextOpen] = useState(false);

  const { fields, setField, validation, setFieldTouched } = useConfigSlice([
    "useCustomTermsAndPrivacyPolicy",
    "useCustomSmsOptIn",
    "customTermsAndPrivacyPolicy",
    "termsAndPrivacyPolicySeparateLineItems",
    "customSmsOptIn",
    "numberOfCategories",
    "displayedCategories",
    "phoneNumberValidation",
    ...ID_KEY_PAIRS.map(([, introId]) => introId),
  ]);

  return (
    <Container>
      <Body1>
        These groups of questions (categories) reveal demographic data and
        consumer preferences.
      </Body1>
      <StyledCard>
        <H3>How many categories should each fan answer?</H3>
        <Select
          onChange={(v) => setField("numberOfCategories", v)}
          value={fields.numberOfCategories.content}
          options={NUMBER_OF_CATEGORIES_OPTIONS}
          placeholder="Select"
          style={{ width: "112px", flexShrink: 0 }}
        />
      </StyledCard>
      <Body1>Categories are intelligently chosen from this list:</Body1>
      <Ul>
        <Li>Fan Contact Info</Li>
        {CATEGORIES_OPTIONS.map((option) => (
          <Li
            key={option.id}
            disabled={!fields.displayedCategories.content.includes(option.id)}
          >
            {option.header}
          </Li>
        ))}
      </Ul>
      <FlexColumn style={{ alignItems: "flex-start" }}>
        <InlineButton
          text
          leftIcon={<SvgEdit />}
          onClick={() => setEditCategoriesOpen(true)}
        >
          Edit
        </InlineButton>
        <InlineButton
          text
          leftIcon={<SvgGear />}
          onClick={() => setEditIntroTextOpen(true)}
        >
          Customize Category Intro Text
        </InlineButton>
      </FlexColumn>
      <Caption>
        All participants will be asked to give their contact information
        (including email) unless they have previously given one. Our system will
        intelligently survey your fans progressively depending on what they have
        responded to in past surveys.
      </Caption>
      <EditCategoriesDisplayed
        initialValues={fields.displayedCategories.content}
        numberOfCategories={Number(fields.numberOfCategories.content)}
        isOpen={editCategoriesOpen}
        onClose={() => setEditCategoriesOpen(false)}
        onSave={(value) => {
          setField(
            "displayedCategories",
            CATEGORIES_OPTIONS_IDS.filter((c) => value.includes(c)),
          );
          setEditCategoriesOpen(false);
        }}
      />
      <EditCategoriesIntroText
        initialValues={ID_KEY_PAIRS.reduce(
          (p, [id, introId]) => ({
            ...p,
            [id]: fields[introId].content.en,
          }),
          {},
        )}
        displayedCategories={fields.displayedCategories.content}
        isOpen={editIntroTextOpen}
        onClose={() => setEditIntroTextOpen(false)}
        onSave={(values) => {
          ID_KEY_PAIRS.forEach(([id, introId]) => {
            setField(introId, { en: values[id] ?? "" });
          });
          setEditIntroTextOpen(false);
        }}
      />
      <Divider />
      <PhoneNumberContainer>
        <Body1>
          You can decide how new contacts provide their phone number:
        </Body1>
        <Select
          label="Phone Number"
          placeholder="Select an option..."
          options={PHONE_NUMBER_OPTIONS}
          onChange={(v: PhoneNumberValidation) =>
            setField("phoneNumberValidation", v)
          }
          value={fields.phoneNumberValidation.content}
        />
      </PhoneNumberContainer>
      {isGlobalAdmin && (
        <>
          <Divider />
          <H4 style={{ marginBottom: 20 }}>Custom Opt-Ins</H4>
          <CenterFlexRow style={{ marginBottom: 15 }}>
            <Switch
              value={fields.useCustomTermsAndPrivacyPolicy.content}
              onChange={(value) =>
                setField("useCustomTermsAndPrivacyPolicy", value)
              }
            />
            <Label style={{ marginLeft: 5 }}>
              Use Custom Terms & Privacy Policy
            </Label>
          </CenterFlexRow>
          {fields.useCustomTermsAndPrivacyPolicy.content && (
            <>
              <SurveyBodyTextArea
                label="Custom Terms & Privacy Policy"
                placeholder="Enter Custom Terms & Privacy Policy"
                hasError={
                  fields.customTermsAndPrivacyPolicy.isTouched &&
                  !!validation.customTermsAndPrivacyPolicy
                }
                helperText={
                  (fields.customTermsAndPrivacyPolicy.isTouched &&
                    validation.customTermsAndPrivacyPolicy) ||
                  ""
                }
                value={fields.customTermsAndPrivacyPolicy.content.en}
                onChange={(text) =>
                  setField("customTermsAndPrivacyPolicy", { en: text })
                }
                onBlur={() => setFieldTouched("customTermsAndPrivacyPolicy")}
                style={{ marginBottom: 15 }}
              />
              <SurveyBodyTextArea
                label="Separate Line Terms & Privacy Policy (optional)"
                placeholder="Enter Optional Separate Line"
                hasError={
                  fields.termsAndPrivacyPolicySeparateLineItems.isTouched &&
                  !!validation.termsAndPrivacyPolicySeparateLineItems
                }
                helperText={
                  (fields.termsAndPrivacyPolicySeparateLineItems.isTouched &&
                    validation.termsAndPrivacyPolicySeparateLineItems) ||
                  ""
                }
                value={fields.termsAndPrivacyPolicySeparateLineItems.content.en}
                onChange={(text) =>
                  setField("termsAndPrivacyPolicySeparateLineItems", {
                    en: text,
                  })
                }
                onBlur={() =>
                  setFieldTouched("termsAndPrivacyPolicySeparateLineItems")
                }
              />
            </>
          )}
          <CenterFlexRow style={{ marginTop: 15, marginBottom: 15 }}>
            <Switch
              value={fields.useCustomSmsOptIn.content}
              onChange={(value) => setField("useCustomSmsOptIn", value)}
            />
            <Label style={{ marginLeft: 5 }}>Use Custom SMS Opt In</Label>
          </CenterFlexRow>
          {fields.useCustomSmsOptIn.content && (
            <SurveyBodyTextArea
              label="Custom SMS Opt In"
              placeholder="Enter Custom SMS Opt In"
              hasError={
                fields.customSmsOptIn.isTouched && !!validation.customSmsOptIn
              }
              helperText={
                (fields.customSmsOptIn.isTouched &&
                  validation.customSmsOptIn) ||
                ""
              }
              value={fields.customSmsOptIn.content.en}
              onChange={(text) => setField("customSmsOptIn", { en: text })}
              onBlur={() => setFieldTouched("customSmsOptIn")}
            />
          )}
        </>
      )}
    </Container>
  );
};

const Container = styled(FlexColumn)`
  ${Caption} {
    margin-top: 44px;
    color: #333;
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding-bottom: 12px;
    ${H3} {
      font-size: 16px;
      line-height: 24px;
    }
    ${Body1} {
      font-size: 12px;
      line-height: 18px;
    }
    ${Caption} {
      font-size: 10px;
      line-height: 16px;
      margin-top: 20px;
    }
  }
`;

const InlineButton = styled(Button)`
  padding: 6px 0;
  height: auto;
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Li = styled(Body1).attrs({ as: "li" })<{ disabled?: boolean }>`
  font-weight: 500;
  ${(props) =>
    props.disabled &&
    css`
      color: #999999;
      text-decoration: line-through;
    `};
`;

const PhoneNumberContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
    gap: 12px;
  }
`;

const StyledCard = styled(Card)`
  display: flex;
  align-items: center;
  column-gap: 44px;
  border-color: #dcdfe0;
  margin: 16px 0 28px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    align-items: stretch;
    row-gap: 12px;
    padding: 16px 20px 20px;
  }
`;

const SurveyBodyTextArea = styled(Textarea)`
  textarea {
    height: 137px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: var(--max-font-primary);
    color: var(--text-color);

    ::placeholder {
      color: #999999;
      font-family: var(--max-font-primary);
      font-weight: 400;
      font-size: 12px;
      line-height: normal;
      user-select: none;
    }
  }
`;

const Ul = styled.ul`
  padding: 0 0 0 20px;
  color: #333333;
  list-style: disc;
  margin: 12px 0;
`;

const Divider = styled(DividerBase)`
  margin: 20px 0;
  ${({ theme }) => theme.mediaQueries.desktop} {
    margin: 32px 0;
  }
`;
