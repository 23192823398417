import styled from "styled-components";
import { SetBioAppNoData } from "./AppCardNoData";
import { SetBioKnockout } from "assets/logos";
import { useHistory, useParams } from "react-router-dom";
import { Body1, H2, H3, Subtitle1, Subtitle2 } from "melodies-source/Text";
import { AppCard, ListItemButtonAvatar } from "./AppCard";
import { FlexColumn, FlexRow } from "Components/Flex";
import {
  IconClipboardCheck,
  IconCloudDownload,
  IconGlobe,
  IconPointer,
} from "assets/svg";
import { useSetBioAnalytics } from "hooks";
import { DateRange } from "Routes/Reporting/SetBio";
import { DateTime } from "luxon";
import { MixedChart } from "Components";
import { NoData } from "Routes/Reporting/SetBio/Components";

interface SetBioAppCardProps extends React.HTMLAttributes<HTMLDivElement> {
  active?: boolean;
}

const dateRange: DateRange = { start: DateTime.now(), duration: { days: 6 } };

export const SetBioAppCard = ({ active }: SetBioAppCardProps) => {
  const { artistId } = useParams<{ artistId: string }>();
  const history = useHistory();

  const {
    totalVisits,
    totalClicks,
    signups,
    topSources,
    topLinks,
    metricsByDay,
    hasAnalytics,
  } = useSetBioAnalytics({
    artistId,
    dateRange,
  });

  if (!active) {
    return <SetBioAppNoData />;
  }

  const filteredTopLinks = topLinks.filter(({ clicks }) => clicks > 0);

  return (
    <StyledAppCard
      logo={<SetBioKnockout />}
      primaryButtonText="Edit Page"
      secondaryButtonText="View Report"
      onPrimaryClick={() => history.push("set-bio/editor")}
      onSecondaryClick={
        !hasAnalytics ? undefined : () => history.push("reporting/set-bio")
      }
    >
      {!hasAnalytics ? (
        // Replace <NoData /> with <NoActivity /> once help articles are completed. @bamarrs
        // <NoActivity />
        <NoData style={{ gridColumn: "1 / span 2", color: "#dcdfe0" }} />
      ) : (
        <>
          <ContainerHeavy>
            <FlexColumn xCenter>
              <H2>Visits per Day</H2>
              <Subtitle2>Past 7 days</Subtitle2>
            </FlexColumn>
            <Content flex="1 1">
              <FlexColumn flex="1 1">
                <MixedChart
                  indexBy="label"
                  data={metricsByDay}
                  defs={[
                    {
                      chartType: "bar",
                      dataKey: "visits",
                      fill: "#ffffff",
                      showLabel: true,
                      formatLabel: (label: any) =>
                        typeof label === "number" && label > 0
                          ? label
                          : undefined,
                    },
                  ]}
                  hideLegend
                  hideYAxis
                  hideTooltip
                  tickFormatter={(tick) =>
                    DateTime.fromJSDate(
                      new Date(`${tick}, ${new Date().getFullYear()}`),
                    ).toFormat("L/d")
                  }
                  tickFont={{
                    fontSize: "10px",
                    fontWeight: 700,
                    color: "#D1ADFF",
                  }}
                  tickMarginXAxis={5}
                  height={160}
                />
              </FlexColumn>
              <FlexRow xSpaceAround>
                <FlexColumn xCenter>
                  <H3>{totalVisits.toLocaleString()}</H3>
                  <Subtitle1>
                    <IconGlobe />
                    Visit{totalVisits !== 1 ? "s" : ""}
                  </Subtitle1>
                </FlexColumn>
                <FlexColumn xCenter>
                  <H3>{totalClicks.toLocaleString()}</H3>
                  <Subtitle1>
                    <IconPointer />
                    Click{totalClicks !== 1 ? "s" : ""}
                  </Subtitle1>
                </FlexColumn>
                <FlexColumn xCenter>
                  <H3>{signups.toLocaleString()}</H3>
                  <Subtitle1>
                    <IconClipboardCheck />
                    Sign-Up{signups !== 1 ? "s" : ""}
                  </Subtitle1>
                </FlexColumn>
              </FlexRow>
            </Content>
          </ContainerHeavy>
          <ContainerLight>
            <FlexColumn xCenter>
              <H2>Recent Activity</H2>
              <Subtitle2>Past 7 days</Subtitle2>
            </FlexColumn>
            {!!filteredTopLinks.length && (
              <Dimension yEnd>
                <Subtitle1>
                  <IconPointer />
                  Top Clicks
                </Subtitle1>
                {filteredTopLinks.map((link) => (
                  <Metric key={`top-link-${link.id}`}>
                    <Body1>{link.label}</Body1>
                    <Body1>
                      {link.clicks} Click{link.clicks !== 1 ? "s" : ""}
                    </Body1>
                  </Metric>
                ))}
              </Dimension>
            )}
            {topSources?.[0] && (
              <Dimension yEnd>
                <Subtitle1>
                  <IconCloudDownload />
                  Top Source
                </Subtitle1>
                <Metric>
                  <Body1>{topSources?.[0].label}</Body1>
                  <Body1>
                    {topSources?.[0].visits} Visit
                    {topSources?.[0].visits !== 1 ? "s" : ""}
                  </Body1>
                </Metric>
              </Dimension>
            )}
          </ContainerLight>
        </>
      )}
    </StyledAppCard>
  );
};

// Removed until help articles are completed.
//
// const NoActivity = () => {
//   const isDesktop = useDesktopMediaQuery();
//   return (
//     <NoActivityContainer>
//       <H2>Amp up your page</H2>
//       <Body1>
//         Check out this helpful resource to make the most of SET.Bio:
//       </Body1>
//       <TipCard
//         image={`${process.env.REACT_APP_ASSETS_PATH}/artist-portal/set-page/visits-metric-example.png`}
//         actionLabel="Learn more"
//         description="Tips to boost traffic to your page"
//         orientation={isDesktop ? "horizontal" : "vertical"}
//         color="#A224AC"
//         imageWidth="104px"
//         justifyImage="center"
//         alignImage="center"
//       />
//     </NoActivityContainer>
//   );
// };

// const NoActivityContainer = styled(FlexColumn)`
//   align-items: center;
//   grid-column: 1 / span 2;
//   height: 100%;
//   place-self: center;

//   ${({ theme }) => theme.media.mobile} {
//     & > ${Body1} {
//       margin-top: 4px;
//       margin-bottom: 32px;
//       text-align: center;
//       max-width: 234px;
//       font-size: 12px;
//       line-height: 18px;
//     }
//     & > div {
//       max-width: 220px;
//       margin-bottom: 32px;
//     }
//   }

//   ${({ theme }) => theme.media.desktop} {
//     & > ${H2} {
//       font-size: 26px;
//       line-height: 36px;
//     }
//     & > ${Body1} {
//       margin-top: 4px;
//       margin-bottom: 24px;
//       text-align: center;
//     }
//     & > div {
//       max-width: 380px;
//     }
//   }
// `;

const Content = styled(FlexColumn)`
  gap: 8px;
`;

const Dimension = styled(FlexColumn)`
  gap: 5px;
`;

const Metric = styled(FlexRow)`
  width: 100%;
  gap: 24px;
  padding-left: 24px;
  & > ${Body1}:first-child {
    flex-grow: 1;
  }
  & > ${Body1} + ${Body1} {
    font-weight: 600;
    flex-shrink: 0;
  }
`;

const StyledAppCard = styled(AppCard)`
  background: linear-gradient(244.03deg, #9747ff 17.41%, #5701b7 93.87%);

  ${({ theme }) => theme.mediaQueries.mobile} {
    background: linear-gradient(265.3deg, #9747ff 0.74%, #5701b7 99.26%);
  }

  ${ListItemButtonAvatar} {
    color: #ffffff;
    background-color: #e71e3d;
  }
`;

const ContainerLight = styled(FlexColumn)`
  background-color: rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  min-height: 280px;
  padding: 20px 24px 32px;
  color: #ffffff;
  gap: 24px;

  ${Subtitle2} {
    color: #d1adff;
    margin-top: 4px;
  }

  ${Subtitle1} {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #d1adff;
    margin-bottom: 1px;

    svg {
      width: 18px;
      height: 18px;
    }
  }
`;

const ContainerHeavy = styled(ContainerLight)`
  background-color: rgba(0, 0, 0, 0.5);
  gap: 32px;
`;
