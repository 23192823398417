import { SurveyTemplate } from "Routes/SetFan/dashboard/Components/Templates/types";
import { ReactComponent as IconMegaphone } from "assets/svg/megaphone.svg";

export const tourReminder: SurveyTemplate<{ artist?: string }> = {
  icon: <IconMegaphone />,
  title: "Tour Announcement Sign Up",
  description:
    "Invite fans to the be among to the first people to hear about new tour dates.",
  templateId: "tourReminder",
  template: ({ artist }) => ({
    fields: {
      description: {
        content: {
          en: "Tour Announcement Signup",
        },
      },
      header: {
        content: {
          en: "Be The First to Hear",
        },
      },
      body: {
        content: {
          en: `If you want to be among the first to find out about upcoming ${artist} tour dates, sign up here, and we'll be sure to let you know when you can get your tickets.`,
        },
      },
      numberOfCategories: {
        content: "1",
      },
      displayedCategories: {
        content: [
          "demographics",
          "fooddrink",
          "alcohol",
          "clothing",
          "vehicle",
          "entertainment",
        ],
      },
      thankYouHeader: {
        content: {
          en: "Thanks!",
        },
      },
      thankYouBody: {
        content: {
          en: "We'll be announcing new tour dates soon!",
        },
      },
    },
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-tour---tou-b54164"
        : "pp-tour---tou-50c563",
  },
};
