import { ReactComponent as IconShirtPick } from "assets/svg/shirt-pick.svg";
import { SurveyTemplate } from "../../types";

export const pickFavDesign: SurveyTemplate = {
  icon: <IconShirtPick />,
  title: "Pick Your Favorite Design",
  description:
    "Ask your fans to choose their favorite merch design, and which items to make.",
  templateId: "pickFavDesign",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Pick Your Favorite Design",
        },
      },
    },
    surveyQuestions: [
      {
        type: "ImageQuestion",
        required: true,
        showLabels: false,
        actionRequired: true,
        id: "question_1",
        label: {
          en: "Which design do you love the most?",
        },
      },
      {
        id: "question_2",
        label: {
          en: "What attracted you to this design, and which merch items would you love to see it on?",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-merch---pi-f16927"
        : "pp-merch---pi-861121",
    fields: [
      {
        name: "merchPlan",
        type: "radio",
        label: "What's your current merch status?",
        options: ["Available now", "Gathering feedback"],
        getValidation: (fields) =>
          fields.merchPlan ? false : "This field cannot be left blank",
      },
    ],
  },
};
