import React, { useEffect, useState } from "react";
import {
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getAuth,
  multiFactor,
} from "firebase/auth";
import { TextInput } from "melodies-source/TextInput";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { Body1, Body2 } from "melodies-source/Text";
import { CountryPhone, CountryPhoneState } from "melodies-source/CountryPhone";
import { Step, useSetupMFAContext } from "..";
import { FirebaseError } from "firebase-admin";
import toast from "react-hot-toast";
import { useCountdown } from "hooks/useCountdown";

export const PhoneMFA: React.FC = () => {
  const [phoneNumber, setPhoneNumber] = useState<CountryPhoneState>();
  const [verificationCode, setVerificationCode] = useState("");
  const [verificationId, setVerificationId] = useState(null);
  const [recaptchaVerifier, setRecaptchaVerifier] =
    useState<RecaptchaVerifier>(null);
  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [enrollUserLoading, setEnrollUserLoading] = useState(false);
  const { resendIn, startCountdown } = useCountdown(60 * 1000);
  const { setStep, onClose, onSuccess } = useSetupMFAContext();

  const setupPhone = async () => {
    try {
      setSendCodeLoading(true);
      const auth = getAuth();
      const multiFactorSession = await multiFactor(
        auth.currentUser,
      ).getSession();

      const phoneInfoOptions = {
        phoneNumber: phoneNumber.value,
        session: multiFactorSession,
      };

      const phoneAuthProvider = new PhoneAuthProvider(auth);

      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        phoneInfoOptions,
        recaptchaVerifier,
      );
      setVerificationId(verificationId);
      startCountdown();
    } catch (err) {
      const error = err as FirebaseError;
      switch (error.code) {
        case "auth/invalid-phone-number": {
          toast.error("Invalid phone number");
          break;
        }
        case "auth/requires-recent-login": {
          toast("Your session is not recent. Log in again");
          onClose();
          break;
        }
        default: {
          console.error(error);
          toast.error("There was an error");
        }
      }
    } finally {
      setTimeout(() => setSendCodeLoading(false), 1000);
    }
  };
  const enrollUser = async () => {
    try {
      setEnrollUserLoading(true);
      const cred = PhoneAuthProvider.credential(
        verificationId,
        verificationCode,
      );
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

      await multiFactor(getAuth().currentUser).enroll(multiFactorAssertion);
      onSuccess();
      toast.success("Phone set up successfully");
    } catch (err) {
      const error = err as FirebaseError;
      switch (error.code) {
        case "auth/requires-recent-login":
        case "auth/user-token-expired": {
          toast("Your session is not recent. Log in again");
          onClose();
          break;
        }
        default: {
          console.error(error);
          toast.error("There was an error");
        }
      }
    } finally {
      setEnrollUserLoading(false);
    }
  };

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(
      getAuth(),
      "recaptcha-container-id",
      { size: "invisible" },
    );
    recaptchaVerifier.render();
    setRecaptchaVerifier(recaptchaVerifier);
  }, []);

  return (
    <>
      <Body1>We'll send a code to you phone number.</Body1>
      <Sections>
        <Section>
          <StyledCountryPhone
            value={phoneNumber?.value}
            onChange={setPhoneNumber}
          />
          <div id="recaptcha-container-id"></div>
          {resendIn ? (
            <Body2>Resend in {resendIn}</Body2>
          ) : (
            <Button onClick={setupPhone} loading={sendCodeLoading}>
              Send code
            </Button>
          )}
        </Section>
        {!sendCodeLoading && verificationId && (
          <Section>
            <TextInput
              placeholder="Code"
              value={verificationCode}
              onChange={setVerificationCode}
            />
            <Button onClick={enrollUser} loading={enrollUserLoading}>
              Enroll
            </Button>
          </Section>
        )}
      </Sections>
      <Button variant="tertiary" onClick={() => setStep(Step.SelectMFA)}>
        Back
      </Button>
    </>
  );
};

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${Body2} {
    text-align: center;
  }
`;

const StyledCountryPhone = styled(CountryPhone)`
  img {
    margin: 0;
  }
`;
