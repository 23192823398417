import { TextInput, useArtistContext } from "Components";
import { REQUIRED_MSG } from "Utils/yupStrictPassword";
import { Field, FieldProps, Form, Formik } from "formik";
import { Button } from "melodies-source/Button";
import { ImageUpload } from "melodies-source/ImageUpload";
import { useMemo } from "react";
import * as yup from "yup";
import { PreviewImage } from "./PreviewImage";
import { ReplaceImageButton } from "Components/ReplaceImageButton";
import styled from "styled-components";
import { FlexRow } from "Components/Flex";

interface Values {
  src: string;
  label: string;
}

const VALIDATION_SCHEMA = yup.object().shape({
  src: yup.string().required(REQUIRED_MSG),
  label: yup.string().required(REQUIRED_MSG),
});

interface ImageQuestionUploaderProps {
  option?: Values;
  onCancel?: () => void;
  onSubmit?: (values: Values) => void;
}

export const ImageQuestionUploader = ({
  option,
  onCancel,
  onSubmit,
}: ImageQuestionUploaderProps) => {
  const { artistGroupDoc } = useArtistContext();

  const initialValues = useMemo(
    () => ({
      src: option?.src ?? "",
      label: option?.label ?? "",
    }),
    [option],
  );

  const handleSubmit = (values: Values) => {
    onSubmit?.(values);
  };

  return (
    <Formik<Values>
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={handleSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form>
          {values.src ? (
            <>
              <PreviewImage src={values.src} />
              <ReplaceImageButton onClick={() => setFieldValue("src", "")} />
            </>
          ) : (
            <div style={{ color: "#333" }}>
              <ImageUpload
                uploadPath={`surveys/${artistGroupDoc.id}/questions`}
                uploadWithFileExt={false}
                withAspectRatio
                onComplete={(url) => setFieldValue("src", url)}
              />
            </div>
          )}
          {values.src && (
            <>
              <div style={{ marginTop: 26, marginBottom: 26 }}>
                <Field name="label">
                  {({ field, meta }: FieldProps) => (
                    <TextInput
                      label="Image Label"
                      hasError={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                      asString={false}
                      {...field}
                    />
                  )}
                </Field>
              </div>
              <ModalActions>
                <Button type="button" variant="tertiary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit">{option ? "Update" : "Add"}</Button>
              </ModalActions>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
};

export const ModalActions = styled(FlexRow)`
  justify-content: flex-end;
  margin-top: 30px;
  gap: 24px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
    gap: 8px;
  }
`;
