import { DateTime } from "luxon";
import styled from "styled-components";
import { FlexColumn, Font18 } from "../../../styled";
import { useDesktopMediaQuery, useTimeDiff } from "hooks";
import { SvgConnected } from "melodies-source/Svgs/Connected";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc, getFirestore } from "firebase/firestore";
import { Aggregation, Aggregations } from "models/survey";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import QuestionShowcase from "./QuestionShowcase";
import { useArtistContext } from "Components";
import { formatNumber } from "Utils/format";
import { H3, Td } from "melodies-source/Text";
import { FlexRow } from "Components/Flex";

export const CurrentSurveyCard = ({
  surveyId,
  title,
  responses,
  lastResponse,
  compact,
}: {
  surveyId: string;
  title: string;
  responses: number;
  lastResponse: DateTime;
  compact?: boolean;
}) => {
  const [showcasedQuestion, setShowcasedQuestion] = useState<
    Omit<Aggregation, "options"> & {
      options: { label: string; id: string; count: number }[];
    }
  >();
  const { id: artistGroupId } = useArtistContext();
  const history = useHistory();

  const [aggregations, loading] = useDocumentData(
    doc(getFirestore(), `sts3_surveys/${surveyId}/aggregations/prod`),
  );

  useEffect(() => {
    if (!loading) {
      // first preference is single select
      let showcase = Object.entries(aggregations as Aggregations)
        .map(([id, agg]) => ({ id, ...agg }))
        .find(
          (agg) =>
            agg.type === "SingleSelectQuestion" &&
            Object.keys(agg.options).length > 2 &&
            Object.keys(agg.options).length < 8,
        ) as Aggregation | undefined;

      if (showcase) {
        setShowcasedQuestion({
          ...showcase,
          options: Object.entries(showcase.options)
            .filter(([k]) => k !== "no-response")
            .sort(([_a, a], [_b, b]) => b.count - a.count)
            .map(([_, val]) => ({ ...val, label: val.label ?? '"other"' })),
        });
        return;
      }

      // second is multi select
      showcase = Object.entries(aggregations as Aggregations)
        .map(([id, agg]) => ({ id, ...agg }))
        .find(
          (agg) =>
            agg.type === "MultipleSelectQuestion" &&
            Object.keys(agg.options).length > 2 &&
            Object.keys(agg.options).length < 8,
        ) as Aggregation | undefined;

      if (showcase) {
        setShowcasedQuestion({
          ...showcase,
          options: Object.entries(showcase.options)
            .filter(([k]) => k !== "no-response")
            .sort(([_a, a], [_b, b]) => b.count - a.count)
            .map(([_, val]) => ({ ...val, label: val.label ?? '"other"' })),
        });
        return;
      }

      // finally ranked
      showcase = Object.entries(aggregations as Aggregations)
        .map(([id, agg]) => ({ id, ...agg }))
        .find((agg) => agg.type === "RankedQuestion") as
        | Aggregation
        | undefined;
      if (showcase) {
        setShowcasedQuestion({
          ...showcase,
          options: Object.entries(showcase.options)
            .filter(([k]) => k !== "no-response")
            .map(([_, val]) => val),
        });
        return;
      }
    }
  }, [aggregations, loading]);

  const { diff } = useTimeDiff({
    eventTime: lastResponse.startOf("day").toMillis(),
  });

  const lastResponseFormatted = lastResponse.hasSame(DateTime.local(), "day")
    ? "Today"
    : diff.displayString + " ago";

  return (
    <CurrentSurveyContainer
      onClick={() =>
        history.push(`/${artistGroupId}/reporting/set-fan/surveys/${surveyId}`)
      }
    >
      <CurrentSurveyContent compact={compact}>
        <ShowcaseContentColumn style={{ marginBottom: compact ? 0 : 10 }}>
          <H3 style={{ fontWeight: 600, marginBottom: 4 }}>{title}</H3>
          {compact && (
            <Td style={{ marginBottom: 16 }}>
              <span>{formatNumber(responses)}</span> Responses • Last Response:{" "}
              <span>{lastResponseFormatted}</span>
            </Td>
          )}
          {!compact && (
            <>
              <div style={{ marginBottom: 5 }}>
                <Font18 fontWeight={700}>{formatNumber(responses)} </Font18>
                <Font18>Total Respondents</Font18>
              </div>
              <div>
                <Font18>Last Response: </Font18>
                <Font18 fontWeight={700}>{lastResponseFormatted}</Font18>
              </div>
            </>
          )}
        </ShowcaseContentColumn>
        <ShowcaseContentColumn>
          {!!showcasedQuestion && (
            <QuestionShowcase
              showcasedQuestion={showcasedQuestion}
              compact={compact}
              totalResponses={responses}
            />
          )}
        </ShowcaseContentColumn>
      </CurrentSurveyContent>
      <Footer>
        <SvgConnected />
        <Td>Currently Collecting Data</Td>
      </Footer>
    </CurrentSurveyContainer>
  );
};

const CurrentSurveyContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  color: #ffffff;
  position: relative;
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 20px 32px;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px;
  }
`;

const CurrentSurveyContent = styled.div<{ compact?: boolean }>`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 10px;
  ${({ compact }) => compact && "flex-direction: column;"}

  ${({ theme }) => theme.media.tablet} {
    flex-direction: column;
  }
  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
  }
`;

const Footer = styled(FlexRow)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ShowcaseContentColumn = styled(FlexColumn)`
  width: 100%;

  & > ${Td} {
    font-weight: 400;
    span {
      font-weight: 700;
    }
  }
`;
