import { SESSION_PATHS } from "Routes/Session/paths";
import type { FirebaseApp, FirebaseError } from "firebase/app";
import {
  User,
  getAuth,
  signInWithCustomToken,
  signInWithEmailLink,
  signOut,
  isSignInWithEmailLink,
  getMultiFactorResolver,
  MultiFactorResolver,
} from "firebase/auth";
import { History } from "history";
import { LoginMethod } from "./types";

const URLActions = ["init", "end", "go", "resume", "login"] as const;

type URLAction = (typeof URLActions)[number];

export const API_ROUTE = "/session";
interface HttpErrorWireFormat {
  message: string;
  status: string;
}

class HttpError extends Error {
  public error: HttpErrorWireFormat;

  constructor(error: HttpErrorWireFormat) {
    super(error.message);
    this.error = error;
  }
}

/**
 * NEVER USE THESE QUERYSTRING PARAMS
 */
const ParamsToRemove = [
  "apiKey",
  "oobCode",
  "continueUrl",
  "languageCode",
  "mode",
  "tenantId",
  "email",
] as const;

const isFirebaseError = (error: unknown): error is FirebaseError => {
  if (typeof error === "object" && error !== null) {
    if ("code" in error && typeof error.code === "string") {
      return true;
    }
  }
  return false;
};

const formatError = (
  error: HttpError | HttpErrorWireFormat | string,
  defaultError: HttpErrorWireFormat = {
    message: "Internal error",
    status: "INTERNAL",
  },
) => {
  if (!(error instanceof HttpError)) {
    const keys = Object.getOwnPropertyNames(error || {});
    if (keys.includes("message") && keys.includes("status")) {
      error = new HttpError(error as HttpErrorWireFormat);
    } else {
      error = new HttpError(defaultError);
    }
  }
  return error;
};

export namespace Api {
  /**
   * Get a fully formed URL for the given API action.
   */
  export function url(action: "init" | "end" | "resume" | "login"): string;
  export function url(action: "go", params: { url: string }): string;
  export function url(
    action: URLAction,
    params?: Record<string, string>,
  ): string {
    const url = new URL(API_ROUTE, window.location.origin);
    url.searchParams.set("action", action);
    Object.entries(params || {}).forEach(([key, value]) =>
      url.searchParams.set(key, value),
    );
    return url.toString();
  }

  /**
   * Call an API action.
   */
  export async function call(
    action: Exclude<URLAction, "go">,
    params?: RequestInit,
  ): Promise<any>;
  export async function call(url: string, params: RequestInit): Promise<any>;
  export async function call(
    urlOrAction: URLAction | string,
    params: RequestInit = {},
  ): Promise<any> {
    const url = urlOrAction.match(/^https?:\/\//i)
      ? urlOrAction
      : Api.url(urlOrAction as Exclude<URLAction, "go">);

    return fetch(url, {
      ...{
        method: "POST",
        headers: { "content-type": "application/json" },
      },
      ...params,
    })
      .then(async (res) => {
        const data = await res.json().catch(() => ({}));
        if (res.status >= 400) {
          throw formatError(data.error, {
            message: res.statusText,
            status: "INTERNAL",
          });
        }
        return data;
      })
      .catch((error) => {
        throw formatError(error, {
          message: error.message,
          status: "INTERNAL",
        });
      });
  }
}

/**
 * Initialize a session by setting a session cookie.
 * This method does not throw an error.
 */
export async function init(idToken: string): Promise<boolean>;
export async function init(user: User): Promise<boolean>;
export async function init(userOrIdToken: string | User): Promise<boolean> {
  const idToken =
    typeof userOrIdToken === "string"
      ? userOrIdToken
      : await userOrIdToken?.getIdToken().catch(() => "");

  if (idToken) {
    return Api.call("init", {
      body: JSON.stringify({ idToken }),
    })
      .then(() => true)
      .catch(() => false);
  }

  return false;
}

/**
 * Removes the session cookie
 */
export const removeCookie = async (): Promise<void> => {
  Api.call("end")
    .then(() => {
      console.log("session cookies removed");
    })
    .catch((error) => {
      console.error("Error ending session", error);
    });
};

/**
 * Resume the user's session using cookies and optionally a magic link.  If
 * successful, returns the user, null otherwise.  If "search" params are
 * supplied, at attempt will be made to sign-in the user using a magic link.
 * If not supplied, or the attempt fails, then an attempt will be made to
 * sign-in the user using the session cookie.
 *
 * This method does not throw an error.
 */
export const resume = async (
  opts: {
    /**
     * Search params from query string.  If supplied an attempt will be made to
     * login using magic link.
     */
    href?: string;

    /**
     * Used in conjunction with the "search" params for magic link sign-in.  If
     * not provided, an attempt will be made to find the email in the "search".
     */
    email?: string;

    /**
     * If we get a FirebaseError response, this function can be used to do some
     * custom handling.
     *
     * See:
     *  https://firebase.google.com/docs/reference/js/auth#autherrorcodes
     *  https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#error-codes
     */
    firebaseError?: (error: FirebaseError) => unknown | Promise<unknown>;
  } = {},
  history: History,
  setMfaResolver: (resolver: MultiFactorResolver) => void,
  setLoginMethod: (method: LoginMethod) => void,
  firebase?: FirebaseApp,
): Promise<User | null> => {
  const auth = getAuth(firebase);

  let user: User | null = null;
  if (opts.href) {
    const { searchParams } = new URL(opts.href);
    if (isSignInWithEmailLink(auth, opts.href)) {
      console.log("Found magic link");
      let { email } = opts;
      if (!email) {
        email = searchParams.get("email") || "";
      }

      if (email) {
        const parsed = new URL(window.location.href);
        for (const key of ParamsToRemove) {
          parsed.searchParams.delete(key);
        }

        // Clear out the magic link data from the address bar
        window.history.replaceState(null, "", parsed.href);

        user = await signInWithEmailLink(auth, email, opts.href).catch(
          async (error) => {
            if (error.code === "auth/multi-factor-auth-required") {
              const resolver = getMultiFactorResolver(getAuth(), error);
              setMfaResolver(resolver);
              setLoginMethod("link");
              const params = new URLSearchParams();
              params.set("redirect", window.location.pathname);
              history.push(
                `${SESSION_PATHS.LOGIN_WITH_MFA}?${params.toString()}`,
              );
            } else {
              console.error("Error with magic link", error);
              if (isFirebaseError(error) && opts.firebaseError) {
                await opts.firebaseError(error);
              }
            }
            return null;
          },
        );
      }
    }
  }

  if (!user) {
    user = await Api.call("resume")
      .then((data) => {
        const { token } = data;
        if (token === "signout") {
          return signOut(auth).then(() => null);
        } else if (token) {
          return signInWithCustomToken(auth, data.token)
            .then(({ user }) => user)
            .catch((error) => {
              console.error("Error signing in with token", error);
              return null;
            });
        }
        return null;
      })
      .catch(() => null);
  }

  return user;
};

/**
 * Send a login link to the user's email.  If this returns false, the request
 * did not succeed.  This can happen if the email address is invalid OR if
 * are too many successive requests with the same email address.  This method
 * should not be called more than once per 10 seconds.
 *
 * This method does not throw an error.
 */
export const login = async (email: string, to?: string): Promise<boolean> =>
  Api.call("login", {
    body: JSON.stringify({ email, to: to ? to : window.location.origin }),
  })
    .then(() => true)
    .catch(() => false);

/**
 * Generate a URL that can be used to auto-negotiate sign-in for the current
 * user, possibly at a different domain.
 */
export const go = (redirect: string): string => {
  const url = new URL("/go", window.location.origin);
  url.searchParams.set("url", redirect);
  return url.toString();
};
