import { ReactComponent as TemplateIcon } from "assets/svg/favShortForm.svg";
import { SurveyTemplate } from "../../types";

export const favoriteShortFormContent: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Favorite Short Form Content",
  description:
    "Find out what social media content is resonating with your fans.",
  templateId: "favoriteShortFormContent",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Favorite Short Form Content",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "Which types of short form content do you find yourself viewing the most? Choose all that apply.",
        },
        type: "MultipleSelectQuestion",
        options: [
          { label: { en: "Music Snippets" } },
          { label: { en: "Behind-the-Scenes Clips" } },
          { label: { en: "Dance and Performance Videos" } },
          { label: { en: "TikTok Challenges" } },
          { label: { en: "Lifestyle and Fashion Posts" } },
          { label: { en: "Collaborations with Other Artists" } },
          { label: { en: "Personal Messages and Thoughts" } },
          { label: { en: "Fan Interaction Moments" } },
          { label: { en: "Live Q&A Sessions" } },
          { label: { en: "Announcements and News" } },
        ],
      },
      {
        id: "question_2",
        label: {
          en: "What influences your choice of content? Is it the mood, relevance, creativity, or something else?",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes" ? "pp-marketing-6bf3bf" : "pp-marketing-cec27a",
  },
};
