import { useTimeContext } from "Components";
import { DateTime, DurationObjectUnits } from "luxon";
import { useEffect, useState } from "react";

export const useTimeDiff = ({ eventTime }: { eventTime: number }) => {
  const [diff, setDiff] = useState<{
    time: DurationObjectUnits;
    value: number;
    unit: string;
    displayString: string;
  }>({
    time: {},
    value: -1,
    unit: "",
    displayString: "",
  });

  const { time } = useTimeContext();

  useEffect(() => {
    if (eventTime) {
      const timeDiff = DateTime.fromMillis(eventTime)
        .diff(time, ["years", "months", "days", "hours", "minutes", "seconds"])
        .toObject();

      const absTimeDiff = {
        years: Math.abs(timeDiff.years),
        months: Math.abs(timeDiff.months),
        days: Math.abs(timeDiff.days),
        hours: Math.abs(timeDiff.hours),
        minutes: Math.abs(timeDiff.minutes),
        seconds: Math.round(Math.abs(timeDiff.seconds)),
      };

      const formattedTimeDiff = getFormattedTimeDiff(absTimeDiff);

      setDiff({ time: absTimeDiff, ...formattedTimeDiff });
    }
  }, [eventTime, time]);

  return { diff, time };
};

const getFormattedTimeDiff = (
  time: DurationObjectUnits,
): { displayString: string; value: number; unit: string } => {
  if (time.years > 0) {
    const unit = time.years === 1 ? "Year" : "Years";
    return {
      displayString: `${time.years} ${unit}`,
      value: time.years,
      unit,
    };
  }

  if (time.months > 0) {
    const unit = time.months === 1 ? "Month" : "Months";
    return {
      displayString: `${time.months} ${unit}`,
      value: time.months,
      unit,
    };
  }

  if (time.days > 0) {
    const unit = time.days === 1 ? "Day" : "Days";
    return {
      displayString: `${time.days} ${unit}`,
      value: time.days,
      unit,
    };
  }

  if (time.seconds >= 0) {
    let value = 0,
      unit = "";

    if (time.hours > 0) {
      value = time.hours;
      unit = time.hours > 1 ? "Hours" : "Hour";
    } else {
      value = time.minutes;
      unit = time.minutes > 1 ? "Mins" : "Min";
    }

    return {
      displayString: `${time.hours < 10 ? `0${time.hours}` : time.hours}:${
        time.minutes < 10 ? `0${time.minutes}` : time.minutes
      }:${
        time.seconds < 10
          ? `0${Math.round(time.seconds)}`
          : Math.round(time.seconds)
      }`,
      value,
      unit,
    };
  }

  return { displayString: "", value: 0, unit: "Mins" };
};
