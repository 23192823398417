import React from "react";
import { Step, useSetupMFAContext } from "..";
import { Body1, Body2 } from "melodies-source/Text";
import { ReactComponent as MessageIcon } from "assets/svg/message-single.svg";
import { ReactComponent as QRCodeIcon } from "assets/svg/qr-code.svg";
import styled from "styled-components";
import { varHexToRGB } from "Utils";

export const SelectMFA: React.FC = () => {
  const { setStep } = useSetupMFAContext();

  return (
    <>
      <Body1>Select a verification method:</Body1>
      <Methods>
        <Method onClick={() => setStep(Step.SetupPhone)}>
          <MethodIcon>
            <MessageIcon />
          </MethodIcon>
          <MethodDescription>
            <Body1>SMS Message</Body1>
            <Body2>Use your phone number.</Body2>
          </MethodDescription>
        </Method>
        <Method onClick={() => setStep(Step.SetupTOTP)}>
          <MethodIcon>
            <QRCodeIcon />
          </MethodIcon>
          <MethodDescription>
            <Body1>Authenticator App</Body1>
            <Body2>
              Use an authenticator app such as Google Authenticator.
            </Body2>
          </MethodDescription>
        </Method>
      </Methods>
    </>
  );
};

const Methods = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 10px 0;
`;

const Method = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 20px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  gap: 10px;
  cursor: pointer;

  &:hover {
    background-color: var(--hover-background-color);
  }
`;

const MethodIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 50%;
  background-color: ${({ theme }) =>
    theme.custom?.icon.background || varHexToRGB("--main-color", 0.1)};
  color: var(--main-color);
`;

const MethodDescription = styled.div``;
