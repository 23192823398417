import { ReactComponent as IconShirtHeart } from "assets/svg/shirt-heart.svg";
import { SurveyTemplate } from "../../types";

export const favoriteMerchItems: SurveyTemplate = {
  icon: <IconShirtHeart />,
  title: "Favorite Merch Items",
  description:
    "Build interest in merch by asking fans what items should be made next.",
  templateId: "favoriteMerchItems",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Favorite Merch Items",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "Which merch items excite you the most?",
        },
        type: "MultipleSelectQuestion",
        options: [
          {
            label: {
              en: "Custom Vinyl Figures",
            },
          },
          {
            label: {
              en: "Eco-Friendly Tote Bags",
            },
          },
          {
            label: {
              en: "Exclusive Posters",
            },
          },
          {
            label: {
              en: "Signature Hoodies",
            },
          },
          {
            label: {
              en: "Enamel Pins Set",
            },
          },
          {
            label: {
              en: "Lyric Books",
            },
          },
        ],
      },
      {
        id: "question_2",
        label: {
          en: "Have any other merch ideas? We'd love to know!",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-merch---fa-6b1dd9"
        : "pp-merch---fa-e7e523",
  },
};
