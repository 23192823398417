import slowPulseV2 from "assets/lotties/slow-pulse-v2.json";
import complatedPulseSlow from "assets/lotties/completed-pulse-slow-v2.json";
import fastPulseV4 from "assets/lotties/fast-pulse-v4.json";
import { useLayoutEffect } from "react";
import Lottie from "react-lottie-player";

export const RingsRouter = () => {
  useLayoutEffect(() => {
    document.getElementById("loader")?.remove();
  }, []);

  return (
    <>
      <Lottie animationData={slowPulseV2} renderer="canvas" play={true} />
      <Lottie animationData={fastPulseV4} renderer="canvas" play={true} />
      <Lottie
        animationData={complatedPulseSlow}
        renderer="canvas"
        play={true}
      />
    </>
  );
};
