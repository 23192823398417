import { H1 } from "melodies-source/Text";
import styled, { css } from "styled-components";

interface PageContentWrapperProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "title"> {
  title: React.ReactNode;
  action?: React.ReactNode;
}

export const PageContentWrapper = ({
  children,
  title,
  action,
  ...props
}: PageContentWrapperProps) => {
  return (
    <PageContentWrapperContainer {...props}>
      {!(!action && !title) && (
        <HeaderContainer hasAction={!!action}>
          {typeof title === "string" ? <PageTitle>{title}</PageTitle> : title}
          {action}
        </HeaderContainer>
      )}
      {children}
    </PageContentWrapperContainer>
  );
};

export const PageTitle = styled(H1)`
  color: #ffffff;
  line-height: 40px;
  margin-top: 8px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 4px;
    font-size: 26px;
    line-height: 34px;
  }
`;

export const HeaderContainer = styled.div<{ hasAction?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;

  & > svg {
    align-self: flex-start;
    color: white;
    height: 48px;
    width: auto;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 20px;

    & > svg {
      align-self: center;
      ${(p) =>
        !p.hasAction &&
        css`
          margin: 0 auto;
        `};
      height: 32px;
    }
  }
`;

const PageContentWrapperContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px;
  flex-grow: 1;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px;
  }
`;
