import { ReactComponent as IconFavorite } from "assets/svg/favorite.svg";
import { SurveyTemplate } from "../../types";

export const favoriteArtists: SurveyTemplate = {
  icon: <IconFavorite />,
  title: "Favorite Artists",
  description: "Learn which artists' fans you should be marketing to.",
  templateId: "favoriteArtists",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Favorite Artists",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "Which artists are on your current playlist?",
        },
        type: "TextQuestion",
      },
      {
        id: "question_2",
        label: {
          en: "Any hidden gems you've discovered that you'd recommend to a fellow fan?",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes" ? "pp-marketing-6db77a" : "pp-marketing-0d3a6f",
  },
};
