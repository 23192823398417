import { AdditionalGroupsAvatars } from "Components/AdditionalGroups";
import { FlexColumn, FlexRow } from "Components/Flex";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { functions } from "firebase-internal";
import { Timestamp } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useSetLiveEvents } from "hooks";
import { useUpcomingConcerts } from "hooks/useUpcomingConcerts";
import { DateTime } from "luxon";
import { Button as ButtonBase } from "melodies-source/Button";
import { Modal, ModalContainer } from "melodies-source/Modal";
import { Radio } from "melodies-source/Selectable";
import { Spinner } from "melodies-source/Spinner";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { Body1 } from "melodies-source/Text";
import { colors } from "melodies-source/Theme/colors";
import { Concert, ManualConcert } from "models/artist";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { cityStateFromAddress } from "Utils";
import { CheckList } from "./CheckList";
import { CreateShow } from "./Create";
import { getViewText } from "./getViewText";
import { ListContainer } from "./ListContainer";
import { ListItem } from "./ListItem";
import { SharedArtistsList } from "./SharedArtistsList";
import { ConcertDetails, ListOption } from "./types";
import { useArtistContext } from "Components";
import { useIsMobile } from "melodies-source/utils";

export interface PublishRequest {
  id: string;
  status?: string;
  action?: string;
  additional?: string[];
  manualConcerts?: ManualConcert[];
  uid?: string;
  isAdmin?: boolean;
}

export type PublishResponse = {
  events: string[];
  errors: string[];
};

type SourceEvent = {
  venue: string;
  builderId: string;
  additionalGroups?: string[];
};

type CreateOption = Partial<
  Pick<
    Concert,
    | "id"
    | "venue"
    | "address"
    | "city"
    | "state"
    | "timeZone"
    | "setliveEventId"
  >
> & {
  date?: Date | Timestamp | number;
  displayAddress?: string;
};

const createOption = (concert: CreateOption): ListOption => {
  const { address, displayAddress, date, timeZone, city, state } = concert;
  let time;
  if (date instanceof Timestamp) {
    time = DateTime.fromJSDate(date.toDate());
  }
  if (date instanceof Date) {
    time = DateTime.fromJSDate(date);
  }
  if (typeof date === "number") {
    time = DateTime.fromMillis(date);
  }
  time = time?.setZone(timeZone);
  return {
    ...concert,
    date: time,
    displayAddress:
      displayAddress || address
        ? cityStateFromAddress(address)
        : city && state
        ? `${city}, ${state}`
        : "Unknown",
  };
};

export type DuplicateViews =
  | "source"
  | "noConcerts"
  | "singleConcert"
  | "multipleConcerts"
  | "manualConcert";

export type SelectedType = "manual" | "linked" | "unlinked";
export type SelectedConcerts = {
  [K in SelectedType]: string[];
};

export const Duplicate = ({
  onComplete,
}: {
  onComplete: (v?: boolean) => void;
}) => {
  const [sharedEventConfirm, setSharedEventConfirm] = useState(false);
  const [dupLoading, setDupLoading] = useState<boolean>(false);
  const [manualShows, setManualShows] = useState<ConcertDetails<Date>[]>([]);
  const [sourceEvent, setSourceEvent] = useState<SourceEvent | undefined>();
  const [view, setView] = useState<DuplicateViews>("source");
  const { artistId } = useParams<{ artistId: string }>();
  const [selected, setSelected] = useState<SelectedConcerts>({
    manual: [],
    linked: [],
    unlinked: [],
  });
  const { customApp, language } = useCustomAppContext();
  const [concerts, concertsLoading] = useUpcomingConcerts(artistId);
  const { logAction } = useArtistContext();

  const isMobile = useIsMobile();

  const { setLiveEvents, loading: setliveLoading } = useSetLiveEvents({
    filterBy: "deletedAt:0 && status:=published && version:=2",
    sort: "startsAt:desc",
    resultsPerPage: 100,
  });

  const loading = setliveLoading || concertsLoading || dupLoading;
  const isStep1 = view === "source";

  const handleClose = () => {
    setSourceEvent(undefined);
    onComplete();
  };

  const publishedEvents = setLiveEvents.map(
    ({ startsAt, timeZoneName, ...rest }) =>
      createOption({
        ...rest,
        date: startsAt,
        timeZone: timeZoneName,
      }),
  );

  let upcoming = concerts?.map((c) => createOption(c as unknown as Concert));
  const available = upcoming?.filter(({ setliveEventId }) => !setliveEventId);

  if (manualShows?.length) {
    const updatedManualShows = manualShows.map((m) => ({
      ...createOption(m),
      isManual: true,
    }));
    upcoming = upcoming.concat(updatedManualShows);
  }

  const isValid =
    !!selected.linked.length ||
    !!selected.unlinked.length ||
    !!selected.manual.length;

  useEffect(() => {
    if (!concertsLoading && !isValid && available?.length) {
      setSelected({ ...selected, unlinked: available.map(({ id }) => id) });
    }
  }, [concerts]);

  const publish = async (manualConcert?: ManualConcert | ManualConcert[]) => {
    if (!isValid && !manualConcert) {
      toast.error(
        `Your ${language.event.type.singular.toLowerCase()} selection is invalid.`,
      );
      return;
    }
    try {
      setDupLoading(true);
      const publishAPI = httpsCallable<PublishRequest, PublishResponse>(
        functions,
        "setlivev3-event-publish",
      );
      const request: PublishRequest = {
        id: sourceEvent.builderId,
        action: "duplicate",
        status: "published",
      };
      if (selected.manual?.length) {
        request.manualConcerts = manualShows.filter(({ id }) =>
          selected.manual.includes(id),
        );
      }
      if (manualConcert) {
        request.manualConcerts = Array.isArray(manualConcert)
          ? manualConcert
          : [manualConcert];
      }
      if (!!selected.unlinked.length || !!selected.linked.length) {
        request.additional = selected.unlinked.concat(selected.linked);
      }
      const results = await publishAPI(request);
      if (!!results?.data?.events?.length) {
        await logAction("portal_duplicate_event", { request });
        onComplete(true);
        toast.success(
          `Your ${language.event.type.singular} has been duplicated.`,
        );
      } else {
        toast.error(
          `There was an issue duplicating your ${language.event.type.singular}.`,
        );
      }
    } catch (error) {
      toast.error(
        `We encountered an error while duplicating your ${language.event.type.singular}.`,
      );
      console.log("error", error);
    } finally {
      setDupLoading(false);
    }
  };

  const handleNextClick = () => {
    let nextView = "noConcerts" as DuplicateViews;
    if (upcoming?.length && (available.length || upcoming.length > 1)) {
      nextView = upcoming.length === 1 ? "singleConcert" : "multipleConcerts";
    }
    setView(nextView);
  };

  const { header, description } = getViewText(
    view,
    sourceEvent,
    customApp?.company.name || "SET",
    language.event.type.singular.toLowerCase(),
    language.event.type.plural.toLowerCase(),
  );

  const handleManualShow = (
    concert: ConcertDetails<Date> | ConcertDetails<Date>[],
  ) => {
    if (view === "noConcerts") {
      publish(concert);
    } else {
      setManualShows((c) =>
        c.concat(Array.isArray(concert) ? concert : [concert]),
      );
      setSelected((s) => ({
        ...s,
        manual: s.manual.concat(
          Array.isArray(concert) ? concert.map((c) => c.id) : [concert.id],
        ),
      }));
      setView("multipleConcerts");
    }
  };

  const handleCloseSharedEventConfirm = () => {
    setSharedEventConfirm(false);
  };

  return (
    <>
      <Modal
        header="Duplicate Shared Event?"
        isOpen={sharedEventConfirm}
        onClose={handleCloseSharedEventConfirm}
      >
        <SharedArtistsList
          ids={sourceEvent?.additionalGroups}
          onCancel={handleCloseSharedEventConfirm}
          onConfirm={() => {
            handleCloseSharedEventConfirm();
            handleNextClick();
          }}
        />
      </Modal>
      <StyledModal
        header={loading ? "" : header}
        isOpen
        onClose={() => (!dupLoading ? handleClose() : {})}
        variant="large"
        withBackdropClose={!sharedEventConfirm}
        withViewportMaxHeight={!isMobile}
      >
        <ModalContent>
          {loading ? (
            <LoadingContainer>
              <Spinner color={colors.black40} />
            </LoadingContainer>
          ) : ["noConcerts", "manualConcert"].includes(view) ? (
            <CreateShow
              view={view}
              upcomingShows={upcoming}
              onCreate={handleManualShow}
              onCancel={() =>
                setView(
                  view === "manualConcert" ? "multipleConcerts" : "source",
                )
              }
            />
          ) : (
            <GridContainer>
              <Column>
                <Body1>{description}</Body1>
              </Column>
              <Column>
                {view === "singleConcert" && (
                  <ListItem
                    label={upcoming?.[0]?.venue}
                    caption={upcoming?.[0]?.address}
                    caption2={upcoming?.[0]?.date.toFormat(
                      "LLL d, cccc • h:mm a (ZZZZ)",
                    )}
                  />
                )}
                {view === "multipleConcerts" && (
                  <CheckList
                    upcomingShows={upcoming}
                    unlinkedShows={available}
                    setSelected={setSelected}
                    selected={selected}
                    manualShows={manualShows}
                  />
                )}
                {view === "source" && (
                  <ListContainer>
                    {publishedEvents.map((o) => (
                      <Radio
                        key={o.id}
                        label={
                          <RatioTitle>
                            {o.venue}
                            {!!o.additionalGroups?.length && (
                              <AdditionalGroupsAvatars
                                ids={o.additionalGroups}
                                size={20}
                                tooltip={false}
                              />
                            )}
                          </RatioTitle>
                        }
                        labelColor="main"
                        description={
                          <>
                            {o.displayAddress}
                            <br />
                            {o.date.toFormat(
                              "ccc, LLL d, yyyy • h:mm a (ZZZZ)",
                            )}
                          </>
                        }
                        onChange={() =>
                          setSourceEvent({
                            venue: o.venue,
                            builderId: o.id,
                            additionalGroups: o.additionalGroups,
                          })
                        }
                        value={o.id === sourceEvent?.builderId}
                      />
                    ))}
                  </ListContainer>
                )}
              </Column>
            </GridContainer>
          )}

          {!["manualConcert", "noConcerts"].includes(view) && (
            <OuterButtonContainer style={{ marginTop: 32 }}>
              {isStep1 && (
                <Button
                  variant="tertiary"
                  onClick={handleClose}
                  disabled={dupLoading}
                >
                  Cancel
                </Button>
              )}
              {["singleConcert", "multipleConcerts"].includes(view) &&
                !loading && (
                  <Button
                    variant="tertiary"
                    onClick={() => setView("manualConcert")}
                    style={{ color: "var(--main-color)" }}
                  >
                    <SvgAddAlt style={{ marginRight: 8 }} />
                    Manually add a show
                  </Button>
                )}
              <InnerButtonContainer flex="1 1 auto" xEnd>
                {!isStep1 && (
                  <Button
                    style={{ marginRight: 12, minWidth: 120 }}
                    onClick={() => setView("source")}
                    variant="outlined"
                    disabled={dupLoading}
                  >
                    Back
                  </Button>
                )}
                {isStep1 ? (
                  <Button
                    style={{ minWidth: 160 }}
                    onClick={() => {
                      if (!!sourceEvent?.additionalGroups?.length) {
                        setSharedEventConfirm(true);
                      } else {
                        handleNextClick();
                      }
                    }}
                    disabled={!sourceEvent?.builderId || loading}
                  >
                    Next
                  </Button>
                ) : (
                  <Button
                    style={{ minWidth: 160 }}
                    onClick={() => publish()}
                    disabled={!isValid || loading}
                  >
                    {dupLoading ? "Processing" : "Duplicate"}
                  </Button>
                )}
              </InnerButtonContainer>
            </OuterButtonContainer>
          )}
        </ModalContent>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  ${({ theme }) => theme.mediaQueries.desktop} {
    ${ModalContainer} {
      min-height: 560px;
    }
  }
`;

const Button = styled(ButtonBase)`
  width: auto;
`;

const InnerButtonContainer = styled(FlexRow)`
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    order: 1;
    ${Button} {
      width: 100%;
      position: relative;
      order: 2;
    }
    ${Button} + ${Button} {
      position: relative;
      order: 1;
      margin-bottom: 12px;
    }
  }
`;

const OuterButtonContainer = styled(FlexRow)`
  margin-top: 32px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    ${Button} {
      width: 100%;
    }
    & > ${Button} {
      margin-top: 12px;
      align-self: center;
      width: auto;
      order: 2;
      position: relative;
    }
  }
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 380px;
  grid-template-rows: 1fr;
  column-gap: 44px;
  flex-grow: 1;
  min-height: 0;
  ${Body1} {
    i {
      font-style: italic;
    }
    & > span {
      font-weight: 600;
    }
  }

  ${(p) => p.theme.mediaQueries.mobile} {
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    row-gap: 32px;
  }
`;

const Column = styled(FlexColumn)`
  min-height: 0;
  ${({ theme }) => theme.mediaQueries.mobile} {
    ${ListContainer} {
      max-height: 40dvh;
    }
  }
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 0;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
`;

const RatioTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;
