import { DefaultLayout } from "Components";
import { Card } from "melodies-source/Card";
import { GlobalStyles } from "styled";
import styled from "styled-components";
import { Preview } from "../Components/Preview";
import { Dispatch, SetStateAction, useState } from "react";
import { SectionContainer } from "Routes/SetFan/editor/Components/SectionContainer";
import { SvgClipboard } from "melodies-source/Svgs/Clipboard";
import { SvgChecklist } from "melodies-source/Svgs/Checklist";
import { ReactComponent as IconPalette } from "assets/svg/palette.svg";
import { ReactComponent as IconTrophy } from "assets/svg/trophy-large.svg";
import { ReactComponent as IconUserQuestion } from "assets/svg/user-question.svg";
import { ReactComponent as IconMessageCheck } from "assets/svg/message-check.svg";
import { SurveyInfoContent } from "../slices/SurveyInfoContent";
import { Appearance } from "../slices/Appearance";
import { Contest } from "../slices/Contest";
import { SurveyQuestions } from "../slices/SurveyQuestions";
import { ProfilingQuestions } from "../slices/ProfilingQuestions";
import { ThankYouMessage } from "../slices/ThankYouMessage";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { useSurveyBuilder } from "../hooks/useSurveyBuilder";
import {
  BuilderProvider,
  useBuilderContext,
} from "../Components/BuilderContext";
import { H2 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { CenterFlexRow } from "Routes/styled";
import { SongsQuestion } from "../Components/SongsQuestion";
import { ContestTitleAddon } from "../slices/ContestTitleAddon";
import { useIsMobile } from "melodies-source/utils";
import { PreviewContainer, PreviewFab } from "../wizard";

const slices = [
  { icon: <SvgClipboard />, title: "Survey Info", slice: SurveyInfoContent },
  { icon: <IconPalette />, title: "Appearance", slice: Appearance },
  {
    icon: <IconTrophy />,
    title: "Sweepstakes",
    slice: Contest,
    titleAddon: ContestTitleAddon,
  },
  {
    icon: <SvgChecklist />,
    title: "Survey Questions",
    slice: SurveyQuestions,
  },
  {
    icon: <IconUserQuestion />,
    title: "Profiling Questions",
    slice: ProfilingQuestions,
  },
  {
    icon: <IconMessageCheck />,
    title: "Thank You Message",
    slice: ThankYouMessage,
  },
];

export const Editor = ({
  match,
}: RouteComponentProps<{ surveyId: string }>) => {
  const [page, setPage] = useState<number | null>(0);
  const { survey, version } = useSurveyBuilder(match.params.surveyId);

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      {survey && version && (
        <BuilderProvider survey={survey} version={version}>
          <Switch>
            <Route
              path={match.path}
              exact={true}
              render={() => <InnerEditor page={page} setPage={setPage} />}
            />
            <Route
              path={`${match.path}/setlist/:setlistId?`}
              render={() => <InnerSetlist backPath={match.url} />}
            />
          </Switch>
        </BuilderProvider>
      )}
    </DefaultLayout>
  );
};

interface InnerSetlistProps {
  backPath: string;
}

const InnerSetlist = ({ backPath }: InnerSetlistProps) => {
  return (
    <div style={{ padding: 40 }}>
      <SongsQuestion backPath={backPath} />
    </div>
  );
};

interface InnerEditorProps {
  page: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const InnerEditor = ({ page, setPage }: InnerEditorProps) => {
  const { onPublish, publishingStatus, status } = useBuilderContext();
  const [isPreviewVisible, setIsPreviewVisible] = useState<boolean>(false);
  const isMobile = useIsMobile();

  const actions = (
    <Actions>
      {status === "draft" && (
        <Button
          loading={publishingStatus === "draft"}
          disabled={publishingStatus === "live"}
          variant="tertiary"
          onClick={() => onPublish({ status: "draft" })}
          style={{ minWidth: 156 }}
        >
          Save as Draft
        </Button>
      )}
      <Button
        style={{ minWidth: 162 }}
        onClick={() => onPublish({ status: "live" })}
        loading={publishingStatus === "live"}
        disabled={publishingStatus === "draft"}
      >
        {status === "draft" ? "Publish" : "Update"}
      </Button>
    </Actions>
  );

  return (
    <PageGridContainer>
      <Title>Edit Survey</Title>
      {!isMobile && actions}
      <Frame>
        <PageContentCard isElevated>
          {slices.map((section, index) => (
            <SectionContainer
              {...section}
              active={index === page}
              onClick={() => setPage((curr) => (curr === index ? null : index))}
              lastIndex={index === slices.length - 1}
              key={`survey-section-${index}`}
            />
          ))}
          {isMobile && actions}
        </PageContentCard>
        {(!isMobile || isPreviewVisible) && (
          <PreviewContainer isVisible={isPreviewVisible}>
            <Preview />
          </PreviewContainer>
        )}
        <PreviewFab
          isVisible={isPreviewVisible}
          onClick={() => setIsPreviewVisible((v) => !v)}
        />
      </Frame>
    </PageGridContainer>
  );
};

const Title = styled(H2)`
  color: var(--main-color);
  grid-area: title;
  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 20px;
    line-height: 28px;
  }
`;

export const PageGridContainer = styled.div`
  display: grid;
  grid-template: "title actions" "frame frame" / 1fr 400px;
  align-items: center;
  row-gap: 32px;
  padding: 32px 44px 44px;

  ${({ theme }) => theme.media.mobile} {
    grid-template: "title" "frame" / 1fr;
    padding: 20px 0;
    margin: 0 20px;
    row-gap: 0;
  }
`;

const Actions = styled(CenterFlexRow)`
  grid-area: actions;
  gap: 12px;
  justify-content: flex-end;
  ${({ theme }) => theme.mediaQueries.mobile} {
    border-top: 1px solid var(--box-shadow-color);
    padding: 24px 20px;
    flex-direction: column-reverse;
    gap: 8px;
    align-items: stretch;
  }
`;

const Frame = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  grid-area: frame;
  display: grid;
  grid-template: auto / 1fr 400px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    grid-template: auto / 1fr;
  }
`;

const PageContentCard = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  padding: 0;
  border: none;
  margin-right: 44px;
  flex-grow: 1;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 20px;
    margin-right: 0;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  }
`;
