import { DefaultLayout, useArtistContext } from "Components";
import { GlobalStyles } from "styled";
import { StatusCards, Welcome } from "./Components";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { Modal } from "melodies-source/Modal";
import styled from "styled-components";
import { SetBioAppCard } from "./Components/AppCards/SetBioAppCard";
import { SetLiveAppCard } from "./Components/AppCards/SetLiveAppCard";
import { SetFanAppCard } from "./Components/AppCards/SetFanAppCard";
import { Spinner } from "melodies-source/Spinner";
import { useDashboard } from "./Components/AppCards/useDashboard";

export const Dashboard = () => {
  const { showWelcome, hideWelcome } = useArtistContext();
  const { customApp } = useCustomAppContext();
  const { loading, setLive, setFan, setBio } = useDashboard();

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      <StatusCards />
      {loading ? (
        <Spinner style={{ flexGrow: 1 }} />
      ) : (
        <Content>
          <SetLiveAppCard {...setLive} />
          {!customApp && (
            <>
              <SetFanAppCard {...setFan} />
              <SetBioAppCard {...setBio} />
            </>
          )}
        </Content>
      )}
      {!customApp && (
        <Modal
          isOpen={showWelcome}
          onClose={() => hideWelcome()}
          withCloseIcon={false}
          variant="large"
        >
          <Welcome />
        </Modal>
      )}
    </DefaultLayout>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 0 0 44px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 0 0 32px;
  }
`;
