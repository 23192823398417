import { FlexColumn } from "Components/Flex";
import { SvgOpen } from "melodies-source/Svgs/Open";
import { Body1, Body2, H1, H3 } from "melodies-source/Text";
import { Button } from "melodies-source/Button";
import { ReactComponent as IconAudience } from "assets/svg/audience-gradient.svg";
import { ReactComponent as IconDocumentEdit } from "assets/svg/document-edit.svg";
import styled, { css } from "styled-components";
import { ReportMetricCard } from ".";
import { useHistory } from "react-router-dom";

interface SignUpsProps {
  total: undefined | number | null;
}

export const SignUps = ({ total, ...props }: SignUpsProps) => {
  const nav = useHistory();
  return (
    <ReportMetricCard isElevated {...props}>
      {total === undefined ? (
        <FlexColumn
          flex="1 1 auto"
          gap="16px"
          xCenter
          yCenter
          style={{ textAlign: "center" }}
        >
          <StyledIconDocumentEdit />
          <FlexColumn gap="8px">
            <StyledH3>Add a sign-up form to enhance your page</StyledH3>
            <Body1>Engage your fans and grow your contact list</Body1>
          </FlexColumn>
          <Button
            style={{ marginTop: 16 }}
            onClick={() => nav.push("../set-bio")}
          >
            Edit Page
          </Button>
        </FlexColumn>
      ) : (
        <>
          <H3>Sign-Ups</H3>
          <SignUpContent>
            <IconAudience />
            <H1>{total?.toLocaleString()}</H1>
            <Button text onClick={() => nav.push("../fans")} style={{ gap: 8 }}>
              View All Contacts
              <SvgOpen />
            </Button>
          </SignUpContent>
          <Body2>Captured Last 30 Days</Body2>
        </>
      )}
    </ReportMetricCard>
  );
};

const StyledIconDocumentEdit = styled(IconDocumentEdit)`
  ${({ theme }) =>
    theme.custom &&
    css`
      path:first-child {
        fill: var(--secondary-color);
      }
      path:not(:first-child) {
        fill: var(--main-color);
      }
    `}
`;

const SignUpContent = styled(FlexColumn)`
  align-items: center;
  ${H1} {
    line-height: 44px;
  }
`;

const StyledH3 = styled(H3)`
  font-size: 18px;
  line-height: 26px;
`;
