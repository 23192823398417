import {
  CATEGORIES_OPTIONS_IDS,
  CategoryOptionId,
} from "@max/common/dist/setfan";
import { CATEGORIES_OPTIONS } from "Routes/SetFan/constants/profileCategories";
import { FieldArray, Form, Formik } from "formik";
import { Button } from "melodies-source/Button";
import { Body1, H2 } from "melodies-source/Text";
import styled from "styled-components";
import * as yup from "yup";
import { SelectionCard, IconContainer } from "Components/SelectionCard";
import { SvgAudience } from "melodies-source/Svgs/Audience";
import { ModalActions } from "./AddQuestion/ImageQuestionUploader";
import { ResponsiveModal } from "Components/ResponsiveModal";

const OptionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const Save = styled(Button)`
  min-width: 156px;
`;

interface Values {
  categoryOptionIds: CategoryOptionId[];
}

interface EditCategoriesFormProps {
  initialValues?: CategoryOptionId[];
  numberOfCategories: number;
  onCancel?: () => void;
  onSave?: (values: CategoryOptionId[]) => void;
}

const EditCategoriesForm = ({
  initialValues = [...CATEGORIES_OPTIONS_IDS],
  numberOfCategories,
  onCancel,
  onSave,
}: EditCategoriesFormProps) => {
  const plural = numberOfCategories !== 1;

  const validationSchema = yup.object().shape({
    categoryOptionIds: yup
      .array()
      .of(yup.string())
      .min(
        numberOfCategories - 1,
        `Select at least ${numberOfCategories} ${
          plural ? "categories" : "category"
        }.`,
      ),
  });

  return (
    <Formik<Values>
      initialValues={{ categoryOptionIds: initialValues }}
      validationSchema={validationSchema}
      onSubmit={({ categoryOptionIds }) => onSave(categoryOptionIds)}
    >
      {({ values, touched, errors }) => (
        <Form>
          <FieldArray name="categoryOptionIds">
            {({ push, remove }) => (
              <OptionList>
                <StyledSelectionCard
                  header="Fan Contact Info"
                  subHeader="Gather the names, locations, and phone numbers of your supporters."
                  icon={<SvgAudience width={35} height={35} />}
                  locked
                  selected
                />
                {CATEGORIES_OPTIONS.map((option) => (
                  <StyledSelectionCard
                    key={option.id}
                    header={option.header}
                    subHeader={option.subHeader}
                    icon={option.icon}
                    selected={values.categoryOptionIds.includes(option.id)}
                    onClick={() =>
                      values.categoryOptionIds.includes(option.id)
                        ? remove(values.categoryOptionIds.indexOf(option.id))
                        : push(option.id)
                    }
                  />
                ))}
              </OptionList>
            )}
          </FieldArray>
          {touched.categoryOptionIds && errors.categoryOptionIds && (
            <Body1 style={{ color: "#FF0000", marginTop: 16 }}>
              {errors.categoryOptionIds}
            </Body1>
          )}
          <ModalActions>
            <Button type="button" text={true} onClick={() => onCancel?.()}>
              Cancel
            </Button>
            <Save type="submit">Save</Save>
          </ModalActions>
        </Form>
      )}
    </Formik>
  );
};

interface EditCategoriesDisplayedProps {
  initialValues?: CategoryOptionId[];
  numberOfCategories: number;
  isOpen?: boolean;
  onClose?: () => void;
  onSave?: (values: CategoryOptionId[]) => void;
}

export const EditCategoriesDisplayed = ({
  initialValues,
  numberOfCategories,
  isOpen,
  onClose,
  onSave,
}: EditCategoriesDisplayedProps) => {
  return (
    <ResponsiveModal
      variant="large"
      header={
        <>
          <H2>Edit Categories Displayed</H2>
          <Body1>
            Your fans will see up to your chosen number of categories. These
            categories will be intelligently chosen from this list. Please
            uncheck any that you don't want shown:
          </Body1>
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      withBackdropClose={false}
    >
      <EditCategoriesForm
        numberOfCategories={numberOfCategories}
        initialValues={initialValues}
        onCancel={onClose}
        onSave={onSave}
      />
    </ResponsiveModal>
  );
};

const StyledSelectionCard = styled(SelectionCard)`
  ${({ theme }) => theme.mediaQueries.mobile} {
    ${IconContainer} {
      display: none;
    }
  }
`;
