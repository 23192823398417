import { FlexColumn } from "Components/Flex";
import { KeyedValue } from "Components/MixedChart";
import { Td } from "melodies-source/Text";
import { useState } from "react";
import {
  Cell,
  Pie,
  PieChart as PieChartBase,
  ResponsiveContainer,
} from "recharts";
import styled, { css } from "styled-components";

export interface PieChartProps extends React.HTMLAttributes<HTMLDivElement> {
  dataKey: string;
  nameKey: string;
  data?: KeyedValue[];
  colors?: string[];
  onSelectItem?: (label: string) => void;
  layout?: "horizontal" | "vertical";
}

const defaultColors = ["#0095EF", "#3C50B1", "#6A38B3", "#A224AC", "#E81DE0"];

export const PieChart = ({
  dataKey,
  nameKey,
  data,
  colors,
  onSelectItem,
  layout = "vertical",
  ...props
}: PieChartProps) => {
  const [selectedItemId, setSelectedItemId] = useState<string | undefined>();
  const selColors = colors || defaultColors;

  const selectItem = (label: string | undefined) => {
    setSelectedItemId(label);
    if (onSelectItem) onSelectItem(label);
  };

  const isHorizontal = layout === "horizontal";

  return (
    <ChartContainer {...props} isHorizontal={isHorizontal}>
      <ResponsiveContainer minHeight="138px" height="100%" width="100%">
        <PieChartBase width={200} height={200}>
          <Pie
            data={data}
            dataKey={dataKey}
            innerRadius="65%"
            outerRadius="100%"
            startAngle={90}
            endAngle={-360}
            paddingAngle={2}
            style={{ outline: "none" }}
          >
            {data.map((cell, index) => (
              <Cell
                key={`cell-${index}`}
                fill={selColors[index % selColors.length]}
                opacity={cell.id === selectedItemId ? 0.5 : 1}
                onMouseOver={() => selectItem(cell.id)}
                onMouseLeave={() => selectItem(undefined)}
                stroke="none"
              />
            ))}
          </Pie>
        </PieChartBase>
      </ResponsiveContainer>
      <ChartLegend
        {...{
          dataKey,
          nameKey,
          data,
          colors,
          selectedItemId,
          setSelectedItemId: selectItem,
          layout,
        }}
      />
    </ChartContainer>
  );
};

const ChartContainer = styled.div<{ isHorizontal?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;

  ${(p) =>
    p.isHorizontal &&
    css`
      display: grid;
      grid-template-columns: 1fr 50%;
      align-items: center;
    `};
`;

export type ChartLegendProps = {
  selectedItemId?: string;
  setSelectedItemId?: (label: string) => void;
} & PieChartProps;

export const ChartLegend = ({
  dataKey,
  nameKey,
  data,
  colors,
  selectedItemId,
  setSelectedItemId,
  ...props
}: ChartLegendProps) => {
  const selColors = colors || defaultColors;
  return (
    <ChartLegendContainer {...props}>
      {data?.map((d, index) => (
        <ListItem
          key={`legend-item-${index}`}
          active={selectedItemId === d.id}
          onMouseOver={() => setSelectedItemId(d.id)}
          onMouseLeave={() => setSelectedItemId(undefined)}
        >
          <Circle style={{ background: selColors[index % selColors.length] }} />
          <Td>{d[nameKey]}</Td>
          <Td>{d[dataKey]}</Td>
        </ListItem>
      ))}
    </ChartLegendContainer>
  );
};

export const ChartLegendContainer = styled(FlexColumn)``;

export const ListItem = styled.div<{ active?: boolean }>`
  align-items: center;
  display: grid;
  column-gap: 6px;
  grid-template-columns: 12px 1fr auto;
  padding: 8px 12px;
  color: var(--text-color);
  border-radius: 6px;
  cursor: default;

  ${Td} {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  ${Td}:last-child {
    color: var(--secondary-text-color);
  }

  ${(p) =>
    p.active &&
    css`
      background-color: var(--box-shadow-color);
    `};
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 6px;
`;
