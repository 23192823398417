import React from "react";
import styled from "styled-components";
import { H2, Subtitle1 } from "melodies-source/Text";
import { InfoBars } from "Components/InfoBars";
import { NoData } from "Components/NoData";
import { RefreshIcon } from "Components/RefreshIcon";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import { Divider } from "Components/Divider";
import { Card } from "Components/Card";
import { useMobileMediaQuery } from "hooks/useMobileMediaQuery";
import { Spinner } from "melodies-source/Spinner";
import { FanDetailsCard } from "./FanDetailsCard";

export const Results: React.FC = () => {
  const { votes, votesLoading, votesError } = useArtistEventContext();
  const isMobile = useMobileMediaQuery();

  const items = votes
    ? Object.entries(votes.items || {})
        .map(([id, vote]) => {
          return {
            id: id,
            title: id === "vote" ? "Vote for your favorite" : vote.label,
            description:
              id === "vote"
                ? "We want you to be part of the show! Vote now for your favorite song you want us to perform next!"
                : undefined,
            list: Object.keys(vote.votes || {})
              .map((id) => ({
                label: vote.options[id],
                value: vote.votes[id],
                unit: {
                  singular: "vote",
                  plural: "votes",
                },
              }))
              .sort((a, b) => a.label.localeCompare(b.label)),
          };
        })
        .filter((a) => a.list.length > 0)
        .sort((a, b) => a.id.localeCompare(b.id))
    : [];

  if (items.length > 0) {
    const index = items.findIndex((item) => item.id === "vote");
    const item = items.splice(index, 1);
    items.unshift(item[0]);
  }

  const fullWidthQuestionCondition = (item) =>
    item.list.length >= 5 || isMobile;

  const fanDetailsCardCondition = (index, item, items) =>
    index === 0 &&
    item.list.length <= 4 &&
    (items.length > 1 ? fullWidthQuestionCondition(items[index + 1]) : true);

  return (
    <StyledCard isElevated>
      <H2>Results</H2>
      {votesLoading ? (
        <Spinner />
      ) : votesError ? (
        <RefreshIcon />
      ) : items.length > 0 ? (
        <QuestionsContainer>
          {items.map((item, index, items) => (
            <React.Fragment key={item.id}>
              <Question fullWidth={fullWidthQuestionCondition(item)}>
                {item.title && <Subtitle1>{item.title}</Subtitle1>}
                {item.description && (
                  <Description>{item.description}</Description>
                )}
                <InfoBars
                  key={index}
                  data={item.list}
                  twoCols={item.list.length >= 5}
                  mode="percentage"
                />
              </Question>
              {fanDetailsCardCondition(index, item, items) && !isMobile && (
                <FanDetailsCard />
              )}
              {index < items.length - 1 &&
                (isMobile ||
                  fullWidthQuestionCondition(item) ||
                  fullWidthQuestionCondition(items[index + 1]) ||
                  fanDetailsCardCondition(index, item, items) ||
                  (index > 1 &&
                    !fullWidthQuestionCondition(items[index - 1]) &&
                    !fullWidthQuestionCondition(item))) && <Divider />}
            </React.Fragment>
          ))}
        </QuestionsContainer>
      ) : (
        <NoData />
      )}
    </StyledCard>
  );
};
interface QuestionProps {
  fullWidth: boolean;
}

const Question = styled.div<QuestionProps>`
  width: ${({ fullWidth }) => (fullWidth ? 100 : 46)}%;

  ${Subtitle1} {
    color: var(--header-text-color);
  }
`;

const Description = styled.p`
  font-size: 14px;
  color: var(--text-color);
  line-height: 21px;
`;

const QuestionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 8%;
  row-gap: 20px;
  width: 100%;
`;

const StyledCard = styled(Card)`
  ${H2} {
    text-align: center;
    line-height: 21px;
    margin: 0 0 30px 0;
  }
`;
