import useUserIdToken from "./useUserIdToken";

export interface AiConcertResult {
  date: string;
  venue: string;
  address?: string;
  city?: string;
  state?: string;
  country?: string;
  timeZone: string;
  coordinates: [number, number];
}

export const useConcertsAi = () => {
  const userIdToken = useUserIdToken();

  const analyze = async (prompt: string): Promise<AiConcertResult[]> => {
    if (!userIdToken || !prompt) {
      return;
    }

    const response = await fetch(process.env.REACT_APP_CONCERTS_AI_PATH, {
      method: "POST",
      body: JSON.stringify({ prompt }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userIdToken}`,
      },
    });

    const json = await response.json();

    return json?.response ?? [];
  };

  return { analyze };
};
