export const GOOGLE_PLACES_SEARCH_TYPES = [
  "bar",
  "night_club",
  "point_of_interest",
  "route",
  "premise",
];
export const GOOGLE_PLACES_ESTABLISHMENT_TYPES = [
  "bar",
  "establishment",
  "night_club",
  "point_of_interest",
];

export const googlePrediction = async (input: string) => {
  const autocompleteService = new google.maps.places.AutocompleteService();

  const { predictions } = await autocompleteService.getPlacePredictions(
    { input },
    (predictions, status) => {
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
        return [];
      }
      return predictions;
    },
  );

  return predictions;
};

export const googleGeocoder = async (placeId: string) => {
  const geocoder = new google.maps.Geocoder();

  const { results } = await geocoder.geocode({ placeId, language: "en" });

  return results;
};

export const GOOGLE_MAP_PIN_ICON = {
  path: `M9 4C11.7614 4 14 6.23858 14 9C14 11.7614 11.7614 14 9 14C6.23858 14 4 11.7614 4 9C4 6.23858 6.23858 4 9 4ZM9 5C6.79086 5 5 6.79086 5 9C5 11.2091 6.79086 13 9 13C11.2091 13 13 11.2091 13 9C13 6.79086 11.2091 5 9 5Z
  M9.65773 21.7533L9 22.3276L8.34227 21.7533C2.81131 16.9237 0 12.7068 0 9C0 3.75066 4.09705 0 9 0C13.9029 0 18 3.75066 18 9C18 12.7068 15.1887 16.9237 9.65773 21.7533ZM1 8.92599C1 12.1521 3.63613 16.2106 9 21C14.3639 16.2106 17 12.1521 17 8.92599C17 4.27978 13.3839 1 9 1C4.61613 1 1 4.27978 1 8.92599Z`,
  fillOpacity: 1,
  fillColor: "#000000",
  scale: 1.4,
};

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_FIREBASE_APIKEY;

export interface TimeZoneResponse {
  status:
    | "OK"
    | "INVALID_REQUEST"
    | "OVER_DAILY_LIMIT"
    | "OVER_QUERY_LIMIT"
    | "REQUEST_DENIED"
    | "UNKNOWN_ERROR"
    | "ZERO_RESULTS";
  dstOffset?: number;
  errorMessage?: string;
  rawOffset?: number;
  timeZoneId?: string;
  timeZoneName?: string;
}

const getTimeZoneByGeocode = async (
  lat: number,
  lng: number,
): Promise<TimeZoneResponse> => {
  const requestUrl = new URL(
    "https://maps.googleapis.com/maps/api/timezone/json",
  );
  requestUrl.searchParams.set("location", `${lat},${lng}`);
  requestUrl.searchParams.set(
    "timestamp",
    Date.now().toString().substring(0, 10),
  );
  requestUrl.searchParams.set("key", GOOGLE_MAPS_API_KEY || "");

  const res = await fetch(requestUrl.toString());
  const json = await res.json();
  return json;
};

export const getTimeZoneIdByGeocode = async (lat: number, lng: number) => {
  const timeZoneResponse = await getTimeZoneByGeocode(lat, lng);

  let timeZoneId: string | undefined = undefined;
  if (timeZoneResponse.status === "OK") {
    ({ timeZoneId } = timeZoneResponse);
  }

  return timeZoneId;
};
