import {
  Button,
  DefaultAppLanding,
  DefaultLayout,
  PageContentWrapper,
  PageHeaderImage,
  useArtistContext,
} from "Components";
import { FlexColumn, FlexRow } from "Components/Flex";
import { Body1, H2, H3, H4, Li, LinkText } from "melodies-source/Text";
import { useIsMobile } from "melodies-source/utils";
import { GlobalStyles } from "styled";
import styled from "styled-components";
import { Card } from "melodies-source/Card";
import { TextInput } from "melodies-source/TextInput";
import { SvgSearch } from "melodies-source/Svgs/Search";
import { useEffect, useState } from "react";
import DropdownMenu from "Components/DropdownMenu";
import { SvgDropdown } from "melodies-source/Svgs/Dropdown";
import { useArtistSurveys } from "hooks/useArtistSurveys";
import { SvgLink } from "melodies-source/Svgs/Link";
import { SvgMoreLarge } from "melodies-source/Svgs/MoreLarge";
import { ReactComponent as SvgCircleChecked } from "assets/svg/circle-checked.svg";
import { ReactComponent as SvgCircleBlock } from "assets/svg/circle-block.svg";
import { ReactComponent as SvgClipboardEdit } from "assets/svg/clipboard-edit.svg";
import { ReactComponent as SvgRefresh } from "assets/svg/refresh.svg";
import { ReactComponent as SvgFileText } from "assets/svg/file-text.svg";
import { SvgIndividual } from "melodies-source/Svgs/Individual";
import { DateTime } from "luxon";
import { SurveyStatus, SurveyTypesenseDocument } from "@max/common/dist/setfan";
import { SvgGear } from "melodies-source/Svgs/Gear";
import { SvgDelete } from "melodies-source/Svgs/Delete";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useHistory } from "react-router-dom";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import {
  ConfirmFeatureModal,
  GetFeaturedLinkModal,
  GetLinkModal,
} from "./Components/GetLinkModal";
import { ReactComponent as SvgStar } from "./Components/SvgStar.svg";
import { ReactComponent as SvgFeatured } from "./Components/SvgFeatured.svg";
import { getPublishFunction } from "@max/common/dist/functions/setfan/publish";
import { getFunctions } from "firebase/functions";
import { addToast } from "melodies-source/Toast";
import { Link } from "melodies-source/Link";
import {
  query,
  collection,
  getFirestore,
  where,
  updateDoc,
} from "firebase/firestore";
import { useCollection } from "react-firebase-hooks/firestore";
import { TemplateProvider, useTemplates } from "./Components/Templates";
import { formatNumber } from "Utils/format";
import { SvgAddAlt } from "melodies-source/Svgs/AddAlt";
import { SetFanKnockout } from "assets/logos";
import { Spinner } from "melodies-source/Spinner";
import animationData from "assets/lotties/fan-marketing-animation.json";
import { IconBroadCast, IconGearAlt, IconPalette, IconPulse } from "assets/svg";

const publish = getPublishFunction(getFunctions());

const SortByOptions = [
  "Newest",
  "Oldest",
  "Published",
  "Draft",
  "Ended",
] as const;
type SortBy = (typeof SortByOptions)[number];

const SurveyMenuOptions = [
  "fanView",
  "editSurvey",
  "setFeatured",
  "endSurvey",
  "resumeSurvey",
  "deleteSurvey",
];
type SurveyMenuOption = (typeof SurveyMenuOptions)[number];

export const Dashboard = () => {
  return (
    <TemplateProvider>
      <DashboardInternal />
    </TemplateProvider>
  );
};

const DashboardInternal = () => {
  const isMobile = useIsMobile();
  const history = useHistory();

  const { id } = useArtistContext();
  const { setModal } = useTemplates();

  useEffect(() => {
    if (history.location.state && history.location.state["create"] === true) {
      setModal({ selectCategory: true });
    }
  }, []);

  const { lockScroll } = useScrollLockContext();

  const CardHeader = isMobile ? H3 : H2;

  const [search, setSearch] = useState("");
  const [sortBy, setSortBy] = useState<SortBy>(SortByOptions[0]);
  const [surveyUpdating, setSurveyUpdating] = useState("");
  const [featuredLinkOpen, setFeaturedLinkOpen] = useState(false);
  const [confirmFeaturedOpen, setConfirmFeaturedOpen] = useState(null);
  const [subdomains] = useCollection(
    query(
      collection(getFirestore(), `artist_subdomain`),
      where("artistGroupId", "==", id),
    ),
  );

  const featuredDoc = subdomains?.docs?.[0];

  const hasFeaturedSupport = featuredDoc?.exists();

  const featuredId = featuredDoc?.data()?.surveyId;

  const surveyFilterBy = () => {
    switch (sortBy) {
      case "Draft":
        return "status:=[draft,unpublished]";
      case "Ended":
        return "status:=[completed,cancelled]";
      case "Published":
        return "status:=[published,live]";
      // sort by handled below
      case "Newest":
      case "Oldest":
        break;
      default:
        throw new Error(`invalid sortBy selection: ${sortBy}`);
    }
  };

  const filterBy = [
    surveyFilterBy(),
    "surveyType:=1",
    `artistGroupId:${id}`,
    `status:!=deleted`,
  ].filter((v) => !!v);

  const {
    documents: surveys,
    loading: loadingSurveys,
    forceReload,
  } = useArtistSurveys({
    sortBy: sortBy === "Oldest" ? "createdAt:asc" : "createdAt:desc",
    filterBy: filterBy.join(" && "),
    query: search,
    queryBy: "title,status,description",
  });

  const updateStatus = async (
    surveyId: string,
    status: Exclude<SurveyStatus, "unpublished" | "published" | "cancelled">,
  ) => {
    setSurveyUpdating(surveyId);
    try {
      const {
        data: { success },
      } = await publish({ surveyId, status });

      if (!success) {
        throw new Error("publish returned unsuccessfully");
      } else {
        forceReload();
      }
    } catch (err) {
      console.error(err);
      addToast(
        `Unable to ${
          status === "live"
            ? "resume"
            : status === "completed"
            ? "end"
            : "delete"
        } survey. Please try again.`,
        "error",
      );
    } finally {
      setSurveyUpdating("");
    }
  };

  const currentTitle = surveys.find(
    (tSurvey) => tSurvey.surveyId === featuredId,
  )?.title;

  const updateFeatured = () => {
    updateDoc(featuredDoc.ref, {
      surveyId: confirmFeaturedOpen,
    })
      .then(() => {
        addToast(
          `${featuredDoc.id}.set.fan now points to ${currentTitle}`,
          "success",
        );
      })
      .catch(() => {
        addToast(`Updating featured event failed.`, "error");
      });
    setConfirmFeaturedOpen(null);
  };

  const onSurveyMenuSelect = async (
    menuItem: SurveyMenuOption,
    surveyId: string,
  ) => {
    switch (menuItem) {
      case "fanView":
        window.open(`${process.env.REACT_APP_SET_FAN_URL}/${surveyId}`);
        break;
      case "editSurvey":
        history.push(`surveys/${surveyId}`);
        break;
      case "endSurvey":
        await updateStatus(surveyId, "completed");
        break;
      case "resumeSurvey":
        await updateStatus(surveyId, "live");
        break;
      case "deleteSurvey":
        await updateStatus(surveyId, "deleted");
        break;
      case "setFeatured":
        setConfirmFeaturedOpen(surveyId);
        break;
      default:
        throw new Error(`invalid menu selection for survey: ${menuItem}`);
    }
  };

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      <PageHeaderImage app="fan" />
      <PageContentWrapper
        title={
          <SetFanKnockout
            style={{ ...(!surveys.length && { alignSelf: "center" }) }}
          />
        }
        action={
          !!surveys.length && (
            <CreateSurveyButton
              variant="primary"
              leftIcon={<SvgAddAlt />}
              onClick={() => {
                lockScroll();
                setModal({ selectCategory: true });
              }}
              style={{
                backgroundColor: "var(--set-fan-primary)",
                color: "var(--main-color)",
              }}
            >
              Create Survey
            </CreateSurveyButton>
          )
        }
      >
        {loadingSurveys ? (
          <Spinner color="var(--set-fan-primary)" />
        ) : !surveys.length ? (
          <DefaultAppLanding
            app="fan"
            title="Turn Followers Into Contacts"
            body={
              <FlexColumn gap="20px">
                <Body1>
                  SET.Fan empowers artists to collect fan contact info and
                  insights via custom sign-up forms, surveys, sweepstakes and
                  more.
                </Body1>
                <BulletList $iconColor="var(--set-fan-primary)">
                  <IconGearAlt />
                  <Li>Reclaim control from social media algorithms</Li>
                  <IconBroadCast />
                  <Li>
                    Easily promotable on Instagram, TikTok and livestreams
                  </Li>
                  <IconPulse />
                  <Li>Gather insights to power your artist marketing</Li>
                  <IconPalette />
                  <Li>Create a sweepstakes or survey in 2 minutes</Li>
                </BulletList>
              </FlexColumn>
            }
            actionText="Get Started"
            onAction={() => {
              lockScroll();
              setModal({ selectCategory: true });
            }}
            video={{
              url: "https://storage.googleapis.com/set-live-stage.appspot.com/artist-portal/video/set-fan-promotion.mp4",
            }}
            animation={animationData}
          />
        ) : (
          <SurveysCard isElevated>
            <CardHeader
              style={{ color: "var(--main-color)", marginBottom: 28 }}
            >
              Surveys
            </CardHeader>
            <CardActions>
              <SearchInput
                leftIcon={<SvgSearch />}
                value={search}
                placeholder="Search for a survey"
                onChange={(text) => setSearch(text)}
              />
              <div>
                {featuredId && (
                  <Link
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginRight: "20px",
                    }}
                    onClick={() => setFeaturedLinkOpen(true)}
                  >
                    <SvgStar style={{ marginRight: "5px" }} />
                    Get Featured Link
                  </Link>
                )}
                <DropdownMenu
                  labelAs={H4}
                  options={SortByOptions.map((opt) => ({
                    label: opt,
                    value: opt,
                  }))}
                  icon={<SvgDropdown />}
                  onClick={(val) => setSortBy(val)}
                  value={sortBy}
                  forceSide={isMobile ? "right" : "left"}
                />
              </div>
            </CardActions>
            <SurveyList>
              {loadingSurveys ? (
                <SurveyCardLoader />
              ) : (
                surveys.map((survey) =>
                  surveyUpdating === survey.id ? (
                    <SurveyCardLoader key={survey.id} />
                  ) : (
                    <SurveyCard
                      key={survey.id}
                      survey={survey}
                      onSurveyMenuSelect={onSurveyMenuSelect}
                      hasFeaturedSupport={hasFeaturedSupport}
                      featuredId={featuredId}
                      featuredUrl={`https://${featuredDoc?.id}.set.fan`}
                    />
                  ),
                )
              )}
            </SurveyList>
          </SurveysCard>
        )}
      </PageContentWrapper>
      <GetFeaturedLinkModal
        isOpen={featuredLinkOpen}
        onClose={() => setFeaturedLinkOpen(false)}
        title={currentTitle}
        link={`https://${subdomains?.docs?.[0]?.id}.set.fan`}
      />
      <ConfirmFeatureModal
        isOpen={confirmFeaturedOpen}
        onClose={() => setConfirmFeaturedOpen(false)}
        onConfirm={updateFeatured}
        currentTitle={currentTitle}
        link={`${subdomains?.docs?.[0]?.id}.set.fan`}
      />
    </DefaultLayout>
  );
};

const SurveyCard = ({
  survey,
  onSurveyMenuSelect,
  hasFeaturedSupport,
  featuredId,
  featuredUrl,
}: {
  survey: SurveyTypesenseDocument;
  onSurveyMenuSelect: (val: SurveyMenuOption, surveyId: string) => void;
  hasFeaturedSupport: boolean;
  featuredId: string;
  featuredUrl?: string;
}) => {
  const { id: artistGroupId } = useArtistContext();
  const [getLinkModalOpen, setGetLinkModalOpen] = useState(false);

  const isActive = survey.status === "published" || survey.status === "live";
  const color = isActive ? "#1B0076" : "#999";
  const hasResults = survey.responses > 0;
  const isFeatured = survey.id === featuredId;

  const history = useHistory();

  return (
    <StyledSurveyCard isElevated>
      <SurveyDropdownMenu
        disableAnimation
        options={SurveyMenu(survey, hasFeaturedSupport, featuredId)}
        icon={<SvgMoreLarge />}
        onClick={(selection) => onSurveyMenuSelect(selection, survey.id)}
        forceSide="left"
      />
      <SurveyIconContainer>
        <SvgClipboardEdit />
      </SurveyIconContainer>
      <SurveyCardContent>
        <H2>{survey.internalTitle || survey.title}</H2>
        <FlexRow style={{ marginBottom: 5 }}>
          <H3>
            {formatNumber(survey.responses)} Response
            {survey.responses === 1 ? "" : "s"} •
            {survey.responses === 0
              ? " Awaiting Responses"
              : ` Last Response: ${DateTime.fromMillis(
                  survey.lastResponse,
                ).toRelative()}`}
          </H3>
        </FlexRow>
        <FlexRow style={{ alignItems: "center" }}>
          <SurveyStatusComp status={survey.status ?? "unpublished"} />
          {isFeatured && <SvgFeatured style={{ marginLeft: "10px" }} />}
        </FlexRow>
      </SurveyCardContent>
      <SurveyCardActions>
        <Button
          onClick={() => {
            if (isActive || survey.status === "completed") {
              history.push(
                `/${artistGroupId}/reporting/set-fan/surveys/${survey.surveyId}`,
              );
            }
          }}
          variant="primary"
          style={{ marginBottom: 10, width: 156 }}
          disabled={!hasResults}
        >
          {isActive || survey.status === "completed"
            ? "View Results"
            : "Publish"}
        </Button>
        <FlexRow
          style={{
            alignItems: "center",
            cursor: isActive ? "pointer" : "not-allowed",
          }}
        >
          <SvgLink
            style={{
              height: 16,
              width: 16,
              transform: "rotate(-45deg)",
              color,
              marginRight: 2,
            }}
          />
          <LinkText
            as="a"
            style={{
              color,
              textDecoration: "none",
              pointerEvents: !isActive ? "none" : "auto",
            }}
            onClick={() => setGetLinkModalOpen(true)}
          >
            Get Link
          </LinkText>
          <GetLinkModal
            isOpen={getLinkModalOpen}
            onClose={() => setGetLinkModalOpen(false)}
            surveyId={survey.id}
            featuredUrl={isFeatured ? featuredUrl : null}
          />
        </FlexRow>
      </SurveyCardActions>
    </StyledSurveyCard>
  );
};

const SurveyCardLoader = () => (
  <StyledSurveyCard isElevated>
    <Skeleton circle width={88} height={88} style={{ marginRight: 30 }} />
    <FlexColumn style={{ marginRight: "auto" }}>
      <Skeleton
        width={300}
        count={3}
        wrapper={(props) => (
          <div style={{ lineHeight: "1.5rem" }}>{props.children}</div>
        )}
      />
    </FlexColumn>
    <Skeleton height={78} width={150} />
  </StyledSurveyCard>
);

const SurveyDropdownMenu = styled(DropdownMenu)`
  position: absolute;
  top: 12px;
  right: 11px;
`;

const fanView = {
  icon: <SvgIndividual style={{ marginRight: 12 }} />,
  label: "Fan View",
  value: "fanView",
};
const editSurvey = {
  icon: <SvgGear style={{ marginRight: 12 }} />,
  label: "Edit",
  value: "editSurvey",
};
const endSurvey = {
  icon: <SvgCircleBlock style={{ marginRight: 12 }} />,
  label: "End Survey",
  value: "endSurvey",
};
const resumeSurvey = {
  icon: <SvgRefresh style={{ marginRight: 12 }} />,
  label: "Resume Survey",
  value: "resumeSurvey",
};
const deleteSurvey = {
  icon: <SvgDelete style={{ marginRight: 12, color: "#E45C52" }} />,
  label: "Delete",
  value: "deleteSurvey",
  labelStyles: ["color: #E45C52"],
};
const setFeatured = {
  icon: <SvgStar style={{ marginRight: 12 }} />,
  label: "Feature This Survey",
  value: "setFeatured",
};

const SurveyMenu = (
  survey: SurveyTypesenseDocument,
  hasFeaturedSupport: boolean,
  featuredId: string,
  disableEdit?: boolean,
) => {
  let extras = [];
  if (hasFeaturedSupport && featuredId !== survey.id) {
    extras.push(setFeatured);
  }
  let menu = [
    fanView,
    !disableEdit && editSurvey,
    endSurvey,
    ...extras,
    deleteSurvey,
  ];
  if (survey.status === "draft" || survey.status === "unpublished") {
    menu = [!disableEdit && editSurvey, deleteSurvey];
  }

  if (survey.status === "completed" || survey.status === "cancelled") {
    menu = [fanView, !disableEdit && editSurvey, resumeSurvey, deleteSurvey];
  }
  menu = menu.filter((m) => m);
  return menu;
};

const SurveyStatusComp = ({ status }: { status: SurveyStatus }) => {
  switch (status) {
    case "draft":
    case "unpublished":
      return (
        <>
          <SvgFileText style={{ marginRight: 3 }} />
          <H4 style={{ color: "#FC6716" }}>DRAFT</H4>
        </>
      );
    case "live":
    case "published":
      return (
        <>
          <SvgCircleChecked style={{ color: "#1DAB98", marginRight: 3 }} />
          <H4 style={{ color: "#1DAB98" }}>PUBLISHED</H4>
        </>
      );
    case "completed":
    case "cancelled":
      return (
        <>
          <SvgCircleBlock style={{ marginRight: 3 }} />
          <H4 style={{ color: "#999" }}>SURVEY ENDED</H4>
        </>
      );
  }
};

const SurveyIconContainer = styled(FlexRow)`
  background: #f6f9fb;
  border-radius: 50%;
  margin-right: 30px;

  justify-content: center;
  align-items: center;

  svg path:last-child {
    fill: var(--set-fan-primary);
  }

  ${({ theme }) => theme.media.mobile} {
    width: 75px;
    height: 75px;
    margin-right: 0;
  }
`;

const SurveyList = styled(FlexColumn)`
  & > div:nth-last-child(n + 2) {
    margin-bottom: 20px;
  }
`;

const CreateSurveyButton = styled(Button)`
  ${({ theme }) => theme.media.mobile} {
    width: auto;
    padding: 10px 16px;
  }
`;

const CardActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 31px;

  & > div:nth-child(2) {
    display: flex;
  }

  ${({ theme }) => theme.media.mobile} {
    flex-direction: column;
    width: 100%;
    gap: 20px;

    & > div:nth-child(2) {
      width: 100%;
      justify-content: space-between;
    }
  }
`;

const SearchInput = styled(TextInput)`
  ${({ theme }) => theme.media.desktop} {
    width: 380px;
  }
`;

const StyledSurveyCard = styled(Card)`
  padding: 33px 45px;
  display: flex;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.16);
  position: relative;

  ${({ theme }) => theme.media.mobile} {
    padding: 24px 32px;
    flex-direction: column;
    gap: 15px;
  }
`;

const SurveysCard = styled(Card)`
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: white;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.15);
  padding: 29px 30px;
  border: none;

  ${({ theme }) => theme.media.mobile} {
    width: 100%;
    padding: 20px;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  }
`;

const SurveyCardContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  gap: 4px;
  ${H2} {
    margin-right: 12px;
  }

  ${({ theme }) => theme.media.mobile} {
    align-items: center;
    margin-right: 0;
    ${H2} {
      margin-right: 0;
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
    }
    ${H3} {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      text-align: center;
    }
  }
`;

const SurveyCardActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 15px;
    width: 100%;

    button {
      width: 100% !important;
    }
  }
`;

export const BulletList = styled.div<{ $iconColor?: string }>`
  display: grid;
  grid-template-columns: 20px 1fr;
  column-gap: 10px;
  row-gap: 16px;
  color: #333333;
  ${Li} {
    line-height: 20px;
  }
  svg {
    color: ${(p) => p.$iconColor};
    width: 20px;
    height: 20px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    column-gap: 8px;
    row-gap: 12px;
    svg {
      width: 18px;
      height: 18px;
    }
    ${Li} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;
