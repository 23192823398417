import { MutableDateConcert } from "Components";
import {
  collection,
  CollectionReference,
  getFirestore,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { useRef } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { Data } from "react-firebase-hooks/firestore/dist/firestore/types";

export type ConcertData<T = Timestamp> = Data<
  MutableDateConcert<T>,
  "id",
  "ref"
>;

export const useUpcomingConcerts = (artistGroupId: string) => {
  const now = useRef(Timestamp.fromDate(new Date()));

  const [concerts, loading] = useCollectionData<
    MutableDateConcert,
    "id",
    "ref"
  >(
    query(
      collection(
        getFirestore(),
        "artist_groups",
        artistGroupId,
        "concerts",
      ) as CollectionReference<MutableDateConcert>,
      where("date", ">", now.current),
      where("deletedAt", "==", null),
    ),
    {
      idField: "id",
      refField: "ref",
    },
  );

  return [concerts, loading] as const;
};
