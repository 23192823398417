import { DefaultLayout, PageTitle, useArtistContext } from "Components";
import styled from "styled-components";
import {
  ArtistName,
  ArtistImage,
  ArtistTeammates,
  ArtistQrCode,
  ArtistLinks,
  ArtistConcerts,
  Advanced,
} from "./Components";
import ArtistIntegrations from "./Components/ArtistIntegrations";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const Settings = () => {
  const { customApp } = useCustomAppContext();
  const { isGlobalAdmin } = useArtistContext();
  return (
    <DefaultLayout>
      <Content>
        <Header>Settings</Header>
        <ArtistName />
        <ArtistImage />
        <ArtistQrCode />
        <ArtistConcerts />
        {isGlobalAdmin && <Advanced />}
        {!customApp && <ArtistLinks />}
        <ArtistTeammates />
        {!customApp && <ArtistIntegrations />}
      </Content>
    </DefaultLayout>
  );
};

const Header = styled(PageTitle)`
  color: var(--header-text-color);
  margin-bottom: 30px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 20px;
  }
`;

const Content = styled.div`
  padding: 40px;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px;
  }
`;
