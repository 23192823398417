import React from "react";
import styled from "styled-components";
import { Body1, Body2, H1, H2, H3 } from "melodies-source/Text";
import { RefreshIcon } from "Components/RefreshIcon";
import { Spinner } from "melodies-source/Spinner";
import { Item } from "./Item";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import { Card } from "Components/Card";
import { FlexColumn, FlexRow } from "Components/Flex";
import { Divider } from "Components/Divider";
import { useCustomAppContext } from "contexts/CustomAppContext";

export const StatsMobile: React.FC = () => {
  const {
    entriesTotals,
    entriesLoading,
    entriesError,
    clicks,
    clicksLoading,
    clicksError,
    visits,
    visitsLoading,
    visitsError,
  } = useArtistEventContext();
  const { customApp } = useCustomAppContext();
  const eventAudiencePlural = customApp?.event.audience.plural || "Fans";

  const totalClicks =
    clicks?.reduce((total, elem) => total + elem.total, 0) ?? 0;

  return (
    <StyledCard isElevated>
      <FlexColumn xCenter>
        <H3>Total Entries</H3>
        {entriesLoading ? (
          <StyledSpinner />
        ) : entriesError ? (
          <StyledRefreshIcon />
        ) : (
          <H1>{entriesTotals?.all}</H1>
        )}
        <Divider />
        <StyledFlexRow>
          <Item
            label="Total Visits"
            total={visits?.total ?? 0}
            description="Total visits to
your experience"
            loading={visitsLoading}
            error={visitsError}
          />
          <Item
            label={`Unique ${eventAudiencePlural}`}
            total={visits?.unique ?? 0}
            description={`${eventAudiencePlural} that visited,
counted once.`}
            loading={visitsLoading}
            error={visitsError}
          />
          <Item
            label="Clicks"
            total={totalClicks}
            description={`Times ${eventAudiencePlural.toLocaleLowerCase()} clicked 
into your links`}
            loading={clicksLoading}
            error={clicksError}
          />
        </StyledFlexRow>
      </FlexColumn>
    </StyledCard>
  );
};

const StyledRefreshIcon = styled(RefreshIcon)`
  margin-top: 5px;
  color: var(--main-color);
`;

const StyledSpinner = styled(Spinner)`
  margin: 20px 0 15px;
  transform: scale(0.7);
`;

const StyledFlexRow = styled(FlexRow)`
  gap: 20px;
`;

const StyledCard = styled(Card)`
  h3,
  h4,
  p {
    color: var(--secondary-text-color);
  }

  ${H1} {
    color: var(--main-color);
  }
  ${Divider} {
    margin-bottom: 24px;
  }
`;
