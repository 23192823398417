import { MenuMode, useMenuContext } from "Components";
import styled, { css } from "styled-components";
import { AppLinks } from "./AppLinks";
import {
  ArtistGroup,
  SideMenuAvatarButton,
  SideMenuToggleBackdrop,
  SideMenuToggleButton,
} from "./ArtistGroup";
import { NavLinks } from "./NavLinks";
import { Teammates } from "./Teammates";
import { AppSecondaryLinks } from "./AppSecondaryLinks";
import { useMenuBarHeight } from "hooks/useMenuBarHeight";

export const SideBar = () => {
  const { menuMode } = useMenuContext();
  const { menuBarHeight } = useMenuBarHeight();

  return (
    <Container menuMode={menuMode} menuBarHeight={menuBarHeight}>
      <ArtistGroup />
      <NavLinks />
      <AppLinks />
      <AppSecondaryLinks />
      {menuMode === "expanded" && <Teammates />}
    </Container>
  );
};

interface ContainerProps {
  menuMode: MenuMode;
  menuBarHeight: number;
}

const Container = styled.div<ContainerProps>`
  width: 25vw;
  flex-shrink: 0;
  box-shadow: inset -1px 0 0 0 var(--box-shadow-color);
  z-index: 15;
  background: #fff;
  transition: 300ms ease;
  position: relative;

  ${({ theme }) => theme.mediaQueries.mobile} {
    position: fixed;
    top: ${(p) => p.menuBarHeight}px;
    bottom: 0;
    left: 0;
    width: 100%;
    transform: ${(p) => (p.menuMode === "expanded" ? "" : "translateX(-100%)")};
    transition: transform ease-in-out 100ms;
    overflow: auto;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    width: ${(p) => (p.menuMode === "expanded" ? "300px" : "85px")};
    &:hover {
      ${SideMenuToggleBackdrop} {
        opacity: 1;
      }
      ${SideMenuAvatarButton} {
        opacity: 1;
      }
      ${SideMenuToggleButton} {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
      }
    }
  }

  ${({ theme }) => {
    if (!theme.custom) return null;
    const { background, color } = theme.custom.layout.sideBar;
    return css`
      background: ${background};
      color: ${color};
    `;
  }}
`;
