import styled from "styled-components";
import { useRouteMatch } from "react-router-dom";
import { LinkItem, Link } from "./Components/Link";
import { ReactComponent as SvgDashboard } from "assets/svg/dashboard2.svg";
import { SvgMail } from "melodies-source/Svgs/Mail";
import { SvgChart } from "melodies-source/Svgs/Chart";

export const NavLinks = () => {
  let {
    params: { artistId },
  } = useRouteMatch(`/:artistId`) as { params: { artistId: string } };

  const links: LinkItem[] = [
    {
      icon: <SvgDashboard />,
      label: "Dashboard",
      to: `/${artistId}/`,
      exact: true,
    },
    {
      icon: <SvgMail />,
      label: "Contact List",
      to: `/${artistId}/fans`,
    },
    {
      icon: <SvgChart />,
      label: "Reporting",
      to: `/${artistId}/reporting`,
    },
  ];
  return (
    <Container>
      {links.map((link) => (
        <Link key={link.label} {...link} />
      ))}
    </Container>
  );
};
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 20px 16px 0;
`;
