import { ReactComponent as TemplateIcon } from "assets/svg/coverSong.svg";
import { SurveyTemplate } from "../../types";

export const coverSong: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Cover Song",
  description:
    "Enable your fans to vote for the cover song you'll be trying on today's live.",
  templateId: "coverSong",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Cover Song",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "Which song should we cover?",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-livestream-19e70a"
        : "pp-livestream-e96e6e",
    fields: [
      {
        name: "date",
        type: "text",
        label: "When does your livestream take place?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.date ? false : "This field cannot be left blank",
      },
    ],
  },
};
