import { FlexColumn } from "Components/Flex";
import { ProfilingQuestions as ProfilingQuestionsBase } from "Routes/SetFan/slices/ProfilingQuestions";
import styled from "styled-components";

export const ProfilingQuestions = () => {
  return (
    <Container>
      <ProfilingQuestionsBase />
    </Container>
  );
};

const Container = styled(FlexColumn)`
  ${({ theme }) => theme.mediaQueries.desktop} {
    padding-bottom: 44px;
  }
`;
