import { Button } from "Components";
import { Body1, H2 } from "melodies-source/Text";
import styled, { useTheme } from "styled-components";
import { useHistory } from "react-router-dom";
import { SvgRightLarge } from "melodies-source/Svgs/RightLarge";
import { FlexColumn, FlexRow } from "Components/Flex";
import { SelectionCard } from "Components/SelectionCard";
import { useCreateSurveyBuilder } from "Routes/SetFan/hooks/useCreateSurveyBuilder";
import { useTemplates } from "./TemplateContext";
import { surveyTemplates } from "./defaults";
import {
  ResponsiveModal,
  ResponsiveFooterActions,
} from "Components/ResponsiveModal";

interface CreatorGoalsProps {
  onClose: VoidFunction;
  onSelect: VoidFunction;
}

export const SelectCategoryModal = ({
  onClose,
  onSelect,
}: CreatorGoalsProps) => {
  const history = useHistory();
  const { create, isLoading } = useCreateSurveyBuilder();
  const { colors } = useTheme();
  const { templateCategory, setTemplateCategory, modal } = useTemplates();

  const templateCategories = Object.values(surveyTemplates).map(
    ({ templates, ...rest }) => rest,
  );

  return (
    <ResponsiveModal
      isOpen={modal.selectCategory}
      onClose={onClose}
      variant="large"
      header={
        <>
          <H2>Select a Category</H2>
          <Body1>
            Please share your goals, and we'll provide tailored survey templates
            with relevant questions.
          </Body1>
        </>
      }
    >
      <ListContainer>
        {templateCategories.map((goal) => (
          <SelectionCard
            {...{
              ...goal,
              selected: templateCategory === goal.key,
              onClick: () => {
                setTemplateCategory(goal.key);
              },
            }}
          />
        ))}
      </ListContainer>
      <ResponsiveFooterActions>
        <Button
          variant="tertiary"
          loading={isLoading}
          style={{ color: colors.main }}
          onClick={async () => {
            const { survey } = await create();
            history.push(`surveys/${survey.id}/wizard`);
          }}
        >
          Build My Own Survey
        </Button>

        <PrimaryActionsContainer>
          <Button
            variant="tertiary"
            disabled={isLoading}
            onClick={() => onClose()}
            style={{ color: colors.main }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            rightIcon={<SvgRightLarge />}
            onClick={onSelect}
            disabled={!templateCategory || isLoading}
            style={{ minWidth: 199 }}
          >
            See Templates
          </Button>
        </PrimaryActionsContainer>
      </ResponsiveFooterActions>
    </ResponsiveModal>
  );
};

export const PrimaryActionsContainer = styled(FlexRow)`
  flex-grow: 1;
  justify-content: flex-end;
  gap: 12px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
    gap: 12px;
  }
`;

const ListContainer = styled(FlexColumn)`
  gap: 16px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 12px;
  }
`;
