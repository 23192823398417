import { useLayoutEffect, useState } from "react";

export const useMenuBarHeight = () => {
  const [menuBarHeight, setMenuBarHeight] = useState(0);

  useLayoutEffect(() => {
    const handleResize = () => {
      setMenuBarHeight(document.getElementById("menu-bar").offsetHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { menuBarHeight };
};
