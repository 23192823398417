import { ReactComponent as Megaphone } from "assets/svg/megaphone.svg";
import { SurveyTemplate } from "../../types";

export const newReleaseAnnouncement: SurveyTemplate = {
  icon: <Megaphone />,
  title: "New Release Announcement",
  description:
    "Give fans the opportunity to sign up to be among the first to hear about a release.",
  templateId: "newReleaseAnnouncement",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "New Release Announcement",
        },
      },
    },
    surveyQuestions: [
      {
        id: "text_1",
        type: "text",
        variants: { mobile: "Headline4", desktop: "Headline3" },
        label: {
          en: "Enter your email to stay updated.",
        },
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-sweeps---n-ff6398"
        : "pp-new-releas-cd4719",
    fields: [
      {
        name: "format",
        type: "radio",
        label: "What format is this release? (optional)",
        options: ["Single", "EP", "Album"],
        defaultValue: "",
      },
    ],
  },
};
