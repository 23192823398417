import React from "react";
import { GetAccountInfoUserResponse } from "firebase-admin/lib/auth/user-record";
import { Body1 } from "melodies-source/Text";
import { ReactComponent as MessageIcon } from "assets/svg/message-single.svg";
import { ReactComponent as QRCodeIcon } from "assets/svg/qr-code.svg";
import styled from "styled-components";
import { DateTime } from "luxon";

interface Props {
  mfaInfo: GetAccountInfoUserResponse["mfaInfo"];
}

export const FactorDetail: React.FC<Props> = ({ mfaInfo }) => {
  const factor = mfaInfo[0];

  return (
    <>
      <Body1>A second factor method was already set up.</Body1>
      <Method>
        <div>
          <Body1>
            <b>Method: </b>
            {factor.phoneInfo ? "SMS Message" : "Authenticator App"}
          </Body1>
          {factor.phoneInfo && (
            <Body1>
              <b>Phone: </b>
              {factor.phoneInfo}
            </Body1>
          )}
          <Body1>
            <b>Date: </b>
            {DateTime.fromISO(factor.enrolledAt).toLocaleString(
              DateTime.DATE_MED,
            )}
          </Body1>
        </div>
        <MethodIcon>
          {factor.phoneInfo ? <MessageIcon /> : <QRCodeIcon />}
        </MethodIcon>
      </Method>
    </>
  );
};

const Method = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  gap: 10px;
`;

const MethodIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 50%;
  color: var(--main-color);

  svg {
    width: 40px;
    height: 40px;
  }
`;
