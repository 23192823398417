import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
  useReducer,
} from "react";
import { decode } from "ngeohash";
import { useTypesenseProxyContext } from "contexts/TypesenseProxyContext";
import { useArtistContext } from "Components";
import { DateTime } from "luxon";
import isEqual from "lodash/isEqual";
import extend from "lodash/extend";

interface Filters {
  age?: any;
  gender?: any;
  location?: any;
  source?: any;
  allSources?: any;
  dateAddedStart?: DateTime;
  dateAddedEnd?: DateTime;
  updatedStart?: DateTime;
  updatedEnd?: DateTime;
}

export const FanTableContext = createContext(undefined);

export const reducer = (currentState: Filters, payload: Filters) => {
  if (isEqual(currentState, payload)) {
    return currentState;
  }
  return extend({}, currentState, payload);
};

export const FanTableProvider = ({ children, ...props }) => {
  const { proxySearch } = useTypesenseProxyContext();
  const [locationText, setLocationText] = useState("");
  const [sourceText, setSourceText] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [sort, setSortInternal] = useState({ field: "name", direction: "asc" });
  const [fans, setFans] = useState([]);

  const { id: groupId } = useArtistContext();

  const [filters, dispatch] = useReducer(reducer, {});
  const setFilters = (update: { [key: string]: any }) => dispatch(update);

  const [found, setFound] = useState(0);

  const filterCount = Object.keys(filters).reduce((acc, v) => {
    let emptyFilter =
      v === "source" || v === "location" ? !filters[v]?.length : false;
    return !!filters[v] && !emptyFilter ? acc + 1 : acc;
  }, 0);

  const clear = () => {
    setFilters({
      age: null,
      gender: null,
      location: null,
      source: null,
      allSources: null,
      dateAddedStart: null,
      dateAddedEnd: null,
      updatedStart: null,
      updatedEnd: null,
    });
  };

  const filter_by = useMemo(() => {
    let ageFilters = "",
      genderFilters = "",
      locationFilters = "",
      sourceFilters = "",
      dateStartFilters = "",
      dateEndFilters = "",
      updatedStartFilters = "",
      updatedEndFilters = "";

    if (filters.age) {
      ageFilters = `age:[${filters.age.min}..${filters.age.max}]`;
    }

    if (filters.gender) {
      genderFilters = `gender:[${filters.gender}]`;
    }

    if (filters?.location?.length) {
      locationFilters = filters.location
        .map((l) => ({ ...decode(l.geo), distance: l.distance }))
        .map(
          (coords) =>
            `coords:(${coords.latitude}, ${coords.longitude}, ${coords.distance} mi)`,
        )
        .join(" || ");
    }

    if (filters?.source?.length) {
      if (filters.allSources) {
        sourceFilters = filters.source
          .map((s) => `sources:${s?.id}`)
          .join(" && ");
      } else {
        sourceFilters = `sources:[${filters.source
          .map((s) => s.id)
          .join(",")}]`;
      }
    }

    if (filters.dateAddedStart) {
      dateStartFilters = `createdAt:>=${filters.dateAddedStart.toMillis()}`;
    }
    if (filters.dateAddedEnd) {
      dateEndFilters = `createdAt:<=${filters.dateAddedEnd.toMillis()}`;
    }
    if (filters.updatedStart) {
      updatedStartFilters = `updatedAt:>=${filters.updatedStart.toMillis()}`;
    }
    if (filters.updatedEnd) {
      updatedEndFilters = `updatedAt:<=${filters.updatedEnd.toMillis()}`;
    }

    return [
      `groupId:${groupId}`,
      ageFilters,
      genderFilters,
      locationFilters,
      sourceFilters,
      dateStartFilters,
      dateEndFilters,
      updatedStartFilters,
      updatedEndFilters,
    ]
      .filter((filterOption) => filterOption.length)
      .join(" && ");
  }, [
    filters.age,
    filters.gender,
    filters.location,
    filters.source,
    filters.allSources,
    filters.dateAddedStart,
    filters.dateAddedEnd,
    filters.updatedStart,
    filters.updatedEnd,
  ]);

  const searchParameters = useMemo(
    () => ({
      q: "*",
      query_by: `name`,
      sort_by: `${sort.field}:${sort.direction}`,
      filter_by,
      page: 1,
      per_page: 20,
    }),
    [sort.field, sort.direction, filter_by],
  );

  useEffect(() => {
    if (proxySearch) {
      setLoading(true);
      proxySearch("contacts", searchParameters, true).then((res) => {
        setLoading(false);
        let docs = res?.hits?.map(({ document }) => document) || [];
        setFans(docs);
        setFound(res?.found || 0);
      });
    }
  }, [proxySearch, sort, searchParameters]);

  const getNextPage = async () => {
    if (proxySearch) {
      setPageLoading(true);
      return proxySearch(
        "contacts",
        {
          ...searchParameters,
          page: fans.length / 20 + 1,
        },
        true,
      ).then((res) => {
        setPageLoading(false);
        let docs = res.hits.map(({ document }) => document);
        setFans([...fans, ...docs]);
      });
    }
  };

  const setSort = (sortField: string) => {
    if (sort.field === sortField) {
      setSortInternal({
        field: sortField,
        direction: sort.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSortInternal({ field: sortField, direction: "asc" });
    }
  };
  const value = {
    fans,
    loading,
    pageLoading,
    sort,
    setSort,
    filters,
    setFilters,
    filterCount,
    clear,
    locationText,
    searchParameters,
    setLocationText,
    sourceText,
    setSourceText,
    found,
    getNextPage,
    hasMore: fans.length < found,
  };

  return (
    <FanTableContext.Provider value={value} {...props}>
      {children}
    </FanTableContext.Provider>
  );
};

export const useFanTableContext = () => useContext(FanTableContext);
