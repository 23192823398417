import styled from "styled-components";
import Modal from "../modal";
import AlertIcon from "../AlertIcon";
import { Button } from "melodies-source/Button";
import { Select } from "melodies-source/Select";
import {
  MailchimpList,
  MailchimpMergeTags,
  AudienceIntegrationStatus,
} from "./types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import MergeFieldsSelect from "./MergeFieldsSelect";
import {
  getAudienceListsForArtist,
  getListMergeTagsForArtist,
  initiateBackfill,
  isMailchimpConnected,
  removeMailchimpAccount,
} from "./mailchimpActions";
import { useDesktopMediaQuery, useSearchParams } from "hooks";
import LargeCheckIcon from "./LargeCheckIcon";
import { CircularLoader, useArtistContext } from "Components";

export const AudienceIntegrationModal = ({
  artistGroupId,
  artistName,
  isOpen,
  onClose,
  selectedList,
  setSelectedList,
}: {
  artistGroupId: string;
  artistName: string;
  isOpen: boolean;
  onClose: () => void;
  selectedList: MailchimpList | null;
  setSelectedList: Dispatch<SetStateAction<MailchimpList>>;
}) => {
  const [audienceLists, setAudienceLists] = useState<MailchimpList[]>([]);
  const [mergeTags, setMergeTags] = useState<MailchimpMergeTags | null>(null);
  const [status, setStatus] = useState(
    AudienceIntegrationStatus.SEARCHING_AUDIENCES,
  );

  const { searchParams, history } = useSearchParams();

  // on mount, get mailchimp lists/audiences for artist
  useEffect(() => {
    // when the mailchimp oauth callback occurs, we pass an empty artist group id
    // otherwise this status should never be hit
    if (!artistGroupId) {
      setStatus(AudienceIntegrationStatus.CONNECTING_MAILCHIMP);
      return;
    }

    (async () => {
      try {
        const mailchimpConnected = await isMailchimpConnected(artistGroupId);

        if (!mailchimpConnected) return;

        const { lists } = await getAudienceListsForArtist(artistGroupId);

        if (!lists.length) {
          throw new Error("no audiences found");
        }

        setAudienceLists(lists);

        setStatus(AudienceIntegrationStatus.AUDIENCE_LINK);
      } catch (error) {
        console.error(error);

        setStatus(AudienceIntegrationStatus.NO_AUDIENCE_ERROR);
      }
    })();
  }, [artistGroupId, isOpen]);

  const getListMergeTags = async (listId: string, listName: string) => {
    setStatus(AudienceIntegrationStatus.ANALYZING_AUDIENCE);

    try {
      const { mergeTags } = await getListMergeTagsForArtist(
        listId,
        artistGroupId,
        listName,
      );

      setMergeTags(mergeTags);

      setStatus(AudienceIntegrationStatus.CONNECT_FIELDS);
    } catch (error) {
      console.error(error);

      setStatus(AudienceIntegrationStatus.CONNECTION_ERROR);
    }
  };

  const onBackfillClickHandler = async () => {
    await initiateBackfill(artistGroupId);

    setStatus(AudienceIntegrationStatus.CONTACTS_SYNCING);
  };

  const onModalClose = () => {
    searchParams.delete("mailchimpStatus");

    history.replace({ search: searchParams.toString() });

    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} service="mailchimp">
      {status === AudienceIntegrationStatus.CONNECTING_MAILCHIMP && (
        <ConnectingMailchimp />
      )}
      {status === AudienceIntegrationStatus.SEARCHING_AUDIENCES && (
        <SearchingAudiences />
      )}
      {status === AudienceIntegrationStatus.AUDIENCE_LINK && (
        <AudienceLink
          artistName={artistName}
          lists={audienceLists}
          selectedList={selectedList}
          setSelectedList={setSelectedList}
          onNext={getListMergeTags}
        />
      )}
      {status === AudienceIntegrationStatus.NO_AUDIENCE_ERROR && (
        <NoAudienceError onClose={onModalClose} />
      )}
      {status === AudienceIntegrationStatus.MAILCHIMP_NOT_CONNECTED && (
        <MailchimpNotConnected />
      )}
      {status === AudienceIntegrationStatus.ANALYZING_AUDIENCE && (
        <AnalyzingAudience listName={selectedList.name} />
      )}
      {status === AudienceIntegrationStatus.CONNECTION_ERROR && (
        <ConnectionError setStatus={setStatus} />
      )}
      {status === AudienceIntegrationStatus.CONNECTING_AUDIENCE && (
        <ConnectingAudience />
      )}
      {status === AudienceIntegrationStatus.CONNECT_FIELDS && (
        <MergeFieldsSelect
          artistGroupId={artistGroupId}
          selectedList={selectedList}
          mergeTags={mergeTags}
          setMergeTags={setMergeTags}
          setStatus={setStatus}
        />
      )}
      {status === AudienceIntegrationStatus.AUDIENCE_CONNECTED && (
        <AudienceConnected
          onClose={onModalClose}
          onBackfillClick={onBackfillClickHandler}
        />
      )}
      {status === AudienceIntegrationStatus.CONTACTS_SYNCING && (
        <ContactsSyncing onClose={onModalClose} />
      )}
    </Modal>
  );
};

const AnalyzingAudience = ({ listName }: { listName: string }) => (
  <>
    <LoaderContainer>
      <CircularLoader />
    </LoaderContainer>
    <BodyText style={{ marginBottom: 5 }}>Please wait...</BodyText>
    <BodyText>We're analyzing "{listName}" Audience</BodyText>
  </>
);

const AudienceConnected = ({
  onClose,
  onBackfillClick,
}: {
  onClose: () => void;
  onBackfillClick: () => void;
}) => {
  const isDesktop = useDesktopMediaQuery();

  return (
    <>
      <LargeCheckIcon
        height={isDesktop ? "81px" : "42px"}
        width={isDesktop ? "81px" : "42px"}
        sx={{ marginBottom: 15 }}
      />
      <HeaderText>Your Mailchimp audience is now connected!</HeaderText>
      <br />
      <BodyText>
        If you would like all of your existing SET contacts to be synced with
        Mailchimp, click the button below.
      </BodyText>
      <br />
      <BodyText>
        If you close without syncing existing contacts, only new contacts
        collected will be sent to your Mailchimp audience
      </BodyText>
      <br />
      <Button
        style={{ maxWidth: 385, marginBottom: 17 }}
        onClick={onBackfillClick}
      >
        Sync Existing Contacts
      </Button>
      <Button
        variant="secondary"
        style={{ maxWidth: 385 }}
        onClick={() => onClose()}
      >
        Only Sync New Contacts
      </Button>
    </>
  );
};

const AudienceLink = ({
  artistName,
  lists,
  selectedList,
  setSelectedList,
  onNext,
}: {
  artistName: string;
  lists: MailchimpList[];
  selectedList: MailchimpList | null;
  setSelectedList: Dispatch<SetStateAction<MailchimpList>>;
  onNext: (listId: string, listName: string) => Promise<void>;
}) => (
  <>
    <HeaderText style={{ marginBottom: 15 }}>
      Which audience would you like to link this SET account for {artistName}?
    </HeaderText>
    <BodyText style={{ marginBottom: 20 }}>
      Please select the audience below:
    </BodyText>
    <StyledSelect
      value={selectedList?.id}
      placeholder="Select a list"
      options={lists.map((list) => ({
        label: `"${list.name}" - ${list.stats.member_count} contact${
          list.stats.member_count !== 1 ? "s" : ""
        }`,
        value: list.id,
      }))}
      onChange={(selectionId) =>
        setSelectedList(lists.find((list) => list.id === selectionId))
      }
    />
    <StyledButton
      style={{ marginTop: 40 }}
      onClick={() => onNext(selectedList.id, selectedList.name)}
      disabled={!selectedList}
    >
      Next
    </StyledButton>
  </>
);

const ConnectingAudience = () => (
  <>
    <LoaderContainer>
      <CircularLoader />
    </LoaderContainer>
    <BodyText>Please wait...</BodyText>
    <BodyText>We're connecting your Mailchimp Audience</BodyText>
  </>
);

const ConnectingMailchimp = () => (
  <>
    <LoaderContainer>
      <CircularLoader />
    </LoaderContainer>
    <BodyText>Please wait...</BodyText>
    <BodyText>We're connecting your Mailchimp account</BodyText>
  </>
);

const ConnectionError = ({
  setStatus,
}: {
  setStatus: Dispatch<SetStateAction<AudienceIntegrationStatus>>;
}) => {
  const isDesktop = useDesktopMediaQuery();
  return (
    <>
      <AlertContainer
        style={{ width: isDesktop ? 81 : 42, height: isDesktop ? 81 : 42 }}
      >
        <AlertIcon height={isDesktop ? 41 : 21} />
      </AlertContainer>
      <HeaderText>Something went wrong</HeaderText>
      <BodyText>Connection process has failed.</BodyText>
      <BodyText>Please try again or select a different Audience.</BodyText>
      <StyledButton
        style={{ marginTop: 27 }}
        onClick={() => setStatus(AudienceIntegrationStatus.AUDIENCE_LINK)}
      >
        Go Back
      </StyledButton>
    </>
  );
};

const ContactsSyncing = ({ onClose }: { onClose: () => void }) => (
  <>
    <LargeCheckIcon sx={{ marginBottom: 15 }} />
    <BodyText>Existing Contacts started syncing</BodyText>
    <br />
    <StyledButton onClick={onClose}>Close</StyledButton>
  </>
);

const MailchimpNotConnected = () => {
  const isDesktop = useDesktopMediaQuery();
  return (
    <>
      <AlertContainer
        style={{ width: isDesktop ? 81 : 42, height: isDesktop ? 81 : 42 }}
      >
        <AlertIcon height={isDesktop ? 41 : 21} />
      </AlertContainer>
      <BodyText>Your Mailchimp audience is not connected!</BodyText>
      <StyledButton style={{ marginTop: 27 }}>Close</StyledButton>
    </>
  );
};

const NoAudienceError = ({ onClose }: { onClose: () => void }) => {
  const isDesktop = useDesktopMediaQuery();
  return (
    <>
      <AlertContainer
        style={{ width: isDesktop ? 81 : 42, height: isDesktop ? 81 : 42 }}
      >
        <AlertIcon height={isDesktop ? 41 : 21} />
      </AlertContainer>
      <BodyText>We didn't find any Audience, please try again</BodyText>
      <StyledButton style={{ marginTop: 27 }} onClick={onClose}>
        Go Back
      </StyledButton>
    </>
  );
};

const SearchingAudiences = () => (
  <>
    <LoaderContainer>
      <CircularLoader />
    </LoaderContainer>
    <BodyText>Finding Mailchimp Audiences...</BodyText>
  </>
);

export const MailchimpRemovalModal = ({
  artistGroupId,
  onClose,
  isOpen,
}: {
  artistGroupId: string;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [removingMailchimp, setRemovingMailchimp] = useState(false);
  const [removalError, setRemovalError] = useState("");
  const { logAction } = useArtistContext();

  const onRemoveMailchimp = async () => {
    setRemovingMailchimp(true);

    setRemovalError("");

    try {
      await removeMailchimpAccount(artistGroupId);

      await logAction("portal_remove_mailchimp");

      onClose();
    } catch (error) {
      console.error((error as Error).message);

      setRemovalError(
        `Error removing mailchimp account: ${(error as Error).message}`,
      );

      setRemovingMailchimp(false);
    }
  };

  const modalBody = () => {
    if (removalError) {
      return (
        <HeaderText style={{ color: "#e45c52" }}>{removalError}</HeaderText>
      );
    }

    if (removingMailchimp) {
      return (
        <>
          <CircularLoader height={30} sx={{ marginRight: "0.5rem" }} />
          <HeaderText>Removing Mailchimp account...</HeaderText>
        </>
      );
    }

    return (
      <HeaderText>
        Are you sure you want to unlink your Mailchimp account?
      </HeaderText>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} service="mailchimp">
      {modalBody()}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginTop: 40,
        }}
      >
        <Button
          onClick={() => onRemoveMailchimp()}
          style={{ marginRight: "2rem" }}
          disabled={removingMailchimp}
        >
          Confirm
        </Button>
        <Button
          variant="secondary"
          onClick={onClose}
          disabled={removingMailchimp}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

const AlertContainer = styled.div`
  width: 81px;
  height: 81px;
  margin-bottom: 15px;
  background: rgba(228, 92, 82, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
`;

const BodyText = styled.div`
  font-size: 17px;
`;

const HeaderText = styled.div`
  font-size: 20px;
`;

const LoaderContainer = styled.div`
  margin-bottom: 19px;
`;

const StyledButton = styled(Button)`
  max-width: 385px;
`;

const StyledSelect = styled(Select)`
  max-width: 380px;
  & p {
    text-align: left;
  }
`;
