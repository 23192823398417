import {
  Modal,
  ModalCloseIcon,
  ModalContainer,
  ModalHeaderContainer,
  ModalWrapper,
} from "melodies-source/Modal";
import styled from "styled-components";
import { Body1, H2 } from "melodies-source/Text";
import { FlexRow } from "./Flex";

export const ResponsiveModal = styled(Modal)`
  ${ModalHeaderContainer} {
    position: relative;
    flex-direction: column;
    margin-bottom: 24px;
    gap: 8px;
    ${H2} {
      color: var(--header-text-color);
      padding-right: 44px;
    }
    ${Body1} {
      color: var(--secondary-text-color);
    }
    ${ModalCloseIcon} {
      margin: -4px;
      position: absolute;
      top: 0;
      right: 0;
      color: var(--secondary-text-color);
      &:hover {
        color: var(--text-color);
      }
    }
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    ${ModalHeaderContainer} {
      margin-bottom: 16px;
      ${H2} {
        font-size: 20px;
        line-height: 28px;
      }
      ${Body1} {
        font-size: 12px;
        line-height: 18px;
      }
    }
    ${ModalWrapper} {
      padding: 0 20px;
    }
    ${ModalContainer} {
      padding: 24px 20px;
    }
  }
`;

export const ResponsiveFooterActions = styled(FlexRow)`
  margin-top: 32px;
  gap: 20px;
  justify-content: flex-end;

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
    align-items: stretch;
    margin-top: 24px;
    gap: 8px;
  }
`;
