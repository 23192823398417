import React from "react";
import { DefaultLayout, PageHeaderImage } from "Components";
import { GlobalStyles } from "styled";
import styled from "styled-components";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import { Info } from "./Info";
import { Entries } from "./Entries";
import { Card } from "Components/Card";
import { Activity, StyledCard as ActivityCard } from "./Activity";
import { FanLocations } from "./FanLocations";
import { Impressions } from "./Impressions";
import { Clicks } from "./Clicks";
import { Results } from "./Results";
import { PostShowEmail } from "./PostShowEmail";
import { Sweepstakes } from "./Sweepstakes";

export const ShowDetails: React.FC = () => {
  const { event, votes, votesLoading, votesError } = useArtistEventContext();
  const eventIsVersion2 = event?.version === 2;

  return (
    <DefaultLayout>
      <GlobalStyles grayBG />
      <PageHeaderImage app="live" />
      <Container>
        <StyledCard isElevated>
          <Info />
          {eventIsVersion2 &&
            (Object.keys(votes?.items ?? {}).length > 0 ||
              (!votesLoading && votesError)) && <Results />}
          <CardsContainer>
            <Col>
              <Entries />
              <FanLocations />
            </Col>
            <Activity />
          </CardsContainer>
          {eventIsVersion2 && <Impressions />}
          {eventIsVersion2 && <Clicks />}
          {eventIsVersion2 && <PostShowEmail />}
          {eventIsVersion2 && <Sweepstakes />}
        </StyledCard>
      </Container>
    </DefaultLayout>
  );
};

const StyledCard = styled(Card)`
  padding: 40px;

  ${({ theme }) => theme.media.mobile} {
    padding: 25px;
  }
`;

const Col = styled.div``;

const CardsContainer = styled.div`
  display: flex;
  gap: 30px;

  ${Col}, ${ActivityCard} {
    flex-basis: 50%;
  }

  ${({ theme }) => theme.media.mobile} {
    flex-wrap: wrap;
    gap: 0;

    ${Col}, ${ActivityCard} {
      flex-basis: 100%;
    }
  }
`;

const Container = styled.div`
  position: relative;
  padding: 37px 46px;
  font-family: "Poppins";

  ${({ theme }) => theme.media.mobile} {
    padding: 20px 0;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;
