import { useArtistContext } from "Components";
import { pluralize } from "Utils/pluralize";
import { useSpotifySearch } from "hooks/useSpotifySearch";
import {
  AutocompleteAsync,
  ButtonWrap,
  Option,
  OptionProps,
} from "melodies-source/Autocomplete";
import { Modal } from "melodies-source/Modal";
import { SvgSearchAlt } from "melodies-source/Svgs/SearchAlt";
import { Body2, Selected } from "melodies-source/Text";
import { colors } from "melodies-source/Theme";
import { PropsWithChildren, useState } from "react";
import styled from "styled-components";
import { CustomSongForm } from "./CustomSongForm";
import { Section, SectionTitle } from "./components";
import { Album, Song } from "./types";

const StyledAutocompleteAsync = styled(AutocompleteAsync)`
  ${ButtonWrap} {
    border-color: #e6e9eb;
  }
`;

interface WithSongType {
  type: "track";
  onSelect?: (song: Song) => void;
  exclusions?: string[];
}

interface WithAlbumType {
  type: "album";
  onSelect?: (album: Album) => void;
  // already selected albums to exclude from search
  exclusions?: string[];
}

interface SpotifySearchProps {
  album?: Album;
  withCustomButton?: boolean;
}

export const SpotifySearch = ({
  album,
  type,
  withCustomButton = true,
  onSelect,
  exclusions,
  children,
}: PropsWithChildren<SpotifySearchProps & (WithSongType | WithAlbumType)>) => {
  const { name: artistName } = useArtistContext();
  const { debouncedSearch, fetchAlbumTracks } = useSpotifySearch();
  const [text, setText] = useState("");

  const [[customSongValues, customSongOpen], setCustomSong] = useState([
    { name: "", album: "", artist: "" },
    false,
  ]);

  const resetCustomSong = (name = "", open = false) =>
    setCustomSong([{ name, album: album?.name, artist: artistName }, open]);

  return (
    <>
      <Modal
        header="Manually Add Song"
        headerColor="navy"
        isOpen={customSongOpen}
        withBackdropClose={false}
        onClose={() => resetCustomSong()}
      >
        <CustomSongForm
          initialValues={customSongValues}
          onCancel={() => resetCustomSong()}
          onSubmit={(values) => {
            if (type === "track") {
              const item: Song = {
                id: window.crypto.randomUUID(),
                name: values.name,
                album: values.album,
                artist: values.artist,
                src: album?.src ?? "",
                visible: true,
                deletable: true,
              };

              onSelect?.(item);
            }

            resetCustomSong();
          }}
        />
      </Modal>
      <Section>
        <SectionTitle style={{ marginTop: -10, marginBottom: 10 }}>
          <SvgSearchAlt />{" "}
          {type === "album"
            ? "Search for Albums"
            : album
            ? `Search for Tracks to Add to ${album?.name}`
            : "Search for Tracks"}
        </SectionTitle>
        <StyledAutocompleteAsync
          placeholder="Type Track Name..."
          text={text}
          setText={setText}
          onChange={async (option) => {
            setText("");

            if (type === "track") {
              const item: Song = {
                id: `spotify-${option.data.id}`,
                name: option.data.name,
                album: option.data.album.name,
                artist: option.data.artists.map((a) => a.name).join(", "),
                src: option.data.album.images[0].url,
                visible: true,
                deletable: false,
              };
              onSelect?.(item);
            } else {
              const albumTracks = await fetchAlbumTracks(option.data.id);

              const item: Album = {
                id: `spotify-${option.data.id}`,
                name: option.data.name,
                artist: option.data.artists.map((a) => a.name).join(", "),
                src: option.data.images[0].url,
                visible: true,
                deletable: false,
                songs: albumTracks.items.map((trackItem) => ({
                  id: `spotify-${trackItem.id}`,
                  name: trackItem.name,
                  album: option.data.name,
                  artist: trackItem.artists.map((a) => a.name).join(", "),
                  src: option.data.images[0].url,
                  visible: true,
                  deletable: false,
                })),
              };

              onSelect?.(item);
            }
          }}
          getOptions={async (term: string) => {
            const data = await debouncedSearch(term, type);

            if (type === "track") {
              return data?.tracks?.items?.map(
                (track: any) =>
                  ({
                    label: track.name,
                    value: `spotify-${track.id}`,
                    caption: track.artists.map((a) => a.name).join(", "),
                    caption2: track.album.name,
                    avatarSrc: track.album.images[0].url,
                    data: track,
                  } as Option),
              );
            } else {
              return data?.albums?.items
                ?.filter((album) => !exclusions?.includes(album.id))
                .map(
                  (album: any) =>
                    ({
                      label: album.name,
                      value: `spotify-${album.id}`,
                      caption: album.artists.map((a) => a.name).join(", "),
                      caption2: pluralize(album.total_tracks, "Track"),
                      avatarSrc: album.images[0].url,
                      data: album,
                    } as Option),
                );
            }
          }}
          setCustom={
            withCustomButton
              ? () => {
                  resetCustomSong(text, true);
                  setText("");
                }
              : undefined
          }
          buttonText={text ? `Manually add “${text}”` : "Manually add Track"}
          customOption={CustomOption}
          spinnerColor={colors.black20}
        />
        {children}
      </Section>
    </>
  );
};

const CustomContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 24px;
  cursor: pointer;

  &:hover {
    background: #f2f5f7;
  }
`;

const CustomCover = styled.img`
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  object-fit: cover;
`;

const CustomContent = styled.div`
  padding-left: 10px;
`;

const CustomOption = ({
  label,
  caption,
  caption2,
  avatarSrc,
  ...props
}: OptionProps) => {
  return (
    <CustomContainer {...props}>
      <CustomCover src={avatarSrc} />
      <CustomContent>
        <Selected>{label}</Selected>
        <Body2>{caption}</Body2>
        <Body2>{caption2}</Body2>
      </CustomContent>
    </CustomContainer>
  );
};
