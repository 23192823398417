import React, { useEffect } from "react";
import styled from "styled-components";
import { Button } from "melodies-source/Button";
import { Body1, H1 } from "melodies-source/Text";
import { ReactComponent as CheckIcon } from "assets/svg/check-circle-green.svg";
import { Card } from "melodies-source/Card";
import { StepProps } from "..";
import { doc, getFirestore, setDoc } from "firebase/firestore";
import { useUser } from "auth";

export const AccountReady: React.FC<StepProps> = () => {
  const { user } = useUser();

  const updateProfile = async () => {
    try {
      await setDoc(
        doc(getFirestore(), `profiles/${user.uid}`),
        {
          shouldSetupMFA: false,
        },
        {
          merge: true,
        },
      );
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    return () => {
      updateProfile();
    };
  }, []);

  return (
    <StyledCard isElevated>
      <Container>
        <CheckIcon />
        <H1>Account Ready</H1>
        <Body1>You successfully configured your account.</Body1>
        <DashboardButton onClick={() => updateProfile()}>
          Go to your dashboard
        </DashboardButton>
      </Container>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: min(500px, 90vw);

  a {
    color: var(--main-color);
  }

  svg {
    width: 60px;
  }

  ${Body1} {
    margin: 10px 0 5px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px 40px 40px;

  ${Body1} {
    text-align: center;
  }
`;

const DashboardButton = styled(Button)`
  margin: 30px 0 5px 0;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 20px;
  }
`;
