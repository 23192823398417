import React from "react";
import styled, { css } from "styled-components";
import { Card as MelodiesCard } from "melodies-source/Card";
import { Divider } from "Components";
import { ReactComponent as OpenIcon } from "assets/svg/open.svg";
import { Spinner } from "melodies-source/Spinner";

interface Action {
  text: string;
  onClick: () => void;
  loading?: boolean;
}
interface Props {
  children: React.ReactNode;
  action?: Action;
}

type CardProps = Omit<React.ComponentProps<typeof MelodiesCard>, "children">;

export const Card: React.FC<CardProps & Props> = ({
  children,
  action,
  ...props
}) => {
  return (
    <StyledCard hasAction={!!action} {...props}>
      <>
        {action ? (
          <>
            <Content>{children}</Content>
            <div>
              <Divider />
              <Action onClick={action.onClick}>
                {action.loading ? (
                  <Spinner />
                ) : (
                  <>
                    <span>{action.text}</span>
                    <OpenIcon />
                  </>
                )}
              </Action>
            </div>
          </>
        ) : (
          children
        )}
      </>
    </StyledCard>
  );
};

interface StyledCardProps {
  hasAction: boolean;
}

const StyledCard = styled(MelodiesCard)<StyledCardProps>(
  ({ hasAction, theme }) => css`
    background-color: var(--component-background-color) !important;
    box-shadow: 0px 0px 50px var(--box-shadow-color);
    margin-top: 32px;
    border: none;

    ${theme.media.mobile} {
      width: 100%;
      margin-top: 20px;
      box-shadow: 0px 0px 25px var(--box-shadow-color);
    }

    ${hasAction
      ? css`
          padding: 0;
          display: grid;
          grid-template-rows: 1fr auto;
        `
      : css`
          padding: 35px 30px;

          ${theme.media.mobile} {
            padding: 20px;
          }
        `}
  `,
);

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 32px 48px 28px;

  ${({ theme }) => theme.media.mobile} {
    padding: 20px;
  }
`;

const Action = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 3px 0 15px;
  gap: 5px;

  :hover {
    color: var(--main-color);
  }

  span {
    font-size: 16px;
    font-weight: 600;
  }
`;
