import { ReactComponent as TemplateIcon } from "assets/svg/fanFav.svg";
import { SurveyTemplate } from "../../types";

export const fanFavorite: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Fan Favorite",
  description:
    "Offer your fans the ability to choose the focus of today's live: performance, demo leak, storytime or more.",
  templateId: "fanFavorite",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Fan Favorite",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "What should be the spotlight of the next Livestream?",
        },
        type: "SingleSelectQuestion",
        options: [
          {
            label: {
              en: "An intimate performance",
            },
          },
          {
            label: {
              en: "Sneak peek demo leak",
            },
          },
          {
            label: {
              en: "Behind-the-scenes stories",
            },
          },
          {
            label: {
              en: "Q&A",
            },
          },
          {
            label: {
              en: "Unreleased song preview",
            },
          },
          {
            label: {
              en: "Creative process breakdown",
            },
          },
        ],
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-livestream-326e95"
        : "pp-livestream-a21a05",
    fields: [
      {
        name: "date",
        type: "text",
        label: "When does your livestream take place?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.date ? false : "This field cannot be left blank",
      },
    ],
  },
};
