import React from "react";
import styled from "styled-components";
import { Card } from "melodies-source/Card";
import { H2 } from "melodies-source/Text";
import { StepProps } from "..";
import { SetupMFA } from "Routes/Account/SetupMFA";

export const MFA: React.FC<StepProps> = ({ handleNext }) => {
  return (
    <StyledCard isElevated>
      <H2>Set up MFA</H2>
      <SetupMFA withModal={false} onSuccess={handleNext} />
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  width: min(500px, 90vw);

  & > ${H2} {
    text-align: center;
    margin-bottom: 10px;
  }
`;
