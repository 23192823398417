import { ArtistInfo } from "Routes/SetFan/Components/SongsQuestion/types";
import { SurveyTemplate } from "Routes/SetFan/dashboard/Components/Templates/types";
import { autoId } from "Routes/SetFan/util";
import { ReactComponent as IconDreamSetlist } from "assets/svg/dream-setlist.svg";

export const dreamSetlist: SurveyTemplate<ArtistInfo & { artist: string }> = {
  icon: <IconDreamSetlist />,
  title: "Dream Setlist",
  description: `Invite fans to "set the set" and submit their dream setlist to build interest in the next tour.`,
  complexity: "low",
  templateId: "dreamSetlist",
  template: ({ albums, artist }) => ({
    fields: {
      description: {
        content: {
          en: "Dream Setlist",
        },
      },
      header: {
        content: {
          en: `Create the Ultimate ${artist} Set List`,
        },
      },
      body: {
        content: {
          en: `Ever wanted to build the perfect ${artist} set list? This is your chance! Vote for your favorites and maybe ${artist} will play the songs you chose!`,
        },
      },
      numberOfCategories: {
        content: "2",
      },
      displayedCategories: {
        content: [
          "demographics",
          "fooddrink",
          "alcohol",
          "clothing",
          "vehicle",
          "entertainment",
        ],
      },
      thankYouHeader: {
        content: {
          en: "Thanks for your Vote!",
        },
      },
      thankYouBody: {
        content: {
          en: `Be sure to catch ${artist} on tour and see if your songs get played!`,
        },
      },
    },
    surveyQuestions: [
      {
        options:
          albums?.map((album) => ({
            id: album.id,
            label: { en: album.name },
            src: { en: album.src },
            options: album.songs.map((song) => ({
              id: song.id,
              label: { en: song.name },
              src: { en: song.src },
            })),
          })) || [],
        id: autoId(),
        actionRequired: false,
        required: true,
        label: {
          en: `Choose the songs that would be on your ideal ${artist} set list!`,
        },
        type: "SongsQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-tour---dre-5a4973"
        : "pp-copy-of-to-6e0c8b",
  },
};
