import { useState } from "react";
import { Appearance } from "../../slices/Appearance";
import { Contest } from "../../slices/Contest";
import { SurveyInfoContent } from "../../slices/SurveyInfoContent";
import styled from "styled-components";
import { SectionContainer } from "Routes/SetFan/editor/Components/SectionContainer";
import { ReactComponent as IconPalette } from "assets/svg/palette.svg";
import { ReactComponent as IconTrophy } from "assets/svg/trophy-large.svg";
import { ContestTitleAddon } from "Routes/SetFan/slices/ContestTitleAddon";
import { useConfigFlags } from "Routes/SetFan/Components/BuilderContext";
import { FlexColumn } from "Components/Flex";
import { H2 } from "melodies-source/Text";

export const SurveyInfo = () => {
  const { flags } = useConfigFlags();
  const [showAppearance, setShowAppearance] = useState(false);
  const [showContest, setShowContest] = useState(
    flags.showContestConfirmationMessage ?? false,
  );

  return (
    <>
      <Content>
        <SurveyInfoContent isWizard />
      </Content>
      <Sections>
        <SectionContainer
          icon={<IconPalette />}
          title="Appearance"
          slice={Appearance}
          active={showAppearance}
          lastIndex={true}
          onClick={() => setShowAppearance((p) => !p)}
        />
        <SectionContainer
          icon={<IconTrophy />}
          title="Sweepstakes"
          titleAddon={ContestTitleAddon}
          slice={Contest}
          active={showContest}
          lastIndex={true}
          onClick={() => setShowContest((p) => !p)}
        />
      </Sections>
    </>
  );
};

const Content = styled(FlexColumn)`
  width: 100%;
  ${H2} {
    color: var(--text-color);
  }
  ${({ theme }) => theme.mediaQueries.mobile} {
    ${H2} {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }
`;

const Sections = styled.div`
  border-top: 1px solid var(--box-shadow-color);
  margin: 44px -32px 0;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin: 32px -20px 0;
  }
`;
