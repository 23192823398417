import { ReactComponent as IconShirtStar } from "assets/svg/shirt-star.svg";
import { SurveyTemplate } from "../../types";

export const rateNewDesign: SurveyTemplate = {
  icon: <IconShirtStar />,
  title: "Rate the New Design",
  description:
    "Spread the word about the latest design by asking fans to rate it.",
  templateId: "rateNewDesign",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Rate the New Design",
        },
      },
    },
    surveyQuestions: [
      {
        type: "image",
        actionRequired: true,
        showLabels: false,
        required: false,
        src: "",
      },
      {
        type: "RankedQuestion",
        id: "question_1",
        rankRange: 5,
        rankHigh: {
          en: "Love It",
        },
        rankLow: {
          en: "Hate It",
        },
        label: {
          en: "How would you rate this merch design?",
        },
      },
      {
        id: "question_2",
        label: {
          en: "What made you choose this rating?",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-merch---ra-b2513b"
        : "pp-merch---ra-d0b4aa",
  },
};
