import styled from "styled-components";
import { useFanTableContext } from "..";
import { Filter } from "./Filter";
import { Radio } from "melodies-source/Selectable";

const StyledFilter = styled(Filter)`
  gap: 15px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column;
    box-shadow: none;
    margin-bottom: 0px;
    gap: 0px;
  }
`;

export const Age = () => {
  const { filters, setFilters } = useFanTableContext();
  const onSelectFilter = (min, max) => {
    setFilters({
      age: {
        min,
        max,
      },
    });
  };
  return (
    <StyledFilter>
      <Radio
        style={{ flex: 1 }}
        onChange={() => {
          setFilters({
            age: null,
          });
        }}
        value={!filters?.age}
        label="All Ages"
      />
      <Radio
        style={{ flex: 1 }}
        onChange={() => {
          onSelectFilter(18, 999);
        }}
        value={filters?.age?.min === 18 && filters?.age?.max === 999}
        label="18+"
      />
      <Radio
        style={{ flex: 1 }}
        onChange={() => {
          onSelectFilter(21, 999);
        }}
        value={filters?.age?.min === 21}
        label="21+"
      />
    </StyledFilter>
  );
};
