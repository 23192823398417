import { ReactComponent as IconDreamSetlist } from "assets/svg/playlistsInfluencers.svg";
import { SurveyTemplate } from "../../types";

export const playlistsInfluencers: SurveyTemplate = {
  icon: <IconDreamSetlist />,
  title: "Playlists & Influencers",
  description:
    "Discover which playlists and influencers you should be reaching out to.",
  templateId: "playlistsInfluencers",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Playlists & Influencers",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        multiline: true,
        label: {
          en: "Which playlists or influencers match my music?",
        },
        type: "TextQuestion",
      },
      {
        id: "question_2",
        label: {
          en: "Explain why they're a great fit.",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes" ? "pp-marketing-2d732a" : "pp-marketing-8412d1",
  },
};
