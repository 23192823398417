import { ImageQuestionModule } from "@max/common/dist/setfan";
import { TextInput } from "Components";
import { CenterFlexRow } from "Routes/styled";
import { REQUIRED_MSG } from "Utils/yupStrictPassword";
import { ReactComponent as SvgAddImage } from "assets/svg/add-image.svg";
import {
  ArrayHelpers,
  Field,
  FieldArray,
  FieldProps,
  Form,
  Formik,
} from "formik";
import { Selectable } from "melodies-source/Selectable";
import { SvgHelp } from "melodies-source/Svgs/Help";
import styled from "styled-components";
import * as yup from "yup";
import { HelpTooltip } from "../../HelpTooltip";
import { QuestionCard } from "../../QuestionCard";
import { Subtitle2 } from "melodies-source/Text";
import { useMemo, useState } from "react";
import {
  QuestionCardList,
  QuestionModalProps,
  QuestionTypeMeta,
} from "./BaseQuestionModal";
import { AddQuestionFooter } from "./Footer";
import { ImageQuestionUploader } from "./ImageQuestionUploader";
import { ResponsiveModal } from "Components/ResponsiveModal";

interface ImageQuestionValues {
  label: string;
  required: boolean;
  showLabels: boolean;
  options: ImageOption[];
}

const VALIDATION_SCHEMA = yup.object().shape({
  label: yup.string().required(REQUIRED_MSG),
  required: yup.boolean().required(REQUIRED_MSG),
  showLabels: yup.boolean().required(REQUIRED_MSG),
  options: yup
    .array()
    .min(2)
    .of(
      yup.object().shape({
        src: yup.string().required(REQUIRED_MSG),
        label: yup.string().required(REQUIRED_MSG),
      }),
    ),
});

interface Uploader {
  open: boolean;
  index?: number;
  option?: ImageOption;
}

const ImageQuestionModal = ({
  dispatch,
  existing,
}: Omit<QuestionModalProps<ImageQuestionModule>, "state">) => {
  const [uploader, setUploader] = useState<Uploader>({ open: false });

  const initialValues: ImageQuestionValues = useMemo(
    () => ({
      label: existing?.label.en ?? "",
      required: !!existing?.required,
      showLabels: !!existing?.showLabels,
      options:
        existing?.options?.map((opt) => ({
          src: opt.src.en,
          label: opt.label.en,
        })) ?? [],
    }),
    [existing],
  );

  const onSubmit = ({
    label,
    required,
    showLabels,
    options,
  }: ImageQuestionValues) => {
    const imageQuestionModule = new ImageQuestionModule({
      id: existing?.id,
      label: { en: label },
      required,
      showLabels,
      options: options.map((option) => ({
        id: option.id || window.crypto.randomUUID(),
        src: { en: option.src },
        label: { en: option.label },
      })),
    });

    dispatch(
      existing
        ? {
            type: "updateModule",
            payload: { idToReplace: existing.id, module: imageQuestionModule },
          }
        : {
            type: "addModule",
            payload: { module: imageQuestionModule },
          },
    );
    dispatch({ type: "closeModal" });
  };

  return (
    <Formik<ImageQuestionValues>
      initialValues={initialValues}
      validationSchema={VALIDATION_SCHEMA}
      onSubmit={onSubmit}
    >
      {({ values, touched, errors, setFieldValue }) => {
        return (
          <Form>
            <QuestionCardList>
              <QuestionCard title="Question" icon={<SvgHelp />}>
                <Field name="label">
                  {({ field, meta }: FieldProps) => (
                    <TextInput
                      label="What is your question?"
                      placeholder="Type your question..."
                      hasError={meta.touched && !!meta.error}
                      helperText={meta.touched && meta.error}
                      asString={false}
                      style={{ maxWidth: 550 }}
                      {...field}
                    />
                  )}
                </Field>
              </QuestionCard>
              <FieldArray name="options">
                {({ push, replace, remove }: ArrayHelpers<ImageOption[]>) => {
                  return (
                    <>
                      <ResponsiveModal
                        header={existing ? "Edit Image" : "Add Image"}
                        withBackdropClose={false}
                        isOpen={uploader.open}
                        onClose={() => setUploader({ open: false })}
                      >
                        <ImageQuestionUploader
                          option={uploader.option}
                          onCancel={() => setUploader({ open: false })}
                          onSubmit={(item) => {
                            if (uploader.index != null) {
                              replace(uploader.index, item);
                            } else {
                              push(item);
                            }

                            setUploader({ open: false });
                          }}
                        />
                      </ResponsiveModal>
                      <QuestionCard
                        title="Upload images"
                        icon={<SvgAddImage />}
                        helpText={
                          Array.isArray(errors.options)
                            ? "Please review uploaded images"
                            : "Please upload at least 2 images"
                        }
                        hasError={
                          touched.options && typeof errors.options === "string"
                        }
                        hasPadding={false}
                      >
                        <Grid>
                          {values.options.map((option, index) => (
                            <div key={index}>
                              <ImageContainer>
                                <ImageMenu>
                                  <ImageAction
                                    type="button"
                                    onClick={() =>
                                      setUploader({ open: true, index, option })
                                    }
                                  >
                                    Edit
                                  </ImageAction>
                                  <ImageAction
                                    type="button"
                                    onClick={() => remove(index)}
                                  >
                                    Delete
                                  </ImageAction>
                                </ImageMenu>
                                <Image src={option.src} />
                              </ImageContainer>
                              <ImageLabel>{option.label}</ImageLabel>
                            </div>
                          ))}
                          <div>
                            <AddImageButton
                              onClick={() => setUploader({ open: true })}
                            >
                              + Add Image
                            </AddImageButton>
                          </div>
                        </Grid>
                        <CenterFlexRow style={{ marginTop: 20 }}>
                          <Selectable
                            variant="checkbox"
                            label="Show image labels to fans"
                            value={values.showLabels}
                            onChange={(v) => setFieldValue("showLabels", v)}
                            style={{ marginRight: 5 }}
                          />
                          <HelpTooltip message="Whether or not to display image labels for image selections" />
                        </CenterFlexRow>
                      </QuestionCard>
                    </>
                  );
                }}
              </FieldArray>
            </QuestionCardList>
            <AddQuestionFooter
              required={values.required}
              confirmLabel={existing ? "Update" : "Add"}
              setRequired={(v) => setFieldValue("required", v)}
              confirmType="submit"
              cancelLabel={existing ? "Cancel" : "Back"}
              onCancel={() =>
                dispatch({ type: existing ? "closeModal" : "openModal" })
              }
            />
          </Form>
        );
      }}
    </Formik>
  );
};

export const imageQuestion: QuestionTypeMeta = {
  icon: <SvgAddImage />,
  key: "imageQuestion",
  header: "Image Question",
  subHeader: "Display a series of images for a participant to choose from",
  body: ImageQuestionModal,
};

type ImageOption = {
  src: string;
  label: string;
  id?: string;
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: auto;
  gap: 20px 32px;

  ${(p) => p.theme.media.mobile} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px 26px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  aspect-ratio: 1;
  position: relative;
  margin-bottom: 4px;
`;

const ImageMenu = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1px;
    height: 60%;
    background: #fff;
    transform: translate(-50%, -50%);
  }
`;

const ImageAction = styled.button`
  flex: 1;
  border: none;
  background: none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  height: 32px;
  cursor: pointer;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  flex-shrink: 0;
`;

const ImageLabel = styled(Subtitle2)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
`;

const AddImageButton = styled.div`
  text-align: center;
  display: flex;
  width: 100%;
  aspect-ratio: 1;
  border-radius: 8px;
  background: none;
  border: 1px solid ${(p) => p.theme.colors.navy};
  color: ${(p) => p.theme.colors.navy};
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  align-items: center;
  justify-content: center;
`;
