import { Footer } from "Components";
import React from "react";
import styled, { css } from "styled-components";
import Logo from "svg/Logo";

export const Layout: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Container>
      <Header>
        <StyledLogo />
      </Header>
      <Content>
        <Children>{children}</Children>
        <StyledFooter />
      </Content>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100%;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  background: var(--background-color);
  height: 60px;
  padding: 0 20px;
`;

const StyledLogo = styled(Logo)`
  width: 40px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) =>
    theme?.custom?.pages.signup.content.background || "#f2f5f7"};
`;

const Children = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -50px;

  & > div {
    margin-top: 60px;
  }
`;

const StyledFooter = styled(Footer)`
  background: none !important;
`;
