import { Button } from "Components";
import { Selectable } from "melodies-source/Selectable";
import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

const ConfirmButton = styled(Button)`
  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 16px;
  }
`;

const CancelButton = styled(Button)`
  color: #1b0076;
  justify-self: center;
  width: auto;
`;

const Container = styled.div`
  display: grid;
  grid-template: auto / 1fr max-content max-content;
  gap: 24px;
  align-items: center;
  position: sticky;
  bottom: 0;
  margin: 0 -40px -40px -40px;
  padding: 24px 40px 40px 40px;
  background: #fff;
  border-radius: 0 0 8px 8px;
  z-index: 1;
  ${({ theme }) => theme.mediaQueries.mobile} {
    ${ConfirmButton} {
      order: 1;
    }
    ${CancelButton} {
      order: 2;
    }
    margin: 0 -20px -24px -20px;
    padding: 12px 20px 24px 20px;
    grid-template: auto / 1fr;
    gap: 8px;
  }
`;

export const AddQuestionFooter = ({
  required,
  setRequired,
  cancelLabel = "Cancel",
  confirmLabel = "Add",
  confirmType,
  confirmDisabled,
  onCancel,
  onConfirm,
}: {
  required: boolean;
  setRequired: Dispatch<SetStateAction<boolean>>;
  cancelLabel?: string;
  confirmLabel?: string;
  confirmType?: "button" | "submit";
  confirmDisabled?: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
}) => {
  return (
    <Container>
      <Selectable
        variant="checkbox"
        label="Required"
        value={required}
        onChange={(selected) => setRequired(selected)}
      />

      <CancelButton
        variant="tertiary"
        type="button"
        onClick={() => onCancel?.()}
      >
        {cancelLabel}
      </CancelButton>
      <ConfirmButton
        onClick={() => onConfirm?.()}
        type={confirmType}
        disabled={confirmDisabled}
      >
        {confirmLabel}
      </ConfirmButton>
    </Container>
  );
};
