import { ReactComponent as TemplateIcon } from "assets/svg/acoustic.svg";
import { SurveyTemplate } from "../../types";

export const erasAndGenres: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Acoustic Version",
  description:
    "Allow your fans to vote on which song you'll be playing an acoustic version of today.",
  templateId: "erasAndGenres",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Acoustic Version",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "Which song for the acoustic session?",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-livestream-038a1f"
        : "pp-livestream-b4eefb",
    fields: [
      {
        name: "date",
        type: "text",
        label: "When does your livestream take place?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.date ? false : "This field cannot be left blank",
      },
    ],
  },
};
