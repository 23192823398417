import {
  HourlyAnalytics,
  LinkAttributes,
} from "@max/common/src/setpage/analytics";
import { HourlyAnalyticsWithId } from ".";

const MetricNames = [
  "totalVisits",
  "uniqueVisits",
  "totalClicks",
  "signups",
] as const;
type MetricName = (typeof MetricNames)[number];

const DimensionNames = [
  "clicksByDsp",
  "clicksBySection",
  "metricsByDay",
  "topLinks",
  "topReleases",
  "topSources",
] as const;
type DimensionName = (typeof DimensionNames)[number];

export type Metric = {
  id: string;
  label: string;
  cvr?: number | null;
  clicks?: number;
  visits?: number;
  fill?: string;
  image?: string;
  // [key: string]: string | number;
};

export type RawLinkMetrics = Partial<
  Record<string, LinkAttributes & { image?: string }>
>;

export type DimensionType = Record<DimensionName, Metric[]>;
export type MetricType = Record<MetricName, number>;
export type Metrics = DimensionType &
  MetricType & { modules: HourlyAnalytics["modules"] };

const incrementLinkClicks = (
  current: RawLinkMetrics,
  next?: RawLinkMetrics,
): RawLinkMetrics => {
  const updatedObject = { ...current };
  if (next) {
    for (const [key, value] of Object.entries(next)) {
      let increment = (updatedObject[key]?.clicks || 0) + value.clicks;
      updatedObject[key] = {
        ...value,
        clicks: increment,
      };
    }
  }
  return updatedObject;
};

const incrementObject = (
  obj1: Record<string, number>,
  obj2?: Record<string, number>,
) => {
  if (!obj2) {
    return obj1;
  }
  const newObj = { ...obj1 };
  for (const [key, value] of Object.entries(obj2)) {
    newObj[key] = (newObj[key] || 0) + value;
  }
  return newObj;
};

export const aggregateHourlyMetrics = (
  hourlyMetrics?: HourlyAnalyticsWithId[],
) => {
  let aggregatedMetrics = {} as HourlyAnalytics;
  hourlyMetrics?.forEach((metricHour) => {
    aggregatedMetrics.modules = {
      ...aggregatedMetrics?.modules,
      ...metricHour.modules,
    };
    aggregatedMetrics.releases = {
      ...aggregatedMetrics?.releases,
      ...metricHour.releases,
    };
    aggregatedMetrics.links = incrementLinkClicks(
      aggregatedMetrics.links,
      metricHour.links,
    );
    aggregatedMetrics.sources = incrementObject(
      aggregatedMetrics.sources,
      metricHour.sources,
    );
    aggregatedMetrics.visits = incrementObject(
      aggregatedMetrics?.visits,
      metricHour.visits,
    ) as HourlyAnalytics["visits"];
    aggregatedMetrics.signups =
      (aggregatedMetrics.signups || 0) + (metricHour.signups || 0);
  });
  return aggregatedMetrics;
};
