import { ReactComponent as SvgCircleBlock } from "assets/svg/circle-block.svg";
import { ReactComponent as SvgCircleChecked } from "assets/svg/circle-checked.svg";
import { SvgErrorAlt } from "melodies-source/Svgs/ErrorAlt";
import { Subtitle2 } from "melodies-source/Text";
import { useEffect } from "react";
import styled from "styled-components";
import { useConfigFlags, useConfigSlice } from "../Components/BuilderContext";
import { CONTEST_FIELDS } from "./Contest";

const Addons = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

const Message = styled.div<{ $error?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(p) => (p.$error ? "#e71e3d" : "#333333")};

  svg {
    color: ${(p) => (p.$error ? "#e71e3d" : "#ffc226")};
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${Subtitle2} {
      display: none;
    }
  }
`;

const Status = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: ${(p) => (p.$active ? "#333" : "#999")};

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: ${(p) => (p.$active ? "#333" : "#999")};
    }
  }
`;

export const ContestTitleAddon = () => {
  const { fields, validation } = useConfigSlice(CONTEST_FIELDS);
  const { flags, setFlagValue } = useConfigFlags();

  const touchedFields = Object.entries(fields).reduce(
    (prev, [key, val]) => (val.isTouched ? [...prev, key] : prev),
    [],
  );
  const hasSomeError = touchedFields.some((key) => validation[key] !== false);
  const allValid = Object.entries(validation).every(([, v]) => v === false);

  useEffect(() => {
    if (allValid && flags.showContestConfirmationMessage) {
      setFlagValue("showContestConfirmationMessage", false);
    }
  }, [allValid, flags.showContestConfirmationMessage, setFlagValue]);

  return (
    <Addons>
      {hasSomeError ? (
        <Message $error={true}>
          <SvgErrorAlt />
          <Subtitle2>Please resolve the highlighted fields</Subtitle2>
        </Message>
      ) : flags.showContestConfirmationMessage ? (
        <Message>
          <SvgErrorAlt />
          <Subtitle2>Please complete the required fields</Subtitle2>
        </Message>
      ) : null}
      <Status $active={fields.isContest.content}>
        {fields.isContest.content ? (
          <>
            <SvgCircleChecked /> ON
          </>
        ) : (
          <>
            <SvgCircleBlock /> OFF
          </>
        )}
      </Status>
    </Addons>
  );
};
