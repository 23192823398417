import { CenterFlexRow, FlexColumn, FlexRow, Font16 } from "Routes/styled";
import styled from "styled-components";
import { TextInput } from "melodies-source/TextInput";
import { SvgSearch } from "melodies-source/Svgs/Search";
import { useEffect, useState } from "react";
import DropdownMenu from "Components/DropdownMenu";
import { EventCard, EventCardLoader } from "Components/EventCard/index";
import Pagination from "Components/Pagination";
import { useNextShow, useSetLiveEvents } from "hooks";
import { useTimeContext } from "Components";
import { svg } from "svg";
import Skeleton from "react-loading-skeleton";
import { useCustomAppContext } from "contexts/CustomAppContext";
import { ReactComponent as CalendarIcon } from "assets/svg/calendar.svg";

const RESULTS_PER_PAGE = 5;

const SetLive = () => {
  const [searchInput, setSearchInput] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showType, setShowType] = useState<"upcoming" | "past">("past");
  const { setLiveEvents, totalEvents, loading } = useSetLiveEvents({
    query: searchInput,
    page: currentPage,
    showType,
    resultsPerPage: RESULTS_PER_PAGE,
  });
  const { nextShow } = useNextShow();
  const { time } = useTimeContext();
  const { customApp, language } = useCustomAppContext();

  useEffect(() => {
    setCurrentPage(1);
  }, [showType]);

  return (
    <>
      <SearchInputContainer>
        <SearchInput
          value={searchInput}
          onChange={setSearchInput}
          placeholder={`Enter Venue to Search for ${language.event.type.singularWithArticle}`}
          leftIcon={<SvgSearch />}
        />
      </SearchInputContainer>
      <>
        <ShowsRow>
          {nextShow && nextShow.startsAt < time.toMillis() && (
            <EventCard
              section="reporting"
              event={nextShow}
              showType="upcoming"
              live
            />
          )}
        </ShowsRow>
        <ShowsRow>
          <DropdownMenu
            options={[
              {
                label: `Upcoming ${language.event.type.plural}`,
                value: "upcoming",
              },
              { label: `Past ${language.event.type.plural}`, value: "past" },
            ]}
            value={showType}
            onClick={(val) => setShowType(val)}
          />
          <ShowCount as="div">
            {loading ? (
              <Skeleton width={70} />
            ) : (
              `${totalEvents} ${
                totalEvents === 1
                  ? language.event.type.singular
                  : language.event.type.plural
              }`
            )}
          </ShowCount>
        </ShowsRow>
        {
          <ShowsContainer>
            {loading ? (
              <EventCardLoader />
            ) : (
              setLiveEvents.map((show) => (
                <EventCard
                  section="reporting"
                  event={show}
                  showType={showType}
                  key={`event-${show.id}`}
                />
              ))
            )}
            {!setLiveEvents.length && !loading && !searchInput && (
              <NoShowsContainer>
                {customApp ? (
                  <CalendarIcon width={80} height={80} />
                ) : (
                  <MusicIcon />
                )}
                <NoShowsText>
                  {customApp ? "No" : "This artist has no"} {showType}{" "}
                  {language.event.type.plural.toLowerCase()} to display.
                </NoShowsText>
              </NoShowsContainer>
            )}
            {loading ? (
              <PaginationContainer>
                <Skeleton width={100} />
              </PaginationContainer>
            ) : (
              totalEvents > 0 && (
                <PaginationContainer>
                  <Pagination
                    selectedPage={currentPage}
                    resultsPerPage={RESULTS_PER_PAGE}
                    totalResults={totalEvents}
                    onSetPage={setCurrentPage}
                    scrollOnChange
                  />
                </PaginationContainer>
              )
            )}
            {searchInput && !loading && !setLiveEvents.length && (
              <NoShowsContainer>
                <LargeSearchIcon />
                <NoShowsText>
                  No {language.event.type.plural} match your criteria. Try using
                  a different term.
                </NoShowsText>
              </NoShowsContainer>
            )}
          </ShowsContainer>
        }
      </>
    </>
  );
};

export default SetLive;

const LargeSearchIcon = styled(SvgSearch)`
  width: 110px;
  height: 110px;
  color: #e8e5f1;
`;

const MusicIcon = styled(svg.MusicAltIcon)``;

const NoShowsContainer = styled(FlexColumn)`
  align-items: center;
  background: var(--component-background-color);
  border-radius: 20px;
  padding: 52px 53px 60px 53px;
  max-width: 372px;
  margin: 80px auto;
  gap: 5px;
`;

const NoShowsText = styled(Font16)`
  color: var(--text-color);
  text-align: center;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 39px;
`;

const SearchInput = styled(TextInput)`
  max-width: 380px;
`;

const SearchInputContainer = styled(FlexRow)`
  padding: 31px 43px 18px 40px;

  ${({ theme }) => theme.media.mobile} {
    padding: 17px 20px;
  }
`;

const ShowsContainer = styled.div`
  margin: 0 40px 39px 40px;

  & > div:nth-last-child(n + 2) {
    margin-bottom: 20px;
  }

  ${({ theme }) => theme.media.mobile} {
    margin: 0 20px 31px 20px;
  }
`;

const ShowCount = styled(Font16)`
  font-style: italic;
  color: var(--text-color);
`;

const ShowsRow = styled(CenterFlexRow)`
  justify-content: space-between;
  margin: 20px 40px 20px 40px;

  ${({ theme }) => theme.media.mobile} {
    margin: 20px;
  }
`;
