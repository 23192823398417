import * as yup from "yup";
import {
  addHttpsProtocolToUrl,
  hasValidServiceDomain,
  validVideoUrl,
} from "Utils/url";

export const REQUIRED_MSG = "This field is required";

export const yupStrictPassword = yup
  .string()
  .required(REQUIRED_MSG)
  .min(8, "Must contain at least 8 characters.")
  .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character.")
  .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character.")
  .matches(
    /^(?=.*[0-9])|(?=.*[!@#\$%\^&\*])/,
    "Must contain at least one number or special character, (e.g., ! @ # $ % ^ & *)",
  );

export const yupPasswordConfirmation = yup
  .string()
  .oneOf([yup.ref("password")], "Passwords do not match")
  .required(REQUIRED_MSG);

export const yupPassword = yup
  .string()
  .min(6, "Password should have at least 6 characters")
  .required(REQUIRED_MSG);

export const yupEmail = yup
  .string()
  .email("Please enter a valid email")
  .required(REQUIRED_MSG);

export const yupRequired = yup.string().nullable().required(REQUIRED_MSG);

export const yupOptional = yup.string().optional();

export const yupNumber = yup.string().matches(/^\d+$/, "Must be a number");

export const yupServiceUrl = (domains: string[], name: string) =>
  yup
    .string()
    .socialHandle()
    .serviceDomain({ domains, name })
    .addHttpsProtocol()
    .url();

yup.addMethod(yup.string, "addHttpsProtocol", function addHttpsProtocol() {
  return this.transform((val: string) =>
    val ? addHttpsProtocolToUrl(val) : "",
  );
});

yup.addMethod(yup.string, "videoUrl", function videoUrl(message) {
  return this.test("videoUrl", message, validVideoUrl);
});

yup.addMethod(yup.string, "socialHandle", function socialHandle() {
  return this.test((value, ctx) => {
    if (value?.startsWith("https://@")) {
      throw ctx.createError({
        message: "Please paste your profile URL, rather than your handle.",
      });
    }
    return true;
  });
});

yup.addMethod(
  yup.string,
  "serviceDomain",
  function serviceDomain(service: { domains: string[]; name: string }) {
    return this.test((value, ctx) => {
      if (!value) {
        return true;
      }

      const hasValidDomain = hasValidServiceDomain(value, service);

      if (!hasValidDomain) {
        throw ctx.createError({
          message: `Please paste a valid URL for ${service.name}`,
        });
      }

      return true;
    });
  },
);
