import styled from "styled-components";

export const PreviewImage = styled.img`
  border-radius: 10px;
  border: 3px solid #dcdcdc;
  width: 100%;
  max-width: 300px;
  height: auto;
  object-fit: contain;
  object-position: center;
`;
