import { SurveyTemplate } from "Routes/SetFan/dashboard/Components/Templates/types";
import { ReactComponent as IconSponsor } from "assets/svg/sponsor.svg";

export const tourSponsor: SurveyTemplate = {
  icon: <IconSponsor />,
  title: "Find A Tour Sponsor",
  description:
    "Ask your fans about themselves to build a fan profile and help land a tour sponsor.",
  complexity: "low",
  contestSuggestion: "Sweepstakes",
  templateId: "tourSponsor",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: "Tour Brand Sponsor",
        },
      },
      header: {
        content: {
          en: "Help Make Our Tour Even Better",
        },
      },
      body: {
        content: {
          en: "This is your chance to help make our tour even better by answering a few questions. Please take a few minutes to answer these questions that might help land a tour sponsor. If you fill the whole thing out, you'll be entered to win VIP passes to the tour!",
        },
      },
      numberOfCategories: {
        content: "6",
      },
      displayedCategories: {
        content: [
          "demographics",
          "fooddrink",
          "alcohol",
          "clothing",
          "vehicle",
          "entertainment",
        ],
      },
      thankYouHeader: {
        content: {
          en: "Thanks for your Help!",
        },
      },
      thankYouBody: {
        content: {
          en: "We appreciate you doing your part to help with the tour! ",
        },
      },
    },
    surveyQuestions: [
      {
        options: [
          {
            label: {
              en: "Allow for more tour stops",
            },
          },
          {
            label: {
              en: "Enable a more elaborate stage show",
            },
          },
          {
            label: {
              en: "Fund giveaways of free stuff at the show",
            },
          },
          {
            label: {
              en: "Help get the word out about the tour",
            },
          },
          {
            label: {
              en: "Bring more staff to make the tour run easily",
            },
          },
        ],
        type: "MultipleSelectQuestion",
        requiredMin: 1,
        actionRequired: false,
        label: {
          en: "How would you like to see a brand sponsor help the next tour?",
        },
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-tour---tou-57a6d9"
        : "pp-tour--tour-146904",
  },
};
