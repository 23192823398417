import styled from "styled-components";
import Modal from "../modal";
import { Button } from "melodies-source/Button";
import { useState } from "react";
import { useDesktopMediaQuery } from "hooks";
import { CircularLoader, useArtistContext } from "Components";
import { TextInput } from "melodies-source/TextInput";
import CheckCircleIcon from "../CheckCircleIcon";
import firebase from "firebase/compat/app";

export const KlaviyoIntegrationModal = ({
  artistGroupId,
  isOpen,
  onClose,
}: {
  artistGroupId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const [status, setStatus] = useState<
    "addApiKey" | "testingApiKey" | "apiKeyAdded"
  >("addApiKey");
  const [apiAddError, setApiAddError] = useState("");
  const [apiKey, setApiKey] = useState("");
  const { logAction } = useArtistContext();

  const isDesktop = useDesktopMediaQuery();

  const onSubmitApiKey = async () => {
    setApiAddError("");
    setStatus("testingApiKey");

    try {
      const response = await firebase
        .functions()
        .httpsCallable("services-klaviyoActions")({
        action: "connect",
        artistGroupId,
        apiKey,
      });

      if (response.data !== "success") {
        throw new Error("Error testing api key, please verify input.");
      }

      await logAction("portal_connect_klaviyo");

      setStatus("apiKeyAdded");
    } catch (error) {
      console.error(error);

      setApiAddError((error as Error).message);

      setStatus("addApiKey");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} service="klaviyo">
      {status === "addApiKey" && (
        <>
          <HeaderText isDesktop={isDesktop}>Create API Key</HeaderText>
          <br />
          <BodyText isDesktop={isDesktop}>
            Please create a private API key and enter it below. Please do not
            share your key via email; our system will encrypt your key:
          </BodyText>
          <br />
          <TextInput
            placeholder="Enter API Key"
            value={apiKey}
            onChange={(text) => setApiKey(text)}
            hasError={!!apiAddError}
          />
          {!!apiAddError && (
            <ErrorText isDesktop={isDesktop}>{apiAddError}</ErrorText>
          )}
          <br />
          <FooterText isDesktop={isDesktop}>
            If you need help with creating one,{" "}
            <a href="https://help.klaviyo.com" target="__blank">
              click here
            </a>{" "}
            to read Klaviyo's guide.
          </FooterText>
          <br />
          <StyledButton disabled={!apiKey} onClick={onSubmitApiKey}>
            Add key and test connection
          </StyledButton>
        </>
      )}
      {status === "testingApiKey" && (
        <>
          <CircularLoader
            height={isDesktop ? 60 : 41}
            sx={{ marginBottom: 10 }}
          />
          <BodyText isDesktop={isDesktop}>Please wait...</BodyText>
          <BodyText isDesktop={isDesktop}>
            We're adding the API Key and testing connection
          </BodyText>
        </>
      )}
      {status === "apiKeyAdded" && (
        <>
          <CheckCircleIcon />
          <BodyText isDesktop={isDesktop}>Success!</BodyText>
          <BodyText isDesktop={isDesktop}>
            Connection stable and API Key added
          </BodyText>
          <Button
            style={{ marginTop: 20 }}
            onClick={() => onBackfillClickHandler(artistGroupId, onClose)}
          >
            <span style={{ marginRight: "0.35rem" }}>
              Sync all existing fans to Klaviyo
            </span>
          </Button>
        </>
      )}
    </Modal>
  );
};

const onBackfillClickHandler = async (
  artistGroupId: string,
  onClose: () => void,
) => {
  await firebase
    .firestore()
    .collection(
      `/artist_groups/${artistGroupId}/artist_group_private/klaviyo/backfills`,
    )
    .add({ backfillRequest: new Date() });

  onClose();
};

export const KlaviyoRemovalModal = ({
  artistGroupId,
  onClose,
  isOpen,
}: {
  artistGroupId: string;
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [removingKlaviyo, setRemovingKlaviyo] = useState(false);
  const [removalError, setRemovalError] = useState("");
  const { logAction } = useArtistContext();

  const isDesktop = useDesktopMediaQuery();

  const onRemoveKlaviyo = async () => {
    setRemovingKlaviyo(true);

    setRemovalError("");

    try {
      await firebase.functions().httpsCallable("services-klaviyoActions")({
        action: "remove",
        artistGroupId,
      });

      await logAction("portal_remove_klaviyo");

      onClose();
    } catch (error) {
      console.error((error as Error).message);

      setRemovalError(
        `Error removing Klaviyo account: ${(error as Error).message}`,
      );
    }

    setRemovingKlaviyo(false);
  };

  const modalBody = () => {
    if (removalError) {
      return (
        <HeaderText style={{ color: "#e45c52" }} isDesktop={isDesktop}>
          {removalError}
        </HeaderText>
      );
    }

    if (removingKlaviyo) {
      return (
        <>
          <CircularLoader height={30} sx={{ marginRight: "0.5rem" }} />
          <br />
          <BodyText isDesktop={isDesktop}>Removing Klaviyo account...</BodyText>
        </>
      );
    }
    return (
      <HeaderText isDesktop={isDesktop}>
        Are you sure you want to unlink your Klaviyo account?
      </HeaderText>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} service="klaviyo">
      {modalBody()}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          marginTop: 40,
        }}
      >
        <Button
          onClick={() => onRemoveKlaviyo()}
          style={{ marginRight: "2rem" }}
          disabled={removingKlaviyo}
        >
          Confirm
        </Button>
        <Button
          variant="secondary"
          onClick={onClose}
          disabled={removingKlaviyo}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};

const BodyText = styled.div<{ isDesktop: boolean }>`
  font-weight: 400;
  font-size: ${({ isDesktop }) => (isDesktop ? "17px" : "12px")};
  line-height: ${({ isDesktop }) => (isDesktop ? "25.5px" : "18px")};
`;

const ErrorText = styled.div<{ isDesktop: boolean }>`
  color: #e45c52;
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "min(1.3vw, 12px)" : "13px")};
  line-height: 19.5px;
  margin-top: 5px;
  align-self: flex-start;
  margin-left: 10px;
`;

const FooterText = styled.div<{ isDesktop: boolean }>`
  font-weight: 500;
  font-size: ${({ isDesktop }) => (isDesktop ? "13px" : "10px")};
  line-height: 15px;
`;

const HeaderText = styled.div<{ isDesktop: boolean }>`
  font-weight: 600;
  font-size: ${({ isDesktop }) => (isDesktop ? "20px" : "17px")};
  line-height: ${({ isDesktop }) => (isDesktop ? "30px" : "25.5px")};
`;

const StyledButton = styled(Button)`
  max-width: 385px;
`;
