import { useState } from "react";
import styled from "styled-components";
import { IconContainer, SelectionCard } from "Components/SelectionCard";
import { Button } from "Components";
import { freeResponse } from "./FreeResponseQuestionModal";
import { imageQuestion } from "./ImageQuestionModal";
import { multipleChoice } from "./MultipleChoiceQuestionModal";
import { ratingQuestion } from "./RatingQuestionModal";
import { image } from "./AddImageModal";
import { text } from "./AddTextModal";
import {
  BaseQuestionModal,
  QuestionModalProps,
  QuestionType,
  QuestionTypeMeta,
} from "./BaseQuestionModal";
import { Body1, H2 } from "melodies-source/Text";
import { useScrollLockContext } from "contexts/ScrollLockContext";
import {
  ResponsiveFooterActions,
  ResponsiveModal,
} from "Components/ResponsiveModal";

export const AddQuestionModal = (props: QuestionModalProps) => {
  if (!props.state.route) {
    return <AddQuestion {...props} />;
  }

  const modalMeta = (() => {
    switch (props.state.route) {
      case "freeResponseQuestion":
        return freeResponse;
      case "imageQuestion":
        return imageQuestion;
      case "multipleChoiceQuestion":
        return multipleChoice;
      case "ratingQuestion":
        return ratingQuestion;
      case "image":
        return image;
      case "text":
        return text;
      default:
        throw new Error(
          `invalid route for add question modal: ${props.state.route}`,
        );
    }
  })();

  return <BaseQuestionModal {...{ ...props, ...modalMeta }} />;
};

const QuestionTypeCard = styled(SelectionCard)<{ last: boolean }>`
  ${({ last }) => !last && "margin-bottom: 15px"};
  ${IconContainer} {
    svg {
      width: 32px;
      height: 32px;
    }
  }
`;

const questionTypes: QuestionTypeMeta[] = [
  freeResponse,
  imageQuestion,
  multipleChoice,
  ratingQuestion,
];

const AddQuestion = (props: QuestionModalProps) => {
  const { unlockScroll } = useScrollLockContext();
  const [selectedType, setSelectedType] = useState<QuestionType>();

  return (
    <ResponsiveModal
      isOpen={props.state.isOpen}
      onClose={() => {
        unlockScroll();
        props.dispatch({ type: "closeModal" });
      }}
      variant="large"
      header={
        <>
          <StyledHeader>Select a Question Type</StyledHeader>
          <Body1>
            Each type has a unique format and can be used to gather different
            kinds of responses.
          </Body1>
        </>
      }
    >
      {questionTypes.map((qType, idx) => (
        <QuestionTypeCard
          {...{
            ...qType,
            onClick: () => {
              setSelectedType(
                selectedType === qType.key ? undefined : qType.key,
              );
            },
            selected: selectedType === qType.key,
            last: idx === questionTypes.length - 1,
          }}
        />
      ))}
      <ResponsiveFooterActions>
        <Button
          style={{ color: "#1b0076" }}
          variant="tertiary"
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() =>
            props.dispatch({ type: "setRoute", payload: selectedType })
          }
          disabled={!selectedType}
          style={{ minWidth: 199 }}
        >
          Next
        </Button>
      </ResponsiveFooterActions>
    </ResponsiveModal>
  );
};

const StyledHeader = styled(H2)`
  color: var(--main-color) !important;
`;
