import {
  DndContext,
  DragEndEvent,
  DragStartEvent,
  MouseSensor,
  PointerActivationConstraint,
  TouchSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  rectSortingStrategy,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { ReactNode, useState } from "react";
import styled from "styled-components";

type Variant = "grid" | "column" | "row";

/*
 * Exposes a prop `activeId` to show drag overlay if needed
 */
export function DndContainer<T extends { id?: UniqueIdentifier }>({
  activationConstraint,
  items,
  onDragEnd,
  options,
  variant,
  children,
}: {
  activationConstraint?: PointerActivationConstraint;
  items: T[];
  onDragEnd: (event: DragEndEvent) => void;
  variant: Variant;
  options?: { columns?: number; gap?: number };
  children: (props: { activeId: UniqueIdentifier }) => ReactNode;
}) {
  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint }),
    useSensor(TouchSensor, { activationConstraint }),
  );
  const [activeId, setActiveId] = useState<UniqueIdentifier>(null);

  return (
    <DndContext
      sensors={sensors}
      onDragStart={handleDragStart}
      onDragEnd={(e) => {
        document.documentElement.removeAttribute("style");
        onDragEnd(e);
        setActiveId(null);
      }}
      onDragCancel={handleDragCancel}
      collisionDetection={closestCenter}
    >
      <SortableContext
        items={items.map((item) => item.id)}
        strategy={
          variant === "grid"
            ? rectSortingStrategy
            : variant === "column"
            ? verticalListSortingStrategy
            : horizontalListSortingStrategy
        }
      >
        {variant === "grid" && (
          <GridContainer columns={options?.columns || 4} gap={options?.gap}>
            {children({ activeId })}
          </GridContainer>
        )}
        {variant === "column" && (
          <FlexContainer variant={variant} gap={options?.gap}>
            {children({ activeId })}
          </FlexContainer>
        )}
        {variant === "row" && (
          <FlexContainer variant={variant} gap={options?.gap}>
            {children({ activeId })}
          </FlexContainer>
        )}
      </SortableContext>
    </DndContext>
  );

  function handleDragStart(event: DragStartEvent) {
    if (!event.active) {
      return;
    }
    document.documentElement.setAttribute("style", "scroll-behavior: auto");
    setActiveId(event.active.id);
  }

  function handleDragCancel() {
    setActiveId(null);
  }
}

const FlexContainer = styled.div<{ variant: "column" | "row"; gap?: number }>`
  display: flex;
  flex-direction: ${({ variant }) => variant};
  ${({ gap }) => typeof gap === "number" && `gap: ${gap}px;`};
  & > div {
    // this is needed to handle quick dragging on mobile
    touch-action: manipulation;
  }
`;

const GridContainer = styled.div<{ columns: number; gap?: number }>`
  display: grid;
  grid-template-columns: ${({ columns }) =>
    `repeat(${columns}, minmax(0, 1fr))`};
  gap: ${({ gap }) => (typeof gap === "number" ? `${gap}px;` : "10px;")};

  & > div {
    // this is needed to handle quick dragging on mobile
    touch-action: manipulation;
  }
`;
