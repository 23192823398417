import React from "react";
import { Redirect } from "react-router-dom";
import { PhoneMFA } from "./PhoneMFA";
import { TotpMFA } from "./TotpMFA";
import {
  PhoneMultiFactorGenerator,
  TotpMultiFactorGenerator,
} from "firebase/auth";
import { SESSION_PATHS } from "../paths";
import { useUser } from "auth";

export const LoginWithMFA: React.FC = () => {
  const { mfaResolver } = useUser();

  return (
    <>
      {mfaResolver?.hints[0]?.factorId ===
      PhoneMultiFactorGenerator.FACTOR_ID ? (
        <PhoneMFA />
      ) : mfaResolver?.hints[0]?.factorId ===
        TotpMultiFactorGenerator.FACTOR_ID ? (
        <TotpMFA />
      ) : (
        <Redirect to={SESSION_PATHS.LOGIN_WITH_PASSWORD} />
      )}
    </>
  );
};
