import { ReactComponent as IconMessageMusic } from "assets/svg/message-music.svg";
import { SurveyTemplate } from "../../types";

export const tellUsYourStory: SurveyTemplate = {
  icon: <IconMessageMusic />,
  title: "Tell Us Your Story",
  description:
    "Ask fans to tell a story from their lives that relates to the theme of a new release.",
  templateId: "tellUsYourStory",
  template: (v, formState) => ({
    fields: {
      description: { content: { en: formState.title } },
    },

    surveyQuestions: [
      {
        type: "TextQuestion",
        id: "question_1",
        multiline: true,
        label: {
          en: "How does this release relate to you?",
        },
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-tell-us-yo-ea06c3"
        : "pp-tell-us-yo-27afd3",
    fields: [
      {
        name: "format",
        type: "radio",
        label: "What format is this release?",
        options: ["Single", "EP", "Album"],
        getValidation: (fields) =>
          !!fields.format ? false : "You must select an option.",
      },
      {
        name: "title",
        type: "text",
        label: "What's the title of the release?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.title ? false : "This field cannot be left blank",
      },
      {
        name: "releaseDate",
        type: "text",
        label: "When is it going to be released?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.releaseDate ? false : "This field cannot be left blank",
      },
      {
        name: "about",
        type: "text",
        label:
          "What's the theme/inspiration for the new release or what it's about",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.about ? false : "This field cannot be left blank",
      },
    ],
  },
};
