import { SurveyBuilderConfig } from "@max/common";
import * as yup from "yup";
import { customPrizeOption } from "../constants/prizes";

const schema = yup.object().shape({
  fields: yup.object().shape({
    description: yup.object().shape({
      content: yup.object().shape({
        en: yup.string().required("Enter a description"),
      }),
    }),
    header: yup.object().shape({
      content: yup.object().shape({
        en: yup.string().required("Enter a heading"),
      }),
    }),
    body: yup.object().shape({
      content: yup.object().shape({
        en: yup.string().required("Add a body"),
      }),
    }),
    isContest: yup.object().shape({
      content: yup.boolean().required(),
    }),
    contestPrize: yup.object().when("isContest", {
      is: (isContest) => isContest.content,
      then: (schema) =>
        schema.shape({
          content: yup.object().shape({
            en: yup.string().required("Enter a prize"),
          }),
        }),
      otherwise: (schema) =>
        schema.shape({
          content: yup.object().shape({
            en: yup.string(),
          }),
        }),
    }),
    contestCustomPrizeDescription: yup
      .object()
      .when(["isContest", "contestPrize"], {
        is: (isContest, contestPrize) =>
          isContest.content &&
          contestPrize.content.en === customPrizeOption.value,
        then: (schema) =>
          schema.shape({
            content: yup.object().shape({
              en: yup.string().required("Enter a prize description"),
            }),
          }),
        otherwise: (schema) =>
          schema.shape({
            content: yup.object().shape({
              en: yup.string(),
            }),
          }),
      }),
    hasContestCustomRules: yup.object().when("isContest", {
      is: (isContest) => isContest.content,
      then: (schema) =>
        schema.shape({
          content: yup
            .boolean()
            .nullable()
            .required("Select a sweepstakes rules option"),
        }),
      otherwise: (schema) =>
        schema.shape({
          content: yup.boolean().nullable(),
        }),
    }),
    contestCustomRulesUrl: yup
      .object()
      .when(["isContest", "hasContestCustomRules"], {
        is: (isContest, hasContestCustomRules) =>
          isContest.content && hasContestCustomRules.content,
        then: (schema) =>
          schema.shape({
            content: yup.object().shape({
              en: yup
                .string()
                .required("Enter sweepstakes rules")
                .url("Please enter a valid URL"),
            }),
          }),
        otherwise: (schema) =>
          schema.shape({
            content: yup.object().shape({
              en: yup.string().url("Please enter a valid URL"),
            }),
          }),
      }),
    contestEndDate: yup.object().when(["isContest", "hasContestCustomRules"], {
      is: (isContest, hasContestCustomRules) =>
        isContest.content && hasContestCustomRules.content === false,
      then: (schema) =>
        schema.shape({
          content: yup.mixed().required("Select a sweepstakes end date"),
        }),
      otherwise: (schema) =>
        schema.shape({
          content: yup.mixed(),
        }),
    }),
    contestPrizeValue: yup
      .object()
      .when(["isContest", "hasContestCustomRules"], {
        is: (isContest, hasContestCustomRules) =>
          isContest.content && hasContestCustomRules.content === false,
        then: (schema) =>
          schema.shape({
            content: yup
              .number()
              .transform((value) => (isNaN(value) ? undefined : value))
              .required("Enter a sweepstakes prize value")
              .positive("Enter a valid sweepstakes prize value")
              .max(
                600,
                "If prize value exceeds $${max}, please provide your own rules",
              ),
          }),
        otherwise: (schema) =>
          schema.shape({
            content: yup.number().nullable(),
          }),
      }),
    thankYouBody: yup.object().shape({
      content: yup.object().shape({
        en: yup.string().required("Add a thank you body"),
      }),
    }),
    thankYouHeader: yup.object().shape({
      content: yup.object().shape({
        en: yup.string().required("Enter a thank you heading"),
      }),
    }),
    phoneNumberValidation: yup.object().shape({
      content: yup.string(),
    }),
    hasFeaturedCta: yup.object().shape({
      content: yup.boolean().nullable(),
    }),
    featuredCtaHeaderText: yup.object().when("hasFeaturedCta", {
      is: (hasFeaturedCta) => hasFeaturedCta.content === true,
      then: (schema) =>
        schema.shape({
          content: yup.object().shape({
            en: yup.string().required("Enter Featured CTA header text"),
          }),
        }),
      otherwise: (schema) =>
        schema.shape({
          content: yup.object().shape({
            en: yup.string(),
          }),
        }),
    }),
    featuredCtaButtonText: yup.object().when("hasFeaturedCta", {
      is: (hasFeaturedCta) => hasFeaturedCta.content === true,
      then: (schema) =>
        schema.shape({
          content: yup.object().shape({
            en: yup.string().required("Enter Featured CTA button text"),
          }),
        }),
      otherwise: (schema) =>
        schema.shape({
          content: yup.object().shape({
            en: yup.string(),
          }),
        }),
    }),
    featuredCtaUrl: yup.object().when("hasFeaturedCta", {
      is: (hasFeaturedCta) => hasFeaturedCta.content === true,
      then: (schema) =>
        schema.shape({
          content: yup.object().shape({
            en: yup
              .string()
              .addHttpsProtocol()
              .url("Enter a valid Featured CTA url")
              .required("Enter Featured CTA url"),
          }),
        }),
      otherwise: (schema) =>
        schema.shape({
          content: yup.object().shape({
            en: yup.string(),
          }),
        }),
    }),
    customTermsAndPrivacyPolicy: yup
      .object()
      .when("useCustomTermsAndPrivacyPolicy", {
        is: (useCustomTermsAndPrivacyPolicy) =>
          useCustomTermsAndPrivacyPolicy.content === true,
        then: (schema) =>
          schema.shape({
            content: yup.object().shape({
              en: yup
                .string()
                .required("Enter a custom terms and privacy policy"),
            }),
          }),
        otherwise: (schema) =>
          schema.shape({
            content: yup.object().shape({
              en: yup.string(),
            }),
          }),
      }),
    termsAndPrivacyPolicySeparateLineItems: yup.object().shape({
      content: yup.object().shape({ en: yup.string() }),
    }),
    customSmsOptIn: yup.object().when("useCustomSmsOptIn", {
      is: (useCustomSmsOptIn) => useCustomSmsOptIn.content === true,
      then: (schema) =>
        schema.shape({
          content: yup.object().shape({
            en: yup.string().required("Enter a custom SMS opt in"),
          }),
        }),
      otherwise: (schema) =>
        schema.shape({
          content: yup.object().shape({ en: yup.string() }),
        }),
    }),
  }),
});

export const validateConfig = (config: SurveyBuilderConfig) => {
  try {
    schema.validateSync(config, { abortEarly: false });
    return false;
  } catch (err) {
    return err.errors as string[];
  }
};

export const validateField = (field: string, config: SurveyBuilderConfig) => {
  try {
    schema.validateSyncAt(field, config);
    return false;
  } catch (err) {
    return err.message;
  }
};
