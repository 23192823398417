import { FlexColumn, FlexRow } from "Components/Flex";
import { SvgRight } from "melodies-source/Svgs/Right";
import { Body1, Subtitle1 } from "melodies-source/Text";
import styled, { css } from "styled-components";

interface TipCardProps extends React.HTMLAttributes<HTMLDivElement> {
  image: string;
  description: string;
  actionLabel: string;
  orientation?: "vertical" | "horizontal";
  imageWidth?: string;
  justifyImage?: string;
  alignImage?: string;
}

export const TipCard = ({
  image,
  description,
  actionLabel,
  onClick,
  orientation = "vertical",
  color,
  imageWidth,
  justifyImage,
  alignImage,
  ...props
}: TipCardProps) => {
  return (
    <Container {...props} isVertical={orientation === "vertical"}>
      <ImageContainer style={{ backgroundColor: color }}>
        <Image
          width={imageWidth}
          src={image}
          style={{
            width: imageWidth,
            justifySelf: justifyImage || "center",
            alignSelf: alignImage || "flex-end",
          }}
        />
      </ImageContainer>
      <Content>
        <FlexRow style={{ flexGrow: 1 }}>
          <Body1>{description}</Body1>
        </FlexRow>
        <Button xSpaceBetween onClick={onClick}>
          <Subtitle1>{actionLabel}</Subtitle1>
          <SvgRight />
        </Button>
      </Content>
    </Container>
  );
};

const ImageContainer = styled.div`
  display: grid;
  width: 100%;
  height: auto;
  aspect-ratio: 1.87;
`;

const Button = styled(FlexRow)`
  color: #8017f6;

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${Subtitle1} {
      font-size: 12px;
      line-height: 18px;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const Content = styled(FlexColumn)`
  background-color: #ffffff;
  color: #333333;
  padding: 17px 15px;
  flex-grow: 1;
  gap: 16px;
  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 14px 12px;
    ${Body1} {
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

const Container = styled(FlexColumn)<{ isVertical?: boolean }>`
  border: 1px solid #dcdfe0;
  border-radius: 12px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  ${(p) =>
    !p.isVertical &&
    css`
      height: 150px;
      flex-direction: row;
      ${ImageContainer} {
        aspect-ratio: 1.13;
        height: 100%;
        width: auto;
      }
    `};
`;
