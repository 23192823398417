import React, { useState } from "react";
import styled from "styled-components";
import { InfoBars } from "Components/InfoBars";
import { Item as DonutChartItem, DonutChart } from "Components/DonutChart";
import { RefreshIcon } from "Components/RefreshIcon";
import { Accordion } from "Components/Accordion";
import { Spinner } from "melodies-source/Spinner";
import { H2 } from "melodies-source/Text";
import { Card, Divider } from "Components";
import { useArtistEventContext } from "contexts/ArtistEventContext";
import { EmailStats } from "./EmailStats";
import { StatsItem } from "./StatsItem";
import { useMobileMediaQuery } from "hooks";
import {
  PreviewRequest,
  PreviewResponse,
  getPostShowEmailPreviewData,
} from "@max/common/dist/email";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useDocumentData } from "react-firebase-hooks/firestore";
import {
  DocumentReference,
  doc,
  getDoc,
  getFirestore,
} from "firebase/firestore";
import { BuilderConfig, BuilderConfigRoot } from "@max/common";
import { EmailPreview } from "./EmailPreview";

const getEmailPreviewHtml = httpsCallable<
  PreviewRequest<"setlive:postshow">,
  PreviewResponse
>(getFunctions(), "communicationv2-preview");

export const PostShowEmail: React.FC = () => {
  const {
    eventId,
    emailAnalytics,
    emailAnalyticsLoading,
    emailAnalyticsError,
  } = useArtistEventContext();
  const [builder] = useDocumentData(
    doc(
      getFirestore(),
      `set_fresh_builder_events/${eventId}`,
    ) as DocumentReference<BuilderConfigRoot>,
  );
  const [emailHtml, setEmailHtml] = useState<Nullable<string>>(null);
  const [emailHtmlLoading, setEmailHtmlLoading] = useState(false);

  const isMobile = useMobileMediaQuery();
  const [previewOpen, setPreviewOpen] = useState(false);

  const togglePreviewOpen = () => setPreviewOpen((open) => !open);

  if (!emailAnalytics) return null;

  interface ClickDetails {
    name: string;
    count: number;
  }

  interface ClickCategory {
    name: string;
    total: number;
    detail: ClickDetails[];
  }

  const socialClicks: ClickCategory = {
    name: "Social",
    total: 0,
    detail: [],
  };

  const streamingClicks: ClickCategory = {
    name: "Streaming",
    total: 0,
    detail: [],
  };

  const surveyClicks: ClickCategory = {
    name: "Post-Show Survey",
    total: 0,
    detail: [],
  };

  const merchClicks: ClickCategory = {
    name: "Merch",
    total: 0,
    detail: [],
  };

  const unsubClicks: ClickCategory = {
    name: "Unsubscribe",
    total: 0,
    detail: [],
  };

  const mainCtaClicks: ClickCategory = {
    name: "Main CTA",
    total: 0,
    detail: [],
  };

  const labels = {
    facebook: "Facebook",
    instagram: "Instagram",
    tiktok: "TikTok",
    twitter: "Twitter (X)",
    amazon: "Amazon Music",
    apple: "Apple Music",
    deezer: "Deezer",
    spotify: "Spotify",
    youtube: "YouTube",
  };

  if (emailAnalytics?.clicked) {
    const { clicked } = emailAnalytics;
    for (const [k, v] of Object.entries(clicked)) {
      if (k.startsWith("social")) {
        socialClicks.total += v;
        socialClicks.detail.push({ name: labels[k.split(".")[1]], count: v });
      } else if (k.startsWith("streaming")) {
        streamingClicks.total += v;
        streamingClicks.detail.push({
          name: labels[k.split(".")[1]],
          count: v,
        });
      } else if (k === "setfan_postshow") {
        surveyClicks.total += v;
        surveyClicks.detail.push({ name: "Post-Show Survey", count: v });
      } else if (k === "merch") {
        merchClicks.total += v;
        merchClicks.detail.push({ name: k.split(".")[1], count: v });
      } else if (k === "unsubscribe") {
        unsubClicks.total += v;
        unsubClicks.detail.push({ name: "Unsubscribe", count: v });
      } else if (k === "mainCta") {
        mainCtaClicks.total += v;
        mainCtaClicks.detail.push({ name: "Main CTA", count: v });
      }
    }
  }

  const clickCategories: ClickCategory[] = [
    socialClicks,
    streamingClicks,
    surveyClicks,
    merchClicks,
    mainCtaClicks,
    unsubClicks,
  ].filter((cat) => cat.total > 0);

  const categoriesTotal = clickCategories?.reduce(
    (total, item) => total + item.total,
    0,
  );

  const data: DonutChartItem[] = clickCategories?.map(({ name, total }) => ({
    id: name,
    label: name,
    value: Math.round((total / categoriesTotal) * 100),
  }));

  const categories = clickCategories?.map(({ name, detail }) => ({
    header: `Clicks: ${name}`,
    body: (
      <InfoBars
        data={detail.map(({ name, count }) => ({
          label: name,
          value: count,
        }))}
      />
    ),
  }));

  // `openedBy` tracks the number of unique users who opened the email, whereas `opened` logs the total number of opens; `opened` is maintained for legacy data compatibility.
  let opened = emailAnalytics?.opened || 0;
  if (emailAnalytics?.openedBy) {
    opened = Object.keys(emailAnalytics.openedBy).length;
  }

  const delivered = emailAnalytics?.delivered || 0;
  const openRate = delivered > 0 ? opened / delivered : 0;
  const openRatePercent = Math.round(openRate * 100);
  const totalClicks = clickCategories.reduce(
    (total, item) => total + item.total,
    0,
  );

  const emailStats = {
    openRate: openRatePercent,
    delivered: delivered,
    opened: opened,
    clicks: totalClicks,
  };

  const getEmailPreview = async () => {
    try {
      setEmailHtmlLoading(true);
      const builderConfig = await getDoc(
        doc(
          getFirestore(),
          `set_fresh_builder_events/${eventId}/versions/${builder?.publishedId}`,
        ) as DocumentReference<BuilderConfig>,
      );

      const data = getPostShowEmailPreviewData(
        builderConfig.data(),
        eventId,
        builder.publishedId,
        process.env.REACT_APP_ASSETS_ROCK_PATH,
      );

      const response = await getEmailPreviewHtml({
        templateId: "setlive:postshow",
        data,
      });

      togglePreviewOpen();
      setEmailHtml(response.data.html);
    } catch (error) {
      console.error(error);
    } finally {
      setEmailHtmlLoading(false);
    }
  };

  return (
    <>
      <StyledCard
        isElevated
        action={{
          text: "View Email",
          onClick: getEmailPreview,
          loading: emailHtmlLoading,
        }}
      >
        <H2>Post Show Email</H2>
        {emailAnalyticsLoading ? (
          <StyledSpinner />
        ) : emailAnalyticsError ? (
          <StyledRefreshIcon />
        ) : (
          <>
            <EmailStats stats={emailStats} />
            {categoriesTotal > 1 && (
              <>
                <StyledDivider />
                <H2>Post Show Email Clicks</H2>
                <DonutChartContainer>
                  <StatsItem value={emailStats.clicks} label="Total Clicks" />
                  <DonutChart data={data} withVerticalLayout={isMobile} />
                </DonutChartContainer>
                <Accordion open={true} sections={categories} twoCols />
              </>
            )}
          </>
        )}
      </StyledCard>
      <EmailPreview
        open={previewOpen}
        setOpen={setPreviewOpen}
        onClose={togglePreviewOpen}
        html={emailHtml}
      />
    </>
  );
};

const StyledCard = styled(Card)`
  ${H2} {
    text-align: center;
  }
`;
const StyledRefreshIcon = styled(RefreshIcon)`
  margin-top: 5px;
`;

const StyledSpinner = styled(Spinner)`
  margin: 20px 0 15px;
  transform: scale(0.7);
`;

const DonutChartContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 35px;
  margin: 50px 20px 60px;

  ${({ theme }) => theme.media.maxTablet} {
    flex-direction: column;
    gap: 30px;
    margin: 20px 20px 40px;
  }
`;

const StyledDivider = styled(Divider)`
  margin: 50px 0;

  ${({ theme }) => theme.media.mobile} {
    margin: 25px 0;
  }
`;
