import { ReactComponent as TemplateIcon } from "assets/svg/erasGenres.svg";
import { SurveyTemplate } from "../../types";

export const erasAndGenres: SurveyTemplate = {
  icon: <TemplateIcon />,
  title: "Eras & Genres",
  description:
    "Generate a report on the types of music your fans are listening to.",
  templateId: "erasAndGenres",
  template: () => ({
    fields: {
      description: {
        content: {
          en: "Eras & Genres",
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "What genres are you into?",
        },
        type: "MultipleSelectQuestion",
        options: [
          { label: { en: "Pop" } },
          { label: { en: "Hip-Hop" } },
          { label: { en: "Rock" } },
          { label: { en: "Electronic" } },
          { label: { en: "Classical" } },
          { label: { en: "Jazz" } },
          { label: { en: "R&B/Soul" } },
          { label: { en: "Country" } },
          { label: { en: "Latin" } },
          { label: { en: "K-Pop" } },
        ],
      },
      {
        id: "question_2",
        label: {
          en: "Which musical eras resonate with you?",
        },
        type: "MultipleSelectQuestion",
        options: [
          { label: { en: "70's" } },
          { label: { en: "80's" } },
          { label: { en: "90's" } },
          { label: { en: "2000's" } },
          { label: { en: "2010's" } },
          { label: { en: "2020's" } },
        ],
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes" ? "pp-marketing-776a95" : "pp-marketing-8014c4",
  },
};
