import { ReactComponent as IconNewMusic } from "assets/svg/new-music.svg";
import { SurveyTemplate } from "../../types";

export const favNewTrack: SurveyTemplate = {
  icon: <IconNewMusic />,
  title: "Favorite New Track",
  description:
    "Generate plays by asking fans to vote for their favorite song from the newest release.",
  templateId: "favNewTrack",
  template: (v, formState) => ({
    fields: {
      description: {
        content: {
          en: formState.title,
        },
      },
    },
    surveyQuestions: [
      {
        id: "question_1",
        label: {
          en: "Which track are you most excited to hear?",
        },
        type: "SingleSelectQuestion",
        options: [
          {
            label: {
              en: "Track 1",
            },
          },
          {
            label: {
              en: "Track 2",
            },
          },
          {
            label: {
              en: "Track 3",
            },
          },
        ],
      },
      {
        id: "question_2",
        label: {
          en: "What sparked your interest in this track?",
        },
        type: "TextQuestion",
      },
    ],
  }),
  prompt: {
    promptId: (state) =>
      state.hasSweeps === "Yes"
        ? "pp-sweeps---f-e4cfb4"
        : "pp-copy-of-fa-e43575",
    fields: [
      {
        name: "format",
        type: "radio",
        label: "What format is this release?",
        options: ["Single", "EP", "Album"],
        getValidation: (fields) =>
          fields.format ? false : "You must select an option.",
      },
      {
        name: "title",
        type: "text",
        label: "What's the title of the release?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.title ? false : "This field cannot be left blank",
      },
      {
        name: "tracks",
        type: "textarea",
        label: "Paste in the tracklist below",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.tracks ? false : "This field cannot be left blank",
      },
      {
        name: "releaseDate",
        type: "text",
        label: "When will it be available (or is it already available)?",
        placeholder: "Enter your answer...",
        getValidation: (fields) =>
          fields.releaseDate ? false : "This field cannot be left blank",
      },
    ],
  },
};
