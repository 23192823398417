import { Modal, ModalContainer } from "melodies-source/Modal";
import { ReactComponent as SvgLinkAlt } from "assets/svg/link-alt.svg";
import { Body1, H2, H4 } from "melodies-source/Text/index";
import styled, { css } from "styled-components";
import { Button, InnerLeft } from "melodies-source/Button";
import { useState } from "react";
import { SvgCheck } from "melodies-source/Svgs/Check";
import { SvgCopy } from "melodies-source/Svgs/Copy";
import toast from "react-hot-toast";

export const GetLinkModal = ({
  isOpen,
  onClose,
  surveyId,
  featuredUrl,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
  surveyId: string;
  featuredUrl?: string;
}) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const [linkFeaturedCopied, setLinkFeaturedCopied] = useState(false);
  const surveyLink = `${process.env.REACT_APP_SET_FAN_URL}/${surveyId}`;

  const onCopyLink = () => {
    navigator.clipboard.writeText(surveyLink);
    setLinkCopied(true);
    toast.success("Copied to clipboard");
  };

  const onCopyFeaturedLink = () => {
    navigator.clipboard.writeText(featuredUrl);
    setLinkFeaturedCopied(true);
    toast.success("Copied to clipboard");
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={() => {
        setLinkCopied(false);
        setLinkFeaturedCopied(false);
        onClose();
      }}
      withCloseIcon={false}
      header={
        <LinkContainer>
          <SvgLinkAlt color="#1DAB98" />
        </LinkContainer>
      }
    >
      <ModalBody>
        <H2 style={{ textAlign: "center" }}>
          Share the link for this SET.Fan experience
        </H2>
        <CopyLinkContainer>
          <DisabledInput>
            <SurveyLinkText>{surveyLink}</SurveyLinkText>
          </DisabledInput>
          <CopyLinkButton
            disabled={linkCopied}
            onClick={onCopyLink}
            leftIcon={linkCopied ? <SvgCheck /> : <SvgCopy />}
          >
            {linkCopied ? "Copied" : "Copy Link"}
          </CopyLinkButton>
        </CopyLinkContainer>
        {featuredUrl && (
          <>
            <Body1 style={{ textAlign: "center" }}>OR</Body1>
            <CopyLinkContainer>
              <DisabledInput style={{ width: "100%" }}>
                <SurveyLinkText>{featuredUrl}</SurveyLinkText>
              </DisabledInput>
              <CopyLinkButton
                disabled={linkFeaturedCopied}
                onClick={onCopyFeaturedLink}
                leftIcon={linkFeaturedCopied ? <SvgCheck /> : <SvgCopy />}
              >
                {linkFeaturedCopied ? "Copied" : "Copy Link"}
              </CopyLinkButton>
            </CopyLinkContainer>
          </>
        )}
        <CloseButton
          onClick={() => {
            setLinkCopied(false);
            setLinkFeaturedCopied(false);
            onClose();
          }}
        >
          Close
        </CloseButton>
      </ModalBody>
    </StyledModal>
  );
};

export const GetFeaturedLinkModal = ({
  isOpen,
  onClose,
  link,
  title,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
  link: string;
  title: string;
}) => {
  const [linkCopied, setLinkCopied] = useState(false);
  const onCopyLink = () => {
    navigator.clipboard.writeText(link);
    setLinkCopied(true);
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onClose={() => {
        setLinkCopied(false);
        onClose();
      }}
      withCloseIcon={false}
      header={
        <LinkContainer>
          <SvgLinkAlt />
        </LinkContainer>
      }
    >
      <ModalBody>
        <H4 style={{ textAlign: "center" }}>Featured Survey</H4>
        <H2 style={{ width: 312, textAlign: "center" }}>{title}</H2>
        <CopyLinkContainer>
          <DisabledInput style={{ width: "100%" }}>
            <SurveyLinkText>{link}</SurveyLinkText>
          </DisabledInput>
          <CopyLinkButton
            disabled={linkCopied}
            onClick={onCopyLink}
            leftIcon={linkCopied ? <SvgCheck /> : <SvgCopy />}
          >
            {linkCopied ? "Copied" : "Copy Link"}
          </CopyLinkButton>
        </CopyLinkContainer>
        <CloseButton
          onClick={() => {
            setLinkCopied(false);
            onClose();
          }}
        >
          Close
        </CloseButton>
      </ModalBody>
    </StyledModal>
  );
};

export const ConfirmFeatureModal = ({
  isOpen,
  onClose,
  onConfirm,
  link,
  currentTitle,
}: {
  isOpen: boolean;
  onClose: VoidFunction;
  onConfirm: VoidFunction;
  link: string;
  currentTitle?: string;
}) => {
  return (
    <StyledModal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      withCloseIcon={false}
      header={`Feature this survey on ${link}?`}
    >
      <ModalBody>
        {currentTitle ? (
          <>
            <Body1 style={{ marginBottom: "12px" }}>
              {link} is currently point to your <b>{currentTitle}</b> survey.
            </Body1>
            <Body1>
              Confirming will point {link} to this survey instead. The previous
              survey will still be accessible via its unique link.
            </Body1>
          </>
        ) : (
          <Body1>{link} is not currently pointing to any survey.</Body1>
        )}
        <div
          style={{
            display: "flex",
            marginTop: "20px",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          <Button variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </ModalBody>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  ${ModalContainer} {
    align-items: center;
  }
  ${H2} {
    width: 80%;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    ${H2} {
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

const CopyLinkButton = styled(Button)<{ disabled: boolean }>`
  padding: 10px 14px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  min-width: 130px;
  &,
  &:focus,
  &:hover {
    box-shadow: none;
  }

  ${(p) =>
    p.disabled &&
    css`
      padding: 10px 16px 10px 12px;
      background-color: #00bd68 !important;
      color: #fff !important;
    `};

  ${({ theme }) => theme.mediaQueries.mobile} {
    min-width: auto;
    padding: 10px 13px;
    height: 44px !important;
    text-indent: -1000px;
    ${InnerLeft} {
      margin: 0;
    }
  }
`;

const CopyLinkContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 24px;

  ${({ theme }) => theme.media.mobile} {
    margin-top: 20px;
    margin-bottom: 16px;

    ${CopyLinkButton} {
      width: auto;
    }
  }
`;

const CloseButton = styled.div`
  display: flex;
  width: 114px;
  height: 56px;
  flex-direction: column;
  justify-content: center;
  color: #1b0076;
  cursor: pointer;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
`;

const DisabledInput = styled.div`
  width: 100%;
  height: 44px;
  border: 1px solid #ccc;
  background: #fff;
  padding: 11px 12px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-radius: 6px;
  border-radius: 6px 0 0 6px;
`;

const SurveyLinkText = styled.span`
  color: black;
  font-family: Arial;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 12px;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-top: 8px;
  }
`;

const LinkContainer = styled.div`
  width: 72px;
  height: 72px;
  background-color: #f2f5f7;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #7666ad;
  svg {
    width: 32px;
    height: 32px;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 56px;
    height: 56px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;
